import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { listHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import { useDispatch, useSelector } from "react-redux"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { HmsDeleteModal, SingleFieldCRUModal } from "pages/HMS/common/hms-crud-modals"
import { isEmpty } from "lodash"

const TestCategories = (props) => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const { loading, options, hmsList, apiKey, error } = useSelector(state => state.Hms)
  const [modal, setModal] = useState(false)
  const [rudFlag, setRUDFlag] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [category, setCategory] = useState({})
  const [categories, setCategories] = useState({})

  const columns = [{
    dataField: "test-categories",
    text: "Test categories",
    sort: false,
    formatter: (cellContent, row) => (
      <Link to="#" onClick={() => handleEdit(row)}>{row.name}</Link>
    )
  }, {
    dataField: "action",
    isDummyField: true,
    text: "",
    formatter: (cellContent, row) => (
      <UncontrolledDropdown className="ms-auto">
        <DropdownToggle
          className="text-muted font-size-16"
          color="white"
        >
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu">
          <Link className="dropdown-item" to="#" onClick={() => handleEdit(row)}>
            Modify
          </Link>
          <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
            Remove
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
  }
  ]

  useEffect(() => {
    if (apiKey === "category_list") {
      setCategories(hmsList)
    }
    if (apiKey === "category_created") {
      setCategories(hmsList)
    }
    if (apiKey === "category_updated") {
      setCategories(hmsList)
    }
    if (apiKey === "category_deleted") {
      setCategories(hmsList)
      toggleDelete()
      toastr.success(`Test category ${category?.name} is deleted`)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/categories/?options=table, search"))
    dispatch(listHms("/hms/diagnosis/categories/", null, "category_list"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  const handleEdit = (category_object) => {
    setCategory(category_object)
    setRUDFlag(0)
    toggle(false)
  }

  const handleDelete = (category_object) => {
    setCategory(category_object)
    toggleDelete(false)
  }

  const handleCreate = () => {
    setCategory({})
    setRUDFlag(1)
    toggle(false)
  }

  const toggle = (def = modal) => {
    setModal(!def)
  }

  const toggleDelete = (state = true) => {
    setDeleteModal(!state)
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/diagnosis/categories/', decodeURIComponent(urlParams).replace("?", ""), "category_list"))
      setCategories({})
    } else {
      dispatch(listHms('/hms/diagnosis/categories/', null, "category_list"))
      setCategories({})
    }
  }

  const createButton = () => {
    return (
      <div className="text-sm-start">
        <Button
          type="button"
          color="primary"
          className="me-2 btn-sm"
          onClick={handleCreate}
        >
          <i className="mdi mdi-plus me-1" />
          Create
        </Button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {error ? (
            <p className="text-center text-danger">{error?.detail}</p>
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  {(!isEmpty(columns)) && (
                    <GenericTable
                      urlParams={urlParams}
                      search={options.search}
                      columns={columns}
                      keyField={"id"}
                      noDataIndication={"No test category created"}
                      data={categories}
                      loading={loading}
                      apiTrigger={apiTrigger}
                      defaultPageSize={options.page_size}
                      createButton={createButton}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          )}

          <HmsDeleteModal
            body={<>
              <ul>
                <li>If Test category is removed. You cannot get it back</li>
                <li>If any data linked to this record will be vanished</li>
              </ul>
            </>}
            loading={loading}
            error={error}
            toggleDelete={toggleDelete}
            dispatchOperation={deleteHms("/hms/diagnosis/categories/", category.id, "category_deleted")}
            deleteModal={deleteModal}
          />

          <SingleFieldCRUModal
            modalLabel={"Test Category"}
            modal={modal}
            toggle={toggle}
            rudFlag={rudFlag}
            data={category}
            apiStartKey={"category"}
            apiEndpoint={"/hms/diagnosis/categories/"}
            prepend={true}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TestCategories;