export const menu = [
  {
    name: "Dashboard",
    path: "/hms",
    children: [
      { id: "dashboard_dashboard", name: "Dashboard", path: "/hms" },
    ],
  },
  {
    name: "Patient Management",
    path: "",
    children: [
      { id: "patient_management_book_appointment", name: "Book Appointment", path: "/hms/patient/appointment/book" },
      { id: "patient_management_appointments", name: "Appointments", path: "/hms/patient/appointments" },
      { id: "patient_management_patient_registration", name: "Patient Registration", path: "/hms/patient/register" },
      { id: "patient_management_patients", name: "Patients", path: "/hms/patients" },
      { id: "patient_management_op_registration", name: "OP Registration", path: "/hms/patient/op/register" },
      { id: "patient_management_out_patients", name: "Out-Patients", path: "/hms/patient/ops" },
      { id: "patient_management_inpatient_admission", name: "Inpatient Admission", path: "/hms/patient/inpatient/register" },
      { id: "patient_management_in_patients", name: "In-Patients", path: "/hms/patient/inpatients" },
      { id: "patient_management_discharge_billing", name: "Inpatient Discharge Billing", path: "/hms/patient/inpatient/discharge/billing" },
      { id: "patient_management_treatment", name: "Treatment", path: "/hms/patient/treatment" },
    ],
  },
  {
    name: "Staff Management",
    path: "",
    children: [
      { id: "staff_management_doctors", name: "Doctors", path: "/hms/staff/doctors" },
      { id: "staff_management_add_doctor", name: "Add Doctor", path: "/hms/staff/doctors/add" },
      { id: "staff_management_staff", name: "Staff", path: "/hms/staff/staff" },
    ],
  },
  {
    name: "Pharmacy",
    path: "",
    children: [
      { id: "pharmacy_home", name: "Home", path: "/hms/pharmacy/home" },
      { id: "pharmacy_medicines", name: "Medicines", path: "/hms/pharmacy/medicines" },
      { id: "pharmacy_stock", name: "Stock", path: "/hms/pharmacy/stock" },
      { id: "pharmacy_orders", name: "Orders", path: "/hms/pharmacy/order-medicine" },
      { id: "pharmacy_suppliers", name: "Suppliers", path: "/hms/pharmacy/supplier" },
      { id: "pharmacy_medicine_sale", name: "Medicine Sale", path: "/hms/pharmacy/medicine-sale" },
      { id: "pharmacy_sold_out_medicines", name: "Sold Out Medicines", path: "/hms/pharmacy/sold-out-medicines" },
    ],
  },
  {
    name: "Diagnosis",
    path: "",
    children: [
      { id: "diagnosis_home", name: "Home", path: "/hms/diagnosis/home" },
      { id: "diagnosis_book_test", name: "Book Test", path: "/hms/diagnosis/test/book" },
      { id: "diagnosis_reports", name: "Reports", path: "/hms/diagnosis/test/reports" },
      { id: "diagnosis_tests", name: "Tests", path: "/hms/diagnosis/tests" },
      { id: "diagnosis_test_categories", name: "Test Categories", path: "/hms/diagnosis/tests/categories" },
      { id: "diagnosis_test_group", name: "Test Group", path: "/hms/diagnosis/test/test-groups" },
    ],
  },
  {
    name: "Billing and Finance",
    path: "/hms/billing/",
    children: [
      { id: "billing_finance_transactions", name: "Transactions", path: "/hms/billing/transactions" },
    ],
  },
  {
    name: "Settings",
    path: "/hms/settings",
    children: [
      { id: "settings_administration", name: "Administration", path: "/hms/settings/administration" },
      { id: "settings_reports", name: "Reports", path: "/hms/settings/reports" },
    ],
  },
];
