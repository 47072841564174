import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Button, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import FlashAlert from "components/Common/flash-message"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { isEmpty } from "lodash"
import { SupplierCRUDModal } from "../pharmacy-pharm-crud-modals"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { getLocalStorageItem, updateURLParams } from "../../common/common"

const Supplier = props => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading, options, hmsList, apiKey, error } = useSelector(state => state.Hms)
  const [modal, setModal] = useState(false)
  const [rudFlag, setRUDFlag] = useState(null)
  const [supplier, setSupplier] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteEnabled, setDeleteEnabled] = useState(false)
  const [supplierData, setSupplierData] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    if (["supplier_list", "supplier_created", "supplier_updated"].includes(apiKey)) {
      setSupplierData(hmsList)
    }
    if (apiKey === "supplier_deleted") {
      setSupplierData(hmsList)
      toggleDelete()
      toastr.success("Supplier is deleted")
    }
    if (apiKey === "supplier-refresh") setSupplierData(hmsList)
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy/suppliers/?options=table, search, formschema, daterange, filters", "suppliers_options", false, true))
    if (urlParams.length > 1) {
      dispatch(listHms("/hms/pharmacy/suppliers/", decodeURIComponent(urlParams).replace("?", ""), "supplier_list"))
    } else {
      const savedFilters = getLocalStorageItem('filters')?.supplier
      updateURLParams(history, savedFilters)
      dispatch(listHms("/hms/pharmacy/suppliers/", savedFilters, "supplier_list"))
    }
    return () => dispatch(resetHmsState())
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(options)) {
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link to={`/hms/pharmacy/supplier/${row?.id}/detail`} title={row?.uid}>
                  {row.name}
                </Link>
              )
            }
          } else if (item.dataField === "email") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <div>{row.email || "---"}</div>
              )
            }
          } else if (item.dataField === "contact_person_2") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <div>
                  <Link className="text-muted" to="#" title={row?.alternate_mobile}>
                    {row.contact_person_2 || "---"}
                  </Link>
                </div>
              )
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "Action",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle
                className="text-muted font-size-16"
                color="white"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link className="dropdown-item" to="#" onClick={() => handleEdit(row)}>
                  Modify
                </Link>
                <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [options])

  const handleEdit = supplier_object => {
    setSupplier(supplier_object)
    setRUDFlag(0)
    toggle(false)
  }

  const handleDelete = supplier_object => {
    setSupplier(supplier_object)
    toggleDelete(false)
  }

  const handleCreate = () => {
    setSupplier({})
    setRUDFlag(1)
    toggle(false)
  }

  function toggle(def = modal) {
    setModal(!def)
  }

  function toggleDelete(state = true) {
    setDeleteModal(!state)
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/pharmacy/suppliers/', decodeURIComponent(urlParams).replace("?", ""), "supplier-refresh"))
      setSupplierData({})
    } else {
      dispatch(listHms('/hms/pharmacy/suppliers/', null, "supplier-refresh"))
      setSupplierData({})
    }
  }

  const createButton = () => {
    return (
      <div className="text-sm-start">
        <Button
          type="button"
          color="primary"
          className="me-2 btn-sm"
          onClick={handleCreate}
        >
          +&nbsp;Create
        </Button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Supplier | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {error ? (
            <p className="text-center text-danger">{error?.detail}</p>
          ) : (
            <Row>
              <Col xs="12">
                {(!isEmpty(columns) && (
                  <GenericTable
                    moduleName="supplier"
                    urlParams={urlParams}
                    search={options?.search}
                    daterange={options?.daterange}
                    filters={options?.filters}
                    columns={columns}
                    keyField={"id"}
                    noDataIndication={"No supplier created"}
                    data={supplierData}
                    loading={loading}
                    apiTrigger={apiTrigger}
                    defaultPageSize={options?.page_size}
                    createButton={createButton}
                  />
                ))}
                <Modal isOpen={deleteModal} toggle={toggleDelete} backdrop="static">
                  <ModalHeader toggle={toggleDelete} tag="h4">
                    ⚠️ Warning: Before deleting supplier, Make sure the below things
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col className="col-12">
                        {error &&
                          <FlashAlert color="danger" delay="10000" className="text-center">{error?.detail}</FlashAlert>
                        }
                        <ul>
                          <li>If supplier is removed. You cannot get it back</li>
                          <li>If any data linked to this record will be vanished</li>
                        </ul>
                        <p>Type <i><b>delete permanently</b></i> to proceed delete operation</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12">
                        <div className="mb-3">
                          <input
                            name="confirmation-text"
                            type="text"
                            className="form-control"
                            required={false}
                            onChange={(e) => {
                              e.target.value === "delete permanently" ? setDeleteEnabled(true) : setDeleteEnabled(false)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="float-end">
                      <Col>
                        <button type="button" className="btn btn-outline-danger btn-sm me-2" disabled={!deleteEnabled || loading}
                          onClick={() => dispatch(deleteHms("/hms/pharmacy/suppliers/", supplier?.id, "supplier_deleted"))}>
                          Yes
                        </button>
                        <button type="button" className="btn btn-light btn-sm"
                          onClick={toggleDelete}>
                          No
                        </button>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <SupplierCRUDModal
        modal={modal}
        toggle={toggle}
        rudFlag={rudFlag}
        data={supplier}
        apiStartKey="supplier"
      />
    </React.Fragment>
  )
}

export default Supplier
