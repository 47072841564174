import React, { useRef, useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Badge, Button, ButtonGroup, Card, CardBody, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, Row, Spinner, Table } from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { resetHmsState, listHms } from "store/actions"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import ReactApexChart from "react-apexcharts"
import DefaultIfNone from "components/Common/default"
import { formatDateTime, genderVerboseFetch } from "components/Common/common";
import { isEmpty } from "lodash";
import { Loader } from "../../common/common";

const StatCard = ({ title, value, path, defaultValue = "None", defaultCount = "None" }) => {
  return (
    <Col xl={3} lg={6} md={6} sm={6} xs={12}>
      <Card className="blog-stats-wid" style={{ backgroundColor: "#fff", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", transition: "0.3s", ":hover": { transform: "scale(1.05)" } }}>
        <CardBody>
          <Link to={path}>
            <div className="d-flex flex-wrap">
              <div className="me-3">
                <p className="text-muted mb-2">{title}</p>
                <h6 className="mb-0" style={{ color: "#000", fontSize: "10px" }}>
                  {typeof value === 'object' && value !== null ?
                    <>
                      <DefaultIfNone format={true} value={value?.selected_count} default_value={defaultCount} />/<DefaultIfNone format={true} value={value?.total} default_value={defaultValue} />
                    </>
                    :
                    <DefaultIfNone format={true} value={value} default_value={defaultValue} />
                  }
                </h6>
              </div>
            </div>
          </Link>
        </CardBody>
      </Card>
    </Col>
  )
};

const HMS = (props) => {
  const dispatch = useDispatch()
  const dateRangeRef = useRef()
  const [periodDropdown, setPeriodDropdown] = useState(false);
  const [period, setPeriod] = useState(null)
  const [dashboardData, setDashboardData] = useState({})
  const [defaultDate, setDefaultDate] = useState([])
  const [selectedDataset, setSelectedDataset] = useState('op_dataset');

  const { loading, apiKey, error, hmsList } = useSelector((state) => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    apiKey: state.Hms.apiKey,
    hmsList: state.Hms.hmsList,
  }))

  useEffect(() => {
    dispatch(listHms("/hms/dashboard/", null, "hms_dashboard"))
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])

  useEffect(() => {
    if (apiKey === "hms_dashboard" || apiKey === "hms_dashboard_date" || apiKey === "hms_dashboard_period") {
      setDashboardData(hmsList)
    }
  }, [apiKey])

  function getURLParam(urlParams) {
    let urlParam = []
    Object.entries(urlParams).map(([k, v]) => {
      if (k !== 'page' || k !== undefined || k !== null || k !== '') {
        if (typeof (v) == 'object') {
          urlParam.push(`${k}=${v[0]}&${k}_end=${v[1]}`)
        } else {
          urlParam.push(`${k}=${v}`)
        }
      }
    })
    return urlParam.join('&')
  }

  const onChangeDateRange = (dateRange) => {
    if (dateRange[0] && dateRange[1]) {
      const start = formatDateTime(dateRange[0], "YYYY-MM-DD");
      const end = formatDateTime(dateRange[1], "YYYY-MM-DD");
      let queryParam = getURLParam({ start, end });
      dispatch(listHms("/hms/dashboard/", queryParam, "hms_dashboard_date"));
      setDefaultDate([start, end]);
    }
    setPeriod(null);
  };

  const onPeriodChange = (period) => {
    let now = moment.utc();
    let start, end;

    switch (period) {
      case "1 Month":
        start = now.clone().subtract(1, "months").startOf("day").format("YYYY-MM-DD");
        end = now.clone().endOf("day").format("YYYY-MM-DD");
        break;
      case "3 Months":
        start = now.clone().subtract(3, "months").startOf("day").format("YYYY-MM-DD");
        end = now.clone().endOf("day").format("YYYY-MM-DD");
        break;
      case "6 Months":
        start = now.clone().subtract(6, "months").startOf("day").format("YYYY-MM-DD");
        end = now.clone().endOf("day").format("YYYY-MM-DD");
        break;
    }

    const queryParam = getURLParam({ start: start, end: end })
    dispatch(listHms("/hms/dashboard/", queryParam, "hms_dashboard_period"))
    dateRangeRef?.current?.flatpickr?.clear();
    setPeriod(period);
    setDefaultDate([start, end]);
  };

  const refreshPage = () => {
    const queryParam = defaultDate[0] && defaultDate[1] ? getURLParam({ start: defaultDate[0], end: defaultDate[1] }) : null
    dispatch(listHms("/hms/dashboard/", queryParam, "hms_dashboard"))
  };

  const clearFilters = () => {
    setPeriod(null);
    dateRangeRef?.current?.flatpickr?.clear();
    setDefaultDate([]);
    dispatch(listHms("/hms/dashboard/", null, "hms_dashboard"))
  }

  const formatData = (dataArray, name = "") => {
    const formattedData = dataArray?.map(item => {
      const formattedDate = formatDateTime(item.x).valueOf();
      return [formattedDate, item.y];
    });

    return [
      {
        name: name,
        data: formattedData || [],
      }
    ];
  };

  const sortGenderOrder = (data) => {
    const customOrder = ["MALE", "FEML", "NSAY"];
    return data?.sort((a, b) => customOrder.indexOf(a.gender) - customOrder.indexOf(b.gender));
  };

  const getPieChart = (genderCountData) => {
    const series = genderCountData?.map((entry) => entry.count) || [];
    const labels = genderCountData?.map((entry) => genderVerboseFetch(entry?.gender)) || [];
    const options = {
      labels,
      colors: ["#556ee6", "#34c38f", "#f46a6a"],
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: true,
        fontSize: "14px",
        offsetX: 0,
        offsetY: 14,
      },
      responsive: [
        {
          breakpoint: 1025,
          options: {
            chart: {
              height: 150,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
          },
        },
      },
    };

    return { series, options };
  };

  const countRevenueOptions = {
    chart: {
      height: 290,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '14%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (val, timestamp) => formatDateTime(timestamp, 'MMM DD, YYYY'),
      },
    },
    yaxis: {
      labels: {
        formatter: val => val && ~~val,
      },
    },
    colors: ['#556ee6', '#f1b44c'],
  };

  const donutOptions = {
    labels: dashboardData?.revenue_by_module?.map(item => item?.module) || [],
    colors: ["#556ee6", "#f1b44c", "#34c38f", "#f46a6a", "#50a5f1", "#ff9f40", "#4caf50", "#e91e63", "#ffd700", "#008080"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const pieOptions = {
    chart: {
      toolbar: false,
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: false,
    },
    color: ["#556ee6", "#ff7b00"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (val, timestamp) => formatDateTime(timestamp, 'MMM DD, YYYY'),
      },
    },
    yaxis: {
      labels: {
        formatter: val => val && ~~val,
      },
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>HMS | Dashboard</title>
        </MetaTags>
        <Container fluid>
          {error ? (
            <p className="text-center text-danger">{error.detail}</p>
          ) : loading && apiKey === "list_hms_dashboard" ? (
            <Loader />
          ) : (
            <>
              <div className="mb-4 d-flex flex-wrap justify-content-end align-items-center">
                <ButtonGroup>
                  <Button color="secondary" size="sm" outline onClick={refreshPage}>
                    <i className="mdi mdi-refresh" />
                  </Button>
                  <Button color="secondary" className="btn btn-sm" outline onClick={clearFilters}>
                    <i className="dripicons-cross" />
                  </Button>
                </ButtonGroup>
                <div className="d-flex align-items-center ms-2">
                  <InputGroup>
                    <Flatpickr
                      className="form-control form-control-sm"
                      placeholder="Select a date range"
                      options={{ mode: "range", dateFormat: "Y-m-d" }}
                      ref={dateRangeRef}
                      onClose={onChangeDateRange}
                      value={!isEmpty(defaultDate) ? defaultDate : []}
                    />
                  </InputGroup>
                  <Dropdown isOpen={periodDropdown} toggle={() => setPeriodDropdown(!periodDropdown)} direction="down" className="ms-2">
                    <DropdownToggle caret color="primary" size="sm">
                      {period || "Period"} <i className="fa fa-caret-down ms-1" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => onPeriodChange("1 Month")} disabled={period === "1 Months"}>
                        Last Month
                      </DropdownItem>
                      <DropdownItem onClick={() => onPeriodChange("3 Months")} disabled={period === "3 Months"}>
                        Last 3 Months
                      </DropdownItem>
                      <DropdownItem onClick={() => onPeriodChange("6 Months")} disabled={period === "6 Months"}>
                        Last 6 Months
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row className="align-items-start">
                        <StatCard title="Patients" value={dashboardData?.patients} dataKey="totalFollowups" path={"/hms/patients"} />
                        <StatCard title="Outpatients" value={dashboardData.outpatients} dataKey="qualified" path={"/hms/patient/ops"} />
                        <StatCard title="Inpatients" value={dashboardData.inpatients} dataKey="todayQualified" path={"/hms/patient/inpatients"} />
                        <StatCard title="Appointments" value={dashboardData.appointments} dataKey="todayFollowups" path={"/hms/patient/appointments"} />
                      </Row>
                      <Row className="align-items-start">
                        <StatCard title="Doctors" value={dashboardData?.doctors} dataKey="todayReminders" path={"/hms/staff/doctors"} />
                        <StatCard title="Staff" value={dashboardData?.staff} dataKey="notInterested" path={"/hms/staff/staff"} />
                        <StatCard title="Revenue" value={dashboardData?.revenue} dataKey="noResponse" path={"#"} defaultValue={"0"} />
                        <StatCard title="Discharges" value={dashboardData?.discharges} dataKey="discharges" path={"/hms/patient/inpatients?discharge_status=true"} defaultValue={"0"} />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Row>
                    <Col lg="6" md="12">
                      <Card>
                        <CardBody>
                          <h4 className="card-title">OPs by gender</h4>
                          <div className="donut-chart-container">
                            <ReactApexChart
                              options={getPieChart(sortGenderOrder(dashboardData?.op_gender_count)).options}
                              series={getPieChart(sortGenderOrder(dashboardData?.op_gender_count)).series || []}
                              type="donut"
                              height={200}
                              className="apex-charts"
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6" md="12">
                      <Card>
                        <CardBody>
                          <h4 className="card-title">IPs by gender</h4>
                          <div className="donut-chart-container">
                            <ReactApexChart
                              options={getPieChart(sortGenderOrder(dashboardData?.ip_gender_count)).options}
                              series={getPieChart(sortGenderOrder(dashboardData?.ip_gender_count)).series || []}
                              type="donut"
                              height={200}
                              className="apex-charts"
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Revenue</h4>
                      <div id="line-chart" dir="ltr">
                        <ReactApexChart
                          series={formatData(dashboardData?.revenue_dataset, "Revenue") || []}
                          options={pieOptions}
                          type="line"
                          height={320}
                          className="apex-charts"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardBody>
                      <div className="clearfix">
                        <div className="float-end">
                          <div className="input-group input-group">
                            <select
                              className="form-select form-select-sm"
                              onChange={(e) => setSelectedDataset(e.target.value)}
                              value={selectedDataset}
                            >
                              <option value="op_dataset">Outpatient</option>
                              <option value="ip_dataset">Inpatient</option>
                              <option value="medicine_sale_dataset">Medicine Sale</option>
                              <option value="diagnosis">Diagnosis</option>
                            </select>
                          </div>
                        </div>
                        <h4 className="card-title mb-4">Count & Revenue</h4>
                      </div>
                      <ReactApexChart
                        options={countRevenueOptions}
                        series={formatData(dashboardData?.[selectedDataset]) || []}
                        type="bar"
                        height="320"
                        className="apex-charts"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-3">Overall revenue</h4>
                      <ReactApexChart
                        options={donutOptions}
                        series={dashboardData?.revenue_by_module?.map(item => item?.count) || []}
                        type="pie"
                        height="382"
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">
                        <span>Top 3 sold medicines</span>
                      </CardTitle>
                      <div className="table-responsive mb-4">
                        <Table className="table table-bordered mb-0">
                          <tbody>
                            {
                              dashboardData?.top_sold_medicines?.map((item, index) =>
                                <tr key={index}>
                                  <td scope="row" className="d-flex justify-content-between">
                                    <span>{item?.medicine}</span>
                                    <span className="text-center">
                                      <Badge className="badge rounded-pill bg-info float-end">{item?.count}</Badge>
                                    </span>
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HMS;