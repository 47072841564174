import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FileUploader } from './common';

const DigitalSignature = ({ modal, toggle, onSave, signature }) => {
  const sigCanvas = useRef({});

  const handleClear = () => {
    sigCanvas.current.clear();
  };

  const handleSave = () => {
    const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    onSave(dataUrl);
    toggle();
  };

  const handleOpened = () => {
    if (signature) {
      sigCanvas.current.fromDataURL(signature);
    }
  };

  const handleAcceptedFiles = async (files) => {
    const resizedFiles = await Promise.all(files.map(file => resizeImage(file)));
    if (resizedFiles.length > 0) {
      const uploadedImage = resizedFiles[0].base64;
      sigCanvas.current.fromDataURL(uploadedImage);
    }
  };

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 800;
          let width = img.width;
          let height = img.height;

          if (width > height && width > maxSize) {
            height = (height * maxSize) / width;
            width = maxSize;
          } else if (height > width && height > maxSize) {
            width = (width * maxSize) / height;
            height = maxSize;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve({
              base64: canvas.toDataURL('image/jpeg', 0.8),
              size: blob.size,
            });
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} backdrop="static" onOpened={handleOpened}>
      <ModalHeader toggle={toggle} className="w-100">
        Draw your signature
      </ModalHeader>
      <ModalBody>
        <SignatureCanvas
          penColor={"black"}
          ref={sigCanvas}
          canvasProps={{ className: 'sig-canvas' }}
          backgroundColor="rgba(255,255,255,1)"
        />
      </ModalBody>
      <ModalFooter>
        <FileUploader
          buttonText="Upload Signature"
          accept="image/*"
          handleAcceptedFiles={handleAcceptedFiles}
          maxSize={5 * 1024 * 1024}
        />
        <Button className="btn btn-sm" color="secondary" onClick={handleClear}>Clear</Button>
        <Button className="btn btn-sm" color="primary" onClick={handleSave}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DigitalSignature;
