import { Badge, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button, CardBody, Table, Spinner } from "reactstrap"
import { useState, useEffect, useRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import { getHms, listHms, postHms, resetHmsState, updateHms } from "store/actions";
import toastr from "toastr"
import { isEmpty, reverse, uniqueId } from "lodash";
import { SubmitLoaderButton, compareTime, dateToTime, formatDateString, getCurrentDate, handleError, handleKeyDown, sortTimes, stringToHumanTime, timeSlotStyle, timeSlotsStyle } from "../../../../common/common";
import { formatDateTime } from "components/Common/common";

export const MedicationModalAdd = ({ modals, toggleViewModal, errors, setErrors, inpID }) => {
  const timesRef = useRef();
  const datesRef = useRef();
  const dispatch = useDispatch()
  const { apiKey, formError, hmsObject } = useSelector(state => state.Hms)
  const [prescription, setPrescription] = useState({ name: "", times: [], dates: [] });
  const [medication, setMedication] = useState(null);
  const [currTime, setCurrTime] = useState(null);
  const [prescriptionCards, setPrescriptionCards] = useState([]);
  const [rudFlag, setRUDFlag] = useState(1)

  useEffect(() => {
    if (formError && (apiKey.startsWith("fail_medication_configure"))) {
      handleError(formError, apiKey, "fail_medication_configure")
    }

    if (apiKey === "medication_configure_post") {
      toastr.success("Medication created successfully")
      toggleViewModal('medicationModalAdd')
      setPrescriptionCards([])
      resetInputs()
      setMedication(hmsObject)
    }

    if (apiKey === "medication_configure_update") {
      toastr.success("Medication updated successfully")
      toggleViewModal('medicationModalAdd')
      setPrescriptionCards([])
      resetInputs()
      setMedication(hmsObject)
    }

    if (apiKey === "medication_configure_get") {
      setPrescriptionCards(transformResponse(hmsObject?.prescription))
      setMedication(hmsObject)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHms("/hms/patient/medications/", inpID, "medication_configure_get"))
    return () => dispatch(resetHmsState())
  }, [])

  const onAddTime = () => {
    if (!currTime) {
      setErrors({ ...errors, selectedTimes: "Time slots are required." });
      return;
    }

    if (currTime[0]) {
      const formattedCurrTime = dateToTime(currTime[0])
      if (!prescription?.times.includes(formattedCurrTime)) {
        setPrescription({ ...prescription, times: [...prescription?.times, formattedCurrTime] });
        setCurrTime(null);
        setErrors({ ...errors, selectedTimes: "" });
      } else {
        setErrors({ ...errors, selectedTimes: "This time already selected" });
      }
    }
  };

  const resetInputs = () => {
    timesRef.current.flatpickr.clear()
    datesRef.current.flatpickr.clear()
    setPrescription({ name: "", times: [], dates: [] });
    setErrors({ ...errors, prescription: null, selectedTimes: null, selectedDates: null });
  }

  const deleteTimeSlot = (timeToRemove) => {
    const updatedTimeSlots = prescription?.times.filter(time => time !== timeToRemove);
    setPrescription({ ...prescription, times: updatedTimeSlots });
  };

  const deleteCard = (index) => {
    const updatedCards = [...prescriptionCards];
    updatedCards.splice(index, 1);
    setPrescriptionCards(updatedCards);
  };

  const updateArrayObject = (array, object) => {
    const newArray = array.map(item =>
      item.id === object.id
        ? { ...item, ...object, id: uniqueId() + Date.now().toString() }
        : item
    );
    return newArray;
  };

  const addMedicationCard = () => {
    if (isEmpty(prescription.name) || isEmpty(prescription.times) || isEmpty(prescription.dates)) {
      setErrors({
        ...errors,
        card: "",
        prescription: isEmpty(prescription.name) ? "This field cannot be empty" : "",
        selectedTimes: isEmpty(prescription.times) ? "Time slots are required." : "",
        selectedDates: isEmpty(prescription.dates) ? "Time slots are required." : "",
      });
      return;
    }

    if (rudFlag === 0) {
      let updatedCards = updateArrayObject(prescriptionCards, prescription);
      setPrescriptionCards(updatedCards);
      setRUDFlag(1);
    } else {
      setPrescriptionCards((preValues) => [...preValues, prescription]);
    }

    resetInputs();
  }

  const handleSubmit = () => {
    if (isEmpty(prescriptionCards)) {
      toastr.error("Please enter valid data")
      return;
    }

    const payload = transformPayload(prescriptionCards)

    if (medication?.inpatient) {
      dispatch(updateHms('/hms/patient/medications/', inpID, payload, "medication_configure_update"))
    } else {
      dispatch(postHms('/hms/patient/medications/', { ...payload, "inpatient": inpID }, "medication_configure_post"))
    }
  };

  const handleEdit = (card) => {
    setPrescription(card)
    setRUDFlag(0)
  }

  function transformResponse(initialResponse) {
    const transformedResponse = {};
    initialResponse.forEach(({ date, prescription }) => {
      prescription.forEach(({ name, times }) => {
        if (!transformedResponse[name]) transformedResponse[name] = { name: name, times: [], dates: [] };
        transformedResponse[name].id = uniqueId() + Date.now().toString()
        transformedResponse[name].times = Array.from(new Set([...transformedResponse[name].times, ...times]));
        transformedResponse[name].dates.push(date);
      });
    });
    return Object.values(transformedResponse);
  }

  function transformPayload(payload) {
    const transformedPrescriptions = payload.reduce((acc, prescription) => {
      prescription.dates.forEach(date => {
        const formattedDate = formatDateTime(date, "YYYY-MM-DD");
        const existingPrescription = acc.find(item => item.date === formattedDate);
        if (existingPrescription) {
          existingPrescription.prescription.push({
            name: prescription.name,
            times: prescription.times
          });
        } else {
          acc.push({
            date: formattedDate,
            prescription: [{
              name: prescription.name,
              times: prescription.times
            }]
          });
        }
      });
      return acc;
    }, []);
    return { prescription: transformedPrescriptions };
  }

  return (
    <Modal
      isOpen={modals.medicationModalAdd}
      toggle={() => toggleViewModal('medicationModalAdd')}
      backdrop="static"
      size="lg"
    >
      <div className="modal-content">
        <AvForm onValidSubmit={handleSubmit}>
          <ModalHeader toggle={() => toggleViewModal('medicationModalAdd')}>Prescribe Medicine</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={6}>
                <Row className="mb-2">
                  <Col>
                    <div>
                      <AvField
                        id="id_prescription"
                        name="prescription"
                        type="textarea"
                        placeholder={"Write prescription here"}
                        className={errors.prescription || errors.card ? 'is-invalid' : ''}
                        validate={{ maxLength: { value: 512 } }}
                        value={prescription?.name}
                        onChange={(e) => setPrescription({ ...prescription, name: e.target.value })}
                      />
                      {errors && (
                        <>
                          {errors?.prescription && (
                            <div className="text-danger small" style={{ margin: '-14px 0 0 3px' }}>
                              {errors?.prescription}
                            </div>
                          )}
                          {errors?.card && (
                            <div className="text-danger small" style={{ margin: '-14px 0 0 3px' }}>
                              {errors?.card}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="mb-2">
                  <Col xl={5} md={8} className="float-start">
                    <Flatpickr
                      className={errors?.selectedDates ? "form-control is-invalid" : "form-control"}
                      placeholder={"Select dates"}
                      options={{
                        mode: "multiple",
                        dateFormat: "Y-m-d",
                        minDate: "today",
                        maxDate: new Date().fp_incr(30),
                      }}
                      value={prescription?.dates}
                      ref={datesRef}
                      onChange={(values) => setPrescription({ ...prescription, dates: values.sort((a, b) => new Date(a) - new Date(b)) })}
                      onKeyDown={handleKeyDown}
                    />
                    {errors && errors?.selectedDates && <div className="invalid-feedback">{errors?.selectedDates}</div>}
                  </Col>
                  <Col md={12} className="float-start mt-3">
                    <div style={timeSlotsStyle}>
                      {prescription?.dates?.map((date, index) => (
                        <Badge key={index} color="secondary" className="me-2 ms-2 mb-2 p-2">
                          {formatDateString(date, "DD MMMM")}
                        </Badge>
                      ))}
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs={6} sm={6} md={6}>
                    <div className="mb-2">
                      <Flatpickr
                        className={errors.selectedTimes ? 'form-control me-3 is-invalid p-2' : 'form-control me-3'}
                        placeholder="Select time"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          time_24hr: false,
                          dateFormat: "h:i K",
                        }}
                        value={currTime}
                        ref={timesRef}
                        onChange={(value) => setCurrTime(value)}
                      />
                      <div className="text-danger small" style={{ margin: '0 0 0 3px' }}>{errors.selectedTimes}</div>
                    </div>
                  </Col>
                  <Col className="ps-0">
                    <div className="d-flex justify-content-between">
                      <Button color="link" className="btn btn-link btn-sm" onClick={onAddTime}><i className="bx bx-plus-medical mt-2"></i></Button>
                    </div>
                  </Col>
                </Row>
                <div style={timeSlotsStyle} className="mb-2">
                  {prescription?.times.map(time => new Date('1970-01-01 ' + time)).sort((a, b) => a - b).map((item, index) => (
                    <Badge key={index} color="light" className="me-2 ms-2 mb-2 p-2 mt-2">
                      {stringToHumanTime(item)}
                      <i className="bx bx-x ms-1" onClick={() => deleteTimeSlot(stringToHumanTime(item, 'HH:mm:ss'))} style={{ cursor: "pointer" }}></i>
                    </Badge>
                  ))}
                </div>
                <Row className="justify-content-end">
                  <Col className="d-flex justify-content-end">
                    <Button className="btn btn-primary btn-sm al" color="primary" onClick={addMedicationCard}>
                      {rudFlag === 1 ? "Add Medication" : "Update Medication"}
                    </Button>
                  </Col>
                </Row>
                <hr />
              </Col>
              <Col style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                {prescriptionCards?.map((card, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between mb-2"
                    style={{ border: "1px solid #ddd6d6", padding: "5px", borderRadius: "5px", cursor: "pointer" }}
                    onClick={() => handleEdit(card)}
                  >
                    <div>
                      <div className="mb-2">
                        {card.dates.map((value, index) => (
                          <Badge key={index} color="light" className="me-1">
                            {formatDateString(value, "DD MMMM")}
                          </Badge>
                        ))}
                      </div>
                      <h6 className="text-truncate">
                        <Link className="text-dark" to="#">
                          {card.name}
                        </Link>
                      </h6>
                      <div>
                        {card.times.map((value, index) => (
                          <Badge key={index} color="light" className="me-1">
                            {stringToHumanTime(value)}
                          </Badge>
                        ))}
                      </div>
                    </div>
                    <div>
                      <Link to="#" onClick={(e) => { e.stopPropagation(); deleteCard(index); }} className="action-icon text-danger">
                        {" "} <i className="bx bx-x font-size-22" />
                      </Link>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={() => toggleViewModal('medicationModalAdd')}>
              Close
            </Button>
            <SubmitLoaderButton type="submit" loading={apiKey === "update_medication_configure_update" || apiKey === "post_medication_configure_post"}>
              Save
            </SubmitLoaderButton>
          </ModalFooter>
        </AvForm>
      </div>
    </Modal>
  )
}

export const MedicationModalTrack = ({ modals, toggleViewModal, inpID }) => {
  const dispatch = useDispatch()
  const { loading, error, hmsObject, apiKey, formError } = useSelector(state => state.Hms)
  const [selectedItems, setSelectedItems] = useState([]);
  const [medication, setMedication] = useState(null);

  useEffect(() => {
    dispatch(getHms(`/hms/patient/medications/${inpID}/track/`, null, "medication_track_get"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "medication_track_post") {
      toastr.success("Medication track submitted")
      clearStuff()
    }
    if (apiKey === "medication_track_get") {
      setMedication(hmsObject)
    }
    if (formError && apiKey && apiKey.startsWith("fail_medication_track")) {
      toastr.error(Object.entries(formError).map(([key, value], idx) =>
        `${key}: ${value}`
      ))
    }
  }, [apiKey])

  const clearStuff = () => {
    toggleViewModal('medicationModalTrack')
  }

  const handleCheckboxChange = (event, name, time) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, { name: name, time }]);
    } else {
      setSelectedItems(selectedItems.filter(item => !(item.name === name && item.time === time)));
    }
  };

  const handleSubmit = () => {
    dispatch(postHms(`/hms/patient/medications/${inpID}/track/`, {
      prescription_data: selectedItems
    }, "medication_track_post"))
  }

  return (
    <Modal
      isOpen={modals.medicationModalTrack}
      toggle={() => toggleViewModal('medicationModalTrack')}
      backdrop="static"
    >
      <div className="modal-content">
        <AvForm onValidSubmit={handleSubmit}>
          <ModalHeader
            toggle={() => toggleViewModal('medicationModalTrack')}
          >
            Medication tracking @{formatDateTime(new Date(), "DD MMM, YYYY")}
          </ModalHeader>
          {loading && apiKey === "medication_track_get" ? (
            <div className="text-center">
              <div className="avatar-md mx-auto">
                <Spinner className="mt-5 fs-1" color="secondary" />
              </div>
              <div className="p-2 mt-4">
                <h4>Fetching information...</h4>
                <p className="text-muted">
                  Please wait while we load the details
                </p>
              </div>
            </div>
          ) : (
            <>
              {error?.detail ?
                <p className="text-center text-muted m-2">{error.detail}</p>
                :
                <>
                  <ModalBody>
                    <CardBody>
                      {medication?.prescription?.map((item, index) =>
                        <div key={index} className="d-flex justify-content-between mb-2" style={{ border: "1px solid #ddd6d6", padding: "5px", borderRadius: "5px" }}>
                          <div>
                            <h6 className="text-truncate">
                              <Link className="text-dark" to="#">
                                {item.name}
                              </Link>
                            </h6>
                            <div style={timeSlotsStyle}>
                              {(item?.times).sort(sortTimes).map((time, index) => {
                                const over = compareTime(time)
                                const checked = medication.prescripted_history.some(historyItem => historyItem.name === item.name && historyItem.time === time)
                                return (
                                  <Badge className="d-flex align-items-center" style={timeSlotStyle} key={index}>
                                    <input
                                      type="checkbox"
                                      className="me-1"
                                      onChange={(event) => handleCheckboxChange(event, item?.name, time)}
                                      disabled={!over || checked}
                                      defaultChecked={checked}
                                    />
                                    {stringToHumanTime(time)}
                                  </Badge>
                                )
                              })
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </ModalBody>
                  <ModalFooter>
                    <Button type="button" color="secondary" onClick={() => toggleViewModal('medicationModalTrack')}>
                      Close
                    </Button>
                    <SubmitLoaderButton type="submit" disabled={isEmpty(selectedItems)} loading={apiKey === "post_medication_track_post"}>
                      Save
                    </SubmitLoaderButton>
                  </ModalFooter>
                </>
              }
            </>
          )}
        </AvForm>
      </div>
    </Modal>
  )
}

export const MedicationModalHistory = ({ modals, toggleViewModal, inpID }) => {
  const dispatch = useDispatch()
  const { loading, error, hmsList, apiKey, formError } = useSelector(state => state.Hms)
  const [medHistory, setMedHistory] = useState([])
  const [date, setDate] = useState(getCurrentDate())

  useEffect(() => {
    dispatch(listHms(`/hms/patient/medications/${inpID}/history/${getCurrentDate()}/`, null, "medication_history_fetch"))
  }, [])

  useEffect(() => {
    if (formError && apiKey && apiKey.startsWith("fail_medication_history")) {
      toastr.error(Object.entries(formError).map(([key, value], idx) =>
        `${key}: ${value}`
      ))
    }

    if (apiKey === "medication_history_fetch") {
      const data = hmsList;
      const sortedPrescription = data?.prescription_data ? data?.prescription_data.slice().sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) : [];
      data.prescription_data = sortedPrescription
      setMedHistory(data)
    }
  }, [hmsList])

  const handleSubmit = (date) => {
    if (!isEmpty(date)) {
      const formattedDate = formatDateTime(date[0], "YYYY-MM-DD");
      setDate(formattedDate);
      dispatch(listHms(`/hms/patient/medications/${inpID}/history/${formattedDate}/`, null, "medication_history_fetch"));
    }
  }

  return (
    <Modal
      isOpen={modals.medicationModalHistory}
      toggle={() => toggleViewModal("medicationModalHistory")}
      backdrop="static"
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => toggleViewModal("medicationModalHistory")}
        >
          Medication History
        </ModalHeader>
        {loading ? (
          <div className="text-center">
            <div className="avatar-md mx-auto">
              <Spinner className="mt-5 fs-1" color="secondary" />
            </div>
            <div className="p-2 mt-4">
              <h4>Fetching information...</h4>
              <p className="text-muted">
                Please wait while we load the history details
              </p>
            </div>
          </div>
        ) : (
          <>
            {error?.detail ?
              <p className="text-center text-muted m-2">{error.detail}</p>
              :
              <>
                <ModalBody>
                  <Row><Col></Col><Col>
                    <span>Prescription Date</span>
                    <Flatpickr
                      className={"form-control form-control-sm"}
                      options={{
                        mode: "single",
                        dateFormat: "Y-m-d",
                        defaultDate: date,
                        maxDate: "today"
                      }}
                      value={date}
                      onChange={(value) => handleSubmit(value)}
                    />
                  </Col></Row>
                  <div className="table-responsive">
                    <Table className="table align-middle table-nowrap">
                      <thead>
                        <tr>
                          <th>Medication</th>
                          <th>Actual Time</th>
                          <th>Given Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(medHistory?.prescription_data) ?
                          reverse(medHistory?.prescription_data).map(
                            (medication, index) => {
                              return (
                                <tr key={index}>
                                  <td>{medication.name}</td>
                                  <td>{stringToHumanTime(medication.time)}</td>
                                  <td>{formatDateTime(medication.timestamp, "LT")}</td>
                                </tr>
                              )
                            }
                          )
                          :
                          <tr><td colSpan={3} className="text-center text-muted">History not available for this day</td></tr>
                        }
                      </tbody>
                    </Table>
                  </div>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => toggleViewModal("medicationModalHistory")}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </>
            }
          </>
        )}
      </div>
    </Modal>
  )
}