import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { deleteHms, getHms, getHmsOptions, resetHmsState } from "store/actions";
import { Loader } from "pages/HMS/common/common";
import { TableChip } from "components/Common/common";
import { SupplierCRUDModal } from "../pharmacy-pharm-crud-modals";
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import toastr from "toastr"

const SupplierDetail = (props) => {
  const dispatch = useDispatch();
  const { error, hmsObject, apiKey, updateResponse, loading } = useSelector(state => state.Hms);
  const [supplier, setSupplier] = useState({});
  const [dropdown, setDropdown] = useState(false)
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    if (apiKey === "supplier_details") {
      setSupplier(hmsObject);
    }
    if (apiKey === "supplier_details_updated") {
      setSupplier(updateResponse);
      setModal(false);
    }
    if (apiKey === "supplier_detail_deleted") {
      toastr.success("Supplier is deleted")
      setDeleteModal(false)
      setSupplier({})
      window.location.href = "/hms/pharmacy/supplier"
    }
  }, [apiKey]);

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy/suppliers/?options=formschema", "suppliers_detail_options", false, true))
    if (props.match.params.supplierID) dispatch(getHms("/hms/pharmacy/suppliers/", props.match.params.supplierID, "supplier_details"))
    return () => dispatch(resetHmsState())
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {supplier?.user_data?.full_name && `${supplier?.user_data.full_name} |`} Yanthura
          </title>
        </MetaTags>
        <Card>
          <CardBody>
            {apiKey === "get_supplier_details" ? (
              <Loader />
            ) : error ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : (
              <Row>
                <Col>
                  <div className="d-flex justify-content-between">
                    <h5>Supplier Information</h5>
                    <Dropdown isOpen={dropdown} toggle={() => setDropdown(!dropdown)} direction="down">
                      <DropdownToggle caret color="primary" size="sm">
                        Actions
                        &nbsp;<i className="fa fa-caret-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <Link className="dropdown-item" to="#" onClick={() => setModal(true)}>
                          Modify
                        </Link>
                        <Link className="dropdown-item" to="#" onClick={() => setDeleteModal(true)}>
                          Remove
                        </Link>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <hr />
                  {supplier ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Supplier name"
                          value={supplier?.name}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Contact person"
                          value={supplier?.contact_person}
                        />
                        <TableChip
                          label="Mobile"
                          value={supplier?.mobile}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Contact person 2"
                          value={supplier?.contact_person_2}
                        />
                        <TableChip
                          label="Mobile 2"
                          value={supplier?.alternate_mobile}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Email"
                          value={supplier?.email}
                        />
                        <TableChip
                          label="Website"
                          value={supplier?.website}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Gst number"
                          value={supplier?.gst_number}
                        />
                        <TableChip
                          label="Customer type"
                          value={supplier?.customer_type}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Notes"
                          value={supplier?.notes}
                        />
                      </div>
                    </>
                  ) : (
                    <p className="text-center text-danger">Supplier information not existed</p>
                  )}
                </Col>
                <Col lg={1}></Col>
                <Col>
                  {supplier?.address ? (
                    <>
                      <h5 className="mt-2">Address</h5>
                      <hr />
                      <div className="d-flex justify-content-between">
                        <TableChip label="Line 1" value={supplier?.address?.line1} />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip label="Line 2" value={supplier?.address?.line2} />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip label="District" value={supplier?.address?.district} />
                        <TableChip label="City" value={supplier?.address?.city} />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip label="State" value={supplier?.address?.state} />
                        <TableChip label="Pincode/Zipcode" value={supplier?.address?.pincode} />
                      </div>
                    </>
                  ) : (
                    <p className="text-center text-danger">Address details not existed</p>
                  )}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>

      <SupplierCRUDModal
        modal={modal}
        toggle={() => setModal(false)}
        rudFlag={0}
        data={supplier}
        apiStartKey="supplier_details"
      />

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If supplier is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={() => setDeleteModal(false)}
        dispatchOperation={deleteHms("/hms/pharmacy/suppliers/", supplier?.id, "supplier_detail_deleted")}
        deleteModal={deleteModal}
      />
    </React.Fragment >
  );
};

export default SupplierDetail;
