import React, { useEffect, useRef, useState } from "react"
import { Button, ButtonGroup, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, Row } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import "assets/scss/datatables.scss"
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import Flatpickr from "react-flatpickr"
import Chip from "components/Common/chips";
import { frameURLParam, tableSearchIconSizeFetch, loadOrNoData } from "components/Common/common"
import { openPdf } from "helpers/api_helper"
import { getLocalStorageItem, setLocalStorageItem, updateURLParams } from "./common";
import { search as searchOptions } from "./common";
import { EAsyncSelect } from "./errored-avfields";

const GenericTable = props => {
  let {
    urlParams,
    filters,
    columns,
    keyField = "id",
    noDataIndication = "Records not found",
    search,
    daterange,
    loading = false,
    defaultPageSize = 15,
    data,
    removeQueryParams = false,
    moduleName = null,
    apiTrigger,
    exportUrl = "",
  } = props

  const location = useLocation()
  const history = useHistory()
  let parentCbRef = {}
  let timeoutObject = null
  const dateRangeRef = useRef()
  const inputRef = useRef()
  urlParams = new URLSearchParams(urlParams)

  const [filtersCopy, setFiltersCopy] = useState({})
  const [showFilters, setShowFilters] = useState(localStorage.getItem("showFilters") === null ? true : localStorage.getItem("showFilters") === "true");
  const defaultCurrentPage = 1
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [dropdownSelectedOptions, setDropdownSelectedOptions] = useState({})
  const [period, setPeriod] = useState(null)
  const [defaultDate, setDefaultDate] = useState([])
  const [periodDropdown, setPeriodDropdown] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState({})
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  // Pagination options
  const pageOptions = {
    custom: true,
    page: currentPage,
    hidePageListOnlyOnePage: true,
    sizePerPage: pageSize,
    totalSize: data.count,
    sizePerPageList: [10, 15, 20, 25, 30].map((value) => ({ text: value, value })),
  };

  // Populate filters from URL parameters
  useEffect(() => {
    const loadedFilters = filtersCopy;
    filters &&
      Object.entries(filters).forEach(([key, val]) => {
        const paramValue = urlParams.get(key);
        if (paramValue !== null) {
          loadedFilters[key] =
            val.type === "checkbox"
              ? { ...val, data: val.data.map((item) => ({ ...item, checked: paramValue.includes(item.value) })) }
              : val.type === "singlerange"
                ? { ...val, value: Number(paramValue) }
                : val.type === "boolean"
                  ? { ...val, value: paramValue === "true" }
                  : { ...val, value: paramValue };
        }
      });

    if (search && loadedFilters?.search) {
      loadedFilters.search.value = urlParams.get("search") || "";
    }
    if (loadedFilters?.daterange) {
      const dateStart = urlParams.get("date");
      const dateEnd = urlParams.get("date_end");
      loadedFilters.daterange.value = dateStart && dateEnd ? [dateStart, dateEnd] : [];
    }

    setFiltersCopy(loadedFilters);
  }, [filters, search, daterange, urlParams]);

  useEffect(() => {
    if (filters) setFiltersCopy(filters);
    if (search) setFiltersCopy((prevState) => ({ ...prevState, search }));
    if (daterange) setFiltersCopy((prevState) => ({ ...prevState, daterange }));
  }, [filters, search, daterange]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const dateStart = urlSearchParams.get("date");
    const dateEnd = urlSearchParams.get("date_end");

    if (dateStart && dateEnd) {
      setDefaultDate([dateStart, dateEnd]);
    }
    const filters = JSON.parse(localStorage.getItem("filters"))?.[moduleName];
    setIsLocked(filters || filters === "");
  }, []);

  const searchBar = (event) => {
    const value = event.target.value.trim();
    if (value === "" || value?.length > 1) {
      filtersCopy["search"]["value"] = value;
      shootFetcher(filtersCopy, { lazy: true, updateUrl: !removeQueryParams });
    }
  };

  const onDaterangeChange = (dateRange) => {
    if (!isEmpty(dateRange)) {
      let [start_date, end_date] = [moment(dateRange[0]), moment(dateRange[1])]
      let date_ranges = [start_date.format("YYYY-MM-DD"), end_date.format("YYYY-MM-DD")]
      let tObj = filtersCopy
      tObj["daterange"]["value"] = (start_date > end_date) ? date_ranges.reverse() : date_ranges
      shootFetcher(tObj, {})
      setPeriod(null)
    }
  }

  const onSingleRangeChange = (key, value) => {
    filtersCopy[key].value = value;
    shootFetcher(filtersCopy, { lazy: true });
  };

  const onParentCheckboxChange = (key, event) => {
    const { value, checked } = event.target;
    const tObj = { ...filtersCopy };
    tObj[key].data.forEach((parentObject) => {
      if (parentObject.value === value) {
        parentObject.checked = checked;
        parentObject.data?.forEach((childObject) => {
          childObject.checked = checked;
        });
      }
    });
    shootFetcher(tObj, {});
  };

  const onChildCheckboxChange = (parentKey, key, value, checked) => {
    const tObj = { ...filtersCopy };
    parentCbRef[parentKey].current.indeterminate = null;
    tObj[key].data.forEach((parentObject) => {
      const parentDisable = [];
      parentObject.data?.forEach((childObject) => {
        if (childObject.value === value) childObject.checked = checked;
        parentDisable.push(childObject.checked);
      });
      if (parentDisable.length) {
        const boolValues = new Set(parentDisable);
        parentObject.checked = boolValues.size === 1 && boolValues.has(true);
        if (boolValues.size !== 1) parentCbRef[parentKey].current.indeterminate = true;
      }
    });
    shootFetcher(tObj, {});
  };

  const onNumberInputChange = (key, event) => {
    filtersCopy[key].value = event.target.value;
    shootFetcher(filtersCopy, { lazy: true });
  };

  const onRadioChange = (key, event) => {
    filtersCopy[key].value = event.target.value;
    shootFetcher(filtersCopy, {});
  };

  const onSelectChange = (key, event) => {
    filtersCopy[key].value = event.target.value;
    shootFetcher(filtersCopy, {});
  };

  const onDropdownSelectChange = (value, callback, searchApi, key) => {
    searchOptions(value, callback, searchApi, () =>
      setIsMenuOpen((prev) => ({ ...prev, [key]: value.length > 0 }))
    );
  };

  const onBooleanChange = (key, e) => {
    filtersCopy[key].value = e.target.checked;
    shootFetcher(filtersCopy, {});
  };

  const updateDropdownSelectedOptions = (key, value, optionValueKey) => {
    setIsOptionSelected(!!value);
    setDropdownSelectedOptions((prevState) => ({ ...prevState, [key]: value }));
    filtersCopy[key].value = String(value?.[optionValueKey] ?? "");
    shootFetcher(filtersCopy, { updateUrl: false });
  };

  // Helper function for API call with updated filters
  const shootFetcher = (filterData, { page = currentPage, pagesize = pageSize, isPageHit = false, lazy = false, updateUrl = true }) => {
    if (!isPageHit) page = defaultCurrentPage;
    setFiltersCopy(filterData);
    const fetchData = () => {
      const urlParams = frameURLParam(filterData, page, pagesize);
      apiTrigger(urlParams);
      if (updateUrl) updateURLParams(history, urlParams);
    };
    if (lazy) {
      clearTimeout(timeoutObject);
      timeoutObject = setTimeout(fetchData, 500);
    } else fetchData();
    setCurrentPage(page);
    setPageSize(pagesize);
  };

  const onPageChange = (type, { page, sizePerPage }) => {
    shootFetcher(filtersCopy, { page, isPageHit: true });
  };

  const pageSizeChange = (value) => {
    shootFetcher(filtersCopy, { pagesize: value, isPageHit: true });
  };

  const filtersPlace = () => {
    if (!urlParams) return;
    const urlParamKeys = Array.from(urlParams.keys());
    filtersCopy &&
      Object.entries(filtersCopy).forEach(([k, v], idx) => {
        if (v && urlParamKeys.includes(k)) {
          <Chip key={idx} label={v.label} data={v} onClick={removeChip} />;
        }
      });
  };

  const refreshPage = () => {
    apiTrigger(decodeURIComponent(location.search).replace("?", ""));
    if (removeQueryParams) inputRef.current.value = "";
    setDropdownSelectedOptions({});
    setIsOptionSelected(false);
  };

  const clearFilters = () => {
    setFiltersCopy(prevState => {
      let updatedState = { ...prevState };

      Object.keys(updatedState).forEach(key => {
        if (updatedState[key].type === "checkbox") {
          updatedState[key].data = updatedState[key].data.map(item => ({
            ...item,
            checked: false,
          }));
        } else {
          updatedState[key].value = "";
        }
      });

      return updatedState;
    });

    apiTrigger();
    updateURLParams(history);
    setDefaultDate([]);
    dateRangeRef?.current?.flatpickr?.clear();
    inputRef.current.value = "";
    setPeriod(null);
    setDropdownSelectedOptions({});
    setIsOptionSelected(false);
  };

  const removeChip = (tObj) => {
    shootFetcher(tObj, {});
    if (isEmpty(tObj.daterange.value)) dateRangeRef.current.flatpickr.clear();
  };

  const onPeriodChange = (period) => {
    let now = moment.utc();
    let start, end;

    switch (period) {
      case "Today":
        start = now.clone().startOf("day").format("YYYY-MM-DD");
        end = now.clone().endOf("day").format("YYYY-MM-DD");
        break;

      case "Week":
        start = now.clone().subtract(7, "days").startOf("day").format("YYYY-MM-DD");
        end = now.clone().endOf("day").format("YYYY-MM-DD");
        break;

      case "1 Month":
        start = now.clone().subtract(1, "month").startOf("day").format("YYYY-MM-DD");
        end = now.clone().endOf("day").format("YYYY-MM-DD");
        break;

      case "3 Months":
        start = now.clone().subtract(3, "months").startOf("day").format("YYYY-MM-DD");
        end = now.clone().endOf("day").format("YYYY-MM-DD");
        break;

      case "6 Months":
        start = now.clone().subtract(6, "months").startOf("day").format("YYYY-MM-DD");
        end = now.clone().endOf("day").format("YYYY-MM-DD");
        break;

      default:
        start = now.clone().startOf("day").format("YYYY-MM-DD");
        end = now.clone().endOf("day").format("YYYY-MM-DD");
    }

    onDaterangeChange([start, end]);
    dateRangeRef?.current?.flatpickr?.clear();
    setPeriod(period);
    setDefaultDate([start, end]);
  };

  const toggleFilters = () => {
    const updatedShowFilters = !showFilters;
    setShowFilters(updatedShowFilters);
    localStorage.setItem("showFilters", updatedShowFilters);
  };

  const toggleLock = () => {
    const savedFilters = getLocalStorageItem("filters");
    if (moduleName) {
      const updatedLockState = !isLocked;
      setIsLocked(updatedLockState);
      if (updatedLockState) {
        savedFilters[moduleName] = location.search.substring(1);
      } else {
        delete savedFilters[moduleName];
      }
      setLocalStorageItem("filters", savedFilters);
    }
  };

  const exportDownload = () => {
    setIsLoading(true);
    const urlParams = frameURLParam(filtersCopy)
    const url = urlParams ? `${exportUrl}?export=true&${urlParams}` : `${exportUrl}?export=true `;
    const fileName = `${new Date().toISOString().split(".")[0].replace(/[^\d]/gi, "")}.xlsx`;
    openPdf(url, { responseType: "blob", headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" } })
      .then((response) => {
        const blob = new Blob([response]);
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(() => window.URL.revokeObjectURL(blobUrl), 5000);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Row>
        {/* Side Filters */}
        {!isEmpty(filters) && showFilters && (
          <Col lg="3">
            <Card>
              <CardBody>
                {filtersCopy && Object.entries(filtersCopy).map(([key, valObj], mainKeyIdx) =>
                  (valObj?.type === "singlerange") ? (
                    <div className="mt-3 pt-3" key={mainKeyIdx}>
                      <h5 className="font-size-14 mb-2">{valObj?.label}</h5>
                      <input
                        type="range"
                        className="form-range"
                        min="0"
                        max={valObj?.max}
                        step={valObj?.step ? valObj?.step : "0.1"}
                        value={valObj?.value}
                        id={`id${key}`}
                        onChange={(e) => onSingleRangeChange(key, e.target.value)}
                      />
                      <div className="d-flex justify-content-between">
                        <p></p>
                        {valObj?.proxy ? (
                          <>
                            {valObj?.proxy === "time" ?
                              <>
                                <h5>{moment.utc(Math.ceil(valObj?.value) * 1000).format("m:ss")} mins</h5>
                                <p>{Math.ceil(valObj?.max / 60)} mins</p>
                              </>
                              : null   // can add new ones here
                            }
                          </>
                        ) : (
                          <>
                            <h5>{valObj?.value}</h5>
                            <p>{valObj?.max}</p>
                          </>
                        )}
                      </div>
                    </div>
                  ) : valObj?.type === "checkbox" ? (
                    <div className="mt-3 pt-3" key={mainKeyIdx}>
                      <h5 className="font-size-14 mb-2">{valObj?.label}</h5>
                      {valObj?.data.map((parentCbObj, idx) =>
                        <div key={idx} className="mb-1">
                          <ul className="metismenu list-unstyled">
                            <li>
                              <div className="has-arrow">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`id${parentCbObj?.label}`}
                                  value={parentCbObj?.value}
                                  checked={parentCbObj?.checked}
                                  onChange={(e) => onParentCheckboxChange(key, e)}
                                  ref={parentCbRef && parentCbRef[parentCbObj?.value]}
                                />
                                <label className="form-check-label ms-2 text-capitalize" htmlFor={`id${parentCbObj?.label}`}>
                                  {parentCbObj?.label}
                                </label>
                              </div>
                              {parentCbObj?.data && (
                                <ul className="sub-menu" aria-expanded="false">
                                  <li>
                                    {parentCbObj?.data.map((childCbObj, cidx) =>
                                      <div key={cidx}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={`id${childCbObj?.label}`}
                                          value={childCbObj?.value}
                                          checked={childCbObj?.checked}
                                          onChange={(e) => onChildCheckboxChange(parentCbObj?.value, key, e.target.value, e.target.checked)}
                                        />
                                        <label className="form-check-label ms-2 text-capitalize" htmlFor={`id${childCbObj?.label}`}>
                                          {childCbObj?.label}
                                        </label>
                                        <br />
                                      </div>
                                    )}
                                  </li>
                                </ul>
                              )}
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  ) : valObj?.type === "dropdownsearch" ? (
                    <div className="mt-3 pt-3" key={mainKeyIdx}>
                      <EAsyncSelect
                        menuIsOpen={isMenuOpen?.[key]}
                        onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, [key]: false }))}
                        options={{ label: valObj?.label }}
                        value={[dropdownSelectedOptions?.[key]]}
                        loadOptions={(value, callback) => onDropdownSelectChange(value, callback, valObj?.searchApi, valObj?.apiKey)}
                        onChange={(value) => updateDropdownSelectedOptions(key, value, valObj?.optionValueKey)}
                        placeholder={valObj?.placeholder}
                        getOptionLabel={(e) => valObj?.renderOptions(e)}
                        getOptionValue={e => e?.[valObj?.optionValueKey]}
                        inputHeight={isOptionSelected && dropdownSelectedOptions?.[key] ? valObj?.inputHeight : undefined}
                      />
                    </div>
                  ) : valObj?.type === "radio" ? (
                    <div className="mt-3 pt-3" key={mainKeyIdx}>
                      <h5 className="font-size-14 mb-2">{valObj?.label}</h5>
                      {valObj?.data.map((item, idx) =>
                        <div className="form-check mb-1" key={idx}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name={item.value}
                            id={`id${item.value}`}
                            value={item.value}
                            checked={valObj?.value === item.value ? true : false}
                            onChange={(e) => onRadioChange(key, e)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`id${item.value}`}
                          >
                            {item.label}
                          </label>
                        </div>
                      )}
                    </div>
                  ) : valObj?.type === "dropdown" ? (
                    <div className="mt-3 pt-3" key={mainKeyIdx}>
                      <h5 className="font-size-14 mb-2">{valObj?.label}</h5>
                      <select className="form-control" onChange={(e) => onSelectChange(key, e)} defaultValue={valObj?.value}>
                        <option value="">---</option>
                        {valObj?.data.map((item, idx) =>
                          <option key={idx} value={item.value}>{item.label}</option>
                        )}
                      </select>
                    </div>
                  ) : valObj?.type === "number" ? (
                    <div className="mt-3 pt-3" key={mainKeyIdx}>
                      <h5 className="font-size-14 mb-2">{valObj?.label}</h5>
                      <div className="mb-1">
                        <input
                          className="form-control"
                          type="number"
                          name={key}
                          id={`id${key}`}
                          value={valObj?.data}
                          onChange={(e) => onNumberInputChange(key, e)}
                        />
                      </div>
                    </div>
                  ) : valObj?.type === "boolean" && (
                    <div className="mt-3 pt-3" key={mainKeyIdx}>
                      <h5 className="font-size-14 mb-2">{valObj?.label}</h5>
                      <div className="mb-1">
                        <ul className="metismenu list-unstyled">
                          <li>
                            <div className="has-arrow">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={`id${key}`}
                                value={valObj?.value}
                                checked={valObj?.value}
                                onChange={(e) => onBooleanChange(key, e)}
                              // ref={booleanRef && booleanRef[valObj?.value]}
                              />
                              <label className="form-check-label ms-2 text-capitalize" htmlFor={`id${key}`}>
                                {valObj?.label}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )
                )}
              </CardBody>
            </Card>
          </Col>
        )}

        {/* Main Table */}
        <Col lg={isEmpty(filters) || !showFilters ? "12" : "9"}>
          <Card>
            <CardBody className="mb-5">
              {(search || daterange) && (
                <Row className="mb-2 d-flex justify-content-between">
                  {search && (
                    <Col md="4">
                      <div className="d-flex align-items-center">
                        {!isEmpty(filters) && (
                          <button
                            type="button"
                            className="btn btn-sm font-size-16 me-2"
                            onClick={toggleFilters}
                          >
                            <i className="bx bx-slider" />
                          </button>
                        )}
                        <div className="search-box d-inline-block mb-2">
                          <div className="position-relative">
                            <input
                              className="form-control"
                              type="search"
                              onInput={searchBar}
                              placeholder={search.placeholder || ""}
                              defaultValue={search.value || ""}
                              ref={inputRef}
                            />
                            <i className="bx bx-search-alt search-icon" style={tableSearchIconSizeFetch(localStorage.getItem("fontSize"))} />
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}

                  <Col>
                    <div className={`d-flex ${window.innerWidth <= 767 ? "justify-content-start flex-wrap" : "justify-content-end"}`}>
                      {props.createButton && <span className="mb-2">{props.createButton()}</span>}
                      <div className="text-center mb-2">
                        <ButtonGroup>
                          {exportUrl && (
                            <Button color="secondary" className="btn btn-sm" outline onClick={exportDownload} disabled={isLoading} title="Export to Excel">
                              <i className="bx-rotate-180 bx bxs-download" />
                            </Button>
                          )}
                          <Button color="secondary" className="btn btn-sm" outline onClick={refreshPage}>
                            <i className="mdi mdi-refresh" />
                          </Button>
                          <Button color="secondary" className="btn btn-sm" outline onClick={clearFilters}>
                            <i className="dripicons-cross" />
                          </Button>
                          {moduleName && (
                            <Button color="secondary" className="btn btn-sm" outline={!isLocked} onClick={toggleLock}>
                              {isLocked ? <i className="bx bx-lock-alt" /> : <i className="bx bx-lock-open-alt" />}
                            </Button>
                          )}
                        </ButtonGroup>
                      </div>

                      {daterange && (
                        <>
                          <div className={`${window.innerWidth <= 767 ? "mb-2" : "ms-2"}`}>
                            <InputGroup>
                              <Flatpickr
                                className="form-control form-control-sm"
                                placeholder={!isEmpty(defaultDate) ? defaultDate : "Select daterange range"}
                                options={{
                                  mode: "range",
                                  dateFormat: "Y-m-d",
                                  minDate: "2000-01",
                                  defaultDate: !isEmpty(daterange.value) ? daterange.value : []
                                }}
                                value={!isEmpty(defaultDate) ? defaultDate : []}
                                ref={dateRangeRef}
                                onClose={onDaterangeChange}
                              />
                            </InputGroup>
                          </div>

                          <div className={`${window.innerWidth <= 767 ? "mb-2" : "ms-2"}`}>
                            <Dropdown isOpen={periodDropdown} toggle={() => setPeriodDropdown(!periodDropdown)} direction="down">
                              <DropdownToggle caret color="primary" size="sm">
                                {period || "Period"} <i className="fa fa-caret-down ms-1" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => onPeriodChange("Today")} disabled={period === "Today"}>
                                  Today
                                </DropdownItem>
                                <DropdownItem onClick={() => onPeriodChange("Week")} disabled={period === "Week"}>
                                  Last Week
                                </DropdownItem>
                                <DropdownItem onClick={() => onPeriodChange("1 Month")} disabled={period === "1 Month"}>
                                  Last Month
                                </DropdownItem>
                                <DropdownItem onClick={() => onPeriodChange("3 Months")} disabled={period === "3 Months"}>
                                  Last 3 Months
                                </DropdownItem>
                                <DropdownItem onClick={() => onPeriodChange("6 Months")} disabled={period === "6 Months"}>
                                  Last 6 Months
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField={keyField}
                    columns={columns}
                    data={!isEmpty(data?.results) ? data?.results : []}
                    bootstrap4
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive" style={{ minHeight: "240px" }}>
                              <BootstrapTable
                                remote
                                responsive
                                bordered={false}
                                striped={false}
                                onTableChange={onPageChange}
                                classes={"table align-middle table-nowrap table-sm"}
                                noDataIndication={loadOrNoData(loading, noDataIndication)}
                                headerWrapperClasses={"thead-light text-capitalize"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                                onSizePerPageChange={pageSizeChange}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GenericTable
