import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, Container } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import GenericTable from "../../common/table"
import { HmsDeleteModal } from "../../common/hms-crud-modals"
import { deletePaginationResult, updatePaginationResult } from "components/Common/common"
import { isEmpty } from "lodash"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { BedCRUDModal } from "./modals"
import { handleIncludedKeysError, Loader } from "pages/HMS/common/common"

const BedConfig = props => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const { loading, options, hmsList, apiKey, error, formError, hmsSingleObject, updateResponse, hmsObject } = useSelector(state => state.Hms)
  const [modal, setModal] = useState(false)
  const [bed, setBed] = useState({})
  const [rudFlag, setRUDFlag] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [bedData, setBedData] = useState({})

  const columns = [{
    dataField: "ward",
    text: "Ward",
    sort: false,
    formatter: (cellContent, row) => (
      <Link to="#" onClick={() => handleEdit(row)}>{row.ward_name}</Link>
    )
  }, {
    dataField: "bed_number",
    text: "Bed number",
    sort: false,
  }, {
    dataField: "room_number",
    text: "Room number",
    sort: false,
  }, {
    dataField: "floor",
    text: "Floor",
    sort: false,
  }, {
    dataField: "is_available",
    text: "Available",
    sort: false,
    formatter: (cellContent, row) => (
      <span>{row.is_available
        ? <i className="bx bxs-check-circle font-size-16 text-success"></i>
        : <i className="bx bxs-x-circle font-size-16 text-danger"></i>
      }</span>
    )
  }, {
    dataField: "room_category",
    text: "Room category",
    sort: false,
    formatter: (cellContent, row) => (
      <span>{row.room_category_name}</span>
    )
  }, {
    dataField: "room_type",
    text: "Sharing",
    sort: false,
  }, {
    dataField: "price",
    text: "Price",
    sort: false,
  }, {
    dataField: "action",
    isDummyField: true,
    text: "",
    formatter: (cellContent, row) => (
      <UncontrolledDropdown className="ms-auto">
        <DropdownToggle
          className="text-muted font-size-16"
          color="white"
        >
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link className="dropdown-item" to="#" onClick={() => handleEdit(row)}>
            Modify
          </Link>
          <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
            Remove
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
  }
  ]

  useEffect(() => {
    if (apiKey === "list_bed") setBedData(hmsList)
    if (apiKey === "post_bed") {
      setBedData({ ...bedData, results: [hmsSingleObject, ...bedData.results] })
      setModal(false)
    }
    if (apiKey === "update_bed") {
      setBedData(updatePaginationResult(bedData, updateResponse, 'id'))
      setModal(false)
    }
    if (apiKey === "delete_bed") {
      setBedData(deletePaginationResult(bedData, 'id', hmsObject))
      setModal(false)
      toggleDelete()
      toastr.success(`Bed ${bed?.bed_number} deleted from room number ${bed?.room_number}`)
    }
    if (formError && apiKey.startsWith("fail_")) {
      const includedKeys = ["non_field_errors"]
      handleIncludedKeysError(formError, apiKey, "fail_", includedKeys, false)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/bedconfig/?options=formschema,search", "options_bed", false, true));
    dispatch(listHms("/hms/patient/bedconfig/", null, "list_bed"))
    return () => dispatch(resetHmsState())
  }, [])

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/patient/bedconfig/", decodeURIComponent(urlParams).replace("?", ""), "list_bed"))
      setBedData({})
    } else {
      dispatch(listHms("/hms/patient/bedconfig/", null, "list_bed"))
      setBedData({})
    }
  }

  const handleEdit = bed_object => {
    setBed(bed_object)
    setRUDFlag(0)
    toggle(false)
  }

  const handleDelete = bed_object => {
    setBed(bed_object)
    toggleDelete(false)
  }

  const handleCreate = () => {
    setBed({})
    setRUDFlag(1)
    toggle(false)
  }

  const toggle = (def = modal) => {
    setModal(!def)
  }

  const toggleDelete = (state = true) => {
    setDeleteModal(!state)
  }

  function createButton() {
    return (
      <div className="text-sm-start">
        <Button
          type="button"
          color="primary"
          className="me-2 btn-sm"
          onClick={handleCreate}
        >
          <i className="mdi mdi-plus me-1" />
          Create a bed
        </Button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {apiKey === "options_options_bed" ? (
            <Loader />
          ) : error && !["BED_IN_USE"].includes(error.code) ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (!isEmpty(columns)) && (
            <GenericTable
              urlParams={urlParams}
              search={options?.search}
              columns={columns}
              keyField={"id"}
              noDataIndication={"No records created"}
              data={bedData}
              loading={apiKey === "list_list_bed"}
              apiTrigger={apiTrigger}
              defaultPageSize={options?.page_size}
              createButton={createButton}
              removeQueryParams
            />
          )}
        </Container>
      </div>

      <BedCRUDModal
        modal={modal}
        toggle={toggle}
        rudFlag={rudFlag}
        apiStartKey="bed_config"
        data={bed}
        options={true}
      />

      <HmsDeleteModal
        body={<>
          <ul>
            <li>Move patients to another beds before removing bed</li>
            <li>If bed is removed. You cannot get it back</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/patient/bedconfig/", bed?.id, "delete_bed")}
        deleteModal={deleteModal}
      />
    </React.Fragment>
  )
}

export default BedConfig;
