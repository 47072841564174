import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { listHms, resetHmsState } from "store/actions"
import PharmacyHomeDetail from "./home-details"
import PharmacyHome from "./home"
import { Container } from "reactstrap"

const PharmacyIndex = (props) => {
  const [activeTab, setActiveTab] = useState(null);
  const [configuration, setConfiguration] = useState({})
  const dispatch = useDispatch()

  const { hmsList, apiKey, postResponse, updateResponse, error } = useSelector((state) => ({
    hmsList: state.Hms.hmsList,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
    error: state.Hms.error,
  }))

  useEffect(() => {
    if (apiKey === "fetch_pharmacy_configuration") {
      setConfiguration(hmsList?.results?.[0])
      setActiveTab(hmsList?.results?.length > 0 ? "PharmacyHomeDetail" : "PharmacyHome")
    }
    if (apiKey === "configuration_pharmacy_post") {
      setConfiguration(postResponse)
      setActiveTab("PharmacyHome")
    }
    if (apiKey === "configuration_pharmacy_update") {
      setConfiguration(updateResponse)
      setActiveTab("PharmacyHome")
    }

  }, [apiKey])

  useEffect(() => {
    dispatch(listHms("hms/pharmacy-configuration/", null, "fetch_pharmacy_configuration"))
    return () => dispatch(resetHmsState())
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {error ?
            <p className="text-center text-danger">{error?.detail}</p>
            :
            <>
              {activeTab === "PharmacyHomeDetail" ? (
                <PharmacyHomeDetail
                  configuration={configuration}
                  setActiveTab={setActiveTab}
                />
              ) : activeTab === "PharmacyHome" ? (
                <PharmacyHome
                  configuration={configuration}
                  setActiveTab={setActiveTab}
                />
              ) : null}
            </>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PharmacyIndex
