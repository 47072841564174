import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { Button, Card, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"
import { isEmpty } from "lodash";
import { GroupCRUModal } from "./modals";

const TestGroup = (props) => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const [modals, setModals] = useState({ test: false, parameter: false, delete: false })
  const [test, setTest] = useState({})
  const [tests, setTests] = useState({})
  const [rudFlag, setRUDFlag] = useState(null)
  const [columns, setColumns] = useState([])
  const [filters, setFilter] = useState({})

  const { loading, options, hmsList, apiKey, error } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    if (apiKey === "tests_list") {
      setTests(hmsList)
    }
    if (apiKey === "test_group_deleted") {
      setTests(hmsList)
      toggleDelete()
      toastr.success(`Test ${test.name} is deleted`)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/test-groups/?options=table, search, daterange, filters, formschema", "test_group_options"))
    dispatch(listHms("/hms/diagnosis/test-groups/", null, "tests_list"))
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(options)) {
      if (options.filters && apiKey === "test_group_options") setFilter(options.filters);

      if (options.table_columns) {
        const buffer = options.table_columns.map(item => {
          if (item.dataField === "name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link to={`/hms/diagnosis/test/test-groups/${row.id}/detail`}>
                  {row.name}
                </Link>
              )
            };
          } else {
            return { ...item };
          }
        });

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle className="text-muted font-size-16 border-0" color="white" disabled={row?.status === "Expired"}>
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link to="#" className="dropdown-item" onClick={() => handleEdit(row)}>
                  Modify
                </Link>
                <Link to="#" className="dropdown-item" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        };

        setColumns([...buffer, actionColumn]);
      }
    }
  }, [options]);

  const handleEdit = (test_object) => {
    setTest(test_object)
    setRUDFlag(0)
    toggleTest(false)
  }

  const handleDelete = (test_object) => {
    setTest(test_object)
    toggleDelete(false)
  }

  const handleCreate = () => {
    setTest({})
    setRUDFlag(1)
    toggleTest(false)
  }

  function toggleTest(def = modals.test) {
    setModals(prevModals => ({ ...prevModals, test: !def }));
  }

  function toggleDelete(def = modals.delete) {
    setModals(prevModals => ({ ...prevModals, delete: !def }));
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/diagnosis/test-groups/', decodeURIComponent(urlParams).replace("?", ""), "tests_list"))
      setTests({})
    } else {
      dispatch(listHms('/hms/diagnosis/test-groups/', null, "tests_list"))
      setTests({})
    }
  }

  function createButton() {
    return (
      <div className="text-sm-start">
        <Button
          type="button"
          color="primary"
          className="me-2 btn-sm"
          onClick={handleCreate}
        >
          <i className="mdi mdi-plus me-1" />
          Add New Test Group
        </Button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Test Group | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                {(!isEmpty(columns)) && (
                  <GenericTable
                    urlParams={urlParams}
                    filters={filters}
                    search={options.search}
                    columns={columns}
                    keyField={"id"}
                    noDataIndication={"No tests created"}
                    data={tests}
                    loading={loading}
                    apiTrigger={apiTrigger}
                    defaultPageSize={options.page_size}
                    createButton={createButton}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <HmsDeleteModal
          body={<>
            <ul>
              <li>If test group is removed. You cannot get it back</li>
              <li>If any data linked to this record will be vanished</li>
            </ul>
          </>}
          loading={loading}
          error={error}
          toggleDelete={toggleDelete}
          dispatchOperation={deleteHms("/hms/diagnosis/test-groups/", test.id, "test_group_deleted")}
          deleteModal={modals.delete}
        />

        <GroupCRUModal
          modal={modals.test}
          toggle={toggleTest}
          rudFlag={rudFlag}
          data={test}
          apiStartKey="test_group"
        />
      </div>
    </React.Fragment>
  )
}

export default TestGroup;