import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { Card, Col, Row, CardBody, Label, Form, Button } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { deleteHms, getHmsOptions, postHms, postUploadHms, resetHmsState } from "store/actions"
import toastr from "toastr"
import { handleError, Loader, SubmitLoaderButton } from "pages/HMS/common/common"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import { EAvFieldInput } from "pages/HMS/common/errored-avfields"
import { AvForm } from "availity-reactstrap-validation"
import { isEmpty } from "lodash"
import { cleanFilenameForSign } from "components/Common/common"

const Support = (props) => {
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { apiKey, error, formError, options, signedURLs, actionStatus, hmsObject } = useSelector(state => state.Hms)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [filePreviews, setFilePreviews] = useState([])
  const [signedLinks, setSignedLinks] = useState([])

  useEffect(() => {
    if (apiKey === "ticket_created") {
      formRef.current?.reset()
      setSelectedFiles([])
      setFilePreviews([])
      setSignedLinks([])
    }
    if (apiKey?.startsWith("file_deleted")) {
      setFilePreviews(prevPreviews =>
        prevPreviews.filter(prevState => cleanFilenameForSign(prevState.path) !== hmsObject)
      );
      setSignedLinks(prevLinks =>
        prevLinks.filter(prevState => prevState.file_name !== hmsObject)
      );
    }
    if (formError && apiKey.startsWith("fail_ticket_created")) {
      const excludedKeys = ["description"]
      handleError(formError, apiKey, "fail_ticket_created", excludedKeys)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/tickets/?options=formschema", "ticket_options"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  const handleValidSubmit = (e, values) => {
    values.attachments = !isEmpty(signedLinks) ? signedLinks.map((f) => f.file_path) : []
    dispatch(postHms("/hms/tickets/", values, "ticket_created", false))
  }

  const handleAcceptedFiles = (files) => {
    let filenames = [];
    let callBucket = {};
    let previews = [];

    if (files?.length + signedLinks?.length > 10) {
      toastr.error("You can upload a maximum of 10 files");
      return;
    }

    files.forEach(file => {
      if (file.size > 3 * 1024 * 1024) {
        toastr.error("File size exceeds 3MB");
        return;
      }

      if (!file.type.startsWith("image/")) {
        toastr.error("Only image files are allowed");
        return;
      }

      let uid = Date.now() + Math.floor(Math.random() * 1000);
      let filename = cleanFilenameForSign(file.name);
      filenames.push({ uid: uid, filename: filename });
      callBucket[uid] = file;

      previews.push(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size)
        })
      )
    });

    setSelectedFiles(prevFiles => ({ ...prevFiles, ...callBucket }));
    setFilePreviews(prevPreviews => [...prevPreviews, ...previews]);

    if (!isEmpty(filenames)) {
      let payload = { filenames: filenames, target: "support" };
      dispatch(postUploadHms("/hms/uploader/upload/", payload, "upload_support"));
    }
  }

  const removeFile = (file) => {
    if (file?.path) {
      dispatch(deleteHms("/hms/uploader/delete/?file=", cleanFilenameForSign(file?.path), `file_deleted_${file?.path}`))
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  useEffect(() => {
    if (!isEmpty(signedURLs)) {
      let filenames = []
      Promise.all(signedURLs.map(signedURL => fetch(signedURL.safelink, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: selectedFiles[signedURL.uid]
      }))).then(responses => {
        responses.map(response => {
          if (response.status === 200) {
            filenames.push(response.url)
          }
        })
        if (!isEmpty(filenames)) {
          filenames.map((filename) => {
            setSignedLinks((prevVal) => ([
              ...prevVal,
              {
                uid: Date.now() + Math.floor(Math.random() * 1000),
                file_path: filename.match(/support\/[^?]+/)[0],
                file_name: filename.match(/\/([^\/?]+)\?/)[1]
              }
            ]));
          });
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        setSelectedFiles([])
      })
    }
  }, [signedURLs])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Support | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          <Col xl={["ticket_created_success"].includes(actionStatus) ? 6 : 12}>
            <Card>
              <CardBody>
                {apiKey === "options_ticket_options" ? (
                  <Loader />
                ) : error ? (
                  <p className="text-center text-danger">{error.detail}</p>
                ) : (["ticket_created_success"].includes(actionStatus)) ? (
                  <div className="p-2">
                    <div className="text-center">
                      <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                      <div className="p-2 mt-2">
                        <h4>Ticket raised successfully</h4>
                        <p className="mt-2 mb-0">
                          Thanks for writing to us. We will get back to you shortly.
                        </p>
                        <p>
                          Our technical team will look into your ticket and take into account if your issue/enhancement meets the acceptance criteria.
                        </p>
                        <div className="d-flex justify-content-center flex-wrap mt-3">
                          <Button
                            color="primary"
                            onClick={() => window.location.replace("/profile/support")}
                            className={window.innerWidth <= 425 ? "btn-sm me-1 mb-1" : "me-1 mb-1"}
                          >
                            Raise Another
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
                    <Row>
                      <Col>
                        <EAvFieldInput
                          field="description"
                          name="description"
                          type="textarea"
                          isError={formError?.description}
                          options={options?.form_schema?.description}
                          placeholder="Describe your issue"
                          helpMessage={options?.form_schema?.description?.help_text}
                          rows="5"
                          validate={{
                            required: { value: true, errorMessage: "Description is required" },
                            maxLength: { value: 1000, errorMessage: "Description must not exceed 1000 characters" },
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {options?.form_schema?.attachments?.label}
                      </Label>
                      <Form>
                        <div className="dropzone-previews mb-4" id="file-previews">
                          <Row className="g-3">
                            {filePreviews?.map((file, index) =>
                              signedLinks.some((link) => link.file_name === cleanFilenameForSign(file.path)) && (
                                <Col xl={3} lg={4} md={6} sm={12} key={index}>
                                  <Card className="shadow-sm border dz-processing dz-image-preview dz-success dz-complete mb-2 position-relative d-inline-block w-100">
                                    <div className="p-1">
                                      <Row className="align-items-center ps-0">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            width="100"
                                            className="avatar-sm rounded bg-light"
                                            src={file.preview}
                                          />
                                        </Col>
                                        <Col className="ps-0 text-truncate">
                                          <Link to="#" className="text-muted font-weight-bold">
                                            {file.name}
                                          </Link>
                                        </Col>
                                      </Row>
                                    </div>
                                    <Link
                                      to="#"
                                      label="Remove"
                                      className="p-0 position-absolute bg-white rounded-circle cursor-pointer d-flex justify-content-center align-items-center"
                                      style={{ top: "-13px", right: "-12px", border: "1px solid #ccc", width: "24px", height: "24px" }}
                                      onClick={apiKey === `delete_file_deleted_${file?.path}` ? undefined : () => removeFile(file)}
                                    >
                                      <i className={`mdi mdi-close-thick font-size-16 ${apiKey === `delete_file_deleted_${file?.path}` ? "text-secondary" : "text-danger"}`} />
                                    </Link>
                                  </Card>
                                </Col>
                              ))}
                          </Row>
                        </div>
                        <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)} multiple accept="image/*" maxSize={3 * 1024 * 1024} maxFiles={10}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div className="dz-message needsclick" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Form>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <SubmitLoaderButton type="submit" loading={apiKey === "post_ticket_created"}>
                            Submit
                          </SubmitLoaderButton>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Support;
