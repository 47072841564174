import { deletePaginationResult, prependToPaginationResult, updatePaginationResult } from "components/Common/common"
import { DELETE_HMS, DELETE_HMS_FAIL, DELETE_HMS_SUCCESS, DELETE_DIAG_TEST_PARAM, DELETE_DIAG_TEST_PARAM_FAIL, DELETE_DIAG_TEST_PARAM_SUCCESS, DOWNLOAD_REPORT_HMS, DOWNLOAD_REPORT_HMS_FAIL, DOWNLOAD_REPORT_HMS_SUCCESS, GET_HMS, GET_HMS_FAIL, GET_HMS_SUCCESS, GET_DIAG_TEST_PARAM, GET_DIAG_TEST_PARAM_FAIL, GET_DIAG_TEST_PARAM_SUCCESS, LIST_HMS, LIST_HMS_FAIL, LIST_HMS_SUCCESS, LIST_DIAG_TEST_PARAM, LIST_DIAG_TEST_PARAM_FAIL, LIST_DIAG_TEST_PARAM_SUCCESS, OPTIONS_HMS, OPTIONS_HMS_FAIL, OPTIONS_HMS_SUCCESS, POST_HMS, POST_HMS_FAIL, POST_HMS_SUCCESS, POST_DIAG_TEST_PARAM, POST_DIAG_TEST_PARAM_FAIL, POST_DIAG_TEST_PARAM_SUCCESS, RESET_HMS, UPDATE_HMS, UPDATE_HMS_FAIL, UPDATE_HMS_SUCCESS, UPDATE_DIAG_TEST_PARAM, UPDATE_DIAG_TEST_PARAM_FAIL, UPDATE_DIAG_TEST_PARAM_SUCCESS, UPLOAD_HMS, UPLOAD_HMS_FAIL, UPLOAD_HMS_SUCCESS, CHAINED_OPTIONS_HMS, CHAINED_OPTIONS_HMS_SUCCESS, CHAINED_OPTIONS_HMS_FAIL, OPTIONS_PUBLIC, OPTIONS_PUBLIC_SUCCESS, OPTIONS_PUBLIC_FAIL, GET_PUBLIC, GET_PUBLIC_SUCCESS, GET_PUBLIC_FAIL, LIST_PUBLIC, LIST_PUBLIC_SUCCESS, LIST_PUBLIC_FAIL, POST_PUBLIC, POST_PUBLIC_SUCCESS, POST_PUBLIC_FAIL, UPDATE_PUBLIC, UPDATE_PUBLIC_SUCCESS, UPDATE_PUBLIC_FAIL, DELETE_PUBLIC, DELETE_PUBLIC_SUCCESS, DELETE_PUBLIC_FAIL } from "./actionTypes"
import { addObject, removeObject, updateObject, alterPaymentModeChoices, formSchemaConversionAvField } from "pages/HMS/common/common";

const INIT_STATE = {
  loading: false,
  options: {},
  apiObject: {},
  appointmentObject: {},
  error: null,
  formError: null,

  isPosted: false,
  isUpdated: false,
  isDeleted: false,
  uploadingLeads: false,
  autoAssign: false,
  assignToAgents: null,
  patients: {},
  patientObject: {},
  dropdownData: {},
  formData: {
    step: 1
  },
  displayOptionsModified: false,
  dashboardData: {},
  timeslotObject: {},
  timeslots: [],

  hmsObject: {},
  apiKey: null,
  hmsList: [],
  crudStatus: "neutral",
  cudStatus: null,
  reportDownloadStatus: null,
  base64Report: null,
  hmsSingleObject: {},
  postResponse: {},
  updateResponse: {},
  actionStatus: null,
  signedURLs: null,
  paramList: [],
  paramObject: {},
  paramSingleObject: {},
  optionsChainedHms: {},
  modifiedFormSchema: {},
}


const Hms = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_HMS:
      return INIT_STATE;

    case OPTIONS_HMS:
      return {
        ...state,
        loading: true,
        error: null,
        apiKey: `options_${action.key}`,
      }
    case OPTIONS_HMS_SUCCESS:
      return {
        ...state,
        loading: false,
        options: action.alterPaymentModeChoices ? alterPaymentModeChoices(action.payload) : action.payload,
        modifiedFormSchema: action.modifiedFormSchema ? formSchemaConversionAvField(action.payload.form_schema) : state.modifiedFormSchema,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
      }
    case OPTIONS_HMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case GET_HMS:
      return {
        ...state,
        loading: true,
        error: null,
        apiKey: `get_${action.key}`,
      }
    case GET_HMS_SUCCESS:
      return {
        ...state,
        loading: false,
        hmsObject: action.payload,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
      }
    case GET_HMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case LIST_HMS:
      return {
        ...state,
        error: null,
        loading: true,
        hmsList: {},
        apiKey: `list_${action.key}`,
        // hmsObject: {},
      }
    case LIST_HMS_SUCCESS:
      return {
        ...state,
        loading: false,
        hmsList: action.payload,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
        // hmsObject: action.payload,
      }
    case LIST_HMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case POST_HMS:
      return {
        ...state,
        loading: true,
        error: null,
        formError: null,
        apiKey: `post_${action.key}`,
      }
    case POST_HMS_SUCCESS:
      return {
        ...state,
        loading: false,
        hmsList: action.prepend ? prependToPaginationResult(state.hmsList, action.payload) : state.hmsList,
        postResponse: action.payload,
        apiKey: action.key,
        hmsSingleObject: action.payload,
        actionStatus: `${action.key}_success`
      }
    case POST_HMS_FAIL:
      return {
        ...state,
        loading: false,
        formError: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case UPDATE_HMS:
      return {
        ...state,
        loading: true,
        formError: null,
        apiKey: `update_${action.key}`,
      }
    case UPDATE_HMS_SUCCESS:
      return {
        ...state,
        loading: false,
        hmsList: updatePaginationResult(state.hmsList, action.payload, action.updateKey),
        updateResponse: action.payload,
        apiKey: action.key,
        hmsSingleObject: action.payload,
        actionStatus: `${action.key}_success`
      }
    case UPDATE_HMS_FAIL:
      return {
        ...state,
        loading: false,
        formError: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case DELETE_HMS:
      return {
        ...state,
        loading: true,
        formError: null,
        error: null,
        apiKey: `delete_${action.key}`,
        // isPosted: false,
        // isUpdated: false,
        // isDeleted: true,
        // cudStatus: "DELETING"
      }
    case DELETE_HMS_SUCCESS:
      return {
        ...state,
        loading: false,
        hmsList: deletePaginationResult(state.hmsList, 'id', action.payload),
        apiKey: action.key,
        actionStatus: `${action.key}_success`,
        hmsObject: action.payload,
        // isDeleted: false,
        // cudStatus: "DELETED",
      }
    case DELETE_HMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
        // isDeleted: false,
        // cudStatus: "DELETE_FAIL",
      }

    case DOWNLOAD_REPORT_HMS:
      return {
        ...state,
        loading: true,
        error: null,
        apiKey: `get_${action.key}`,
      }
    case DOWNLOAD_REPORT_HMS_SUCCESS:
      return {
        ...state,
        loading: false,
        apiKey: action.key,
        hmsObject: action.payload
      }
    case DOWNLOAD_REPORT_HMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case UPLOAD_HMS:
      return {
        ...state,
        formError: null,
        loading: true,
        apiKey: `post_${action.key}`,
      }
    case UPLOAD_HMS_SUCCESS:
      return {
        ...state,
        formError: null,
        loading: false,
        apiKey: action.key,
        signedURLs: action.payload,
      }
    case UPLOAD_HMS_FAIL:
      return {
        ...state,
        formError: action.payload,
        loading: false,
        apiKey: `fail_${action.key}`,
      }

    case LIST_DIAG_TEST_PARAM:
      return {
        ...state,
        error: null,
        loading: true,
        paramList: {},
        apiKey: `list_${action.key}`,
        // paramObject: {},
      }
    case LIST_DIAG_TEST_PARAM_SUCCESS:
      return {
        ...state,
        loading: false,
        paramList: action.payload,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
        // paramObject: action.payload,
      }
    case LIST_DIAG_TEST_PARAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case GET_DIAG_TEST_PARAM:
      return {
        ...state,
        loading: true,
        error: null,
        apiKey: `get_${action.key}`,
      }
    case GET_DIAG_TEST_PARAM_SUCCESS:
      return {
        ...state,
        loading: false,
        paramObject: action.payload,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
      }
    case GET_DIAG_TEST_PARAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case POST_DIAG_TEST_PARAM:
      return {
        ...state,
        loading: true,
        error: null,
        formError: null,
        apiKey: `post_${action.key}`,
      }
    case POST_DIAG_TEST_PARAM_SUCCESS:
      return {
        ...state,
        loading: false,
        paramList: addObject(action.payload, state.paramList),
        postResponse: action.payload,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
      }
    case POST_DIAG_TEST_PARAM_FAIL:
      return {
        ...state,
        loading: false,
        formError: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case UPDATE_DIAG_TEST_PARAM:
      return {
        ...state,
        loading: true,
        formError: null,
        apiKey: `update_${action.key}`,
      }
    case UPDATE_DIAG_TEST_PARAM_SUCCESS:
      return {
        ...state,
        loading: false,
        paramList: updateObject(action.payload, state.paramList),
        updateResponse: action.payload,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
      }
    case UPDATE_DIAG_TEST_PARAM_FAIL:
      return {
        ...state,
        loading: false,
        formError: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case DELETE_DIAG_TEST_PARAM:
      return {
        ...state,
        loading: true,
        formError: null,
        error: null,
        apiKey: `delete_${action.key}`,
      }
    case DELETE_DIAG_TEST_PARAM_SUCCESS:
      return {
        ...state,
        loading: false,
        paramList: removeObject(action.payload, state.paramList),
        apiKey: action.key,
        actionStatus: `${action.key}_success`,
      }
    case DELETE_DIAG_TEST_PARAM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case CHAINED_OPTIONS_HMS:
      return {
        ...state,
        error: null,
        loading: true,
        optionsChainedHms: {},
        apiKey: `list_${action.key}`,
      }
    case CHAINED_OPTIONS_HMS_SUCCESS:
      return {
        ...state,
        loading: false,
        optionsChainedHms: action.payload,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
      }
    case CHAINED_OPTIONS_HMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case OPTIONS_PUBLIC:
      return {
        ...state,
        loading: true,
        error: null,
        apiKey: `options_${action.key}`,
      }
    case OPTIONS_PUBLIC_SUCCESS:
      return {
        ...state,
        loading: false,
        options: action.alterPaymentModeChoices ? alterPaymentModeChoices(action.payload) : action.payload,
        modifiedFormSchema: action.modifiedFormSchema ? formSchemaConversionAvField(action.payload.form_schema) : state.modifiedFormSchema,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
      }
    case OPTIONS_PUBLIC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case GET_PUBLIC:
      return {
        ...state,
        loading: true,
        error: null,
        apiKey: `get_${action.key}`,
      }
    case GET_PUBLIC_SUCCESS:
      return {
        ...state,
        loading: false,
        hmsObject: action.payload,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
      }
    case GET_PUBLIC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case LIST_PUBLIC:
      return {
        ...state,
        error: null,
        loading: true,
        hmsList: {},
        apiKey: `list_${action.key}`,
      }
    case LIST_PUBLIC_SUCCESS:
      return {
        ...state,
        loading: false,
        hmsList: action.payload,
        apiKey: action.key,
        actionStatus: `${action.key}_success`
      }
    case LIST_PUBLIC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case POST_PUBLIC:
      return {
        ...state,
        loading: true,
        error: null,
        formError: null,
        apiKey: `post_${action.key}`,
      }
    case POST_PUBLIC_SUCCESS:
      return {
        ...state,
        loading: false,
        hmsList: action.prepend ? prependToPaginationResult(state.hmsList, action.payload) : state.hmsList,
        postResponse: action.payload,
        apiKey: action.key,
        hmsSingleObject: action.payload,
        actionStatus: `${action.key}_success`
      }
    case POST_PUBLIC_FAIL:
      return {
        ...state,
        loading: false,
        formError: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case UPDATE_PUBLIC:
      return {
        ...state,
        loading: true,
        formError: null,
        apiKey: `update_${action.key}`,
      }
    case UPDATE_PUBLIC_SUCCESS:
      return {
        ...state,
        loading: false,
        hmsList: updatePaginationResult(state.hmsList, action.payload, action.updateKey),
        updateResponse: action.payload,
        apiKey: action.key,
        hmsSingleObject: action.payload,
        actionStatus: `${action.key}_success`
      }
    case UPDATE_PUBLIC_FAIL:
      return {
        ...state,
        loading: false,
        formError: action.payload,
        apiKey: `fail_${action.key}`,
      }

    case DELETE_PUBLIC:
      return {
        ...state,
        loading: true,
        formError: null,
        error: null,
        apiKey: `delete_${action.key}`,
      }
    case DELETE_PUBLIC_SUCCESS:
      return {
        ...state,
        loading: false,
        hmsList: deletePaginationResult(state.hmsList, 'id', action.payload),
        apiKey: action.key,
        actionStatus: `${action.key}_success`,
        hmsObject: action.payload,
      }
    case DELETE_PUBLIC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        apiKey: `fail_${action.key}`,
      }

    default:
      return state
  }
}

export default Hms
