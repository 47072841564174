import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import FontSize from "./font-size"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const DisplaySettings = (props) => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(window.location.hash);

  useEffect(() => {
    history.replace(`/profile/display-settings/${activeTab}`)
  }, [activeTab]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>General | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card className="email-leftbar" style={{ position: "fixed" }}>
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "#font-size" })}
                        onClick={() => setActiveTab("#font-size")}
                      >
                        <i className="mdi mdi-format-font me-2"></i>Font Size {" "}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Card>

              <div className="email-rightbar mb-3">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="#font-size">
                    {activeTab === "#font-size" && <FontSize />}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DisplaySettings