import React, { useRef, useState, useEffect } from "react"
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { updateHms, postHms, chainedOptionsHms } from "store/actions"
import { EAvFieldSelect, RequiredFieldsMessage, EAsyncSelect, EAvFieldGenericInput, EAvFieldInput } from "pages/HMS/common/errored-avfields"
import { PhonenumberInput } from "components/Common/input-advanced"
import { handlePhonenumberBlur, handlePhonenumberFocus, errorMappings, SubmitLoaderButton, EAvFieldDOB, Loader, search } from "pages/HMS/common/common"
import toastr from "toastr"
import { isEmpty } from "lodash"
import ConfirmationDialog from "pages/HMS/common/confirmation-dialog"
import moment from "moment"
import { SingleFieldCRUModal } from "pages/HMS/common/hms-crud-modals"

export const StaffCRUDModal = ({ modal, toggle, rudFlag, data = {}, apiStartKey }) => {
  const dispatch = useDispatch()
  const formRef = useRef()
  const { apiKey, optionsChainedHms, formError, options, postResponse, modifiedFormSchema } = useSelector((state) => state.Hms)
  const [phonenumber, setPhonenumber] = useState(null);
  const [alternatePhonenumber, setAlternatePhonenumber] = useState(null);
  const [emergencyNumber, setEmergencyNumber] = useState(null);
  const [phoneError, setPhoneError] = useState('');
  const [activeOPAlert, setActiveOPAlert] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [customError, setCustomError] = useState(null)
  const [modalQualification, setModalQualification] = useState(false)
  const [age, setAge] = useState({ dob: null, years: null, months: null, days: null })
  const [state, setState] = useState({ title: '', message: '', isExist: false, full_name: '', username: null });
  const [defaultOptions, setDefaultOptions] = useState({ qualifications: [], roles: null });
  const [selectedState, setSelectedState] = useState({ qualifications: null, roles: null, person: null, });

  useEffect(() => { setCustomError(formError) }, [formError])

  useEffect(() => {
    if (!modal) return;

    const { dob, age, groups } = data?.user_data || {};
    if (dob) {
      const now = moment();
      setAge({ dob, years: age, months: now.diff(dob, "months"), days: now.diff(dob, "days") });
    }

    if (rudFlag === 0) {
      setSelectedState((prevState) => ({
        ...prevState,
        qualifications: data?.qualifications,
        roles: groups,
      }));
    }
  }, [modal]);

  useEffect(() => {
    if (apiKey === `${apiStartKey}_created`) {
      staffToggle()
      toastr.success("Staff created successfully")
      formRef.current?.reset()
    }
    if (apiKey === `${apiStartKey}_updated`) {
      staffToggle()
      toastr.success("Staff updated successfully")
    }
    if (apiKey === "fetch_roles") {
      setDefaultOptions((prevState) => ({
        ...prevState,
        roles: optionsChainedHms?.results,
      }));
    }
    if (apiKey === "fetch_qualifications") {
      setDefaultOptions((prevState) => ({
        ...prevState,
        qualifications: optionsChainedHms,
      }));
    }
    if (apiKey === "staff_qualification_created") {
      setSelectedState((prevState) => ({
        ...prevState,
        qualifications: prevState?.qualifications === null ? [postResponse] : [...prevState?.qualifications, postResponse],
      }));
    }
    if (formError?.detail && apiKey.startsWith("fail_staff_")) {
      setActiveOPAlert(true)
      const [code, username, full_name] = formError?.detail.split("_-_");
      setState((prevState) => ({ ...prevState, ...errorMappings[code], username, full_name }));
      setSelectedState((prevState) => ({
        ...prevState,
        person: { username, full_name }
      }));
    }
  }, [apiKey])

  const handleValidSubmit = (e, values) => {
    if (!phonenumber && !isChecked) {
      setPhoneError('This field is invalid');
      return;
    }
    setPhoneError('');
    values.alternate_phone_number = alternatePhonenumber
    values.emergency_contact_number = emergencyNumber
    values.qualifications = !isEmpty(selectedState?.qualifications) ? selectedState?.qualifications?.map(qualification => qualification?.id) : null;

    values.user_post = {
      username: values.username,
      full_name: values.full_name,
      email: values.email,
      phone_number: phonenumber,
      gender: values.gender,
      dob: age?.dob,
      groups: !isEmpty(selectedState?.roles) ? selectedState?.roles?.map(role => role?.id) : []
    }
    delete values.username
    delete values.full_name
    delete values.email
    delete values.phone_number
    delete values.gender
    delete values.dob

    if (state.isExist || isChecked) {
      delete values.user_post
      values.username = state?.username || selectedState?.person?.username
    }

    if (rudFlag === 0) {
      dispatch(updateHms("/hms/staff/staff/", data.id, values, `${apiStartKey}_updated`))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/staff/staff/", values, `${apiStartKey}_created`))
    }
  }

  const searchRoles = (value, callback) => {
    search(value, callback, "/hms/roles/")
  }

  const searchDiscountUsers = (value, callback) => {
    search(value, callback, "/hms/patient/outpatients/fetch_discount_users/", () => setIsMenuOpen(value.length > 0))
  }

  const searchQualifications = (value, callback) => {
    search(value, callback, "/hms/staff/qualifications/fetch_all/")
  }

  const onConfirm = () => {
    setState((prevState) => ({ ...prevState, isExist: true }));
    setActiveOPAlert(false)
    setIsChecked(true);
    formRef.current.submit();
  }

  function toggleQualification(def = modalQualification) {
    setModalQualification(!def)
    setCustomError(null)
  }

  const staffToggle = () => {
    setAge({})
    setCustomError(null)
    setIsChecked(false)
    setSelectedState((prevState) => ({ ...prevState, qualifications: null, roles: null, person: null }));
    setState((prevState) => ({ ...prevState, isExist: false }));
    toggle()
  }

  return (
    <>
      <Modal isOpen={modal} toggle={staffToggle} backdrop="static" size="lg">
        <ModalHeader toggle={staffToggle} tag="h4">
          <div className="d-flex">
            <span>{rudFlag === 0 ? "Modify Staff" : rudFlag === 1 ? "Add Staff" : ""}</span>
            {rudFlag === 1 && (
              <div className="form-check form-switch form-switch-sm" style={{ marginLeft: "10px" }}>
                <input type="checkbox" className="form-check-input" id="customSwitchsizemd" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                <label className="form-check-label" htmlFor="customSwitchsizemd">Convert To Staff</label>
              </div>
            )}
          </div>
        </ModalHeader>
        {["options_staff_options", "options_staff_detail_options"].includes(apiKey) ? (
          <Loader />
        ) : (
          <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
            <ModalBody style={{ height: "75vh", overflowY: "auto" }}>
              {state.isExist || isChecked ? (
                <Row>
                  <Col>
                    <div className="ajax-select select2-container">
                      <EAsyncSelect
                        isDisabled={!isChecked}
                        options={{ ...options?.form_schema?.full_name, label: "Full name" }}
                        getOptionLabel={e => e?.full_name}
                        getOptionValue={e => e?.username}
                        placeholder="Search users..."
                        onMenuClose={() => setIsMenuOpen(false)}
                        menuIsOpen={isMenuOpen}
                        value={[selectedState?.person]}
                        loadOptions={searchDiscountUsers}
                        onChange={(value) => setSelectedState((prevState) => ({ ...prevState, person: value }))}
                        formError={formError?.username}
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    <Col md={6}>
                      <EAvFieldGenericInput
                        value={data?.user_data?.full_name}
                        isError={customError?.full_name}
                        {...modifiedFormSchema?.full_name}
                      />
                    </Col>
                    <Col md={6}>
                      <EAvFieldGenericInput
                        value={data?.user_data?.username}
                        isError={customError?.username}
                        {...modifiedFormSchema?.username}
                        placeholder="Ex: user-123"
                        helpMessage="An unique id to identify the user"
                        label="Username *"
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      <PhonenumberInput
                        label="Phone number"
                        name="phone_number"
                        value={data?.user_data?.phone_number}
                        error={customError?.phone_number || phoneError}
                        required={true}
                        updateValue={setPhonenumber}
                        onFocus={() => handlePhonenumberFocus(setPhoneError)}
                        onBlur={() => handlePhonenumberBlur(phonenumber, setPhoneError)}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <PhonenumberInput
                        label={options?.form_schema?.alternate_phone_number?.label}
                        name="alternate_phone_number"
                        value={data?.alternate_phone_number}
                        error={customError?.alternate_phone_number}
                        required={options?.form_schema?.alternate_phone_number?.required}
                        updateValue={setAlternatePhonenumber}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <EAvFieldGenericInput
                        value={data?.user_data?.email}
                        isError={customError?.email}
                        {...modifiedFormSchema?.email}
                        label="Email"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6} lg={4}>
                      <EAvFieldSelect
                        field="gender"
                        value={data?.user_data?.gender}
                        isError={customError?.gender}
                        options={{ label: options?.form_schema?.gender?.label, required: true }}
                        choices={
                          <>
                            <option>{"-Select an option-"}</option>
                            {options?.form_schema?.gender?.choices?.map((choice, cidx) =>
                              <option value={choice.value} key={cidx}>{choice.display_name}</option>
                            )}
                          </>
                        }
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <EAvFieldDOB
                        age={age}
                        setAge={setAge}
                        required={options?.form_schema?.dob?.required}
                        width="40%"
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <EAvFieldSelect
                        field="marital_status"
                        value={data.marital_status !== undefined && data.marital_status.toString()}
                        isError={customError?.marital_status}
                        options={{ label: options?.form_schema?.marital_status?.label, required: true }}
                        choices={
                          <>
                            <option>{"---Select an option---"}</option>
                            {[{ value: "true", display_name: "Married" }, { value: "false", display_name: "Unmarried" }].map((choice, cidx) =>
                              <option value={choice.value} key={cidx}>{choice.display_name}</option>
                            )}
                          </>
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3 ajax-select select2-container" style={{ flex: "1" }}>
                        <EAsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions={defaultOptions?.roles}
                          onFocus={() => dispatch(chainedOptionsHms("/hms/roles/", null, "fetch_roles"))}
                          options={options?.form_schema?.groups}
                          value={selectedState?.roles}
                          loadOptions={searchRoles}
                          formError={formError?.groups}
                          onChange={(roles) => setSelectedState((prevState) => ({ ...prevState, roles: roles }))}
                          placeholder="Type to search roles..."
                          getOptionLabel={e => e?.name}
                          getOptionValue={e => e?.id}
                          helpMessage={options?.form_schema?.groups?.help_text}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <h6 className="mt-4 text-muted">Address</h6>
              <hr />
              <Row>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.address}
                    isError={customError?.address}
                    {...modifiedFormSchema?.address}
                  />
                </Col>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.city}
                    isError={customError?.city}
                    {...modifiedFormSchema?.city}
                  />
                </Col>
              </Row>
              <h6 className="mt-4 text-muted">Emergency information</h6>
              <hr />
              <Row>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.emergency_contact_name}
                    isError={customError?.emergency_contact_name}
                    {...modifiedFormSchema?.emergency_contact_name}
                  />
                </Col>
                <Col>
                  <EAvFieldGenericInput
                    value={data?.emergency_contact_relation}
                    isError={customError?.emergency_contact_relation}
                    {...modifiedFormSchema?.emergency_contact_relation}
                  />
                </Col>
                <Col>
                  <PhonenumberInput
                    label="Emergency contact number"
                    name="emergency_contact_number"
                    value={data?.emergency_contact_number}
                    error={customError?.emergency_contact_number}
                    required={options?.form_schema?.emergency_contact_number?.required}
                    updateValue={setEmergencyNumber}
                  />
                </Col>
              </Row>
              <h6 className="mt-4 text-muted">Professional Information</h6>
              <hr />
              <Row>
                <Col xs={12} md={7}>
                  <div className="d-flex align-items-center">
                    <div className="mb-3 ajax-select select2-container" style={{ flex: "1" }}>
                      <EAsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions={defaultOptions?.qualifications}
                        onFocus={() => dispatch(chainedOptionsHms("/hms/staff/qualifications/fetch_all/", null, "fetch_qualifications"))}
                        options={options?.form_schema?.qualifications}
                        value={selectedState?.qualifications}
                        loadOptions={searchQualifications}
                        formError={customError?.qualifications}
                        onChange={(qualifications) => setSelectedState((prevState) => ({ ...prevState, qualifications: qualifications }))}
                        placeholder="Type to search qualifications..."
                        getOptionLabel={e => e?.name}
                        getOptionValue={e => e?.id}
                      />
                    </div>
                    <div onClick={() => toggleQualification(false)} title="Add qualifications" className={`cursor-pointer ms-2 ${customError?.qualifications ? '' : 'mt-3'}`}>
                      <i className="bx bx-plus-medical text-success" />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={5}>
                  <EAvFieldGenericInput
                    value={data?.experience}
                    isError={customError?.experience}
                    {...modifiedFormSchema?.experience}
                    label="Years of experience"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <EAvFieldGenericInput
                    value={data?.certifications}
                    isError={customError?.certifications}
                    {...modifiedFormSchema?.certifications}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <EAvFieldGenericInput
                    value={data?.medical_license_number}
                    isError={customError?.medical_license_number}
                    {...modifiedFormSchema?.medical_license_number}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EAvFieldInput
                    field="date_of_join"
                    type="date"
                    value={data?.date_of_join}
                    isError={customError?.date_of_join}
                    options={options?.form_schema?.date_of_join}
                    dateRange={{ start: { value: -150, units: "years" }, end: { value: 90, units: "days" } }}
                  />
                </Col>
                <Col>
                  <EAvFieldSelect
                    field="role"
                    value={data?.role}
                    isError={customError?.role}
                    options={options?.form_schema?.role}
                    choices={<>
                      <option value={""}>{"---------Select an option---------"}</option>
                      {options?.form_schema?.role?.choices?.map((choice, cidx) =>
                        <option value={choice.value} key={cidx}>{choice.display_name}</option>
                      )}
                    </>}
                  />
                </Col>
              </Row>
              <RequiredFieldsMessage />
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col className="text-end">
                  <SubmitLoaderButton type="submit" size="sm" loading={[`update_${apiStartKey}_updated`, `post_${apiStartKey}_created`].includes(apiKey)}>
                    SAVE
                  </SubmitLoaderButton>
                </Col>
              </Row>
            </ModalFooter>
          </AvForm>
        )}
      </Modal>

      <ConfirmationDialog
        activeOPAlert={activeOPAlert}
        setActiveOPAlert={setActiveOPAlert}
        state={state}
        onConfirm={onConfirm}
      />

      <SingleFieldCRUModal
        modalLabel={"Qualification"}
        modal={modalQualification}
        toggle={toggleQualification}
        rudFlag={1}
        apiStartKey={"staff_qualification"}
        apiEndpoint={"/hms/staff/qualifications/"}
      />
    </>
  )
}
