import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row, CardBody } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { resetHmsState, getHms, updateHms, postHms, listHms, getHmsOptions } from "store/actions"
import toastr from "toastr"
import { Loader } from "pages/HMS/common/common"
import { DownloadModal } from "pages/HMS/common/hms-crud-modals"
import { ConfigureCRUModal } from "../configure/modals"
import { prependToArray, updateArrayObject } from "components/Common/common"
import Dropdown from "./dropdown"

const Utilize = () => {
  const dispatch = useDispatch()
  const { loading, hmsList, apiKey, hmsObject, postResponse, updateResponse } = useSelector(state => state.Hms)
  const [reportData, setReportData] = useState([])
  const [reportKey, setReportKey] = useState("")
  const [settings, setSettings] = useState(null)
  const [rudFlag, setRUDFlag] = useState(null)
  const [modals, setModals] = useState({ configureModal: false, downloadModal: false })
  const [report, setReport] = useState({ header_type: "Default" })

  useEffect(() => {
    if (apiKey === "fetch_settings") {
      setSettings(hmsObject)
    }
    if (apiKey === "settings_created") {
      toastr.success("Settings created")
      setSettings(postResponse)
    }
    if (apiKey === "settings_updated") {
      toastr.success("Settings updated")
      setSettings(updateResponse)
    }
    if (apiKey === "fetch_all_layouts") {
      setReportData(hmsList)
    }
    if (apiKey === `preview_layout_${reportKey}`) {
      toggleModal("downloadModal")
    }
    if (apiKey === "configure_utilize_created") {
      setReportData((prevData) => (prependToArray(prevData, postResponse)))
    }
    if (apiKey === "configure_utilize_updated") {
      setReportData((prevData) => (updateArrayObject(prevData, updateResponse, "id")))
      setReport(updateResponse)
    }
    if (["preview_diagnosis_lab_report", "preview_op_report", "preview_op_prescription_report"].includes(apiKey)) {
      const payload = { ...hmsObject, preview_on: reportKey }
      dispatch(postHms("/hms/hms-report-layouts/preview/", payload, `preview_layout_${reportKey}`, false))
      setReport(hmsObject)
    }
    if (apiKey === "edit_report_layout") {
      setReport(hmsObject)
      handleEdit()
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/hms-report-layouts/?options=formschema", "fetch_config_formschema", false, true))
    dispatch(getHms("/hms/hms-settings/", "unique", "fetch_settings"))
    dispatch(listHms("/hms/hms-report-layouts/fetch_all/", null, "fetch_all_layouts"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  const setSelectedReportKey = (key) => {
    setReportKey(key)
  }

  const handleSelect = (e) => {
    const { name, value } = e.target
    const payload = { [name]: value }

    if (settings?.id) {
      dispatch(updateHms("/hms/hms-settings/", "unique", payload, "settings_updated"))
    } else {
      dispatch(postHms("/hms/hms-settings/", { ...payload, type: "unique" }, "settings_created", false))
    }
  }

  const editReportLayout = (report_id) => {
    if (report_id) {
      dispatch(getHms("/hms/hms-report-layouts/", report_id, "edit_report_layout"))
    }
  }

  const toggleModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }))
  }

  const handleCreate = () => {
    setRUDFlag(1)
    toggleModal("configureModal")
  }

  const handleEdit = () => {
    setRUDFlag(0)
    toggleModal("configureModal")
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs="12">
            <Card>
              <CardBody>
                {loading && apiKey === "get_fetch_settings" ? (
                  <Loader />
                ) : (
                  <>
                    <Dropdown
                      label={"Diagnosis Report"}
                      field={"diagnosis_lab_report"}
                      data={settings}
                      dropdownOptions={reportData}
                      loading={["get_preview_diagnosis_lab_report", "preview_diagnosis_lab_report", "post_preview_layout_diagnosis_lab_report"].includes(apiKey)}
                      handleSelect={handleSelect}
                      editReportLayout={editReportLayout}
                      handleCreate={handleCreate}
                      setSelectedReportKey={setSelectedReportKey}
                    />
                    <Dropdown
                      label={"Outpatient Report"}
                      field={"op_report"}
                      data={settings}
                      dropdownOptions={reportData}
                      loading={["get_preview_op_report", "preview_op_report", "post_preview_layout_op_report"].includes(apiKey)}
                      handleSelect={handleSelect}
                      editReportLayout={editReportLayout}
                      handleCreate={handleCreate}
                      setSelectedReportKey={setSelectedReportKey}
                    />
                    <Dropdown
                      label={"Outpatient Prescription Report"}
                      field={"op_prescription_report"}
                      data={settings}
                      dropdownOptions={reportData}
                      loading={["get_preview_op_prescription_report", "preview_op_prescription_report", "post_preview_layout_op_prescription_report"].includes(apiKey)}
                      handleSelect={handleSelect}
                      editReportLayout={editReportLayout}
                      handleCreate={handleCreate}
                      setSelectedReportKey={setSelectedReportKey}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ConfigureCRUModal
        modal={modals.configureModal}
        toggle={() => toggleModal("configureModal")}
        rudFlag={rudFlag}
        data={rudFlag === 0 ? report : { header_type: "Default" }}
        apiStartKey="configure_utilize"
        prepend={false}
      />

      <DownloadModal
        modal={modals.downloadModal}
        toggle={() => toggleModal("downloadModal")}
        apiStartKey={`preview_layout_${reportKey}`}
        base64String={postResponse}
      />
    </React.Fragment>
  )
}

export default Utilize
