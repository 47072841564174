import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { getHmsOptions, listHms, postHms, updateHms } from "store/hms/actions";
import { EAsyncSelect, EAvFieldGenericInput, RequiredFieldsMessage } from "../../common/errored-avfields"
import toastr from "toastr"
import { Loader, search, SubmitLoaderButton } from "../../common/common";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { SingleFieldCRUModal } from "pages/HMS/common/hms-crud-modals";

export const BedCRUDModal = (props) => {
  const { modal, toggle, rudFlag, apiStartKey, data, prepend = false, ...rest } = props
  const formRef = useRef()
  const dispatch = useDispatch()
  const { options, hmsList, apiKey, formError, hmsSingleObject, modifiedFormSchema } = useSelector(state => state.Hms)
  const [customError, setCustomError] = useState()
  const [wardOptions, setWardOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [modals, setModals] = useState({ modalWard: false, modalCategory: false });
  const [selectedWard, setSelectedWard] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)

  useEffect(() => {
    if (!modal) return;
    if (!rest.options) {
      dispatch(getHmsOptions("/hms/patient/bedconfig/?options=formschema", `${apiStartKey}_options`, false, true));
    }
    if (rudFlag === 0) {
      setSelectedWard({ id: data?.ward, name: data?.ward_name })
      setSelectedCategory({ id: data?.room_category, name: data?.room_category_name })
    }
  }, [modal])

  useEffect(() => { if (formError) setCustomError(formError) }, [formError])

  useEffect(() => {
    if (apiKey === `${apiStartKey}_created`) {
      bedToggle()
      toastr.success("Bed created successfully")
      formRef.current?.reset()
    }
    if (apiKey === `${apiStartKey}_updated`) {
      bedToggle()
      toastr.success("Bed updated successfully")
    }
    if (apiKey === "options_ward") setWardOptions(hmsList?.results)
    if (apiKey === "options_category") setCategoryOptions(hmsList?.results)
    if (apiKey === "ward_created") setSelectedWard(hmsSingleObject)
    if (apiKey === "category_created") setSelectedCategory(hmsSingleObject)
  }, [apiKey])

  const handleSubmit = (e, values) => {
    values.ward = selectedWard?.id
    values.room_category = selectedCategory?.id

    if (rudFlag === 0) {
      dispatch(updateHms("/hms/patient/bedconfig/", data?.id, values, `${apiStartKey}_updated`))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/patient/bedconfig/", values, `${apiStartKey}_created`))
    }
  }

  const bedToggle = () => {
    setCustomError(null)
    setSelectedWard(null)
    setSelectedCategory(null)
    toggle()
  }

  const searchWards = (value, callback) => {
    search(value, callback, "/hms/patient/bed-ward/")
  }

  const searchCategories = (value, callback) => {
    search(value, callback, "/hms/patient/bed-category/")
  }

  const toggleModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }));
  };

  return (
    <>
      <Modal isOpen={modal} toggle={bedToggle} backdrop="static" size="lg">
        <ModalHeader toggle={bedToggle} tag="h4">
          {rudFlag === 0 ? "Edit Bed" : rudFlag === 1 ? "Add Bed" : ""}
        </ModalHeader>
        <ModalBody>
          {apiKey === `options_${apiStartKey}_options` ? (
            <Loader />
          ) : (
            <AvForm onValidSubmit={handleSubmit} ref={formRef}>
              {options?.form_schema && (
                <>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                        <div className="ajax-select select2-container" style={{ flex: "1" }}>
                          <Label className={customError?.ward ? "text-danger" : ''}>
                            {options?.form_schema?.ward?.label} {options?.form_schema?.ward?.required && " *"}
                          </Label>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ width: "100%" }}>
                              <EAsyncSelect
                                cacheOptions
                                value={[selectedWard]}
                                loadOptions={searchWards}
                                formError={customError?.ward}
                                onChange={(value) => setSelectedWard(value)}
                                placeholder="Type to search Ward..."
                                getOptionLabel={e => `${e?.name}`}
                                getOptionValue={e => e?.id}
                                defaultOptions={wardOptions}
                                onFocus={() => dispatch(listHms("/hms/patient/bed-ward/", null, "options_ward"))}
                              />
                            </div>
                            <div onClick={() => toggleModal("modalWard")} title="Add ward" style={{ cursor: "pointer" }} className="ms-2">
                              <i className="bx bx-plus-medical text-success" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                        <div className="ajax-select select2-container" style={{ flex: "1" }}>
                          <Label className={customError?.room_category ? "text-danger" : ''}>
                            {options?.form_schema?.room_category?.label} {options?.form_schema?.room_category?.required && " *"}
                          </Label>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ width: "100%" }}>
                              <EAsyncSelect
                                cacheOptions
                                value={[selectedCategory]}
                                loadOptions={searchCategories}
                                formError={customError?.room_category}
                                onChange={(value) => setSelectedCategory(value)}
                                placeholder="Type to search Category..."
                                getOptionLabel={e => `${e?.name}`}
                                getOptionValue={e => e?.id}
                                defaultOptions={categoryOptions}
                                onFocus={() => dispatch(listHms("/hms/patient/bed-category/", null, "options_category"))}
                              />
                            </div>
                            <div onClick={() => toggleModal("modalCategory")} title="Add category" style={{ cursor: "pointer" }} className="ms-2">
                              <i className="bx bx-plus-medical text-success" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      <EAvFieldGenericInput
                        value={data?.floor}
                        isError={customError?.floor}
                        {...modifiedFormSchema?.floor}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <EAvFieldGenericInput
                        value={data?.room_number}
                        isError={customError?.room_number}
                        {...modifiedFormSchema?.room_number}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <EAvFieldGenericInput
                        value={data?.bed_number}
                        isError={customError?.bed_number}
                        {...modifiedFormSchema?.bed_number}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <EAvFieldGenericInput
                        value={data?.room_type}
                        isError={customError?.room_type}
                        {...modifiedFormSchema?.room_type}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <EAvFieldGenericInput
                        value={data?.price}
                        isError={customError?.price}
                        {...modifiedFormSchema?.price}
                      />
                    </Col>
                    <RequiredFieldsMessage />
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end mb-2">
                        <SubmitLoaderButton type="submit" size="sm" loading={[`${apiStartKey}_created`, apiKey === `${apiStartKey}_updated`].includes(apiKey)}>
                          SAVE
                        </SubmitLoaderButton>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </AvForm>
          )}
        </ModalBody>
      </Modal>

      <SingleFieldCRUModal
        modalLabel="Ward"
        modal={modals.modalWard}
        toggle={() => toggleModal("modalWard")}
        rudFlag={1}
        apiStartKey="ward"
        apiEndpoint="/hms/patient/bed-ward/"
      />

      <SingleFieldCRUModal
        modalLabel="Category"
        modal={modals.modalCategory}
        toggle={() => toggleModal("modalCategory")}
        rudFlag={1}
        apiStartKey="category"
        apiEndpoint="/hms/patient/bed-category/"
      />
    </>
  )
}
