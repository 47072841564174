import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags";
import { Badge, Card, CardBody, Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getHmsOptions, listHms, resetHmsState } from "store/actions";
import GenericTable from "pages/HMS/common/table";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getLocalStorageItem, handleIncludedKeysError, updateURLParams } from "pages/HMS/common/common";
import { ReportDownload } from "components/Common/print-report";

const BookTestReport = props => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const dispatch = useDispatch()
  const { error, hmsList, options, apiKey } = useSelector(state => state.Hms)
  const [filters, setFilter] = useState({})
  const [columns, setColumns] = useState([])
  const [reportList, setReportList] = useState({})

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/book-tests/?options=table, search, daterange, filters", "report_options"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "report_options") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/diagnosis/book-tests/", decodeURIComponent(urlParams).replace("?", ""), "report_list"))
      } else {
        const savedFilters = getLocalStorageItem("filters")?.test_reports
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/diagnosis/book-tests/", savedFilters, "report_list"))
      }
    }
    if (apiKey === "report_list") {
      setReportList(hmsList)
    }
    if (error && error.code !== "TEST_DOES_NOT_EXIST" && apiKey.startsWith("fail_")) {
      const includedKeys = ["detail"]
      handleIncludedKeysError(error, apiKey, "fail_", includedKeys, false)
    }
  }, [apiKey]);

  useEffect(() => {
    if (!isEmpty(options) && apiKey === "report_options") {
      if (options.filters) setFilter(options.filters);
      if (options.table_columns) {
        const buffer = options.table_columns.map(item => {
          if (item.dataField === "patient_name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link to={`/hms/diagnosis/test/${row.id}/detail`} >
                  {row?.patient_data?.patient_name || "---"}
                </Link>
              )
            }
          } else if (item.dataField === "patient_uid") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.patient_data?.patient_uid || "---"
            }
          } else if (item.dataField === "module") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                row?.outpatient ? "Outpatient" : row?.inpatient ? "Inpatient" : row?.patient ? "Patient" : "Direct"
              )
            };
          } else if (item.dataField === "test_count") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                !isEmpty(row?.tests_data) ? (
                  row.tests_data.slice(0, 2).map((test, index) =>
                    index === 0 ? (
                      <Link
                        to="#"
                        className="badge badge-soft-primary font-size-11 m-1 text-truncate"
                        style={{ maxWidth: "100px" }}
                        key={test.id || index}
                        title={`${test.name} ${test?.short_name ? `(${test.short_name})` : ""}`}
                      >
                        {test.name} {test?.short_name ? `(${test.short_name})` : ""}
                      </Link>
                    ) : (
                      <Link
                        to="#"
                        className="badge badge-soft-primary font-size-11 m-1 text-truncate"
                        key={index}
                        title={row.tests_data.slice(1).map(test => test.name).join(", ")}
                      >
                        {row.tests_data.length - 1}+ more
                      </Link>
                    )
                  )
                ) : (
                  "---"
                )
              )
            }
          } else if (item.dataField === "referred_by") {
            return {
              ...item,
              formatter: (cellContent, row) => row.referred_by || row.referred_by_doctor_name || "---"
            }
          } else if (item.dataField === "is_finished") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                row?.is_finished ? (
                  <Badge pill color="success">Yes</Badge>
                ) : (
                  <Badge pill color="danger">No</Badge>
                )
              )
            };
          } else {
            return { ...item };
          }
        });

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle className="text-muted font-size-16 border-0" color="white" disabled={row?.status === "Expired"}>
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link className="dropdown-item" to={`/hms/diagnosis/test/book/${row.id}`}>
                  Modify
                </Link>
                <ReportDownload
                  url={`/hms/diagnosis/book-tests/${row?.id}/invoice_receipt/`}
                  label="Print Invoice"
                  type="menu-item"
                />
                <UncontrolledDropdown direction="down" title={!row?.is_finished ? "Please add results before downloading the report" : ""}>
                  <DropdownToggle className="dropdown-item" caret color="primary" disabled={!row?.is_finished}>
                    Download Report <i className="fa fa-caret-down ms-1" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <ReportDownload
                      label="Separate"
                      type="menu-item"
                      showIcon={false}
                      url={`/hms/diagnosis/book-tests/${row?.id}/test_report/`}
                      payload={{ action: "separate", tests: !isEmpty(row?.results) ? row?.results?.map(test => (test?.id)) : [] }}
                    />
                    <ReportDownload
                      label="Combined"
                      type="menu-item"
                      showIcon={false}
                      url={`/hms/diagnosis/book-tests/${row?.id}/test_report/`}
                      payload={{ action: "combined", tests: !isEmpty(row?.results) ? row?.results?.map(test => (test?.id)) : [] }}
                    />
                  </DropdownMenu>
                </UncontrolledDropdown>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        };

        setColumns([...buffer, actionColumn]);
      }
    }
  }, [options]);

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/diagnosis/book-tests/", urlParams, "report_list"))
      setReportList({})
    } else {
      dispatch(listHms("/hms/diagnosis/book-tests/", null, "report_list"))
      setReportList({})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {error && error.code !== "TEST_DOES_NOT_EXIST" ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (
            <>
              {(!isEmpty(columns)) &&
                <GenericTable
                  moduleName="test_reports"
                  urlParams={urlParams}
                  filters={filters}
                  search={options.search}
                  daterange={options.daterange}
                  columns={columns}
                  keyField={"id"}
                  noDataIndication={"No tests created"}
                  data={reportList}
                  loading={apiKey === "list_report_list"}
                  apiTrigger={apiTrigger}
                  defaultPageSize={options.page_size}
                />
              }
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BookTestReport
