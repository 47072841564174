import PropTypes from 'prop-types'
import React, { useState } from "react"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import ReactDrawer from 'react-drawer';
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from "../CommonForBoth/RightSidebar"
import { withTranslation } from "react-i18next"
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions"
import { menu } from 'pages/HMS/common/hms-menu';
import { savePinPage } from 'pages/HMS/common/common';


const Header = props => {
  const [megaMenu, setMegaMenu] = useState(false)
  const [open, setOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const onDrawerClose = () => {
    setOpen(false);
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => tToggle()}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <Dropdown
              className="dropdown-mega d-none d-lg-block ms-2"
              isOpen={megaMenu}
              toggle={() => setMegaMenu(!megaMenu)}
            >
              <DropdownToggle
                className="btn header-item"
                caret
                tag="button"
              >
                Services <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-megamenu">
                <Row>
                  <Col>
                    <Row>
                      {menu?.map((item, index) => (
                        <Col key={index}>
                          <h5 className="font-size-14 mt-0">{item?.name}</h5>
                          <ul className="list-unstyled megamenu-list">
                            {item?.children.map((child, index) => {
                              const isPinned = JSON.parse(localStorage.getItem("pinPage"))?.id === child?.id
                              return (
                                <li
                                  key={index}
                                  onMouseEnter={() => setHoveredIndex(child?.id)}
                                  onMouseLeave={() => setHoveredIndex(null)}
                                  style={{ position: 'relative' }}
                                >
                                  <Link
                                    to={child?.path}
                                    className={`d-flex align-items-center ${isPinned ? 'text-success' : 'text-muted'}`}
                                    style={{ transition: 'color 0.3s ease' }}
                                  >
                                    <span>
                                      {child?.name}
                                    </span>
                                    {isPinned ? (
                                      <i
                                        className="mdi mdi-pin ms-2 text-success"
                                        style={{ transition: 'opacity 0.3s ease-in-out' }}
                                        title="Unpin this to landing page"
                                        onClick={() => savePinPage(child?.path, child?.id)}
                                      />
                                    ) : (hoveredIndex === child?.id && (
                                      <i
                                        className="mdi mdi-pin-outline ms-2"
                                        style={{ transition: 'opacity 0.3s ease-in-out' }}
                                        title="Pin this to landing page"
                                        onClick={() => savePinPage(child?.path, child?.id)}
                                      />
                                    ))}
                                  </Link>
                                </li>
                              )
                            })}
                          </ul>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={toggleFullscreen}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            {/* <NotificationDropdown /> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
      <ReactDrawer
        open={open}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
