import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { listHms, resetHmsState } from "store/actions"
import DiagnosisHomeDetail from "./home-details"
import DiagnosisHome from "./home"
import { Container } from "reactstrap"

const DiagnosisIndex = (props) => {
  const [activeTab, setActiveTab] = useState(null);
  const [configuration, setConfiguration] = useState({})
  const dispatch = useDispatch()

  const { hmsList, apiKey, postResponse, updateResponse, error } = useSelector((state) => ({
    hmsList: state.Hms.hmsList,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
    error: state.Hms.error,
  }))

  useEffect(() => {
    if (apiKey === "fetch_diagnosis_configuration") {
      setConfiguration(hmsList?.results?.[0])
      setActiveTab(hmsList?.results?.length > 0 ? "DiagnosisHomeDetail" : "DiagnosisHome")
    }
    if (apiKey === "configuration_diagnosis_post") {
      setConfiguration(postResponse)
      setActiveTab("DiagnosisHome")
    }
    if (apiKey === "configuration_diagnosis_update") {
      setConfiguration(updateResponse)
      setActiveTab("DiagnosisHome")
    }

  }, [apiKey])

  useEffect(() => {
    dispatch(listHms("hms/diagnosis-configuration/", null, "fetch_diagnosis_configuration"))
    return () => dispatch(resetHmsState())
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {error ?
            <p className="text-center text-danger">{error?.detail}</p>
            :
            <>
              {activeTab === "DiagnosisHomeDetail" ? (
                <DiagnosisHomeDetail
                  configuration={configuration}
                  setActiveTab={setActiveTab}
                />
              ) : activeTab === "DiagnosisHome" ? (
                <DiagnosisHome
                  configuration={configuration}
                  setActiveTab={setActiveTab}
                />
              ) : null}
            </>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DiagnosisIndex
