import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isMobileView } from "pages/HMS/common/common"

const HmsSidebarContent = props => {
  const ref = useRef()
  const isMobile = isMobileView()
  const [role, setRole] = useState(null)
  const [userObject, setUserObject] = useState(null)

  useEffect(() => {
    let authObject = JSON.parse(localStorage.getItem("authUser"))
    setRole(authObject.role)
    setUserObject(authObject)
  }, [])

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {isMobile ? (
              <>
                <li>
                  <Link to="/hms">
                    <i className="bx bx-bar-chart-alt-2"></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link to="/hms/patient">
                    <i className="bx bx-clinic"></i>
                    <span>Patient Management</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/hms/patient/appointment/book"><span>Book Appointment</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/appointments"><span>Appointments</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/register"><span>Patient Registration</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/patients"><span>Patients</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/op/register"><span>OP Registration</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/ops"><span>Out-Patients</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/inpatient/register"><span>Inpatient Admission</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/inpatients"><span>In-Patients</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/inpatient/discharge/billing"><span>Inpatient Discharge Billing</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/treatment"><span>Treatment</span></Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/hms/staff">
                    <i className="bx bx-id-card"></i>
                    <span>Staff Management</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/hms/staff/doctors"><span>Doctors</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/staff/doctors/add"><span>Add Doctor</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/staff/staff"><span>Staff</span></Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/hms/pharmacy">
                    <i className="mdi mdi-pharmacy"></i>
                    <span>Pharmacy</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/hms/pharmacy/home"><span>Home</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/medicines"><span>Medicines</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/stock"><span>Stock</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/order-medicine"><span>Orders</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/supplier"><span>Suppliers</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/medicine-sale"><span>Medicine Sale</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/sold-out-medicines"><span>Sold Out Medicines</span></Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/hms/diagnosis">
                    <i className="mdi mdi-clipboard-text-search-outline"></i>
                    <span>Diagnosis</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/hms/diagnosis/home"><span>Home</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/diagnosis/test/book"><span>Book Test</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/diagnosis/test/reports"><span>Reports</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/diagnosis/tests"><span>Tests</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/diagnosis/tests/categories"><span>Test Categories</span></Link>
                    </li>
                    <li>
                      <Link to="/hms/diagnosis/test/test-groups"><span>Test Group</span></Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/hms/billing">
                    <i className="bx bx-wallet"></i>
                    <span>Billing and Finance</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/hms/billing/transactions"><span>Transactions</span></Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-title">Settings</li>
                <li>
                  <Link to="/hms/settings/administration">
                    <i className="bx bx-cog"></i>
                    <span>Administration</span>
                  </Link>
                </li>
                <li>
                  <Link to="/hms/settings/reports">
                    <i className="bx bx-chart"></i>
                    <span>Reports</span>
                  </Link>
                </li>
              </>
            ) : (
              <>
                {(location.pathname === "/hms") && (
                  <li>
                    <Link to="/hms">
                      <i className="bx icon-letter">DA</i>
                      <span>Dashboard</span>
                    </Link>
                  </li>
                )}
                {(location.pathname.startsWith("/hms/patient")) && (
                  <>
                    <li className="menu-title">Patient Management</li>
                    <li>
                      <Link to="/hms/patient/appointment/book">
                        <i className="bx icon-letter">BA</i>
                        <span>Book Appointment</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/appointments">
                        <i className="bx icon-letter">AP</i>
                        <span>Appointments</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/register">
                        <i className="bx icon-letter">PR</i>
                        <span>Patient Registration</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/patients">
                        <i className="bx icon-letter">PA</i>
                        <span>Patients</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/op/register">
                        <i className="bx icon-letter">OR</i>
                        <span>OP Registration</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/ops">
                        <i className="bx icon-letter">OP</i>
                        <span>Out-Patients</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/inpatient/register">
                        <i className="bx icon-letter">IA</i>
                        <span>Inpatient Admission</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/inpatients">
                        <i className="bx icon-letter">IP</i>
                        <span>In-Patients</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/inpatient/discharge/billing">
                        <i className="bx icon-letter">DB</i>
                        <span>Inpatient Discharge Billing</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/patient/treatment">
                        <i className="bx icon-letter">TR</i>
                        <span>Treatment</span>
                      </Link>
                    </li>
                  </>
                )}
                {(location.pathname.startsWith("/hms/staff")) && (
                  <>
                    <li className="menu-title">Staff Management</li>
                    <li>
                      <Link to="/hms/staff/doctors">
                        <i className="bx icon-letter">DO</i>
                        <span>Doctors</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/staff/doctors/add">
                        <i className="bx icon-letter">AD</i>
                        <span>Add Doctor</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/staff/staff">
                        <i className="bx icon-letter">ST</i>
                        <span>Staff</span>
                      </Link>
                    </li>
                  </>
                )}
                {(location.pathname.startsWith("/hms/pharmacy")) && (
                  <>
                    <li className="menu-title">Pharmacy</li>
                    <li>
                      <Link to="/hms/pharmacy/home">
                        <i className="bx icon-letter">HO</i>
                        <span>Home</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/medicines">
                        <i className="bx icon-letter">ME</i>
                        <span>Medicines</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/stock">
                        <i className="bx icon-letter">ST</i>
                        <span>Stock</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/order-medicine">
                        <i className="bx icon-letter">OR</i>
                        <span>Orders</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/supplier">
                        <i className="bx icon-letter">SU</i>
                        <span>Suppliers</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/medicine-sale">
                        <i className="bx icon-letter">MS</i>
                        <span>Medicine Sale</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/pharmacy/sold-out-medicines">
                        <i className="bx icon-letter">SO</i>
                        <span>Sold Out Medicines</span>
                      </Link>
                    </li>
                  </>
                )}
                {(location.pathname.startsWith("/hms/diagnosis")) && (
                  <>
                    <li className="menu-title">Diagnosis</li>
                    <li>
                      <Link to="/hms/diagnosis/home">
                        <i className="bx icon-letter">HO</i>
                        <span>Home</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/diagnosis/test/book">
                        <i className="bx icon-letter">BT</i>
                        <span>Book Test</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/diagnosis/test/reports">
                        <i className="bx icon-letter">RE</i>
                        <span>Reports</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/diagnosis/tests">
                        <i className="bx icon-letter">TE</i>
                        <span>Tests</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/diagnosis/tests/categories">
                        <i className="bx icon-letter">TC</i>
                        <span>Test Categories</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/diagnosis/test/test-groups">
                        <i className="bx icon-letter">TG</i>
                        <span>Test Group</span>
                      </Link>
                    </li>
                  </>
                )}
                {(location.pathname.startsWith("/hms/billing")) && (
                  <>
                    <li className="menu-title">Billing and Finance</li>
                    <li>
                      <Link to="/hms/billing/transactions">
                        <i className="bx icon-letter">TR</i>
                        <span>Transactions</span>
                      </Link>
                    </li>
                  </>
                )}
                {(location.pathname.startsWith("/hms/settings")) && (
                  <>
                    <li className="menu-title">Settings</li>
                    <li>
                      <Link to="/hms/settings/administration">
                        <i className="bx icon-letter">AD</i>
                        <span>Administration</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hms/settings/reports">
                        <i className="bx icon-letter">RE</i>
                        <span>Reports</span>
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment >
  )
}

HmsSidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(HmsSidebarContent))