import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Row, Col, CardTitle, Button } from "reactstrap";
import { getHms, getHmsOptions, listHms, postHms, resetHmsState, updateHms } from "store/hms/actions";
import { AvForm } from "availity-reactstrap-validation";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { TableChip } from "components/Common/common";
import { ReportDownload } from "components/Common/print-report";
import { EAsyncSelect, EAvFieldInput, EAvFieldNumber, EAvFieldSelect, RequiredFieldsMessage, AsyncSearch } from "pages/HMS/common/errored-avfields";
import { EAvFieldDiscount, floatRoundDown, GoBack, handleError, Loader, PatientInformation, search } from "pages/HMS/common/common";
import SweetAlert from "react-bootstrap-sweetalert";

const CreateOP = (props) => {
  const dispatch = useDispatch()
  const { loading, error, options, hmsObject, actionStatus, formError, apiKey, hmsSingleObject, hmsList } = useSelector((state) => state.Hms)
  const [showDiscountPercent, setShowDiscountPercent] = useState(() => JSON.parse(localStorage.getItem('fieldTransfers'))?.showDiscountPercent ?? true)
  const [patientSearch, setPatientSearch] = useState(!props.match.params.opID);
  const [formOptions, setFormOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({ doctor: null, discountedPerson: null })
  const [patient, setPatient] = useState([])
  const [opDetail, setOpDetail] = useState({})
  const [isMenuOpen, setIsMenuOpen] = useState({ searchPatients: false, discountedBy: false })
  const [searchOptions, setSearchOptions] = useState({ doctors: null })
  const [activeOPAlert, setActiveOPAlert] = useState(false)
  const [billing, setBilling] = useState({
    discount_percent: 0,
    discount_amount: 0,
    sub_total_amount: 0,
    grand_total_amount: 0,
    cloned_discount_percent: 0,
  })

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/outpatients/?options=formschema", "fetch_op_options"))
    if (props.match.params.opID) {
      dispatch(getHms('/hms/patient/outpatients/', props.match.params.opID, "get_op"))
    }
    return () => dispatch(resetHmsState())
  }, [dispatch])

  useEffect(() => {
    if (options.form_schema) setFormOptions(options.form_schema)
  }, [options])

  useEffect(() => {
    if (apiKey === "get_op") {
      !hmsObject?.parent && removeFollowUp();
      setOpDetail({
        ...hmsObject.patient_data,
        address: hmsObject.patient_data.address_data,
      })
      setSelectedOptions({
        doctor: hmsObject?.doctor_data,
        discountedPerson: hmsObject?.billing?.discount_by ? { id: hmsObject?.billing?.discount_by, full_name: hmsObject?.billing?.discount_by_name } : null,
      });
      setBilling(prevState => ({
        ...prevState,
        discount_percent: hmsObject?.billing?.discount_percent,
        discount_amount: hmsObject?.billing?.discount_amount,
        sub_total_amount: hmsObject?.billing?.sub_total_amount,
        grand_total_amount: hmsObject?.billing?.grand_total_amount.toString(),
      }))
      setPatient(hmsObject)
      dispatch(resetHmsState())
    }
    if (apiKey === "op_check") {
      if (!hmsObject?.is_existed) {
        dispatch(getHms("/hms/patient/patients/", hmsObject?.patient, "op_detail"))
        setPatient({})
        removeFollowUp();
        setPatientSearch(false)
      }
      setActiveOPAlert(hmsObject?.is_existed)
      if (hmsObject?.is_existed) setPatient({ ...hmsObject, parent: hmsObject?.id, mode_of_visit: "FOLLOW_UP" })
    }
    if (apiKey === "op_detail") setOpDetail(hmsObject)
    if (apiKey === "fail_get_op") {
      dispatch(resetHmsState())
      setPatientSearch(true)
    }
    if (formError && apiKey.startsWith("fail_op")) {
      const excludedKeys = [
        "mode_of_visit",
        "reference_type",
        "referred_by",
        "doctor",
        "discount_percent",
        "discount_amount",
        "sub_total_amount",
        "grand_total_amount",
        "payment_mode",
        "cheque_number",
        "cheque_date",
        "height",
        "weight",
        "blood_pressure",
        "spo2",
        "temperature",
        "pulse",
      ]
      handleError(formError, apiKey, "fail_op", excludedKeys)
    }
    if (apiKey === "fetch_consulting_doctors") {
      setSearchOptions({ ...searchOptions, doctors: hmsList?.results })
    }
  }, [apiKey])

  const handleSubmit = (event, values) => {
    values = {
      patient: patient?.patient,
      parent: patient?.parent,
      mode_of_visit: values.mode_of_visit || "DIRECT",
      reference_type: values.reference_type,
      referred_by: values.referred_by,
      doctor: selectedOptions?.doctor?.id,
      height: values.height,
      weight: values.weight,
      blood_pressure: values.blood_pressure,
      spo2: values.spo2,
      temperature: values.temperature,
      pulse: values.pulse,
      billing_post: {
        customer_name: `${opDetail?.first_name} ${opDetail?.last_name}`,
        discount_by: selectedOptions?.discountedPerson ? selectedOptions?.discountedPerson.id : null,
        discount_percent: billing?.discount_percent,
        discount_amount: billing?.discount_amount,
        sub_total_amount: billing?.sub_total_amount,
        grand_total_amount: billing?.grand_total_amount,
        payment_mode: values.payment_mode,
        cheque_date: values.cheque_date,
        cheque_number: values.cheque_number,
      }
    }

    if (props.match.params.opID) {
      dispatch(updateHms('/hms/patient/outpatients/', patient.id, values, "op_modified"))
    } else {
      values.patient = opDetail.id
      dispatch(postHms('/hms/patient/outpatients/', values, "op_created"))
    }
  }

  const searchPatients = (value, callback) => {
    search(value, callback, "/hms/patient/patients/search_patients/", () => setIsMenuOpen((prev) => ({ ...prev, searchPatients: value.length > 0 })), "query")
  }

  const patientSelect = (patient) => {
    if (!isEmpty(patient)) {
      dispatch(getHms(`/hms/patient/patients/${patient.id}/op_check/`, null, "op_check"))
    }
  }

  const updateDoctorSelect = (doctor) => {
    setSelectedOptions({ ...selectedOptions, doctor: doctor })
    setBilling(prevState => ({
      ...prevState,
      discount_percent: 0,
      discount_amount: 0,
      sub_total_amount: doctor?.fee,
      grand_total_amount: doctor?.fee,
    }))
  }

  const searchDoctors = (value, callback) => {
    search(value, callback, "/hms/staff/doctors/")
  }

  const searchDiscountUsers = (value, callback) => {
    search(value, callback, "/hms/patient/outpatients/fetch_discount_users/", () => setIsMenuOpen((prev) => ({ ...prev, discountedBy: value.length > 0 })))
  }

  const onDiscountPercentChange = (value) => {
    if (!isNaN(value) && value >= 0 && value <= 100) {
      let fee = document.getElementById("id_sub_total_amount").value
      fee = fee ? parseFloat(fee).toFixed(2) : 0
      let discount_amount = ((value * fee) / 100).toFixed(2)
      let grand_total_amount = (fee - discount_amount).toFixed(2)
      setBilling(prevState => ({
        ...prevState,
        discount_percent: value,
        discount_amount,
        grand_total_amount,
      }))
      document.getElementById("discounted_by_section").style.display = discount_amount > 0 ? "block" : "none"
    } else {
      setBilling(prevState => ({
        ...prevState,
        discount_amount: 0,
        grand_total_amount: document.getElementById("id_sub_total_amount").value,
      }))
    }
  }

  const onDiscountAmountChange = (value) => {
    const subTotal = floatRoundDown(document.getElementById("id_sub_total_amount").value)

    if (!isNaN(value) && value >= 0 && value <= subTotal) {
      const discountAmount = floatRoundDown(value);
      const grandTotalAmount = floatRoundDown(subTotal - discountAmount);
      const discountPercent = floatRoundDown((value / subTotal) * 100);

      setBilling((prevState) => ({
        ...prevState,
        discount_percent: discountPercent,
        discount_amount: discountAmount,
        grand_total_amount: grandTotalAmount,
      }));

      document.getElementById("discounted_by_section").style.display = grandTotalAmount > 0 ? "block" : "none"
    } else {
      setBilling((prevState) => ({
        ...prevState,
        discount_amount: 0,
        grand_total_amount: subTotal,
      }));
    }
  }

  const onPaymentModeChange = (e) => {
    const value = e.target.value
    document.getElementById("cheque-section").style.display = value === "CHEQUE" ? "block" : "none";
    setPatient(prevState => ({ ...prevState, payment_mode: value }))
    if (value === "SELF") {
      setBilling(prevState => ({ ...prevState, cloned_discount_percent: billing?.discount_percent }))
      onDiscountPercentChange(100)
    } else if (billing?.discount_percent === 100) {
      onDiscountPercentChange(billing?.cloned_discount_percent)
    }
  }

  const onReferenceTypeChange = (e) => {
    document.getElementById("reference-section").style.display = e.target.value === "REFERRAL" ? "block" : "none";
  }

  function removeFollowUp() {
    setFormOptions({ ...formOptions, mode_of_visit: { ...formOptions?.mode_of_visit, choices: formOptions?.mode_of_visit?.choices.filter(choice => choice?.value !== "FOLLOW_UP") } });
  }

  const onSubTotalChange = (e) => {
    const value = e.target.value
    setBilling(prevState => ({ ...prevState, sub_total_amount: value }))
    onDiscountPercentChange(billing?.discount_percent)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>OP Registration | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          {activeOPAlert &&
            <SweetAlert
              title="OP already existed"
              warning
              showCancel
              confirmBtnText="No"
              cancelBtnText="Yes"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => setActiveOPAlert(false)}
              onCancel={() => {
                dispatch(getHms("/hms/patient/patients/", hmsObject?.patient, "op_detail"))
                setPatientSearch(false)
                setActiveOPAlert(false)
              }}
            >
              An active OP already existed for this patient. Do you want to create a follow-up OP?
            </SweetAlert>
          }
          {patientSearch ? (
            <Col xl={8}>
              <Card>
                <CardBody>
                  <AsyncSearch
                    onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, searchPatients: false }))}
                    menuIsOpen={isMenuOpen.searchPatients}
                    value={null}
                    loadOptions={searchPatients}
                    onChange={patientSelect}
                    placeholder="Search with patient name, id, mobile, email"
                    noOptionsMessage={() => (
                      <p className="text-center my-4" style={{ letterSpacing: '1px' }}>
                        No patient records found?{" "}
                        <Link to="/hms/patient/register" className="font-weight-bold">
                          Create a new patient
                        </Link>
                      </p>
                    )}
                    getOptionLabel={(e) => (
                      <div className="custom-option">
                        <div className="user-box">
                          <p className="user-title m-0">{e.full_name}</p>
                          <p className="text-muted mb-0">
                            {e.uid} {e.email ? `| ${e.email}` : ""} | {e.phone_number}
                          </p>
                        </div>
                      </div>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col xl={["op_created_success", "op_modified_success"].includes(actionStatus) ? 6 : 12}>
              <Card>
                <CardBody>
                  {error ?
                    <p className="text-center text-danger">{error.detail}</p>
                    : loading && apiKey === "options_fetch_op_options" || apiKey === "get_get_op" ? (
                      <Loader />
                    ) : (
                      <>
                        {(["op_created_success", "op_modified_success"].includes(actionStatus)) ? (
                          <div className="p-2">
                            <div className="text-center">
                              <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                              <div className="p-2 mt-2">
                                <h4>OP {props.match.params.opID ? "modified" : "created"} successfully</h4>
                                <div className="d-flex justify-content-center flex-wrap mt-4">
                                  <Button
                                    color="primary"
                                    onClick={() => window.location.replace("/hms/patient/op/register")}
                                    className={window.innerWidth <= 425 ? 'btn-sm me-1 mb-1' : 'me-1 mb-1'}
                                  >
                                    Add&nbsp;Another
                                  </Button>
                                  <Link
                                    to={"/hms/patient/ops"}
                                    className={window.innerWidth <= 425 ? 'btn-sm btn btn-primary me-1 mb-1' : 'me-1 btn btn-primary mb-1'}
                                  >
                                    Outpatients
                                  </Link>
                                  <ReportDownload
                                    url={`/hms/patient/outpatients/${hmsSingleObject?.id}/report_prescription/`}
                                    label="Print Report"
                                  />
                                  {hmsSingleObject?.mode_of_visit !== "FOLLOW_UP" && (
                                    <ReportDownload
                                      url={`/hms/patient/outpatients/${hmsSingleObject?.id}/invoice_receipt/`}
                                      label="Print Invoice"
                                    />
                                  )}
                                  <Link
                                    to={`/hms/patient/op/${hmsSingleObject?.id}/detail`}
                                    className={window.innerWidth <= 425 ? 'btn-sm btn btn-primary mb-1' : 'btn btn-primary mb-1'}
                                  >
                                    Explore
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {formOptions && (
                              <>
                                <CardTitle className="mb-4">{props.match.params.opID ? "Modify" : "Create"} an OP</CardTitle>
                                <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                                  <Row>
                                    <Col>
                                      <Row>
                                        <Col>
                                          <div className="mb-3 ajax-select select2-container">
                                            <EAsyncSelect
                                              cacheOptions
                                              options={{ ...formOptions.doctor, label: "Consulting doctor" }}
                                              value={[selectedOptions.doctor]}
                                              loadOptions={searchDoctors}
                                              formError={formError?.doctor}
                                              onChange={updateDoctorSelect}
                                              placeholder="Type to search doctors..."
                                              getOptionLabel={e => `${e.user_data.full_name} - ${e.specialization}`}
                                              getOptionValue={e => e.id}
                                              defaultOptions={searchOptions.doctors}
                                              onFocus={() => dispatch(listHms("/hms/staff/doctors/", null, "fetch_consulting_doctors"))}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <EAvFieldSelect
                                            field="mode_of_visit"
                                            value={patient?.mode_of_visit}
                                            isError={formError?.mode_of_visit}
                                            options={{ ...formOptions.mode_of_visit, label: "Visit mode" }}
                                            choices={<>
                                              {formOptions?.mode_of_visit?.choices?.map((choice, cidx) =>
                                                <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                              )}
                                            </>}
                                            disabled={patient?.mode_of_visit === "FOLLOW_UP"}
                                          />
                                        </Col>
                                        <Col>
                                          <EAvFieldSelect
                                            field="reference_type"
                                            value={(patient.reference_type !== "") ? patient.reference_type : "DIRECT"}
                                            isError={formError?.reference_type}
                                            options={formOptions?.reference_type}
                                            choices={<>
                                              <option value={""}>{"-Select option-"}</option>
                                              {formOptions?.reference_type?.choices.map((choice, cidx) =>
                                                <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                              )}
                                            </>}
                                            onChange={onReferenceTypeChange}
                                            disabled={patient?.mode_of_visit === "FOLLOW_UP"}
                                          />
                                        </Col>
                                        <Col id="reference-section" style={{ display: patient?.reference_type === "REFERRAL" ? 'block' : 'none' }}>
                                          <EAvFieldInput
                                            field="referred_by"
                                            type="text"
                                            value={patient?.referred_by}
                                            isError={formError?.referred_by}
                                            options={formOptions?.referred_by}
                                          />
                                        </Col>
                                      </Row>
                                      {patient?.mode_of_visit === "FOLLOW_UP" ? null : (
                                        <Row>
                                          <Col>
                                            <h5 className="mt-2">Fee Details</h5>
                                            <hr />
                                            <Row>
                                              <Col>
                                                <EAvFieldNumber
                                                  field="sub_total_amount"
                                                  value={billing?.sub_total_amount}
                                                  isError={formError?.sub_total_amount}
                                                  options={{ ...formOptions.sub_total_amount, min_value: "0", max_value: null }}
                                                  onChange={onSubTotalChange}
                                                  disabled={!selectedOptions?.doctor}
                                                  defaultValue="0"
                                                />
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <EAvFieldDiscount
                                                  value={showDiscountPercent ? billing?.discount_percent : billing?.discount_amount}
                                                  showDiscountPercent={showDiscountPercent}
                                                  toggleSwitch={() => setShowDiscountPercent(prev => !prev)}
                                                  onChange={showDiscountPercent ? (e) => onDiscountPercentChange(e.target.value) : (e) => onDiscountAmountChange(e.target.value)}
                                                  disabled={patient?.payment_mode === "SELF" || !selectedOptions?.doctor}
                                                  placeholder={`Enter ${showDiscountPercent ? "in %" : "amount"}`}
                                                  helpMessage={showDiscountPercent ? `${billing?.discount_amount} discount amount` : `${billing?.discount_percent}%`}
                                                />
                                              </Col>
                                            </Row>
                                            <Row id="discounted_by_section" style={{ display: billing?.discount_amount > 0 ? 'block' : 'none' }}><Col>
                                              <div className="mb-3 ajax-select select2-container">
                                                <EAsyncSelect
                                                  onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, discountedBy: false }))}
                                                  menuIsOpen={isMenuOpen.discountedBy}
                                                  options={{ ...formOptions?.discounted_by, label: "Discounted by" }}
                                                  value={[selectedOptions.discountedPerson]}
                                                  loadOptions={searchDiscountUsers}
                                                  formError={formError?.discounted_by}
                                                  onChange={(value) => setSelectedOptions({ ...selectedOptions, discountedPerson: value })}
                                                  placeholder="Type to search persons..."
                                                  getOptionLabel={e => `${e.full_name} ${e.email ? `- ${e.email}` : ''}`}
                                                  getOptionValue={e => e.id}
                                                />
                                              </div>
                                            </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <EAvFieldNumber
                                                  disabled
                                                  field="grand_total_amount"
                                                  value={billing?.grand_total_amount}
                                                  isError={formError?.grand_total_amount}
                                                  options={{ ...formOptions?.grand_total_amount, label: "Grand total", min_value: "0", max_value: null }}
                                                  onChange={(e) => setBilling(prevState => ({ ...prevState, grand_total_amount: e.target.value }))}
                                                  defaultValue="0"
                                                />
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <EAvFieldSelect
                                                  field="payment_mode"
                                                  value={patient?.payment_mode}
                                                  isError={formError?.payment_mode}
                                                  options={formOptions?.payment_mode}
                                                  choices={<>
                                                    <option value={""}>{"-------Select an option-------"}</option>
                                                    {formOptions?.payment_mode?.choices.map((choice, cidx) =>
                                                      <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                                    )}
                                                  </>}
                                                  onChange={onPaymentModeChange}
                                                />
                                              </Col>
                                            </Row>
                                            <Row id="cheque-section" style={{ display: patient?.payment_mode === "CHEQUE" ? 'block' : 'none' }}>
                                              <Col>
                                                <EAvFieldInput
                                                  field="cheque_number"
                                                  type="text"
                                                  value={patient?.billing?.cheque_number}
                                                  isError={formError?.cheque_number}
                                                  options={formOptions?.cheque_number}
                                                />
                                              </Col>
                                              <Col>
                                                <EAvFieldInput
                                                  field="cheque_date"
                                                  type="date"
                                                  value={patient?.billing?.cheque_date}
                                                  isError={formError?.cheque_date}
                                                  options={formOptions?.cheque_date}
                                                />
                                              </Col>
                                            </Row>
                                          </Col>
                                          <RequiredFieldsMessage />
                                        </Row>
                                      )}
                                    </Col>
                                    <Col xl="1"></Col>
                                    <Col>
                                      <h5 className="mt-2">Patient Details</h5>
                                      <hr />
                                      {opDetail?.user_data ? (
                                        <PatientInformation
                                          data={opDetail}
                                          instance={patient}
                                          includedFields={[
                                            "patient_name",
                                            "patient_id",
                                            "gender",
                                            "marital_status",
                                            "date_of_birth",
                                            "age",
                                            "mobile",
                                            "email",
                                            "visit_no",
                                            "op_date",
                                          ]}
                                        />
                                      ) : (
                                        <p className="text-center text-danger">Patient information not existed</p>
                                      )}
                                      <Row>
                                        <h5 className="mt-2">Address</h5>
                                        <hr />
                                        {opDetail?.address ? (
                                          <Col>
                                            <TableChip
                                              label="Line 1"
                                              value={opDetail?.address_data?.line1}
                                            />
                                            <TableChip
                                              label="Line 2"
                                              value={opDetail?.address_data?.line2}
                                            />
                                            <Row>
                                              <Col>
                                                <TableChip
                                                  label="City"
                                                  value={opDetail?.address_data?.city}
                                                />
                                              </Col>
                                              <Col>
                                                <TableChip
                                                  label="State"
                                                  value={opDetail?.address_data?.state}
                                                />
                                              </Col>
                                              <Col>
                                                <TableChip
                                                  label="Pincode/Zipcode"
                                                  value={opDetail?.address_data?.pincode}
                                                />
                                              </Col>
                                            </Row>
                                          </Col>
                                        ) : (
                                          <p className="text-center text-danger">Address details not existed</p>
                                        )}
                                      </Row>
                                      <Row>
                                        <h5 className="mt-2 text-muted">Health Information</h5>
                                        <hr />
                                        <Row>
                                          <Col>
                                            <EAvFieldInput
                                              field="pulse"
                                              type="text"
                                              value={patient?.pulse}
                                              isError={formError?.pulse}
                                              options={formOptions?.pulse}
                                            />
                                          </Col>
                                          <Col>
                                            <EAvFieldInput
                                              field="blood_pressure"
                                              type="text"
                                              value={patient?.blood_pressure}
                                              isError={formError?.blood_pressure}
                                              options={{ ...formOptions?.blood_pressure, label: <span>Blood&nbsp;pressure</span> }}
                                            />
                                          </Col>
                                          <Col>
                                            <EAvFieldNumber
                                              field="spo2"
                                              value={patient?.spo2}
                                              isError={formError?.spo2}
                                              options={{ ...formOptions?.spo2, label: "SpO2" }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <EAvFieldInput
                                              field="temperature"
                                              type="text"
                                              value={patient?.temperature}
                                              isError={formError?.temperature}
                                              options={formOptions?.temperature}
                                            />
                                          </Col>
                                          <Col>
                                            <EAvFieldNumber
                                              field="weight"
                                              value={patient?.weight}
                                              isError={formError?.weight}
                                              options={{ ...formOptions?.weight, minValue: 1, maxValue: 500 }}
                                            />
                                          </Col>
                                          <Col>
                                            <EAvFieldInput
                                              field="height"
                                              type="text"
                                              value={patient?.height}
                                              isError={formError?.height}
                                              options={{ ...formOptions?.height, minValue: 5, maxValue: 500 }}
                                            />
                                          </Col>
                                        </Row>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row className="justify-content-end">
                                    <Col sm={9}>
                                      <div>
                                        {apiKey === "post_op_created" || apiKey === "update_op_modified" ? (
                                          <>
                                            <Button color="primary" className="float-end" disabled>
                                              {props.match.params.opID ? "Modify OP" : "Create OP"}
                                              <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                                            </Button>
                                            <GoBack disabled />
                                          </>
                                        ) : (
                                          <>
                                            <Button type="submit" color="primary" className="float-end">
                                              {props.match.params.opID ? "Modify OP" : "Create OP"}
                                            </Button>
                                            <GoBack historyProp={props.history} />
                                          </>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default CreateOP;
