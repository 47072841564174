import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Col, Card, CardBody, Row, Modal, ModalBody, ModalFooter, Button, ModalHeader, Label, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { deleteHms, getHms, resetHmsState } from "store/actions"
import { TableChip, formatDateTime } from "components/Common/common"
import { Loader } from "pages/HMS/common/common"
import BillingSummary from "pages/HMS/billing/billing-summary"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import toastr from "toastr"
import { PharmacyDeleteModal } from "pages/HMS/pharmacy/pharmacy-pharm-crud-modals"
import { ReportDownload } from "components/Common/print-report"

const TreatmentDetail = (props) => {
  const dispatch = useDispatch()
  const [treatment, setTreatment] = useState({})
  const [billing, setBilling] = useState({})
  const [modals, setModals] = useState({ notesModal: false, deleteModal: false })
  const [dropdown, setDropdown] = useState(false)

  const { loading, hmsObject, apiKey, error } = useSelector(state => ({
    loading: state.Hms.loading,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    error: state.Hms.error,
  }))

  useEffect(() => {
    dispatch(getHms(`/hms/diagnosis/treatments/${props.match.params.treatmentID}/`, null, "treatment_details"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  useEffect(() => {
    if (apiKey === "treatment_details") {
      setTreatment(hmsObject)
      dispatch(getHms("/hms/billing/billing/", hmsObject?.billing, "billing_details"))
    }
    if (apiKey === "billing_details") {
      setBilling(hmsObject)
    }
    if (apiKey === "treatment_detail_deleted") {
      toastr.success("Treatment is deleted")
      setTreatment({})
      toggleDelete()
      window.location.replace("/hms/patient/treatment")
    }
  }, [apiKey])

  const toggleNotes = (def = modals?.notesModal) => {
    setModals(prevModals => ({ ...prevModals, notesModal: !def }))
  }

  const toggleDelete = (def = modals.deleteModal) => {
    setModals(prevModals => ({ ...prevModals, deleteModal: !def }))
  }

  const handleDelete = () => {
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Treatment details | Yanthura</title>
        </MetaTags>
        {apiKey === "get_fetch-order" ? (
          <p className="text-center mt-5">
            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
            Fetching treatment...
          </p>
        ) : (
          <Card>
            <CardBody>
              {loading && apiKey === "get_billing_details" ? (
                <Loader />
              ) : error ? (
                <p className="text-center text-danger">{error.detail}</p>
              ) : (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between">
                      <h5>Patient Information</h5>
                      <Dropdown isOpen={dropdown} toggle={() => setDropdown(!dropdown)} direction="down">
                        <DropdownToggle caret color="primary" size="sm">
                          Actions
                          &nbsp;<i className="fa fa-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <ReportDownload
                            url={`/hms/diagnosis/treatments/${treatment?.id}/invoice_receipt/`}
                            label="Print Invoice"
                            type="menu-item"
                          />
                          <Link className="dropdown-item" to={`/hms/patient/treatment/add/${treatment?.id}`}>
                            Modify
                          </Link>
                          <Link className="dropdown-item" to={"#"} onClick={handleDelete}>
                            Remove
                          </Link>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <hr />
                    <>
                      <Row>
                        <Col>
                          <TableChip
                            label="Patient name"
                            value={treatment?.patient_name}
                          />
                        </Col>
                        <Col>
                          <TableChip
                            label="Procedure"
                            value={treatment?.procedure_name}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TableChip
                            label="Performed doctor"
                            value={treatment?.performed_doctor_name}
                          />
                        </Col>
                        <Col>
                          <TableChip
                            label="Performed date"
                            value={formatDateTime(treatment?.created_at)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label>Notes</Label>
                          {treatment?.notes && treatment?.notes !== "<p><br></p>" ? (
                            <p className="text-primary cursor-pointer" onClick={() => toggleNotes(false)}>
                              Show notes <i className="mdi mdi-eye ms-1"></i>
                            </p>
                          ) : (
                            <p className="text-muted">No notes added</p>
                          )}
                        </Col>
                      </Row>
                    </>
                  </Col>
                  <Col>
                    <BillingSummary billingDetail={billing} />
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        )}
      </div>

      <Modal isOpen={modals.notesModal} scrollable={true} size="xl">
        <ModalHeader toggle={toggleNotes} tag="h4">
          Notes
        </ModalHeader>
        <ModalBody className="p-0" style={{ overflow: "hidden" }}>
          {treatment?.notes && (
            <iframe
              srcDoc={treatment?.notes}
              style={{ border: "none", width: "100%", height: "75vh" }}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggleNotes}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <PharmacyDeleteModal
        body={<>
          <ul>
            <li>If treatment is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/diagnosis/treatments/", treatment?.id, "treatment_detail_deleted")}
        deleteModal={modals.deleteModal}
      />
    </React.Fragment>
  )
}

export default TreatmentDetail
