import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row } from "reactstrap"
import { getHms, getHmsOptions, resetHmsState } from "store/actions";
import { TableBadge, TableChip } from "components/Common/common";
import toastr from "toastr"
import { GroupCRUModal } from "./modals";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Loader } from "pages/HMS/common/common";

const TestGroupDetail = (props) => {
  const dispatch = useDispatch();
  const [test, setTest] = useState({})
  const [parameter, setParameter] = useState({})
  const [modal, setModal] = useState(false);

  const { loading, hmsObject, apiKey, error, updateResponse } = useSelector(state => ({
    loading: state.Hms.loading,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    error: state.Hms.error,
    updateResponse: state.Hms.updateResponse,
  }));

  useEffect(() => {
    if (apiKey === "test_detail") {
      setTest(hmsObject)
    }
    if (apiKey === "group_test_updated") {
      setTest(updateResponse)
    }
    if (apiKey === "parameters_deleted") {
      toastr.success(`Parameter ${parameter.name} is deleted`)
      setParameter({})
      toggleDelete()
    }
  }, [apiKey]);

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/test-groups/?options=table, formschema", "test_options"))
    dispatch(getHms("/hms/diagnosis/test-groups/", props.match.params.testID, "test_detail"));
    return () => {
      dispatch(resetHmsState())
    }
  }, []);

  function toggle() {
    setModal(prevVal => !prevVal)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Test Group | Yanthura</title>
        </MetaTags>
        <Card>
          <CardBody>
            {error ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : loading && apiKey === "get_test_detail" ? (
              <Loader />
            ) : (
              <Row>
                <Col md={"6"}>
                  <div className="mt-2 d-flex justify-content-between">
                    <h5>Details</h5>
                    <Link title="Modify" className="text-muted" to={"#"} onClick={toggle}>
                      <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                    </Link>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <TableChip
                      label="Name"
                      value={test?.name}
                    />
                    <TableChip
                      label="Fee"
                      value={test?.fee}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <TableBadge
                      label="Tests"
                      value={test?.tests}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>

      <GroupCRUModal
        modal={modal}
        toggle={toggle}
        rudFlag={0}
        data={test}
        apiStartKey="group_test"
      />
    </React.Fragment >
  );
};

export default TestGroupDetail;
