import React, { useState, useEffect } from "react"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
// import user1 from "../../../assets/images/users/avatar-1.jpg"
import userico from "../../../assets/yanthura/icons/user.png"
import { Link } from "react-router-dom"

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false)
  const [username, setUsername] = useState(null)
  const [role, setRole] = useState(null)
  const [avatar, setAvatar] = useState()

  useEffect(() => {
    let authUserObject = localStorage.getItem("authUser")

    if (authUserObject) {
      let obj = JSON.parse(authUserObject)
      setRole(obj.role)
      setUsername(obj.username)
      setAvatar(obj.avatar)
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          {avatar ? (
            <img
              className="rounded-circle header-profile-user"
              src={avatar}
              alt="Header Avatar"
            />
          ) : (
            <img
              className="rounded-circle header-profile-user"
              src={userico}
              alt="Header Avatar"
            />
          )}
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>Profile</span>
          </Link>
          <Link to="/profile/display-settings/#font-size" className="dropdown-item">
            <i className="bx bx-cog font-size-16 align-middle me-1" />
            <span>Display Settings</span>
          </Link>
          <Link to="/profile/support" className="dropdown-item">
            <i className="bx bx-support font-size-16 align-middle me-1" />
            <span>Support</span>
          </Link>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileMenu
