import React, { useEffect, useRef, useState } from "react"
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { updateHms, postHms } from "store/actions"
import { EAvFieldInput } from "pages/HMS/common/errored-avfields"
import FlashAlert from "components/Common/flash-message"
import { handleIncludedKeysError, isMobileView, SubmitLoaderButton } from "./common"
import toastr from "toastr"

export const HmsDeleteModal = (props) => {
  const { body, loading, error, toggleDelete, dispatchOperation, deleteModal, isShowHeader = true } = props;
  const dispatch = useDispatch();
  const [deleteEnabled, setDeleteEnabled] = useState(false)
  const [customError, setCustomError] = useState(null)

  useEffect(() => {
    setCustomError(error)
  }, [error])

  const toggle = () => {
    setCustomError(null)
    toggleDelete()
  }

  return (
    <Modal isOpen={deleteModal} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle} tag="h4">
        {isShowHeader && (
          <>
            <i className="bx bx-error me-1 text-warning" />
            <span>Warning: Before deleting the record, make sure of the following:</span>
          </>
        )}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="col-12">
            {customError &&
              <FlashAlert color="danger" delay="10000" className="text-center">{customError?.detail}</FlashAlert>
            }
            {body}
            <p>Type <i><b>delete permanently</b></i> to proceed delete operation</p>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <div className="mb-3">
              <input
                name="confirmation-text"
                type="text"
                className="form-control"
                required={false}
                onChange={(e) => setDeleteEnabled(e.target.value === "delete permanently")}
              />
            </div>
          </Col>
        </Row>
        <Row className="float-end">
          <Col>
            <button type="button" className="btn btn-outline-danger btn-sm me-2" disabled={!deleteEnabled || loading}
              onClick={() => { dispatch && dispatch(dispatchOperation) }}>
              Yes
            </button>
            <button type="button" className="btn btn-light btn-sm" onClick={toggle}>
              No
            </button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export const HmsSimpleDeleteModal = (props) => {
  const { modal, toggle, dispatchOperation } = props;
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.Hms)
  const [customError, setCustomError] = useState(null)

  useEffect(() => {
    setCustomError(error)
  }, [error])

  const toggleDelete = () => {
    setCustomError(null)
    toggle()
  }

  const handleSubmit = () => {
    dispatch(dispatchOperation)
  };

  return (
    <Modal isOpen={modal} toggle={toggleDelete} backdrop="static">
      <ModalHeader toggle={toggleDelete} tag="h4">Confirmation</ModalHeader>
      <ModalBody>
        <Row>
          <Col className="col-12">
            {customError &&
              <FlashAlert color="danger" delay="10000" className="text-center">{customError?.detail}</FlashAlert>
            }
            <p>Are you sure you want to remove this record?</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="text-end">
            <button className="btn btn-primary me-1" disabled={loading} onClick={handleSubmit}>
              Yes
            </button>
            <button className="btn btn-secondary" onClick={toggleDelete}>
              No
            </button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export const SingleFieldCRUModal = (props) => {
  const { modalLabel, modal, toggle, rudFlag, options = { label: "Name", required: true }, data = null, apiEndpoint, apiStartKey, prepend = false } = props
  const dispatch = useDispatch()
  const formRef = useRef()
  const [customError, setCustomError] = useState(null)

  const { loading, apiKey, formError } = useSelector(state => ({
    loading: state.Hms.loading,
    apiKey: state.Hms.apiKey,
    formError: state.Hms.formError,
  }))

  useEffect(() => {
    setCustomError(formError)
  }, [formError])

  useEffect(() => {
    if (apiKey === `${apiStartKey}_created`) {
      singleFieldToggle()
      toastr.success(`${modalLabel} created successfully`)
      formRef.current?.reset()
    }
    if (apiKey === `${apiStartKey}_updated`) {
      singleFieldToggle()
      toastr.success(`${modalLabel} updated successfully`)
    }
  }, [apiKey])

  const handleValidSubmit = (e, values) => {
    if (rudFlag === 0) {
      dispatch(updateHms(apiEndpoint, data.id, values, `${apiStartKey}_updated`))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms(apiEndpoint, values, `${apiStartKey}_created`, prepend))
    }
  }

  const singleFieldToggle = () => {
    setCustomError(null)
    toggle()
  }

  return (
    <Modal isOpen={modal} toggle={singleFieldToggle} backdrop="static">
      <ModalHeader toggle={singleFieldToggle} tag="h4">
        {rudFlag === 0 ? `Modify ${modalLabel}` : rudFlag === 1 ? `Add ${modalLabel}` : ""}
      </ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
          {options && (
            <>
              <Row>
                <Col>
                  <EAvFieldInput
                    field={"name"}
                    type="text"
                    value={data?.name}
                    isError={customError?.name}
                    options={options}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <SubmitLoaderButton type="submit" size="sm" loading={apiKey === `update_${apiStartKey}_updated` || apiKey === `post_${apiStartKey}_created`}>
                      SAVE
                    </SubmitLoaderButton>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export const MultiFieldCRUModal = (props) => {
  const { modalLabel, modal, toggle, rudFlag, body, data = null, apiEndpoint, apiStartKey, prepend = false, size } = props
  const dispatch = useDispatch()
  const formRef = useRef()

  const { loading, apiKey, postResponse, updateResponse } = useSelector(state => ({
    loading: state.Hms.loading,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
  }))

  useEffect(() => {
    if (apiKey === `${apiStartKey}_created`) {
      toggle()
      toastr.success(`${postResponse.name} created successfully`)
      formRef.current?.reset()
    }
    if (apiKey === `${apiStartKey}_updated`) {
      toggle()
      toastr.success(`${updateResponse.name} updated successfully`)
    }
  }, [apiKey])

  const handleValidSubmit = (e, values) => {
    if (rudFlag === 0) {
      dispatch(updateHms(apiEndpoint, data.id, values, `${apiStartKey}_updated`))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms(apiEndpoint, values, `${apiStartKey}_created`, prepend))
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} backdrop="static" size={size}>
      <ModalHeader toggle={toggle} tag="h4">
        {rudFlag === 0 ? `Modify ${modalLabel}` : rudFlag === 1 ? `Add ${modalLabel}` : ""}
      </ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
          {body && (
            <>
              {body}
              <Row>
                <Col>
                  <div className="text-end">
                    <SubmitLoaderButton type="submit" size="sm" loading={apiKey === `update_${apiStartKey}_updated` || apiKey === `post_${apiStartKey}_created`}>
                      SAVE
                    </SubmitLoaderButton>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

export const GenericModalWrapper = ({ name, apiStartKey, modal, toggle, rudFlag, body, size = "lg" }) => {

  const { apiKey } = useSelector(state => ({
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    if (apiKey === `${apiStartKey}_created`) {
      toggle()
      toastr.success(`${name} created successfully`)
    }
    if (apiKey === `${apiStartKey}_updated`) {
      toggle()
      toastr.success(`${name} updated successfully`)
    }
  }, [apiKey])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" scrollable={true} size={size}>
        <ModalHeader toggle={toggle} tag="h4">
          {rudFlag === 0 ? `Modify ${name}` : rudFlag === 1 ? `Add ${name}` : ""}
        </ModalHeader>
        <ModalBody>
          {body}
        </ModalBody>
      </Modal>
    </>
  )
}

export const HMSViewModal = (props) => {
  const { modalLabel = null, modal, toggle, body = null, size = "lg" } = props

  return (
    <Modal isOpen={modal} scrollable={true} size={size}>
      <ModalHeader toggle={toggle} tag="h4">
        {modalLabel}
      </ModalHeader>
      <ModalBody className="p-0" style={{ overflow: "hidden" }}>
        {body}
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}


export const DownloadModal = ({ modal, toggle, apiStartKey, size = "xl", base64String }) => {
  const isMobile = isMobileView()
  const { apiKey, hmsObject, error} = useSelector(state => state.Hms)

  useEffect(() => {
    if (error && apiKey.startsWith(`fail_${apiStartKey}`)) {
      const includedKeys = ['detail'];
      handleIncludedKeysError(error, apiKey, `fail_${apiStartKey}`, includedKeys, false);
    }
    if (apiKey === apiStartKey && isMobile) {
      const fileName = `${new Date().toISOString().split('.')[0].replace(/[-T:]/g, '')}.pdf`;
      const linkSource = `data:application/pdf;base64,${base64String ? base64String : hmsObject}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      toastr.success("PDF downloaded successfully");
    }
  }, [apiKey]);

  return (
    <Modal isOpen={modal && !isMobile} scrollable={true} size={size} toggle={toggle}>
      <ModalBody className="p-0" style={{ overflow: "hidden" }}>
        {apiKey === apiStartKey && (
          <iframe
            src={`data:application/pdf;base64,${base64String ? base64String : hmsObject}`}
            style={{ border: 'none', width: '100%', height: '75vh' }}
          />
        )}
      </ModalBody>
    </Modal>
  );
};