import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button, Spinner, CardBody, Table, Badge } from "reactstrap"
import { useEffect, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import Flatpickr from "react-flatpickr";
import { isEmpty, reverse } from "lodash";
import { getHms, listHms, postHms, resetHmsState, updateHms } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr"
import { AvForm } from "availity-reactstrap-validation";
import { convertDatetimeToDate, formatDateString, getCurrentDate, search, SubmitLoaderButton, timeSlotsStyle } from "../../../../common/common";
import { formatDateTime } from "components/Common/common";
import { EAvFieldInput } from "pages/HMS/common/errored-avfields";

export const HealthModalAdd = (props) => {
  const { modals, toggleViewModal, inpID } = props
  const datesRef = useRef();
  const dispatch = useDispatch();
  const { apiKey, formError, hmsObject } = useSelector(state => state.Hms)
  const [healthMetric, setHealthMetric] = useState(null);
  const [selectedHCs, setSelectedHCs] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    dispatch(getHms("/hms/patient/health_metric_tracker/", inpID, "hc_get"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "hc_get") {
      setSelectedDates(hmsObject?.dates.map(date => new Date(date)))
      setSelectedHCs(hmsObject?.health_checks_data)
      setHealthMetric(hmsObject)
    }
    if (apiKey === "hc_create") {
      toastr.success("Health metrics created")
      toggleViewModal('healthMetricModalAdd')
    }
    if (apiKey === "hc_update") {
      toastr.success("Health metrics updated")
      toggleViewModal('healthMetricModalAdd')
    }
  }, [apiKey]);

  const handleSubmit = () => {
    if (healthMetric?.inpatient) {
      dispatch(updateHms("/hms/patient/health_metric_tracker/", inpID, {
        health_checks: selectedHCs ? selectedHCs.map(item => item.id) : [],
        dates: selectedDates ? selectedDates.map(item => convertDatetimeToDate(item)) : []
      }, "hc_update"))
    } else {
      dispatch(postHms("/hms/patient/health_metric_tracker/", {
        inpatient: inpID,
        health_checks: selectedHCs.map(item => item.id),
        dates: selectedDates.map(item => convertDatetimeToDate(item))
      }, "hc_create"))
    }
  };

  const searchHCs = (value, callback) => {
    search(value, callback, "/hms/patient/health_checks/", () => setIsMenuOpen(value.length > 0))
  }

  return (
    <Modal
      isOpen={modals.healthMetricModalAdd}
      toggle={() => toggleViewModal('healthMetricModalAdd')}
      backdrop="static"
    >
      <div className="modal-content">
        <ModalHeader toggle={() => toggleViewModal('healthMetricModalAdd')}>Health Metric</ModalHeader>
        <ModalBody>
          <Row>
            <div className="mb-3">
              <Col md={8} className="float-start">
                <div style={timeSlotsStyle}>
                  {selectedDates?.map((date, index) => (
                    <Badge key={index} color="secondary" className="me-1 mb-1">
                      {formatDateString(date)}
                    </Badge>
                  ))}
                </div>
              </Col>
              <Col md={4} className="float-end">
                <Flatpickr
                  className={formError?.dates ? "form-control is-invalid" : "form-control"}
                  placeholder={"Select dates"}
                  options={{
                    mode: "multiple",
                    dateFormat: "Y-m-d",
                    minDate: "today",
                    maxDate: new Date().fp_incr(30),
                  }}
                  value={selectedDates}
                  ref={datesRef}
                  onChange={(values) => setSelectedDates(values)}
                />
                {formError && formError?.dates && <div className="invalid-feedback">{formError?.dates}</div>}
              </Col>
            </div>
          </Row>
          <Row>
            <Col>
              <div className="mb-3 ajax-select select2-container">
                <AsyncSelect
                  menuIsOpen={isMenuOpen}
                  onMenuClose={() => setIsMenuOpen(false)}
                  isMulti
                  value={selectedHCs}
                  loadOptions={searchHCs}
                  onChange={value => setSelectedHCs(value)}
                  classNamePrefix="select2-selection"
                  placeholder="Type to search here..."
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.id}
                  closeMenuOnSelect={false}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: formError?.health_checks ? "#eb3443 !important" : "#eee"
                    }),
                  }}
                />
                {formError && formError?.health_checks && <div className="text-danger small">{formError?.health_checks}</div>}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={() => {
            setSelectedDates([])
            setSelectedHCs([])
            datesRef.current.flatpickr.clear()
          }}>
            Clear
          </Button>
          <SubmitLoaderButton onClick={handleSubmit} loading={apiKey === "update_hc_update" || apiKey === "post_hc_create"}>
            Save
          </SubmitLoaderButton>
        </ModalFooter>
      </div>
    </Modal>
  )
}


export const HealthModalTrack = (props) => {
  const { modals, toggleViewModal, inpID } = props
  const [healthMetric, setHealthMetric] = useState(null);
  const dispatch = useDispatch()
  const { error, hmsObject, apiKey, formError } = useSelector(state => state.Hms)

  useEffect(() => {
    dispatch(getHms(`/hms/patient/health_metric_tracker/${inpID}/track/`, null, "hm_get"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "hm_get") setHealthMetric(hmsObject)
    if (apiKey === "hm_track_post") {
      toastr.success("Health metric track submitted")
      clearStuff()
    }
    if (formError && apiKey && apiKey.startsWith("fail_hm")) {
      toastr.error(Object.entries(formError).map(([key, value], idx) =>
        `${key}: ${value}`
      ))
    }
  }, [apiKey])

  const clearStuff = () => {
    toggleViewModal('healthMetricModalTrack')
  }

  const handleSubmit = (e, values) => {
    let payload = Object.entries(values).map(([key, value], idx) => {
      const [id, name] = key.split("_");
      return {
        id: id,
        name: name,
        value: value
      }
    })
    dispatch(postHms(`/hms/patient/health_metric_tracker/${inpID}/track/`, { health_checks_data: payload }, "hm_track_post"))
  }

  return (
    <Modal
      isOpen={modals.healthMetricModalTrack}
      toggle={() => toggleViewModal('healthMetricModalTrack')}
      backdrop="static"
    >
      <div className="modal-content">
        <AvForm onValidSubmit={handleSubmit}>
          <ModalHeader
            toggle={() => toggleViewModal('healthMetricModalTrack')}
          >
            Health metric tracking @{formatDateTime(new Date(), "DD MMM, YYYY")}
          </ModalHeader>
          {error?.detail ?
            <p className="text-center text-muted m-2">{error.detail}</p>
            :
            <>
              <ModalBody>
                <CardBody>
                  {healthMetric?.health_checks?.map((item, index) =>
                    <div className="mb-3" key={index}>
                      <EAvFieldInput
                        field={`${item?.id}_${item?.name}`}
                        value={null}
                        isError={formError?.name}
                        options={{ label: item?.name, required: true }}
                      />
                    </div>
                  )}
                </CardBody>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={() => toggleViewModal('healthMetricModalTrack')}>
                  Close
                </Button>
                <SubmitLoaderButton type="submit" loading={apiKey === "post_hm_track_post"}>
                  Save
                </SubmitLoaderButton>
              </ModalFooter>
            </>
          }
        </AvForm>
      </div>
    </Modal>
  )
}


export const HealthModalHistory = (props) => {
  const { modals, toggleViewModal, inpID } = props
  const dispatch = useDispatch()
  const { loading, error, hmsList, apiKey } = useSelector(state => state.Hms)
  const [hmHistory, setHmHistory] = useState([])
  const [date, setDate] = useState(getCurrentDate())

  useEffect(() => {
    dispatch(listHms(`/hms/patient/health_metric_tracker/${inpID}/history/${getCurrentDate()}/`, null, "fetch_history"))
  }, [])

  useEffect(() => {
    if (apiKey === "fetch_history") {
      let data = hmsList;
      const sortedHm = data?.measurements_data ? data?.measurements_data.slice().sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)) : [];
      data.measurements_data = sortedHm
      setHmHistory(data)
    }
  }, [hmsList])

  const handleSubmit = (date) => {
    if (!isEmpty(date)) {
      const formattedDate = formatDateTime(date[0], "YYYY-MM-DD");
      setDate(formattedDate);
      dispatch(listHms(`/hms/patient/health_metric_tracker/${inpID}/history/${formattedDate}/`, null, "fetch_history"));
    }
  }

  return (
    <Modal
      isOpen={modals.healthMetricModalHistory}
      toggle={() => toggleViewModal("healthMetricModalHistory")}
      backdrop="static"
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => toggleViewModal("healthMetricModalHistory")}
        >
          Health metric History
        </ModalHeader>
        {loading && apiKey === "list_fetch_history" ? (
          <div className="text-center">
            <div className="avatar-md mx-auto">
              <Spinner className="mt-5 fs-1" color="secondary" />
            </div>
            <div className="p-2 mt-4">
              <h4>Fetching information...</h4>
              <p className="text-muted">
                Please wait while we load the history details
              </p>
            </div>
          </div>
        ) : (
          <>
            {error?.detail ?
              <p className="text-center text-muted m-2">{error.detail}</p>
              :
              <>
                <ModalBody>
                  <Row><Col></Col><Col>
                    <span>Metric Date</span>
                    <Flatpickr
                      className={"form-control form-control-sm"}
                      options={{
                        mode: "single",
                        dateFormat: "Y-m-d",
                        defaultDate: date,
                        maxDate: "today"
                      }}
                      value={date}
                      onChange={(value) => handleSubmit(value)}
                    />
                  </Col></Row>
                  <div className="table-responsive">
                    <Table className="table align-middle table-nowrap">
                      <thead>
                        <tr>
                          <th>Metric</th>
                          <th>Value</th>
                          <th>Capture Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(hmHistory?.measurements_data) ?
                          reverse(hmHistory?.measurements_data).map(
                            (measurement, index) => {
                              return (
                                <tr key={index}>
                                  <td>{measurement.name}</td>
                                  <td>{measurement.value}</td>
                                  <td>{formatDateTime(measurement.timestamp, "LT")}</td>
                                </tr>
                              )
                            }
                          )
                          :
                          <tr><td colSpan={3} className="text-center text-muted">History not available for this day</td></tr>
                        }
                      </tbody>
                    </Table>
                  </div>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => toggleViewModal("healthMetricModalHistory")}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </>
            }
          </>
        )}
      </div>
    </Modal>
  )
}