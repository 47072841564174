import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags"
import { Badge, Button, Card, CardBody, Col, Container, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { getHmsOptions, resetHmsState, updateHms, postHms, getHms } from "store/actions"
import { SupplierCRUDModal, MedicineCRUDModal } from "./pharmacy-pharm-crud-modals"
import { EAsyncSelect, EAvFieldInput, EAvFieldNumber } from "../common/errored-avfields"
import { AvForm } from "availity-reactstrap-validation"
import toastr from "toastr"
import { TableChip } from "components/Common/common"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { floatRoundDown, formatPercentage, handleIncludedKeysError, search } from "../common/common"
import { isEmpty } from "lodash"

const MedicineStock = props => {
  const dispatch = useDispatch()
  const formRef = useRef()
  const { loading, options, hmsObject, apiKey, error, formError, postResponse } = useSelector((state) => state.Hms)
  const [aggregateOptions, setAggregateOptions] = useState({ inventory: null, medicine: null, supplier: null })
  const [modal, setModal] = useState({ medicine: false, supplier: false, ack: false })
  const [state, setState] = useState({ stock: null, medicine: null, selectedSupplier: null, rest: null });
  const [isMenuOpen, setIsMenuOpen] = useState({ medicine: false, supplier: false })
  const [billing, setBilling] = useState({ quantity: 0, price: 0, cgst_percent: 0, cgst: 0, sgst_percent: 0, sgst: 0, total_price: 0 });

  useEffect(() => {
    if (apiKey === "medicine-created") {
      toggleMedicine()
      setState(prevState => ({ ...prevState, medicine: postResponse }));
    }
    if (apiKey === "med-stock-created") {
      toastr.success("Stock created successfully")
      setState(prevState => ({ ...prevState, stock: postResponse }));
      setBilling(postResponse);
      toggleAckModal()
    }
    if (apiKey === "med-stock-updated") {
      toastr.success("Stock updated successfully")
      toggleAckModal()
    }
    if (apiKey === "med-stock-fetchone") {
      setState(prevState => ({ ...prevState, stock: hmsObject, medicine: hmsObject?.medicine_data, selectedSupplier: hmsObject?.supplier_data, rest: hmsObject }));
      setBilling(hmsObject);
    }
    if (apiKey === "supplier_add_stock_created") {
      setState(prevState => ({ ...prevState, selectedSupplier: postResponse }));
    }
    if (apiKey === "med-stock-options") {
      setAggregateOptions({
        inventory: options?.form_schema,
        medicine: options?.form_schema?.medicine_data?.children,
        supplier: { ...options?.form_schema?.supplier_data?.children, ...options?.form_schema?.supplier_data?.children?.address?.children }
      })
    }
    if (formError && apiKey.startsWith("fail_")) {
      const includedKeys = ['non_field_errors']
      handleIncludedKeysError(formError, apiKey, "fail_", includedKeys)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy/inventory/?options=formschema", "med-stock-options"))
    if (props.match.params.stockID) {
      dispatch(getHms("/hms/pharmacy/inventory/", props.match.params.stockID, "med-stock-fetchone"))
    }
    return () => dispatch(resetHmsState())
  }, [dispatch])

  useEffect(() => {
    const stockFormSection = document.getElementById("stock-form-section");
    if (stockFormSection) {
      stockFormSection.style.display = state?.medicine ? "block" : "none";
    }
  }, [state?.medicine])

  function toggleMedicine(def = modal.medicine) {
    setModal(prevModal => ({ ...prevModal, medicine: !def }));
  }

  function toggleAckModal(def = modal.ack) {
    setModal(prevModal => ({ ...prevModal, ack: !def }));
  }

  function toggleSupplier(def = modal.supplier) {
    setModal(prevModal => ({ ...prevModal, supplier: !def }));
  }

  const handleValidSubmit = (e, values) => {
    values.medicine = state?.medicine?.id;
    values.supplier = state?.selectedSupplier?.id
    const stockID = props.match.params.stockID ? props.match.params.stockID : state?.stock?.id;

    if (stockID) {
      dispatch(updateHms("/hms/pharmacy/inventory/", stockID, values, "med-stock-updated"))
    } else {
      dispatch(postHms("/hms/pharmacy/inventory/", values, "med-stock-created"))
    }
  }

  const searchMedicine = (value, callback) => {
    search(value, callback, "/hms/pharmacy/medicines/", () => setIsMenuOpen((prev) => ({ ...prev, medicine: value.length > 0 })))
  }

  const searchSupplier = (value, callback) => {
    search(value, callback, "/hms/pharmacy/", () => setIsMenuOpen((prev) => ({ ...prev, supplier: value.length > 0 })), "action=suppliers&query")
  }

  const onChange = (e) => {
    const value = floatRoundDown(e.target.value);
    setBilling(prevState => ({ ...prevState, [e.target.name]: value }));
  };

  const onGSTChange = (e) => {
    const value = formatPercentage(e.target.value);
    setBilling(prevState => ({ ...prevState, [e.target.name]: value }));
  };

  const calculateTotals = () => {
    const { quantity = 0, price = 0, mrp = 0, cgst_percent = 0, sgst_percent = 0 } = billing;
    const subtotal = quantity * price;
    const totalMrp = quantity * mrp;
    const cgst = floatRoundDown(totalMrp * (cgst_percent / 100));
    const sgst = floatRoundDown(totalMrp * (sgst_percent / 100));
    const total_price = floatRoundDown(subtotal + cgst + sgst);
    setBilling(prevState => ({ ...prevState, total_price, cgst, sgst }));
  };

  useEffect(() => {
    calculateTotals();
  }, [billing?.quantity, billing?.price, billing?.cgst_percent, billing?.sgst_percent]);

  function calculateSum(...values) {
    const sum = values.reduce((acc, val) => {
      const num = Number(val);
      return acc + num;
    }, 0);
    return floatRoundDown(sum) || 0;
  }

  const RenderIfStrip = ({ children }) => {
    if (state?.medicine?.type === "Strip") {
      return <>{children}</>;
    }
    return null;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Stock | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              {error ? (
                <p className="text-center text-danger">{error?.detail}</p>
              ) : (
                <Row>
                  <Col>
                    <Row>
                      <Col md={`${isEmpty(props.match.params.stockID) ? "10" : "12"}`}>
                        <div className="d-flex align-items-center">
                          <div className="ajax-select select2-container" style={{ flex: "1" }}>
                            <Label className={formError?.medicine ? "text-danger" : ''}>
                              Medicine
                            </Label>
                            <div className="d-flex align-items-center">
                              <div className="w-100">
                                <EAsyncSelect
                                  onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, medicine: false }))}
                                  menuIsOpen={isMenuOpen.medicine}
                                  value={[state?.medicine]}
                                  loadOptions={searchMedicine}
                                  formError={formError?.medicine}
                                  onChange={(value) => setState(prevState => ({ ...prevState, medicine: value }))}
                                  placeholder="Type to search medicines..."
                                  getOptionLabel={e => `${e?.name} ${e?.dosage ? `| ${e?.dosage}mg` : ''} ${e?.type === "Strip" ? `| ${e?.tablets_per_strip} tabs/strip` : ""}`}
                                  getOptionValue={e => e?.id}
                                  isDisabled={props.match.params.stockID}
                                />
                              </div>
                              {isEmpty(props.match.params.stockID) &&
                                <div onClick={() => toggleMedicine(false)} title="Add medicine" style={{ cursor: "pointer" }} className={`ms-2 ${formError?.medicine ? "mb-3" : ""}`}>
                                  <i className="bx bx-plus-medical text-success" />
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </Col>
                      {isEmpty(props.match.params.stockID) && state?.medicine && (
                        <Col className={`d-flex align-items-end ${formError?.medicine ? "mb-4" : "mb-2"}`}>
                          <Button
                            color="light"
                            className="btn btn-sm ms-2 waves-effect"
                            onClick={() => {
                              setState(prevState => ({ ...prevState, medicine: null, selectedSupplier: null }))
                            }}
                          >
                            CLEAR
                          </Button>
                        </Col>
                      )}
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <AvForm
                          onValidSubmit={handleValidSubmit}
                          ref={formRef}
                        >
                          {aggregateOptions?.inventory && (
                            <div id="stock-form-section" style={{ display: state?.medicine ? "block" : "none" }}>
                              <Row>
                                <Col>
                                  <EAvFieldInput
                                    field="batch_number"
                                    type="text"
                                    value={state?.stock?.batch_number}
                                    isError={formError?.batch_number}
                                    options={aggregateOptions?.inventory?.batch_number}
                                  />
                                </Col>
                                <Col>
                                  <EAvFieldInput
                                    field="expiry_date"
                                    type="date"
                                    value={state?.stock?.expiry_date}
                                    isError={formError?.expiry_date}
                                    options={aggregateOptions?.inventory?.expiry_date}
                                    daterange={{ start: { value: -150, units: "years" }, end: { value: 100, units: "years" } }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="d-flex align-items-center">
                                    <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                      <Label className={formError?.supplier ? "text-danger" : ''}>
                                        Supplier
                                      </Label>
                                      <div className="d-flex align-items-center mb-3">
                                        <div className="w-100">
                                          <EAsyncSelect
                                            onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, supplier: false }))}
                                            menuIsOpen={isMenuOpen.supplier}
                                            value={[state?.selectedSupplier]}
                                            loadOptions={searchSupplier}
                                            formError={formError?.supplier}
                                            onChange={(value) => setState(prevState => ({ ...prevState, selectedSupplier: value }))}
                                            placeholder="Type to search supplier..."
                                            getOptionLabel={e => e?.name}
                                            getOptionValue={e => e.id}
                                            noOptionsMessage={() => (
                                              <p className="text-center my-2" style={{ letterSpacing: '1px' }}>
                                                No supplier found?{" "}
                                                <Link to="#" onClick={() => toggleSupplier(false)} className="font-weight-bold">
                                                  Create a new supplier
                                                </Link>
                                              </p>
                                            )}
                                          />
                                        </div>
                                        <div onClick={() => toggleSupplier(false)} title="Add medicine" style={{ cursor: "pointer" }} className="ms-2">
                                          <i className="bx bx-plus-medical text-success" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <EAvFieldNumber
                                    field="quantity"
                                    value={billing.quantity}
                                    isError={formError?.quantity}
                                    options={aggregateOptions?.inventory?.quantity}
                                    onChange={onChange}
                                    helperComponent={
                                      <span className="small">
                                        <RenderIfStrip>
                                          <span>Total tablets = {calculateSum(billing?.quantity * state?.medicine?.tablets_per_strip)}</span><br />
                                        </RenderIfStrip>
                                        <span>Price = {calculateSum(billing?.quantity * billing?.price)}</span><br />
                                        <span>MRP = {calculateSum(billing?.quantity * billing?.mrp)}</span><br /><br /><br />
                                        <RenderIfStrip>
                                          <b>Tablets / Strip = {state?.medicine?.tablets_per_strip || 0}</b>
                                        </RenderIfStrip>
                                      </span>}
                                  />
                                </Col>
                                <Col>
                                  <EAvFieldNumber
                                    field="complementary"
                                    value={state?.stock?.complementary}
                                    isError={formError?.complementary}
                                    options={aggregateOptions?.inventory?.complementary}
                                    helperComponent={
                                      <span className="small">
                                        <RenderIfStrip>
                                          <span>Total tablets = {calculateSum(billing?.complementary * state?.medicine?.tablets_per_strip)}</span><br />
                                        </RenderIfStrip>
                                        <span>Price = {calculateSum(billing?.complementary * billing?.price)}</span><br />
                                        <span>MRP = {calculateSum(billing?.complementary * billing?.mrp)}</span><br /><br />
                                        <b>Total quantity = {calculateSum(billing?.quantity + (billing?.complementary || 0))}</b><br />
                                        <RenderIfStrip>
                                          <b>Total tablets quantity = {calculateSum(billing?.quantity * state?.medicine?.tablets_per_strip + (billing?.complementary || 0) * state?.medicine?.tablets_per_strip)}</b>
                                        </RenderIfStrip>
                                      </span>
                                    }
                                    onChange={onChange}
                                    defaultValue={"0"}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <EAvFieldNumber
                                    field="price"
                                    value={billing.price}
                                    isError={formError?.price}
                                    options={aggregateOptions?.inventory?.price}
                                    onChange={onChange}
                                    helperComponent={
                                      <span className="small">
                                        <RenderIfStrip>
                                          <span>Price / Tablet = {calculateSum((billing?.quantity * billing?.price) / (billing?.quantity * state?.medicine?.tablets_per_strip))}</span><br />
                                        </RenderIfStrip>
                                        <span>Total price = {calculateSum(billing?.quantity * billing?.price + billing?.complementary * billing?.price)}</span><br />
                                      </span>
                                    }
                                  />
                                </Col>
                                <Col>
                                  <EAvFieldNumber
                                    field="mrp"
                                    value={state?.stock?.mrp}
                                    isError={formError?.mrp}
                                    options={{ ...aggregateOptions?.inventory?.mrp, label: "MRP" }}
                                    onChange={onChange}
                                    helperComponent={
                                      <span className="small">
                                        <RenderIfStrip>
                                          <span>MRP / Tablet = {calculateSum((billing?.quantity * billing?.mrp) / (billing?.quantity * state?.medicine?.tablets_per_strip))}</span><br />
                                        </RenderIfStrip>
                                        <span>Total MRP = {calculateSum(billing?.quantity * billing?.mrp + billing?.complementary * billing?.mrp)}</span>
                                      </span>
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <EAvFieldNumber
                                    field="cgst_percent"
                                    value={billing.cgst_percent}
                                    isError={formError?.cgst_percent}
                                    options={{ ...aggregateOptions?.inventory?.cgst_percent, label: "CGST (%)" }}
                                    onChange={onGSTChange}
                                    defaultValue="0"
                                  />
                                </Col>
                                <Col>
                                  <EAvFieldNumber
                                    field="cgst"
                                    value={billing.cgst}
                                    isError={formError?.cgst}
                                    options={{ ...aggregateOptions?.inventory?.cgst, label: "CGST", min_value: "0", max_value: null }}
                                    disabled
                                    defaultValue="0"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <EAvFieldNumber
                                    field="sgst_percent"
                                    value={billing.sgst_percent}
                                    isError={formError?.sgst_percent}
                                    options={{ ...aggregateOptions?.inventory?.sgst_percent, label: "SGST (%)" }}
                                    onChange={onGSTChange}
                                    defaultValue="0"
                                  />
                                </Col>
                                <Col>
                                  <EAvFieldNumber
                                    field="sgst"
                                    value={billing.sgst}
                                    isError={formError?.sgst}
                                    options={{ ...aggregateOptions?.inventory?.sgst, label: "SGST", min_value: "0", max_value: null }}
                                    disabled
                                    defaultValue="0"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <EAvFieldNumber
                                    field="total_price"
                                    value={billing?.total_price}
                                    isError={formError?.total_price}
                                    options={aggregateOptions?.inventory?.total_price}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-end mb-2 mt-3">
                                    {loading ? (
                                      <button disabled className="btn btn-primary btn-sm save-user">
                                        SAVE
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                                      </button>
                                    ) : (
                                      <button type="submit" className="btn btn-primary btn-sm save-user">
                                        SAVE
                                      </button>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </AvForm>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="1"></Col>
                  <Col>
                    {state?.medicine ? (
                      <>
                        <h5 className="mt-2">Medicine Detail</h5>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <TableChip label="Medicine name" value={state?.medicine?.name} />
                          <TableChip label="mg/ml" value={state?.medicine?.dosage} />
                          {state?.rest?.current_stock && (
                            <TableChip
                              label="Current stock"
                              value={<Badge pill color="secondary">{state?.rest?.current_stock}</Badge>}
                            />
                          )}
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Formula" value={state?.medicine?.formula} />
                        </div>
                        <TableChip label="Manufacturer" value={state?.medicine?.manufacturer} />
                      </>
                    ) : (
                      <div className="text-center">
                        <p>No medicine selected</p>
                        <Button color="light" className="btn btn-sm waves-effect" onClick={() => toggleMedicine(false)}>Create one?</Button>
                      </div>
                    )}
                    {state?.selectedSupplier && state?.medicine && (
                      <>
                        <h5 className="mt-2">Supplier Detail</h5>
                        <hr />
                        <TableChip label="Name" value={state?.selectedSupplier?.name} />
                        <div className="d-flex justify-content-between">
                          <TableChip label="Email" value={state?.selectedSupplier?.email} />
                          <TableChip label="Mobile" value={state?.selectedSupplier?.mobile} />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>

      <MedicineCRUDModal
        modal={modal.medicine}
        toggle={toggleMedicine}
        rudFlag={1}
        options={aggregateOptions?.medicine}
      />

      <SupplierCRUDModal
        modal={modal.supplier}
        toggle={toggleSupplier}
        rudFlag={1}
        options={true}
        apiStartKey="supplier_add_stock"
      />

      <Modal isOpen={modal.ack} toggle={toggleAckModal} backdrop="static">
        <ModalBody>
          <ModalHeader>
            Stock successfully {props.match.params.stockID || state?.stock?.id ? "updated" : "created"}
          </ModalHeader>
          <div className="text-center mt-3">
            <Link className="btn btn-light btn-sm me-2" to="#" onClick={() => window.location.replace("/hms/pharmacy/medicine/medstock")}>
              Add Stock
            </Link>
            <Link className="btn btn-light btn-sm me-2" to="/hms/pharmacy/stock">
              Stocks
            </Link>
            <Link className="btn btn-light btn-sm me-2" to="#" onClick={() => window.location.replace(`/hms/pharmacy/medicine/medstock/${state?.stock?.id}`)}>
              Modify
            </Link>
            <Link
              to={`/hms/pharmacy/medicine/details/${state?.stock?.id}`}
              className={"btn-sm btn btn-light"}
            >
              Explore
            </Link>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default MedicineStock