import React from "react";
import { Table } from "reactstrap";
import { paymentVerboseFetch } from "components/Common/common";
import { Badge } from "reactstrap";

const BillingSummary = ({ billingDetail }) => {
  return (
    <>
      <h5 className="mt-3">Billing Summary</h5>
      <hr />
      {billingDetail ? (
        <div className="table-responsive">
          <Table className="table mb-0 table-sm">
            <tbody>
              <tr>
                <td>Sub total : </td>
                <td><b>{billingDetail?.sub_total_amount}</b></td>
              </tr>
              <tr>
                <td>Discount(%) : </td>
                <td><b>{billingDetail?.discount_percent}</b></td>
              </tr>
              <tr>
                <td>Discount amount : </td>
                <td><b>{billingDetail?.discount_amount > 0 ? `- ${billingDetail?.discount_amount}` : 0}</b></td>
              </tr>
              <tr>
                <td className="w-50">
                  <span>CGST(%) : </span><b>{billingDetail?.cgst_percent}%</b>
                </td>
                <td className="w-50">
                  <span>CGST : </span><b>{billingDetail?.cgst}</b>
                </td>
              </tr>
              <tr>
                <td className="w-50">
                  <span>SGST(%) : </span><b>{billingDetail?.cgst_percent}%</b>
                </td>
                <td className="w-50">
                  <span>SGST : </span><b>{billingDetail?.sgst}</b>
                </td>
              </tr>
              <tr>
                <td className="w-50">
                  Payment status : {billingDetail?.is_paid ? <Badge pill color="success">Paid</Badge> : <Badge pill color="danger">Unpaid</Badge>}
                </td>
                <td className="w-50">
                  Payment mode : <b>{billingDetail?.payment_mode ? paymentVerboseFetch(billingDetail?.payment_mode) : "None"}</b>
                </td>
              </tr>
              <tr>
                <th>Grand total : </th>
                <td><b>{billingDetail?.grand_total_amount}</b></td>
              </tr>
            </tbody>
          </Table>
        </div>
      ) : (
        <p className="text-center text-danger">Billing details not existed</p>
      )}
    </>
  );
};

export default BillingSummary;
