import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Col, Card, CardBody, Row, DropdownToggle, DropdownMenu, Dropdown, Modal, ModalHeader, ModalBody, ModalFooter, Button, Nav, NavItem, NavLink, TabContent, TabPane, Alert, Table } from "reactstrap"
import { chainedOptionsHms, deleteHms, getHms, postHms, resetHmsState } from "store/actions"
import { formatDateTime, TableChip } from "components/Common/common"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import classnames from "classnames"
import { Loader, PatientInformation } from "pages/HMS/common/common"
import PreviewFile from "pages/HMS/common/preview-file"
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"
import toastr from "toastr"
import { ReportDownload } from "components/Common/print-report"

const PatientDetail = (props) => {
  const dispatch = useDispatch()
  const { loading, error, hmsObject, apiKey, optionsChainedHms } = useSelector(state => state.Hms)
  const [patient, setPatient] = useState({})
  const [dropdown, setDropdown] = useState(false)
  const [prescriptions, setPrescriptions] = useState(true)
  const [verticalActiveTab, setVerticalActiveTab] = useState("1")
  const [modals, setModals] = useState({
    prescriptionsModal: false,
    oPReportsModal: false,
    previewOpReports: false,
    previewPrescriptions: false,
    deleteModal: false,
  })


  useEffect(() => {
    if (props.match.params.patientID) dispatch(getHms("/hms/patient/patients/", props.match.params.patientID, "patient_details"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "patient_details" && !isEmpty(hmsObject)) {
      setPatient(hmsObject)
    }
    if (apiKey === "raw_media") {
      setPrescriptions(optionsChainedHms?.results)
    }
    if (apiKey === "patient_detail_deleted") {
      toastr.success(`Patient is deleted`)
      setPatient({})
      toggleDelete()
      window.location.replace("/hms/patients")
    }
  }, [apiKey])

  useEffect(() => {
    if (modals.oPReportsModal) dispatch(chainedOptionsHms(`/hms/patient/patients/${props.match.params.patientID}/raw_media/`, null, "raw_media"))
    if (modals.prescriptionsModal) dispatch(chainedOptionsHms(`/hms/patient/patients/${props.match.params.patientID}/digital_prescriptions/`, null, "raw_media"))
  }, [modals.oPReportsModal, modals.prescriptionsModal])

  const toggleViewModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }))
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setVerticalActiveTab(tab)
    }
  }

  const previewFile = (url, fileName) => {
    dispatch(postHms("/hms/uploader/download/", { [fileName]: url }, "download_file", false))
  }

  const toggleDelete = (def = modals.deleteModal) => {
    setModals(prevModals => ({ ...prevModals, deleteModal: !def }))
  }

  const handleDelete = () => {
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{patient?.user_data?.full_name && `${patient.user_data.full_name} |`} Yanthura</title>
        </MetaTags>
        <Card>
          <CardBody>
            {loading && apiKey === "get_patient_details" ? (
              <Loader />
            ) : error ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : (
              <>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between">
                      <h5>Patient Information</h5>
                      <Dropdown isOpen={dropdown} toggle={() => setDropdown(!dropdown)} direction="down">
                        <DropdownToggle caret color="primary" size="sm">
                          Actions
                          &nbsp;<i className="fa fa-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <Link className="dropdown-item" to={`/hms/patient/register/${patient.id}`}>
                            Modify
                          </Link>
                          <Link className="dropdown-item" to={"#"} onClick={() => toggleViewModal('oPReportsModal')}>
                            OP Reports
                          </Link>
                          <Link className="dropdown-item" to={"#"} onClick={() => toggleViewModal('prescriptionsModal')}>
                            Prescriptions
                          </Link>
                          <Link className="dropdown-item" to={"#"} onClick={handleDelete}>
                            Remove
                          </Link>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <hr />
                    {patient?.user_data ? (
                      <PatientInformation
                        data={patient}
                        includedFields={[
                          "patient_name",
                          "patient_id",
                          "gender",
                          "marital_status",
                          "date_of_birth",
                          "age",
                          "mobile",
                          "email",
                          "alternate_mobile",
                          "occupation",
                          "blood_group",
                          "username"
                        ]}
                      />
                    ) : (
                      <p className="text-center text-danger">Patient details not existed</p>
                    )}
                  </Col>
                  <Col lg={1}></Col>
                  <Col>
                    <div className="d-flex justify-content-between">
                      <h5>Identity Information</h5>
                    </div>
                    <hr />
                    {patient?.identity_number ? (
                      <div className="d-flex justify-content-between">
                        <TableChip label="Identity type" value={patient?.identity_type_name} />
                        <TableChip label="ID number" value={patient?.identity_number} />
                      </div>
                    ) : (
                      <p className="text-center text-danger">Identity information not existed</p>
                    )}
                    <h5 className="mt-2">Insurance Details</h5>
                    <hr />
                    {patient?.health_insurance_data ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Policy number" value={patient?.health_insurance_data?.policy_number} />
                          <TableChip label="Issuer" value={patient?.health_insurance_data?.issuer_name} />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Valid till" value={formatDateTime(patient?.health_insurance_data?.policy_valid_till, "ll")} />
                          <TableChip label="Current status" value={patient?.health_insurance_data?.status} />
                        </div>
                      </>
                    ) : (
                      <p className="text-center text-danger">Insurance details not existed</p>
                    )}
                    <h5 className="mt-2 mb-2">Emergency Contact Details</h5>
                    <hr />
                    {patient?.emergency_contact_name || patient?.emergency_contact_relation || patient?.emergency_contact_number ? (
                      <div className="d-flex justify-content-between">
                        <TableChip label="Contact person" value={patient?.emergency_contact_name} />
                        <TableChip label="Relation" value={patient?.emergency_contact_relation} />
                        <TableChip label="Phone number" value={patient?.emergency_contact_number} />
                      </div>
                    ) : (
                      <p className="text-center text-danger">Emergency contact details not existed</p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5 className="mt-2">Address</h5>
                    <hr />
                    {patient?.address ? (
                      <Col>
                        <TableChip label="Line 1" value={patient?.address_data?.line1} />
                        <TableChip label="Line 2" value={patient?.address_data?.line2} />
                        <Row>
                          <Col><TableChip label="City" value={patient?.address_data?.city} /></Col>
                          <Col><TableChip label="State" value={patient?.address_data?.state} /></Col>
                          <Col><TableChip label="Pincode/Zipcode" value={patient?.address_data?.pincode} /></Col>
                        </Row>
                      </Col>
                    ) : (
                      <p className="text-center text-danger">Address details not existed</p>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </div>

      <Modal isOpen={modals.oPReportsModal} toggle={() => toggleViewModal('oPReportsModal')} backdrop="static" size="lg">
        <div className="modal-content">
          <ModalHeader toggle={() => toggleViewModal('oPReportsModal')}>
            Outpatient Reports
          </ModalHeader>
          {error?.detail ? (
            <p className="text-center text-danger m-2">{error.detail}</p>
          ) : loading && apiKey === "get_raw_media" ? (
            <Loader />
          ) : (
            <>
              <ModalBody>
                <CardBody>
                  {!isEmpty(prescriptions) ? (
                    <Row style={{ minHeight: "200px" }}>
                      <>
                        <Col md="4">
                          <Nav pills className="flex-column">
                            {prescriptions.map((item, index) => (
                              <NavItem key={index}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({ "mb-2": true, active: verticalActiveTab == `${index + 1}` })}
                                  onClick={() => toggleVertical(`${index + 1}`)}
                                >
                                  {formatDateTime(item.created_at, 'MMM D, YYYY')} - {item.number}
                                </NavLink>
                              </NavItem>
                            ))
                            }
                          </Nav>
                        </Col>
                        <Col md="8">
                          <TabContent activeTab={verticalActiveTab} className="text-muted mt-4 mt-md-0">
                            {prescriptions.map((prescription, index) => (
                              <TabPane key={index} tabId={`${index + 1}`}>
                                {prescription?.raw_media ? prescription?.raw_media?.map((url, index) => (
                                  <Alert
                                    key={index}
                                    title="Click to download"
                                    className="cursor-pointer"
                                    color="primary"
                                    onClick={(e) => previewFile(url.file_path, url.file_name)}
                                  >
                                    {url.file_name}
                                  </Alert>
                                )) : (
                                  <p className="text-center text-muted m-2">No reports uploaded for this outpatient</p>
                                )}
                              </TabPane>
                            ))}
                          </TabContent>
                        </Col>
                      </>
                    </Row>
                  ) : (
                    <p className="text-center text-muted m-2">No reports available...</p>
                  )}
                </CardBody>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={() => toggleViewModal('oPReportsModal')}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </div>
      </Modal>

      <Modal isOpen={modals.prescriptionsModal} toggle={() => toggleViewModal('prescriptionsModal')} backdrop="static" size="lg">
        <div className="modal-content">
          <ModalHeader toggle={() => toggleViewModal('prescriptionsModal')}>
            Digital Reports
          </ModalHeader>
          {error?.detail ? (
            <p className="text-center text-danger m-2">{error.detail}</p>
          ) : loading && apiKey === "get_raw_media" ? (
            <Loader />
          ) : (
            <>
              <ModalBody>
                <CardBody>
                  {!isEmpty(prescriptions) ? (
                    <Row style={{ minHeight: "200px" }}>
                      <Col md="12">
                        <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '605px' }}>
                          <Table className="table align-middle mb-0 table-nowrap">
                            <thead className="">
                              <tr>
                                <th>S No</th>
                                <th>OP Number</th>
                                <th>Date</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {prescriptions.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.number}</td>
                                  <td>{formatDateTime(item.created_at, 'MMM D, YYYY')}</td>
                                  <td>
                                    <ReportDownload
                                      url={`/hms/patient/outpatients/${item?.id}/report_digital_prescription/`}
                                      label="Download"
                                      type="text"
                                      className="text-primary"
                                      title="Click to download"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <p className="text-center text-muted m-2">No prescriptions available...</p>
                  )}
                </CardBody>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={() => toggleViewModal('prescriptionsModal')}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </div>
      </Modal>

      <PreviewFile
        modal={modals?.previewPrescriptions}
        toggle={() => toggleViewModal('previewPrescriptions')}
      />

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If patient is removed. You cannot get it back</li>
            <li>All the inpatients, outpatients and any other data linked to this record will be deleted</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/patient/patients/", patient?.id, "patient_detail_deleted")}
        deleteModal={modals.deleteModal}
      />
    </React.Fragment >
  )
}

export default PatientDetail
