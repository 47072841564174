import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { HmsDeleteModal, SingleFieldCRUModal } from "../../common/hms-crud-modals"
import { isEmpty } from "lodash"

const AffiliatedHospitals = props => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const { loading, options, hmsList, apiKey, error } = useSelector(state => state.Hms)
  const [modal, setModal] = useState(false)
  const [affiliatedHospitals, setAffiliatedHospitals] = useState({})
  const [rudFlag, setRUDFlag] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [affiliatedHospital, setAffiliatedHospital] = useState({})

  useEffect(() => {
    if (apiKey === "affiliated_hospitals") {
      setAffiliatedHospital(hmsList)
    }
    if (apiKey === "affiliated_hospitals_settings_created") {
      setAffiliatedHospital(hmsList)
    }
    if (apiKey === "affiliated_hospitals_settings_updated") {
      setAffiliatedHospital(hmsList)
    }
    if (apiKey === "affiliated_hospitals_settings_deleted") {
      setAffiliatedHospital(hmsList)
      toggleDelete()
      toastr.success(`Affiliated hospital ${affiliatedHospitals.name} is deleted`)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/affiliated-hospitals/?options=table, search"))
    dispatch(listHms("/hms/affiliated-hospitals/", null, "affiliated_hospitals"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  const columns = [{
    dataField: "name",
    text: "Affiliated Hospitals",
    formatter: (cellContent, row) => (
      <Link to="#" onClick={() => handleEdit(row)}>{row.name}</Link>
    )
  }, {
    dataField: "action",
    isDummyField: true,
    text: "",
    formatter: (cellContent, row) => (
      <UncontrolledDropdown className="ms-auto">
        <DropdownToggle
          className="text-muted font-size-16"
          color="white"
        >
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu">
          <Link className="dropdown-item" to="#" onClick={() => handleEdit(row)}>
            Modify
          </Link>
          <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
            Remove
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
  }
  ]

  const handleEdit = affiliatedHospitals_object => {
    setAffiliatedHospitals(affiliatedHospitals_object)
    setRUDFlag(0)
    toggle(false)
  }

  const handleDelete = affiliatedHospitals_object => {
    setAffiliatedHospitals(affiliatedHospitals_object)
    toggleDelete(false)
  }

  const handleCreate = () => {
    setAffiliatedHospitals({})
    setRUDFlag(1)
    toggle(false)
  }

  function toggle(def = modal) {
    setModal(!def)
  }

  function toggleDelete(state = true) {
    setDeleteModal(!state)
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/affiliated-hospitals/', decodeURIComponent(urlParams).replace("?", ""), "affiliated_hospitals"))
      setAffiliatedHospital({})
    } else {
      dispatch(listHms('/hms/affiliated-hospitals/', null, "affiliated_hospitals"))
      setAffiliatedHospital({})
    }
  }

  function createButton() {
    return (
      <div className="text-sm-start">
        <Button
          type="button"
          color="primary"
          className="me-2 btn-sm"
          onClick={handleCreate}
        >
          <i className="mdi mdi-plus me-1" />
          Create
        </Button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Row>
            {error ? (
              <p className="text-center text-danger">{error?.detail}</p>
            ) : (
              <Col xs="12">
                <Card>
                  {(!isEmpty(columns)) && (
                    <GenericTable
                      urlParams={urlParams}
                      search={options?.search}
                      columns={columns}
                      keyField={"id"}
                      noDataIndication={"No records created"}
                      data={affiliatedHospital}
                      loading={loading}
                      apiTrigger={apiTrigger}
                      defaultPageSize={options?.page_size}
                      createButton={createButton}
                      removeQueryParams
                    />
                  )}

                  <HmsDeleteModal
                    body={<>
                      <ul>
                        <li>If affiliated hospital is removed. You cannot get it back</li>
                        <li>If any data linked to this record will be vanished</li>
                      </ul>
                    </>}
                    loading={loading}
                    error={error}
                    toggleDelete={toggleDelete}
                    dispatchOperation={deleteHms("/hms/affiliated-hospitals/", affiliatedHospitals?.id, "affiliated_hospitals_settings_deleted")}
                    deleteModal={deleteModal}
                  />

                  <SingleFieldCRUModal
                    modalLabel={"Affiliated Hospital"}
                    modal={modal}
                    toggle={toggle}
                    rudFlag={rudFlag}
                    data={affiliatedHospitals}
                    apiStartKey={"affiliated_hospitals_settings"}
                    apiEndpoint={"/hms/affiliated-hospitals/"}
                    prepend={true}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AffiliatedHospitals;