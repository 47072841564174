import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import Timeslot from "./widgets"
import BedConfig from "./bed-config/bed-config"
import InsuranceIssuer from "./insurance-issuer"
import IdentityType from "./identity-type"
import Roles from "./roles"
import OPValidity from "./op-validity"
import UserPermissions from "./user-permissions";
import Notifications from "./notifications/index";
import Procedure from "./procedure"
import TestUnits from "./test-units"
import AffiliatedHospitals from "./affiliated-hospitals/affiliated-hospitals"
import Qualifications from "./qualifications"
import Ward from "./ward/ward"
import RoomCategory from "./room-category/room-category"
import HealthMetric from "./health-metric/health-metric"

const Setup = (props) => {
  const [activeTab, setActiveTab] = useState("time-slots");

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Administration | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card className="email-leftbar">
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "time-slots" })}
                        onClick={() => setActiveTab("time-slots")}
                      >
                        <i className="mdi mdi-timetable me-2"></i>Timeslots{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "bed-config" })}
                        onClick={() => setActiveTab("bed-config")}
                      >
                        <i className="mdi mdi-bed-outline me-2"></i>Bed Configuration{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "insurance-issuer" })}
                        onClick={() => setActiveTab("insurance-issuer")}
                      >
                        <i className="bx bx-briefcase-alt me-2"></i>Insurance Issuers{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "identity-type" })}
                        onClick={() => setActiveTab("identity-type")}
                      >
                        <i className="mdi mdi-card-account-details-outline me-2"></i>Identity Types{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "procedure" })}
                        onClick={() => setActiveTab("procedure")}
                      >
                        <i className="mdi mdi-stethoscope me-2"></i>Procedure{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "test-units" })}
                        onClick={() => setActiveTab("test-units")}
                      >
                        <i className="mdi mdi-test-tube me-2"></i>Test Units{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "affiliated-hospitals" })}
                        onClick={() => setActiveTab("affiliated-hospitals")}
                      >
                        <i className="mdi mdi-hospital-building me-2"></i>Affiliated Hospitals{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "qualifications" })}
                        onClick={() => setActiveTab("qualifications")}
                      >
                        <i className="mdi mdi-certificate-outline me-2"></i>Qualifications{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "ward" })}
                        onClick={() => setActiveTab("ward")}
                      >
                        <i className="mdi mdi-bunk-bed-outline me-2"></i>Ward{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "room-category" })}
                        onClick={() => setActiveTab("room-category")}
                      >
                        <i className="mdi mdi-google-classroom me-2"></i>Room Category{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "health-metric" })}
                        onClick={() => setActiveTab("health-metric")}
                      >
                        <i className="mdi mdi-list-status me-2"></i>Health Metric{" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "outpatient-validity" })}
                        onClick={() => setActiveTab("outpatient-validity")}
                      >
                        <i className="mdi mdi-account-check-outline me-2"></i>Outpatient validity {" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "roles" })}
                        onClick={() => setActiveTab("roles")}
                      >
                        <i className="mdi mdi-account-outline me-2"></i>Roles {" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "user-permissions" })}
                        onClick={() => setActiveTab("user-permissions")}
                      >
                        <i className="mdi mdi-account-key-outline me-2"></i>User Permissions {" "}
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "notifications" })}
                        onClick={() => setActiveTab("notifications")}
                      >
                        <i className="mdi mdi-bell-outline me-2"></i>Notifications {" "}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Card>

              <div className="email-rightbar mb-3">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="time-slots">
                    {activeTab === "time-slots" && <Timeslot />}
                  </TabPane>
                  <TabPane tabId="bed-config">
                    {activeTab === "bed-config" && <BedConfig />}
                  </TabPane>
                  <TabPane tabId="insurance-issuer">
                    {activeTab === "insurance-issuer" && <InsuranceIssuer />}
                  </TabPane>
                  <TabPane tabId="identity-type">
                    {activeTab === "identity-type" && <IdentityType />}
                  </TabPane>
                  <TabPane tabId="procedure">
                    {activeTab === "procedure" && <Procedure />}
                  </TabPane>
                  <TabPane tabId="test-units">
                    {activeTab === "test-units" && <TestUnits />}
                  </TabPane>
                  <TabPane tabId="affiliated-hospitals">
                    {activeTab === "affiliated-hospitals" && <AffiliatedHospitals />}
                  </TabPane>
                  <TabPane tabId="qualifications">
                    {activeTab === "qualifications" && <Qualifications />}
                  </TabPane>
                  <TabPane tabId="ward">
                    {activeTab === "ward" && <Ward />}
                  </TabPane>
                  <TabPane tabId="room-category">
                    {activeTab === "room-category" && <RoomCategory />}
                  </TabPane>
                  <TabPane tabId="health-metric">
                    {activeTab === "health-metric" && <HealthMetric />}
                  </TabPane>
                  <TabPane tabId="outpatient-validity">
                    {activeTab === "outpatient-validity" && <OPValidity />}
                  </TabPane>
                  <TabPane tabId="roles">
                    {activeTab === "roles" && <Roles />}
                  </TabPane>
                  <TabPane tabId="user-permissions">
                    {activeTab === "user-permissions" && <UserPermissions />}
                  </TabPane>
                  <TabPane tabId="notifications">
                    {activeTab === "notifications" && <Notifications />}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Setup