import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";

const SignaturePreview = ({ label, base64String, toggleModal, handleRemove }) => {

  return (
    <div>
      {base64String ? (
        <div className="position-relative d-inline-block">
          <img
            src={base64String}
            alt="Signature Preview"
            style={{ maxWidth: "100px", border: "1px solid #ccc" }}
            className="img-thumbnail"
          />
          <Link
            to="#"
            onClick={handleRemove}
            label="Remove Signature"
            className="p-0 position-absolute bg-white rounded-circle cursor-pointer d-flex justify-content-center align-items-center"
            style={{ top: "-10px", right: "-10px", border: "1px solid #ccc", width: "20px", height: "20px" }}
          >
            <i className="mdi mdi-close-thick text-danger font-size-14" />
          </Link>
        </div>
      ) : (
        <Button color="primary" className="btn-sm mb-3" onClick={toggleModal}>
          {label}
        </Button>
      )}
    </div>
  );
};

export default SignaturePreview;
