import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { getHmsOptions, listHms, postHms, resetHmsState, updateHms } from "store/hms/actions";
import { EAsyncSelect, EAvFieldNumber, RequiredFieldsMessage } from "pages/HMS/common/errored-avfields";
import toastr from "toastr"
import { calculateUnitDifference, Loader, search, stringToNumber, SubmitLoaderButton } from "../../../common/common";
import { ProcedureCRUDModal } from "../../treatment/modal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Flatpickr from "react-flatpickr"
import moment from "moment";

export const TreatmentCRUDModal = (props) => {
  const { modal, toggle, rudFlag, apiStartKey, data, prepend = false } = props
  const dispatch = useDispatch()
  const { options, formError, apiKey, hmsList, postResponse } = useSelector((state) => state.Hms)
  const [state, setState] = useState({ procedure: null, performedDoctor: null, procDefOpts: null, performedDate: null })
  const [isMenuOpen, setIsMenuOpen] = useState({ performedDoctor: false })
  const [customError, setCustomError] = useState()
  const [modalProcedure, setModalProcedure] = useState(false)
  const [formOptions, setFormOptions] = useState({ treatment: {}, procedure: {} })

  useEffect(() => {
    if (!modal) return;
    dispatch(getHmsOptions("/hms/patient/inpatient-treatments/?options=formschema", `${apiStartKey}_options`, true))
    if (rudFlag === 0) {
      setState((prevState) => ({
        ...prevState,
        procedure: data.procedure_data.find(procedure => procedure.id === data.procedure),
        performedDoctor: data.performed_doctor_data,
      }))
    }
    return () => dispatch(resetHmsState())
  }, [modal])

  useEffect(() => { if (formError) setCustomError(formError) }, [formError])

  useEffect(() => {
    if (apiKey === `${apiStartKey}_options`) {
      setFormOptions((prevState) => ({ ...prevState, treatment: options }))
      dispatch(getHmsOptions("/hms/diagnosis/procedures/?options=formschema", `${apiStartKey}_procedure_options`))
    }
    if (apiKey === `${apiStartKey}_procedure_options`) setFormOptions((prevState) => ({ ...prevState, procedure: options }))
    if (apiKey === `${apiStartKey}_procedure`) setState((prevState) => ({ ...prevState, procDefOpts: hmsList?.results }))
    if (apiKey === `${apiStartKey}_created`) {
      treatmentToggle()
      toastr.success("Treatment created successfully")
    }
    if (apiKey === `${apiStartKey}_updated`) {
      treatmentToggle()
      toastr.success("Treatment updated successfully")
    }
    if (apiKey === "procedure_created") {
      setState((prevState) => ({ ...prevState, procedure: postResponse }))
    }
  }, [apiKey])

  const handleSubmit = (e, values) => {
    values = {
      inpatient: data?.inpatient,
      procedure: state?.procedure?.id,
      performed_doctor: state?.performedDoctor?.id,
      cost: values.cost ? Number(values.cost) : null,
      performed_date: state?.performedDate || data?.performed_date
    }

    if (rudFlag === 0) {
      dispatch(updateHms('/hms/patient/inpatient-treatments/', data?.id, values, `${apiStartKey}_updated`))
    } else {
      dispatch(postHms('/hms/patient/inpatient-treatments/', values, `${apiStartKey}_created`, prepend))
    }
  }

  const searchProcedures = (value, callback) => {
    search(value, callback, "/hms/diagnosis/procedures/")
  }

  const searchDoctors = (value, callback) => {
    search(value, callback, "/hms/staff/doctors/", () => setIsMenuOpen((prev) => ({ ...prev, performedDoctor: value.length > 0 })))
  }

  function toggleProcedure(def = modalProcedure) {
    setModalProcedure(!def);
    setCustomError(null)
  }

  const treatmentToggle = () => {
    setState({})
    toggle()
  }

  return (
    <>
      <Modal isOpen={modal} toggle={treatmentToggle} backdrop="static" scrollable={true}>
        <ModalHeader toggle={treatmentToggle} tag="h4">
          {rudFlag === 0 ? "Modify Treatment" : rudFlag === 1 ? "Add Treatment" : ""}
        </ModalHeader>
        <ModalBody>
          {apiKey === `get_${apiStartKey}_get_treatment` ? (
            <Loader />
          ) : (
            <AvForm onValidSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center">
                        <div className="ajax-select select2-container" style={{ flex: "1" }}>
                          <Label className={formError?.procedure ? "text-danger" : ''}>
                            Procedure *
                          </Label>
                          <div className="d-flex align-items-center mb-3">
                            <div className="w-100">
                              <EAsyncSelect
                                cacheOptions
                                loadOptions={searchProcedures}
                                formError={formError?.procedure}
                                onChange={(procedure) => setState({ ...state, procedure: procedure })}
                                value={[state.procedure]}
                                placeholder="Search treatment..."
                                getOptionLabel={e => e.name}
                                getOptionValue={e => e.id}
                                defaultOptions={state.procDefOpts}
                                onFocus={() => dispatch(listHms("/hms/diagnosis/procedures/", null, `${apiStartKey}_procedure`))}
                              />
                            </div>
                            <div
                              onClick={() => toggleProcedure(false)}
                              title="Add procedure"
                              style={{ cursor: "pointer" }}
                              className={`ms-2 ${formError?.procedure ? "mb-2" : ""}`}
                            >
                              <i className="bx bx-plus-medical text-success" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3 ajax-select select2-container">
                        <EAsyncSelect
                          onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, performedDoctor: false }))}
                          menuIsOpen={isMenuOpen.performedDoctor}
                          options={{ label: "Performed doctor", required: true }}
                          value={[state.performedDoctor]}
                          loadOptions={searchDoctors}
                          formError={formError?.performed_doctor}
                          onChange={(doctor) => setState({ ...state, performedDoctor: doctor })}
                          placeholder="Type to search doctors..."
                          getOptionLabel={e => `${e.user_data.full_name} - ${e.specialization}`}
                          getOptionValue={e => e.id}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <label>Performed Date *</label>
                        <Flatpickr
                          className={`form-control ${formError?.performed_date ? 'is-invalid' : ''}`}
                          options={{ mode: "single", enableTime: true, dateFormat: "d-M-Y h:i K" }}
                          value={data?.performed_date}
                          onChange={(selectedDates) => setState((prevState) => ({ ...prevState, performedDate: selectedDates?.[0] ? new Date(selectedDates[0]).toISOString() : null }))}
                        />
                        {formError?.performed_date && <div className="text-danger small">{formError?.performed_date}</div>}
                      </div>
                    </Col>
                    <Col>
                      <EAvFieldNumber
                        field="cost"
                        value={data?.cost || state?.procedure?.cost}
                        isError={formError?.cost}
                        options={{ label: "Cost", required: true }}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-end mt-3">
                    <Col sm={9}>
                      <div>
                        {apiKey === `post_${apiStartKey}_created` || apiKey === `update_${apiStartKey}_updated` ? (
                          <Button color="primary" className="float-end" disabled>
                            Submit
                            <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                          </Button>
                        ) : (
                          <Button type="submit" color="primary" className="float-end">
                            Submit
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </AvForm>
          )}
        </ModalBody>
      </Modal>

      <ProcedureCRUDModal
        modal={modalProcedure}
        toggle={toggleProcedure}
        rudFlag={1}
        options={formOptions?.procedure?.form_schema}
        customError={customError}
      />
    </>
  )
}

export const BedCRUDModal = (props) => {
  const { modal, toggle, rudFlag, apiStartKey, data, prepend = false } = props
  const dispatch = useDispatch()
  const { formError, apiKey, hmsList } = useSelector((state) => state.Hms)
  const formRef = useRef()
  const [state, setState] = useState({ bed: null, bedOptions: [], fromDate: null, toDate: null, unit: null })
  const [customError, setCustomError] = useState()

  useEffect(() => {
    if (!modal) return;
    if (rudFlag === 0) {
      setState((prevState) => ({ ...prevState, bed: data?.bed_detail_data, fromDate: data?.from_date, toDate: data?.to_date, unit: data?.unit }))
    }
    return () => dispatch(resetHmsState())
  }, [modal])

  useEffect(() => { if (formError) setCustomError(formError) }, [formError])

  useEffect(() => {
    if (apiKey === `${apiStartKey}_created`) {
      bedToggle()
      toastr.success("Bed created successfully")
      formRef.current?.reset()
    }
    if (apiKey === `${apiStartKey}_updated`) {
      bedToggle()
      toastr.success("Bed updated successfully")
    }
    if (apiKey === `${apiStartKey}_options_beds`) {
      setState((prevState) => ({ ...prevState, bedOptions: hmsList?.results }))
    }
  }, [apiKey])

  const handleSubmit = (e, values) => {
    values = {
      from_date: state?.fromDate,
      to_date: state?.toDate,
      unit: state?.unit,
      cost: stringToNumber(values.cost),
      inpatient: data?.inpatient,
      bed_detail: state?.bed?.id
    }

    if (rudFlag === 0) {
      dispatch(updateHms('/hms/patient/inpatient-bedhistory/', data?.id, values, `${apiStartKey}_updated`))
    } else {
      dispatch(postHms('/hms/patient/inpatient-bedhistory/', values, `${apiStartKey}_created`, prepend))
    }
  }

  const searchBeds = (value, callback) => {
    search(value, callback, "/hms/patient/bedconfig/", false, "query")
  }

  const bedToggle = () => {
    setState({})
    setCustomError(null)
    toggle()
  }

  const handleFromDateChange = (selectedDates) => {
    const newFromDate = selectedDates?.[0] ? moment(selectedDates[0]).toISOString() : null;

    setState((prevState) => {
      const updatedState = { ...prevState, fromDate: newFromDate };

      if (updatedState.fromDate && updatedState.toDate) {
        if (moment(updatedState.toDate).isBefore(updatedState.fromDate)) {
          setCustomError({
            from_date: "From Date cannot be greater than To Date",
            to_date: "To Date cannot be less than From Date",
          });
        } else {
          setCustomError({ from_date: null, to_date: null });
          const unit = calculateUnitDifference(updatedState.fromDate, updatedState.toDate);
          updatedState.unit = unit;
        }
      }
      return updatedState;
    });
  };

  const handleToDateChange = (selectedDates) => {
    const newToDate = selectedDates?.[0] ? moment(selectedDates[0]).toISOString() : null;

    setState((prevState) => {
      const updatedState = { ...prevState, toDate: newToDate };

      if (updatedState.fromDate && updatedState.toDate) {
        if (moment(updatedState.toDate).isBefore(updatedState.fromDate)) {
          setCustomError({
            from_date: "From Date cannot be greater than To Date",
            to_date: "To Date cannot be less than From Date",
          });
        } else {
          setCustomError({ from_date: null, to_date: null });
          const unit = calculateUnitDifference(updatedState.fromDate, updatedState.toDate);
          updatedState.unit = unit;
        }
      }
      return updatedState;
    });
  };

  return (
    <>
      <Modal isOpen={modal} toggle={bedToggle} backdrop="static" scrollable={true} size="lg">
        <ModalHeader toggle={bedToggle} tag="h4">
          {rudFlag === 0 ? "Modify Bed" : rudFlag === 1 ? "Add Bed" : ""}
        </ModalHeader>
        <ModalBody>
          {apiKey === `get_${apiStartKey}_get_bed` ? (
            <Loader />
          ) : (
            <AvForm onValidSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col>
                  <div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                    <div className="ajax-select select2-container" style={{ flex: "1" }}>
                      <Label className={customError?.ward ? "text-danger" : ''}>
                        Bed *
                      </Label>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "100%" }}>
                          <EAsyncSelect
                            cacheOptions
                            value={[state?.bed]}
                            loadOptions={searchBeds}
                            formError={customError?.bed}
                            onChange={(value) => setState((prevState) => ({ ...prevState, bed: value }))}
                            placeholder="Type to search bed..."
                            getOptionLabel={e => `${e.room_category_name} | ${e.ward_name} | Bed number - ${e.bed_number} | Room - ${e.room_number} | Floor - ${e.floor} | Price - ${e.price}`}
                            getOptionValue={e => e.id}
                            defaultOptions={state?.bedOptions}
                            onFocus={() => dispatch(listHms("/hms/patient/bedconfig/", null, `${apiStartKey}_options_beds`))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group mb-3">
                    <label className={`${customError?.from_date ? 'text-danger' : ''}`}>From Date *</label>
                    <Flatpickr
                      className={`form-control ${customError?.from_date ? 'is-invalid' : ''}`}
                      options={{ mode: "single", enableTime: true, dateFormat: "d-M-Y h:i K" }}
                      value={state.fromDate}
                      onChange={handleFromDateChange}
                    />
                    {customError?.from_date && <div className="text-danger small">{customError.from_date}</div>}
                  </div>
                </Col>
                <Col>
                  <div className="form-group mb-3">
                    <label className={`${customError?.to_date ? 'text-danger' : ''}`}>To Date *</label>
                    <Flatpickr
                      className={`form-control ${customError?.to_date ? 'is-invalid' : ''}`}
                      options={{ mode: "single", enableTime: true, dateFormat: "d-M-Y h:i K" }}
                      value={state.toDate}
                      onChange={handleToDateChange}
                    />
                    {customError?.to_date && <div className="text-danger small">{customError.to_date}</div>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <EAvFieldNumber
                    field="unit"
                    value={state?.unit}
                    onChange={(e) => setState((prevState) => ({ ...prevState, unit: Number(e.target.value) }))}
                    isError={customError?.unit}
                    options={{ label: "Units", required: true, min_value: 0, max_value: 10000 }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <EAvFieldNumber
                    field="cost"
                    value={data?.cost || state?.bed?.price}
                    isError={customError?.cost}
                    options={{ label: "Cost", required: true, min_value: 0, max_value: 100000 }}
                  />
                </Col>
                <RequiredFieldsMessage />
              </Row>
              <Row>
                <Col>
                  <div className="text-end mb-2">
                    <SubmitLoaderButton
                      type="submit" size="sm"
                      loading={apiKey === `post_${apiStartKey}_created` || apiKey === `update_${apiStartKey}_updated`}
                      disabled={customError?.from_date || customError?.to_date}
                    >
                      SAVE
                    </SubmitLoaderButton>
                  </div>
                </Col>
              </Row>
            </AvForm>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

const ConfirmationModal = ({ isOpen, toggle, formRef }) => {
  const { apiKey } = useSelector((state) => state.Hms)
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submit();
      toggle()
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
            <p className="text-muted">
              I confirm that I have reviewed the bill summary and acknowledge the total payable amount.
              By proceeding, I agree to complete the payment for the discharge process.
            </p>
          </Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        {apiKey === "post_discharge_ip_post" ? (
          <Button color="primary" className="float-end btn-sm" disabled>
            Proceed<i className="bx bx-loader bx-spin font-size-16 align-middle ms-1"></i>
          </Button>
        ) : (
          <Button onClick={handleSubmit} color="primary" disabled={!isChecked}>
            Proceed
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
