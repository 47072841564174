import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, Modal, ModalBody, Badge, Label, ButtonDropdown, DropdownItem, ModalHeader, ModalFooter } from "reactstrap"
import { getHms, getHmsOptions, postHms, resetHmsState, updateHms } from "store/actions";
import { TableChip } from "components/Common/common";
import { isEmpty } from "lodash";
import { handleIncludedKeysError, Loader, PatientInformation } from "pages/HMS/common/common";
import BillingSummary from "pages/HMS/billing/billing-summary";
import { NestedTable } from "./nested-table";
import toastr from "toastr";
import { get, postPdf } from "helpers/api_helper";
import { AvForm } from "availity-reactstrap-validation";
import { EAvFieldCheck } from "pages/HMS/common/errored-avfields";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ReportDownload } from "components/Common/print-report";

const BookTestDetail = (props) => {
  const dispatch = useDispatch();
  const { loading, error, hmsObject, apiKey, options, updateResponse, postResponse, formError } = useSelector(state => state.Hms);
  const [testObject, setTestObject] = useState({});
  const [testsData, setTestsData] = useState([]);
  const [patient, setPatient] = useState({});
  const [billingDetail, setBillingDetail] = useState(null);
  const [modals, setModals] = useState({ ackModal: false, confirmModal: false, resultModal: false, buttonDropdown: false, dropdownOpen: false });
  const [confirmEnabled, setConfirmEnabled] = useState(false)
  const [transformedData, setTransformedData] = useState(null)
  const [selectedTests, setSelectedTests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (apiKey === "test_details") {
      const apiMode = hmsObject?.patient ? "patients" : hmsObject?.outpatient ? "outpatients" : hmsObject?.inpatient ? "inpatients" : null
      const patientID = hmsObject?.patient || hmsObject?.outpatient || hmsObject?.inpatient
      if (apiMode) {
        dispatch(getHms(`/hms/patient/${apiMode}/`, patientID, "patient_details"))
      }
      setTestObject(hmsObject);
      setTestsData(hmsObject.tests_data)

      if (!isEmpty(hmsObject?.tests_data)) {
        Promise.all(hmsObject?.tests_data.map(test =>
          get(`/hms/diagnosis/tests/${test?.test}/all_test_parameters/`).then(response => ({
            status: response.status,
            data: response,
            id: test?.id
          }))
        )).then(results => {
          results.forEach(result => {
            if (result.data) {
              setTestsData(prevTestsData => prevTestsData.map(test => test.id === result.id ? { ...test, parameters: result.data } : test));
            }
          });
        }).catch(err => {
          console.log(err);
        }).finally(() => {
          dispatch(getHms(`/hms/billing/billing/${hmsObject.billing}/`, null, "billing_details"))
        });
      }
    }
    if (apiKey === "patient_details") {
      setPatient(hmsObject?.patient_data || hmsObject);
    }
    if (apiKey === "billing_details") {
      setBillingDetail(hmsObject);
      const updatedTestsData = mergeResults(testsData, testObject?.results);
      setTestsData(updatedTestsData);
    }
    if (apiKey === "status_change") {
      setTestObject((prevState) => ({ ...prevState, status: updateResponse.status }));
    }
    if (apiKey === "book_test_modified") {
      setModals({ ackModal: true, confirmModal: false, resultModal: false, buttonDropdown: false, dropdownOpen: false });
      setConfirmEnabled(false);
      setTestObject(updateResponse);
    }
    if (apiKey === "send_report") {
      toastr.success(postResponse.detail)
    }
    if (formError && apiKey.startsWith("fail_")) {
      const includedKeys = ["detail"]
      handleIncludedKeysError(formError, apiKey, "fail_", includedKeys, false)
    }
  }, [apiKey]);

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/book-tests/?options=formschema,table,search", "bt_options"))
    if (props.match.params.testID) dispatch(getHms("/hms/diagnosis/book-tests/", props.match.params.testID, "test_details"))
    return () => dispatch(resetHmsState())
  }, []);

  useEffect(() => {
    const transformedData = testsData?.map(test => {
      const transformedTests = {
        id: test.test,
        name: test.name,
        short_name: test.short_name || null,
        parameters: transformParameters(test?.parameters)
      };
      return transformedTests;
    });
    setTransformedData(transformedData)
  }, [testsData])

  const onStatusChange = (e) => {
    dispatch(updateHms("/hms/diagnosis/book-tests/", testObject.id, { status: e.target.value }, "status_change"))
  }

  const onTableChange = (e, test) => {
    const updateParameters = (parameters) => {
      return parameters.map(parameter => {
        if (parameter.id === test.id) {
          return { ...parameter, result: e.target.value };
        }
        if (parameter.children && parameter.children.length > 0) {
          return { ...parameter, children: updateParameters(parameter.children) };
        }
        return parameter;
      });
    };

    const updatedTestsData = testsData.map(test => ({ ...test, parameters: updateParameters(test.parameters) }));
    setTestsData(updatedTestsData);
  }

  function transformParameters(parameters) {
    return parameters?.map(parameter => {
      const transformedParameter = {
        id: parameter.id,
        name: parameter.name,
        reference_result: parameter.reference_result,
        unit: parameter.unit,
        unit_name: parameter.unit_name,
        result: parameter.result,
      };
      if (parameter.children && parameter.children.length > 0) {
        transformedParameter.children = transformParameters(parameter.children);
      }
      return transformedParameter;
    });
  }

  const mergeResults = (testsData, testObject) => {
    const resultsMap = new Map();

    const addResultsToMap = (parameters, testId) => {
      parameters?.forEach(param => {
        resultsMap.get(testId).set(param.id, param.result || param.children);
        if (param.children) {
          addResultsToMap(param.children, testId);
        }
      });
    };

    testObject?.forEach(test => {
      resultsMap?.set(test?.id, new Map());
      addResultsToMap(test?.parameters, test?.id);
    });

    const updateParameters = (parameters, testId) => {
      return parameters?.map(param => {
        const updatedParam = { ...param };
        if (resultsMap.get(testId)?.has(param.id)) {
          const resultOrChildren = resultsMap.get(testId).get(param.id);
          if (typeof resultOrChildren === "string") {
            updatedParam.result = resultOrChildren;
          } else if (Array.isArray(resultOrChildren)) {
            updatedParam.children = updateParameters(resultOrChildren, testId);
          }
        }
        if (param.children) {
          updatedParam.children = updateParameters(param.children, testId);
        }
        return updatedParam;
      });
    };

    return testsData.map(test => ({
      ...test,
      parameters: updateParameters(test.parameters, test.test)
    }));
  };

  const handleSubmit = () => {
    if (!isEmpty(transformedData)) {
      dispatch(updateHms("/hms/diagnosis/book-tests/", testObject?.id, { results: transformedData, is_finished: true }, "book_test_modified",))
    } else {
      toastr.error("Please add result")
    }
  }

  const handleValidSubmit = (action = "combined") => {
    const payload = { action: action, tests: !isEmpty(selectedTests) ? selectedTests?.map(test => (test?.id)) : [] }
    download(`/hms/diagnosis/book-tests/${testObject?.id}/test_report/`, payload)
    setSelectedTests([])
  }

  const handleCheckboxChange = (checked, test) => {
    setSelectedTests(prevSelectedTests => {
      if (checked) {
        return prevSelectedTests?.some(perm => perm?.id === test?.id) ? prevSelectedTests : [...prevSelectedTests || [], test];
      }
      return prevSelectedTests?.filter(perm => perm?.id !== test?.id);
    });
  };

  const toggleModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }));
  };

  const download = (url, payload) => {
    setIsLoading(true);
    postPdf(url, payload, {
      responseType: "blob",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const blobUrl = window.URL.createObjectURL(response);
        const newTab = window.open();
        if (newTab) {
          newTab.location = blobUrl;
          setTimeout(() => window.URL.revokeObjectURL(blobUrl), 5000);
        } else {
          toastr.error("Failed to open a new tab. Please check your browser's pop-up blocker settings.");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setModals((prevModals) => ({ ...prevModals, ackModal: false, }));
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{patient?.user_data?.full_name && `${patient.user_data.full_name} |`} Yanthura</title>
        </MetaTags>
        <Card>
          <CardBody>
            {loading && apiKey === "get_test_details" ? (
              <Loader />
            ) : error && !["DIAG_REPORT_DNLD", "TEST_DOES_NOT_EXIST"].includes(error.code) ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : (
              <>
                <Row>
                  <Col md={12} lg={6}>
                    <div className="mt-2 d-flex justify-content-between">
                      <h5>Test Details</h5>
                      <div className="d-flex flex-column flex-sm-row justify-content-end align-items-start">
                        {options?.form_schema?.status &&
                          <select
                            value={testObject?.status ? testObject?.status : ""}
                            onChange={onStatusChange}
                            className="text-start me-3 text-muted border-none outline-none bg-transparent p-0"
                          >
                            {options?.form_schema?.status?.choices?.map((choice, cidx) =>
                              <option value={choice.value} key={cidx}>{choice.display_name}</option>
                            )}
                          </select>
                        }
                        <div className="text-sm-end ms-2">
                          <UncontrolledDropdown direction="down">
                            <DropdownToggle caret color="primary" size="sm">
                              Action <i className="fa fa-caret-down ms-1" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <Link className="dropdown-item" to={`/hms/diagnosis/test/book/${testObject.id}`}>Modify</Link>
                              <ReportDownload
                                label="Print Invoice"
                                url={`/hms/diagnosis/book-tests/${testObject?.id}/invoice_receipt/`}
                                type="menu-item"
                              />
                              <UncontrolledDropdown direction="down" title={!testObject?.is_finished ? "Please add results before downloading the report" : ""}>
                                <DropdownToggle className="dropdown-item" caret color="primary" disabled={!testObject?.is_finished}>
                                  Download Report <i className="fa fa-caret-down ms-1" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <ReportDownload
                                    label="Separate"
                                    type="menu-item"
                                    showIcon={false}
                                    url={`/hms/diagnosis/book-tests/${testObject?.id}/test_report/`}
                                    payload={{ action: "separate", tests: !isEmpty(testObject?.results) ? testObject?.results?.map(test => (test?.id)) : [] }}
                                  />
                                  <ReportDownload
                                    label="Combined"
                                    type="menu-item"
                                    showIcon={false}
                                    url={`/hms/diagnosis/book-tests/${testObject?.id}/test_report/`}
                                    payload={{ action: "combined", tests: !isEmpty(testObject?.results) ? testObject?.results?.map(test => (test?.id)) : [] }}
                                  />
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="text-sm-end ms-2">
                          <Button
                            type="button"
                            color="primary"
                            className="btn-sm"
                            onClick={() => toggleModal("resultModal")}
                            disabled={isEmpty(testsData)}
                          >
                            Add Results
                          </Button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {patient?.user_data || testObject?.referred_by || testObject?.referred_by_doctor_name || testObject?.sample_collected_by || testObject?.result_requested_through || testObject?.status ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Referred by"
                            value={testObject?.referred_by || testObject?.referred_by_doctor_name}
                          />
                          <TableChip
                            label="Sample collected by"
                            value={testObject?.sample_collected_by}
                          />
                          {testObject?.result_requested_through === "Email" && (
                            <TableChip
                              label="Email sent"
                              value={testObject?.report_email_sent ? "Yes" : "No"}
                            />
                          )}
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Result requested by"
                            value={testObject?.result_requested_through}
                          />
                          {testObject?.result_delivery && (
                            <TableChip
                              label="Result delivery"
                              value={testObject?.result_delivery}
                            />
                          )}
                          <TableChip
                            label="Status"
                            value={testObject?.status}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="w-100 me-1">
                            <Label>Tests</Label>
                            <p>
                              {!isEmpty(testsData) ? (testsData.map((item, index) => (
                                <Link
                                  to="#"
                                  className="badge badge-soft-primary font-size-11 me-1 ms-1 mb-2 p-1 cursor-pointer"
                                  key={index}
                                >
                                  {item.name} {item?.short_name ? `(${item.short_name})` : ""}
                                </Link>
                              ))) : (
                                <p className="text-muted">NA</p>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Finished"
                            value={testObject?.is_finished ? (
                              <Badge pill color="success">Yes</Badge>
                            ) : (
                              <Badge pill color="danger">No</Badge>
                            )}
                          />
                          <TableChip
                            label="Module"
                            value={testObject?.patient ? (
                              <Badge pill color="info">Patient</Badge>
                            ) : testObject?.outpatient ? (
                              <Badge pill color="primary">Outpatient</Badge>
                            ) : testObject?.inpatient ? (
                              <Badge pill color="success">Inpatient</Badge>
                            ) : (
                              <Badge pill color="secondary">Direct</Badge>
                            )}
                          />
                          <TableChip defaultIfNull=""></TableChip>
                        </div>
                      </>
                    ) : (
                      <p className="text-center text-danger">Test details not existed</p>
                    )}
                    <BillingSummary billingDetail={billingDetail} />
                  </Col>
                  <Col md={12} lg={6}>
                    <div className="mt-2 d-flex justify-content-between">
                      <h5>Patient Information</h5>
                    </div>
                    <hr />
                    {patient?.user_data ? (
                      <PatientInformation
                        data={patient}
                        includedFields={[
                          "patient_name",
                          "patient_id",
                          "gender",
                          "marital_status",
                          "date_of_birth",
                          "age",
                          "mobile",
                          "email",
                          "blood_group",
                        ]}
                      />
                    ) : testObject?.patient_data ? (
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Patient name"
                          value={`${testObject?.patient_data?.patient_name}`}
                        />
                      </div>
                    ) : (
                      <p className="text-center text-danger">Patient details not existed</p>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </div>

      <Modal isOpen={modals.resultModal} toggle={() => toggleModal("resultModal")} backdrop="static" id="staticBackdrop" scrollable size="xl">
        <ModalHeader toggle={() => toggleModal("resultModal")} tag="h5">
          Test Results
        </ModalHeader>
        <ModalBody style={{ maxHeight: "74vh" }}>
          {!isEmpty(testsData) ? testsData.map((test, index) => (
            <div key={index}>
              <NestedTable test={test} index={index} idx={index} handleChange={onTableChange} />
            </div>
          )) : (
            <p className="text-center text-danger" scope="col" colSpan="4">Tests not existed</p>
          )}
        </ModalBody>
        <ModalFooter>
          {!isEmpty(testsData) && (
            <button className="btn btn-primary save-user" onClick={() => toggleModal("confirmModal")}>
              Submit
            </button>
          )}
        </ModalFooter>
      </Modal>


      <Modal isOpen={modals.confirmModal} toggle={() => toggleModal("confirmModal")} backdrop="static" id="staticBackdrop">
        <ModalHeader toggle={() => toggleModal("confirmModal")} tag="h5">
          Confirm Test Completion
        </ModalHeader>
        <ModalBody>
          <AvForm>
            <EAvFieldCheck
              name="confirm"
              options={{ label: "I confirm that the entered test results are accurate." }}
              onChange={(e) => setConfirmEnabled(e.target.checked)}
            />
          </AvForm>
        </ModalBody>
        <ModalFooter>
          {loading && apiKey === `update_book_test_modified` ? (
            <button disabled className="btn btn-primary save-user">
              Confirm <i className="bx bx-loader bx-spin font-size-16 align-middle ms-1" />
            </button>
          ) : (
            <button type="button" className="btn btn-primary save-user" disabled={!confirmEnabled} onClick={handleSubmit}>
              Confirm
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modals.ackModal} toggle={() => toggleModal("ackModal")} size="lg" fade>
        <ModalHeader toggle={() => toggleModal("ackModal")} tag="h5">
          <div className="d-flex align-items-center">
            <i className="bx bx-check-circle font-size-18 me-2 text-success" />
            <span>Results Submitted Successfully</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xl="6" sm="12">
              <Label className="fw-bold mb-2">Notification:</Label>
              <AvForm>
                <EAvFieldCheck
                  name="email"
                  options={{ label: "Send Email" }}
                  onChange={(e) => {
                    if (e.target.checked && testObject?.result_requested_through === "Email") {
                      dispatch(postHms(`/hms/diagnosis/book-tests/${testObject?.id}/send_notification/`, { [e.target.name]: e.target.checked }, "send_report", false))
                    }
                  }}
                  disabled={["post_send_report", "send_report"].includes(apiKey) || testObject?.result_requested_through === "Offline"}
                  title={testObject?.result_requested_through === "Email" ? "" : "Can't send email due to no email address is available for result delivery"}
                />
              </AvForm>
            </Col>
            <Col xl="6" sm="12" className="vh-50">
              <Label className="fw-bold mb-2">Download Report:</Label>
              <div className="overflow-auto scrollbar-thin" style={{ maxHeight: "42vh" }}>
                {testObject?.results?.map((test) => (
                  <div className="custom-accordion mb-2" key={test?.id}>
                    <div className="d-flex align-items-center">
                      <input
                        id={test?.id}
                        type="checkbox"
                        checked={selectedTests?.some((tests) => tests.id === test.id) ?? false}
                        onChange={(e) => handleCheckboxChange(e.target.checked, test)}
                        className="me-2"
                      />
                      <Link
                        o="#"
                        className="text-body fw-medium d-flex align-items-center w-100 text-truncate"
                        onClick={(e) => (e.preventDefault(), handleCheckboxChange(!selectedTests?.some((t) => t.id === test.id), test))}
                      >
                        {test?.name}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-end mt-2 me-3">
                <ButtonDropdown
                  isOpen={modals.dropdownOpen}
                  toggle={() => toggleModal("dropdownOpen")}
                  title={!isLoading && selectedTests <= 0 ? "No tests are selected" : ""}
                >
                  <Button
                    color="primary"
                    size="sm"
                    style={{ borderRight: "2px solid #ccc" }}
                    onClick={() => handleValidSubmit("combined")}
                    disabled={isLoading || selectedTests <= 0}
                  >
                    Download Report
                  </Button>
                  <DropdownToggle split color="primary" size="sm" disabled={isLoading || selectedTests <= 0}>
                    {isLoading ? <i className="bx bx-loader bx-spin font-size-16 align-middle ms-1" /> : <i className="mdi mdi-download align-middle font-size-12" />}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleValidSubmit("separate")}>Separate</DropdownItem>
                    <DropdownItem onClick={() => handleValidSubmit("combined")}>Combined</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default BookTestDetail;
