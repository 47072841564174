import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { CardBody, Card, Row, Col, CardTitle, Button, Nav, NavItem, NavLink, TabContent, TabPane, Table, Modal, ModalBody, ModalFooter, } from "reactstrap"
import { getHms, getHmsOptions, postHms, resetHmsState } from "store/hms/actions"
import { get } from "helpers/api_helper"
import { AvForm } from "availity-reactstrap-validation"
import { isEmpty } from "lodash"
import { TableChip, formatDateTime, updateArrayObject, updatePaginationResult } from "components/Common/common"
import { AsyncSearch } from "pages/HMS/common/errored-avfields"
import { Loader, PatientInformation, SubmitLoaderButton, calculateUnitDifference, floatRoundDown, handleError } from "pages/HMS/common/common"
import { ReportDownload } from "components/Common/print-report"
import { TreatmentTable, PharmacyTable, BedTable, BillingTable, NoDataIndicationTable, DiagnosisTable } from "./tables"
import toastr from "toastr"
import ConfirmationModal from "./modals"

const InpatientDischarge = (props) => {
  const dispatch = useDispatch()
  const { error, hmsObject, formError, apiKey, postResponse, updateResponse, actionStatus, options } = useSelector((state) => state.Hms)
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [patientSearch, setPatientSearch] = useState(true)
  const [customActiveTab, setCustomActiveTab] = useState("1")
  const [formOptions, setFormOptions] = useState(null)
  const [patient, setPatient] = useState({})
  const [treatments, setTreatments] = useState([])
  const [medicines, setMedicines] = useState([])
  const [beds, setBeds] = useState([])
  const [bookTests, setBookTests] = useState([])
  const [discountedPerson, setDiscountedPerson] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState({ searchPatients: false })
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [billingConfirmation, setBillingConfirmation] = useState(false)
  const [billing, setBilling] = useState({
    treatmentTotalAmount: 0,
    pharmacyTotalAmount: 0,
    pharmacyPaidAmount: 0,
    pharmacyDueAmount: 0,
    pharmacyPaidSubtotalAmount: 0,
    pharmacyUnpaidSubtotalAmount: 0,
    pharmacyPaidDiscountTotalAmount: 0,
    pharmacyPaidTaxAmount: 0,
    pharmacyUnpaidDiscountTotalAmount: 0,
    pharmacyUnpaidTaxAmount: 0,
    bedTotalAmount: 0,
    diagnosisTotalAmount: 0,
    diagnosisPaidAmount: 0,
    diagnosisDueAmount: 0,
    diagnosisPaidSubtotalAmount: 0,
    diagnosisUnpaidSubtotalAmount: 0,
    diagnosisPaidDiscountTotalAmount: 0,
    diagnosisPaidTaxAmount: 0,
    diagnosisUnpaidTaxAmount: 0,
    diagnosisUnpaidDiscountTotalAmount: 0,
    totalAmount: 0,
    totalPaidAmount: 0,
    totalDueAmountWithInsurance: 0,
    totalDueAmount: 0,
    insuranceClaimedAmount: 0,
    discountPercentage: 0,
    discountAmount: 0,
    cgstAmount: 0,
    sgstAmount: 0,
    cgstPercentage: 0,
    sgstPercentage: 0,
    payableAmount: 0,
    paymentMode: "",
    discountType: "",
    chequeNumber: null,
    chequeDate: null,
  })

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/inpatients/?options=formschema", "disc_ip_options", true))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey?.startsWith("fail_discharge")) {
      handleError(formError, apiKey, "fail_discharge")
    }
    if (apiKey === "disc_ip_options") {
      setFormOptions(options)
    }
    if (apiKey === "selected_patient") {
      setPatient(hmsObject)
      dispatch(getHms("/hms/patient/inpatient-discharges/", hmsObject?.id, "discharge_summary"))
    }
    if (apiKey === "discharge_summary") {
      setBillingConfirmation(true)
      setBilling(prevState => ({
        ...prevState,
        insuranceClaimedAmount: hmsObject?.billing_summary?.insurance_claim_amount,
        discountPercentage: hmsObject?.billing_data?.discount_percent,
        cgstPercentage: hmsObject?.billing_data?.cgst_percent,
        sgstPercentage: hmsObject?.billing_data?.sgst_percent,
        paymentMode: hmsObject?.billing_data?.payment_mode,
        discountType: hmsObject?.billing_data?.discount_type,
      }))
      setDiscountedPerson({ id: hmsObject?.billing_data?.discount_by, full_name: hmsObject?.billing_data?.discount_by_name })
    }
    if (apiKey === "inpatient_medicines") {
      setMedicines(hmsObject)
    }
    if (apiKey === "inpatient_treatment_history") {
      setTreatments(hmsObject)
    }
    if (apiKey === "inpatient_bed_history") {
      setBeds(hmsObject)
      setLoading(false)
    }
    if (apiKey === "inpatient_diagnosis_book_tests") {
      setBookTests(hmsObject)
    }
    if (apiKey === "discharge_treatment_created") {
      setTreatments((prevState) => [postResponse, ...prevState]);
    }
    if (apiKey === "discharge_treatment_updated") {
      setTreatments((prevState) => (updateArrayObject(prevState, updateResponse)))
    }
    if (apiKey === "discharge_treatment_deleted") {
      setTreatments(treatments => treatments.filter(item => item.id !== hmsObject));
      toastr.error("Treatments deleted")
    }
    if (apiKey === "pay_bills") {
      setIsModalOpen(false)
    }
    if (apiKey === "discharge_bed_updated") {
      setBeds(updatePaginationResult(beds, updateResponse, 'id'))
    }
    if (apiKey === "discharge_bed_created") {
      setBeds((prevState) => ([postResponse, ...prevState]))
    }
    if (apiKey === "discharge_bed_deleted") {
      setBeds(beds => beds.filter(item => item.id !== hmsObject))
      toastr.error("Bed deleted")
    }
  }, [apiKey])

  useEffect(() => {
    if (customActiveTab === "2") {
      setLoading(true)
      dispatch(getHms("/hms/patient/inpatient-treatments/inpatient-treatment-history/", patient?.id, "inpatient_treatment_history"))
      dispatch(getHms("/hms/pharmacy/medicine_sales/inpatient-medicines/", patient?.id, "inpatient_medicines"))
      dispatch(getHms("/hms/diagnosis/book-tests/inpatients/", patient?.id, "inpatient_diagnosis_book_tests"))
      dispatch(getHms("/hms/patient/inpatient-bedhistory/inpatient-bed-history/", patient?.id, "inpatient_bed_history"))
    }
  }, [customActiveTab])

  var suTo
  const searchPatients = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, searchPatients: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/inpatient-discharges/undischarged_patients/?search=${value}`).then((resp) => {
          callback(resp)
        })
      }, 200)
    }
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  const patientSelect = (patient) => {
    if (!isEmpty(patient)) {
      dispatch(getHms("/hms/patient/inpatients/", patient.id, "selected_patient"))
      setPatientSearch(false)
    }
  }

  const calculateTotals = () => {
    const treatmentTotalAmount = floatRoundDown(treatments?.reduce((sum, item) => sum + Number(item.cost), 0) || 0)

    const pharmacyTotalAmount = floatRoundDown(medicines?.reduce((sum, item) => sum + (item.billing.sub_total_amount || 0), 0))
    const pharmacyPaidAmount = floatRoundDown(medicines?.reduce((sum, item) => sum + (item.billing?.is_deferred === false ? item.billing.grand_total_amount || 0 : 0), 0))
    const pharmacyDueAmount = floatRoundDown(medicines?.reduce((sum, item) => sum + (item.billing?.is_deferred === true ? item.billing.grand_total_amount || 0 : 0), 0))

    const pharmacyPaidSubtotalAmount = floatRoundDown(medicines?.reduce((sum, item) => sum + (item.billing?.is_paid === true ? item.billing.sub_total_amount || 0 : 0), 0))
    const pharmacyUnpaidSubtotalAmount = floatRoundDown(medicines?.reduce((sum, item) => sum + (item.billing?.is_paid === false ? item.billing.sub_total_amount || 0 : 0), 0))
    const pharmacyPaidDiscountTotalAmount = floatRoundDown(medicines?.reduce((sum, item) => sum + (item.billing?.is_paid === true ? item.billing.discount_amount || 0 : 0), 0))
    const pharmacyPaidTaxAmount = floatRoundDown(medicines?.reduce((sum, item) => sum + (item.billing?.is_paid === true ? (item.billing.cgst + item.billing.sgst) || 0 : 0), 0))
    const pharmacyUnpaidDiscountTotalAmount = floatRoundDown(medicines?.reduce((sum, item) => sum + (item.billing?.is_paid === false ? item.billing.discount_amount || 0 : 0), 0))
    const pharmacyUnpaidTaxAmount = floatRoundDown(medicines?.reduce((sum, item) => sum + (item.billing?.is_paid === false ? (item.billing.cgst + item.billing.sgst) || 0 : 0), 0))

    const bedTotalAmount = floatRoundDown(
      beds?.reduce((sum, item) => {
        const unit = item?.unit ?? calculateUnitDifference(item?.from_date, item?.to_date);
        const cost = item?.cost ?? item?.bed_detail_data?.price ?? 0;
        return sum + unit * cost;
      }, 0) || 0
    );

    const diagnosisTotalAmount = floatRoundDown(bookTests?.reduce((sum, item) => sum + item?.billing_data?.sub_total_amount, 0))
    const diagnosisPaidAmount = floatRoundDown(bookTests?.reduce((sum, item) => item?.billing_data?.is_deferred === false ? sum + item?.billing_data?.grand_total_amount : sum, 0))
    const diagnosisDueAmount = floatRoundDown(bookTests?.reduce((sum, item) => item?.billing_data?.is_deferred === true ? sum + item?.billing_data?.grand_total_amount : sum, 0))

    const diagnosisPaidSubtotalAmount = floatRoundDown(bookTests?.reduce((sum, item) => sum + (item.billing_data?.is_paid === true ? item.billing_data.sub_total_amount || 0 : 0), 0))
    const diagnosisUnpaidSubtotalAmount = floatRoundDown(bookTests?.reduce((sum, item) => sum + (item.billing_data?.is_paid === false ? item.billing_data.sub_total_amount || 0 : 0), 0))
    const diagnosisPaidDiscountTotalAmount = floatRoundDown(bookTests?.reduce((sum, item) => sum + (item.billing_data?.is_paid === true ? item.billing_data.discount_amount || 0 : 0), 0))
    const diagnosisPaidTaxAmount = floatRoundDown(bookTests?.reduce((sum, item) => sum + (item.billing_data?.is_paid === true ? (item.billing_data.cgst + item.billing_data.sgst) || 0 : 0), 0))
    const diagnosisUnpaidDiscountTotalAmount = floatRoundDown(bookTests?.reduce((sum, item) => sum + (item.billing_data?.is_paid === false ? item.billing_data.discount_amount || 0 : 0), 0))
    const diagnosisUnpaidTaxAmount = floatRoundDown(bookTests?.reduce((sum, item) => sum + (item.billing_data?.is_paid === false ? (item.billing_data.cgst + item.billing_data.sgst) || 0 : 0), 0))

    const totalAmount = floatRoundDown(treatmentTotalAmount + pharmacyTotalAmount + bedTotalAmount + diagnosisTotalAmount)
    const totalPaidAmount = floatRoundDown(pharmacyPaidAmount + diagnosisPaidAmount)
    const totalDueAmount = floatRoundDown(treatmentTotalAmount + pharmacyDueAmount + bedTotalAmount + diagnosisDueAmount)
    const totalDueAmountWithInsurance = floatRoundDown(treatmentTotalAmount + pharmacyDueAmount + bedTotalAmount + diagnosisDueAmount - billing?.insuranceClaimedAmount)

    const discountAmount = floatRoundDown(totalDueAmountWithInsurance * (billing?.discountPercentage / 100))
    const discountedSubTotal = floatRoundDown(totalDueAmountWithInsurance - discountAmount)
    const cgstAmount = floatRoundDown(discountedSubTotal * (billing?.cgstPercentage / 100))
    const sgstAmount = floatRoundDown(discountedSubTotal * (billing?.sgstPercentage / 100))

    const payableAmount = floatRoundDown(discountedSubTotal + cgstAmount + sgstAmount)

    setBilling(prevState => ({
      ...prevState,
      treatmentTotalAmount,
      pharmacyTotalAmount,
      pharmacyPaidAmount,
      pharmacyDueAmount,
      pharmacyPaidSubtotalAmount,
      pharmacyUnpaidSubtotalAmount,
      pharmacyPaidDiscountTotalAmount,
      pharmacyPaidTaxAmount,
      pharmacyUnpaidDiscountTotalAmount,
      pharmacyUnpaidTaxAmount,
      bedTotalAmount,
      diagnosisTotalAmount,
      diagnosisPaidAmount,
      diagnosisDueAmount,
      diagnosisPaidSubtotalAmount,
      diagnosisUnpaidSubtotalAmount,
      diagnosisPaidDiscountTotalAmount,
      diagnosisPaidTaxAmount,
      diagnosisUnpaidDiscountTotalAmount,
      diagnosisUnpaidTaxAmount,
      totalAmount,
      totalPaidAmount,
      totalDueAmountWithInsurance,
      totalDueAmount,
      discountAmount,
      cgstAmount,
      sgstAmount,
      payableAmount,
    }))
  }

  useEffect(() => {
    !loading && calculateTotals()
  }, [treatments, medicines, beds, billing?.bedTotalAmount, billing?.insuranceClaimedAmount, billing?.discountPercentage, billing?.cgstPercentage, billing?.sgstPercentage, billing?.pharmacyDueAmount])

  const handleSubmit = (e, values) => {

    if (beds.some((bed) => !bed?.from_date || !bed?.to_date || !bed?.bed_detail_data?.price)) {
      toastr.error("Make sure the bed details are correct. It should not contain empty values")
      return
    }

    if (bookTests.some(diagnosis => diagnosis.status !== "Completed")) {
      toastr.error("Make sure the diagnosis tests are completed before paying the bills")
      return
    }

    values = {
      inpatient: patient?.id,
      insurance_claim_amount: billing?.insuranceClaimedAmount || 0,
      billing_post: {
        customer_name: patient?.patient_data?.user_data?.full_name,
        payment_mode: billing?.paymentMode,
        cgst_percent: billing?.cgstPercentage,
        sgst_percent: billing?.sgstPercentage,
        cgst: billing?.cgstAmount,
        sgst: billing?.sgstAmount,
        sub_total_amount: billing?.totalDueAmount,
        discount_percent: billing?.discountPercentage,
        discount_amount: billing?.discountAmount,
        discount_type: billing?.discountType,
        discount_by: discountedPerson?.id,
        grand_total_amount: billing.payableAmount,
        cheque_number: billing?.paymentMode === "CHEQUE" ? billing?.chequeNumber : null,
        cheque_date: billing?.paymentMode === "CHEQUE" ? billing?.chequeDate : null,
      },
      billing_summary: {
        treatments: {
          total_amount: billing?.treatmentTotalAmount,
          due_amount: billing?.totalDueAmount,
          data: treatments?.map(item => ({
            id: item.procedure,
            name: item.procedure_data.find(p => p.id === item.procedure)?.name,
            performed_doctor_id: item.performed_doctor_data.id,
            performed_doctor_name: item.performed_doctor_data.user_data.full_name,
            performed_date: item.performed_date,
            cost: item.cost,
          })),
        },
        pharmacy: {
          total_amount: billing?.pharmacyTotalAmount,
          paid_amount: billing?.pharmacyPaidAmount,
          due_amount: billing?.pharmacyDueAmount,
          data: medicines.map((item) => ({
            id: item.id,
            purchase_date: item.created_at,
            medicines: item.medicines.map((medicine) => ({
              id: medicine.id,
              name: medicine.name,
              batch: medicine.batch_number,
              units: medicine.quantity,
              mrp: medicine.cost,
              payment_status: item.billing.is_paid,
              amount: medicine.total_cost,
            })),
          })),
        },
        bed: {
          total_amount: billing?.bedTotalAmount,
          due_amount: billing?.bedTotalAmount,
          data: beds.map((item) => ({
            ward_id: item?.bed_detail_data?.ward,
            ward_name: item?.bed_detail_data?.ward_name,
            room_id: item?.bed_detail_data?.room_category,
            room_name: item?.bed_detail_data?.room_category_name,
            from_date: item?.from_date,
            to_date: item?.to_date,
            units: item?.unit ?? calculateUnitDifference(item?.from_date, item?.to_date),
            cost: item?.cost ?? item?.bed_detail_data?.price,
            total_cost: (parseFloat(item?.cost ?? item?.bed_detail_data?.price) * (item?.unit ?? calculateUnitDifference(item?.from_date, item?.to_date))),
          })),
        },
        diagnosis: {
          total_amount: billing?.diagnosisTotalAmount,
          paid_amount: billing?.diagnosisPaidAmount,
          due_amount: billing?.diagnosisDueAmount,
          data: bookTests?.flatMap(({ billing_data, tests_data }) =>
            tests_data.map((test) => ({
              id: test.id,
              name: test.name,
              created_date: billing_data.created_at,
              payment_status: billing_data.is_paid,
              amount: billing_data.grand_total_amount,
            }))
          ),
        },
        total_amount: billing?.totalAmount,
        paid_amount: billing?.totalPaidAmount,
        due_amount: billing?.totalDueAmount,
        insurance_claim_amount: billing?.insuranceClaimedAmount,
        discount_amount: billing?.discountAmount,
        cgst_percent: billing?.cgstPercentage,
        sgst_percent: billing?.sgstPercentage,
        cgst: billing?.cgstAmount,
        sgst: billing?.sgstAmount,
        payable_amount: billing?.payableAmount,
      }
    }

    if (patient?.id) {
      dispatch(postHms("/hms/patient/inpatient-discharges/pay_bills/", values, "pay_bills"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Inpatient Discharge Billing | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          {patientSearch ? (
            <Col xl={8}>
              <Card>
                <CardBody>
                  <AsyncSearch
                    onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, searchPatients: false }))}
                    menuIsOpen={isMenuOpen.searchPatients}
                    loadOptions={searchPatients}
                    onChange={patientSelect}
                    placeholder={"Search inpatients"}
                    noOptionsMessage={() => (
                      <p className="text-center my-4" style={{ letterSpacing: '1px' }}>
                        No patients found to discharge
                      </p>
                    )}
                    getOptionLabel={(e) => (
                      <div className="custom-option">
                        <div className="user-box">
                          <p className="user-title m-0">{e.full_name}</p>
                          <p className="text-muted mb-0">
                            {e.uid} {e.email ? `| ${e.email}` : ""} | {e.phone_number}
                          </p>
                        </div>
                      </div>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col xl={actionStatus === "pay_bills_success" ? 6 : 12}>
              <Card>
                <CardBody>
                  {(error && !["fail_discharge_summary"].includes(apiKey)) ? (
                    <p className="text-center text-danger">{error?.detail}</p>
                  ) : actionStatus === "pay_bills_success" ? (
                    <div className="p-2">
                      <div className="text-center">
                        <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                        <div className="p-2 mt-2">
                          <h4>Inpatient bills are cleared</h4>
                          <div className="mt-4">
                            <div className="d-flex justify-content-evenly">
                              <Button
                                color="primary"
                                style={{ minWidth: "120px" }}
                                onClick={() => window.location.replace("/hms/patient/inpatient/discharge/billing")}
                              >
                                OK
                              </Button>
                              <ReportDownload
                                url={`/hms/patient/inpatient-discharges/${patient?.id}/download_discharge_billing_report/`}
                                label="Download Report"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : apiKey === "get_selected_patient" ? (
                    <Loader />
                  ) : (
                    <>
                      <Nav tabs className="nav-tabs-custom nav-justified mb-4">
                        <NavItem>
                          <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1", })} onClick={() => toggleCustom("1")}>
                            <span className="d-block d-sm-none">
                              <i className="fa fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Patient Information</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2" })} onClick={() => toggleCustom("2")} >
                            <span className="d-block d-sm-none">
                              <i className="bx bx-receipt"></i>
                            </span>
                            <span className="d-none d-sm-block">Billing Summary</span>
                          </NavLink>
                        </NavItem>
                        <NavItem></NavItem>
                        <NavItem></NavItem>
                        <NavItem></NavItem>
                      </Nav>
                      <TabContent activeTab={customActiveTab} className="text-muted">
                        <TabPane tabId="1">
                          <Row>
                            <Col>
                              <h5 className="mt-2">Patient Information</h5>
                              <hr />
                              {patient?.patient_data?.user_data ? (
                                <PatientInformation
                                  data={patient?.patient_data}
                                  includedFields={[
                                    "patient_name",
                                    "patient_id",
                                    "gender",
                                    "marital_status",
                                    "date_of_birth",
                                    "age",
                                    "mobile",
                                    "email",
                                  ]}
                                />
                              ) : (
                                <p className="text-center text-danger">Patient details not existed</p>
                              )}
                              <h5 className="mt-2">Admission Details</h5>
                              <hr />
                              {patient?.created_at ? (
                                <TableChip
                                  label="Admitted on"
                                  value={formatDateTime(patient?.created_at)}
                                />
                              ) : (
                                <p className="text-center text-danger">Admission details not existed</p>
                              )}
                              <h5 className="mt-2">Address</h5>
                              <hr />
                              {patient?.patient_data?.address_data ? (
                                <Col>
                                  <TableChip
                                    label="Line 1"
                                    value={patient?.patient_data?.address_data?.line1}
                                  />
                                  <TableChip
                                    label="Line 2"
                                    value={patient?.patient_data?.address_data?.line2}
                                  />
                                  <Row>
                                    <Col>
                                      <TableChip
                                        label="City"
                                        value={patient?.patient_data?.address_data?.city}
                                      />
                                    </Col>
                                    <Col>
                                      <TableChip
                                        label="State"
                                        value={patient?.patient_data?.address_data?.state}
                                      />
                                    </Col>
                                    <Col>
                                      <TableChip
                                        label="Pincode/Zipcode"
                                        value={patient?.patient_data?.address_data?.pincode}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              ) : (
                                <p className="text-center text-danger">
                                  Address details not existed
                                </p>
                              )}
                            </Col>
                            <Col>
                              <h5 className="mt-2">Caretakers</h5>
                              <hr />
                              {patient?.duty_doctor_data || patient?.consulting_doctor_data || patient?.nurse_data ? (
                                <div className="d-flex justify-content-between">
                                  <TableChip
                                    label="Duty doctor"
                                    value={patient?.duty_doctor_data?.user_data?.full_name}
                                  />
                                  <TableChip
                                    label="Consulting doctor"
                                    value={patient?.consulting_doctor_data?.user_data?.full_name}
                                  />
                                  <TableChip
                                    label="Nurse"
                                    value={patient?.nurse_data?.user_data?.full_name}
                                  />
                                </div>
                              ) : (
                                <p className="text-center text-danger">Caretakers details not existed</p>
                              )}
                              <h5 className="mt-2">Insurance</h5>
                              <hr />
                              {patient?.health_insurance_data ? (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Policy number"
                                      value={patient?.health_insurance_data?.policy_number}
                                    />
                                    <TableChip
                                      label="Issuer"
                                      value={patient?.health_insurance_data?.issuer_name}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Valid till"
                                      value={formatDateTime(patient?.health_insurance_data?.policy_valid_till, "ll")}
                                    />
                                    <TableChip
                                      label="Current status"
                                      value={patient?.health_insurance_data?.status}
                                    />
                                  </div>
                                </>
                              ) : (
                                <p className="text-center text-danger">Insurance details not existed</p>
                              )}
                              <h5 className="mt-2">Room Details</h5>
                              <hr />
                              {patient?.current_bed_data ? (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Ward"
                                      value={patient?.current_bed_data?.ward_name}
                                    />
                                    <TableChip
                                      label="Room category"
                                      value={patient?.current_bed_data?.room_category_name}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Floor"
                                      value={patient?.current_bed_data?.floor}
                                    />
                                    <TableChip
                                      label="Room number"
                                      value={patient?.current_bed_data?.room_number}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Bed number"
                                      value={patient?.current_bed_data?.bed_number}
                                    />
                                    <TableChip defaultIfNull="" />
                                  </div>
                                </>
                              ) : (
                                <p className="text-center text-danger">No bed information available</p>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col lg={8} md={12} style={{ height: "80vh", overflowY: "auto", scrollbarWidth: "thin" }}>
                              <div>
                                <div className="mb-3">
                                  <CardTitle className="">Treatment Charges</CardTitle>
                                  <hr style={{ margin: "0.5rem 0 1.5rem 0" }} />
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <TreatmentTable
                                          treatments={treatments}
                                          inpatientID={patient?.id}
                                          billing={billing}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="mb-3">
                                  <CardTitle className="">Pharmacy Charges</CardTitle>
                                  <hr style={{ margin: "0.5rem 0 1.5rem 0" }} />
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        {!isEmpty(medicines) ? (
                                          <PharmacyTable
                                            pharmacyData={medicines}
                                            billing={billing}
                                          />
                                        ) : (
                                          <NoDataIndicationTable noDataIndication="Pharmacy charges not available" />
                                        )
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="mb-3">
                                  <CardTitle className="">Bed Charges</CardTitle>
                                  <hr style={{ margin: "0.5rem 0 1.5rem 0" }} />
                                  <div className="table-responsive">
                                    <BedTable
                                      beds={beds}
                                      setBeds={setBeds}
                                      billing={billing}
                                      setBilling={setBilling}
                                      inpatientID={patient?.id}
                                    />
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <CardTitle className="">Diagnosis charges</CardTitle>
                                  <hr style={{ margin: "0.5rem 0 1.5rem 0" }} />
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        {!isEmpty(bookTests) ? (
                                          <DiagnosisTable
                                            bookTests={bookTests}
                                            billing={billing}
                                          />
                                        ) : (
                                          <NoDataIndicationTable noDataIndication="Diagnosis charges not available" />
                                        )
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                            <Col lg={4} md={12}>
                              <div className="table-responsive">
                                <Table className="table mb-0 table-sm">
                                  <colgroup>
                                    <col style={{ width: '70%' }} />
                                    <col style={{ width: '30%' }} />
                                  </colgroup>
                                  <tbody>
                                    <tr>
                                      <td>Treatment charges</td>
                                      <td>{billing?.treatmentTotalAmount || 0}</td>
                                    </tr>
                                    <tr>
                                      <td>Pharmacy charges</td>
                                      <td>{billing?.pharmacyTotalAmount || 0}</td>
                                    </tr>
                                    <tr>
                                      <td>Bed charges</td>
                                      <td>{billing?.bedTotalAmount || 0}</td>
                                    </tr>
                                    <tr>
                                      <td>Diagnosis charge</td>
                                      <td>{billing?.diagnosisTotalAmount || 0}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                                <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                                  <BillingTable
                                    billing={billing}
                                    setBilling={setBilling}
                                    options={formOptions}
                                    discountedPerson={discountedPerson}
                                    setDiscountedPerson={setDiscountedPerson}
                                  />
                                  <div className="mt-3 text-end">
                                    <SubmitLoaderButton
                                      onClick={() => setIsModalOpen(!isModalOpen)}
                                      size="sm"
                                      loading={apiKey === "post_pay_bills"}
                                      disabled={loading || billing?.payableAmount < 0}
                                      title={billing?.payableAmount < 0 ? "Payable amount should not be the negative amounts" : ""}
                                    >
                                      Pay Bills
                                    </SubmitLoaderButton>
                                  </div>
                                </AvForm>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        formRef={formRef}
      />

      <Modal isOpen={billingConfirmation}>
        <ModalBody>
          Earlier calculated amounts will be overridden. Do you want to proceed anyway?
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={() => {
            setPatientSearch(true)
            setBillingConfirmation(false)
            setCustomActiveTab("1")
          }}>
            Close
          </Button>
          <Button type="button" color="primary" onClick={() => setBillingConfirmation(false)}>
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}
export default InpatientDischarge

