import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import { getHms, getHmsOptions, updateHms } from "../../store/actions"
import toastr from "toastr"
import { EAvFieldInput, EAvFieldSelect } from "pages/HMS/common/errored-avfields"
import { PhonenumberInput } from "components/Common/input-advanced"
import { handlePhonenumberBlur, handlePhonenumberFocus, Loader } from "pages/HMS/common/common"
import { Link } from "react-router-dom"

const Profile = props => {
  const dispatch = useDispatch();
  const { error, options, formError, apiKey, hmsObject, updateResponse, loading } = useSelector(state => ({
    error: state.Profile.error,
    options: state.Hms.options,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    hmsObject: state.Hms.hmsObject,
    updateResponse: state.Hms.updateResponse,
    loading: state.Hms.loading,
  }))
  const [userObject, setUserObject] = useState({})
  const [phonenumber, setPhonenumber] = useState(null);
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    if (apiKey === "profile_details") {
      setUserObject(hmsObject[0]);
    }
    if (apiKey === "profile_update") {
      toastr.success("Profile updated successfully")
      setUserObject(updateResponse);
    }
  }, [apiKey]);

  useEffect(() => {
    dispatch(getHmsOptions("/accounts/profile/?options=formschema", "profile_options"))
    dispatch(getHms("/accounts/profile/", null, "profile_details"))
  }, [])

  function handleValidSubmit(event, values) {
    if (!phonenumber) {
      setPhoneError('This field is invalid');
      return;
    }
    values.phone_number = phonenumber
    setPhoneError('');
    dispatch(updateHms("/accounts/profile/", userObject.username, values, "profile_update"))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {loading && apiKey === "get_profile_details" ? (
            <Loader />
          ) : error ? (
            <p className="text-center text-danger">{error.detail}</p>
          ) : (
            <Card>
              <CardBody>
                <Row>
                  <Col xl="3" sm="12" className="text-center">
                    <div className="avatar-sm mx-auto mb-4">
                      <span className="avatar-title rounded-circle bg-success bg-soft text-success font-size-24">
                        {userObject.full_name ? userObject.full_name.charAt(0) : 'A'}
                      </span>
                    </div>
                    <h5 className="font-size-15 mb-1">
                      <Link to="#" className="text-dark">
                        {userObject?.full_name}
                      </Link>
                    </h5>
                    <p>{userObject?.username}</p>
                    <p>{userObject?.email}</p>
                    <p>{userObject?.phone_number}</p>
                  </Col>
                  <Col xl="9" sm="12" className="text-start">
                    <Row className="justify-content-center">
                      <Col>
                        <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>
                          <Row>
                            <Col>
                              <EAvFieldInput
                                field="full_name"
                                type="text"
                                value={userObject?.full_name}
                                isError={formError?.full_name}
                                options={{ ...options?.form_schema?.full_name, required: true }}
                              />
                            </Col>
                            <Col>
                              <EAvFieldInput
                                field="username"
                                type="text"
                                value={userObject?.username}
                                isError={formError?.username}
                                options={options?.form_schema?.username}
                                placeholder={"Ex: user-123"}
                                helpMessage={"An unique id to identify the user"}
                                disabled={options?.form_schema?.username?.read_only}
                                title="Username cannot be changed"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <PhonenumberInput
                                  label={options?.form_schema?.phone_number?.label}
                                  name="phone_number"
                                  value={userObject?.phone_number}
                                  error={formError?.phone_number || phoneError}
                                  required={true}
                                  updateValue={setPhonenumber}
                                  onFocus={() => handlePhonenumberFocus(setPhoneError)}
                                  onBlur={() => handlePhonenumberBlur(phonenumber, setPhoneError)}
                                />
                              </div>
                            </Col>
                            <Col>
                              <EAvFieldInput
                                field="email"
                                type="email"
                                value={userObject?.email}
                                isError={formError?.email}
                                options={{ ...options?.form_schema?.email, required: true }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <EAvFieldSelect
                                field="gender"
                                value={userObject?.gender}
                                isError={formError?.gender}
                                options={{ ...options?.form_schema?.gender, label: "Sex" }}
                                choices={<>
                                  <option>--Select an option--</option>
                                  {options?.form_schema?.gender?.choices?.map((choice, cidx) =>
                                    <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                  )}
                                </>}
                              />
                            </Col>
                            <Col>
                              <EAvFieldInput
                                field="dob"
                                type="date"
                                value={userObject?.dob}
                                isError={formError?.dob}
                                options={{ label: "Date of Birth", required: true }}
                                daterange={{ start: { value: -150, units: "years" }, end: { value: 0, units: "years" } }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <EAvFieldInput
                                field="address"
                                type="textarea"
                                value={userObject?.address}
                                isError={formError?.address}
                                options={{ ...options?.form_schema?.address, required: true }}
                              />
                            </Col>
                          </Row>
                          <div className="text-end">
                            {loading && apiKey === "update_profile_update" ? (
                              <Button color="primary" disabled>
                                Update
                                <i className="bx bx-loader bx-spin font-size-16 align-middle ms-1"></i>
                              </Button>
                            ) : (
                              <Button type="submit" color="primary">
                                Update
                              </Button>
                            )}
                          </div>
                        </AvForm>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Profile)
