import React, { useEffect, useState, useRef } from "react"
import { Card, Col, Row, CardBody, Modal, ModalHeader, ModalBody, CardFooter, Button } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { getHmsOptions, listHms, resetHmsState, updateHms } from "store/actions"
import toastr from "toastr"
import { AvForm } from "availity-reactstrap-validation"
import { EAsyncSelect } from "../common/errored-avfields"
import { Loader, search } from "../common/common"
import GenericTable from "pages/HMS/common/table"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { updatePaginationResult } from "components/Common/common"
import { isEmpty } from "lodash"

const UserPermissions = (props) => {
  const dispatch = useDispatch()
  const { loading, options, hmsList, apiKey, error, formError, updateResponse } = useSelector(state => state.Hms)
  const location = useLocation()
  const urlParams = location.search
  const formRef = useRef()
  const [userList, setUserList] = useState([])
  const [user, setUser] = useState([])
  const [modal, setModal] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState(null)
  const [defaultRoles, setDefaultRoles] = useState(null)

  const columns = [
    {
      dataField: "img",
      text: "",
      formatter: (cellContent, row) => (
        <div className="avatar-xs" onClick={() => handleEdit(row)}>
          <span className="avatar-title rounded-circle" style={{ color: "#fff" }}>
            {row.full_name
              ?.split(' ')
              ?.map(word => word.charAt(0).toUpperCase())
              ?.slice(0, 2)
              ?.join('')}
          </span>
        </div>
      ),
    },
    {
      text: "User",
      dataField: "user",
      sort: true,
      formatter: (cellContent, row) => (
        <span onClick={() => handleEdit(row)}>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {row.full_name} | {row.username}
            </Link>
          </h5>
          <p className="text-muted mb-0">{row?.email ? `${row?.email} |` : ""} {row.phone_number} </p>
        </span>
      ),
    },
    {
      text: "Roles",
      dataField: "roles",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {row?.groups?.map((role, index) => (
            index < 2 ? (
              <Link to="#" className="badge badge-soft-primary font-size-11 m-1" key={role.id || index}>
                {role.name}
              </Link>
            ) : index === 2 ? (
              <Link to="#" className="badge badge-soft-primary font-size-11 m-1" key={index} title={row.groups.map((role) => role.name).splice(2).join(" ")}>
                {row?.groups?.length - 2} + more
              </Link>
            ) : null
          ))}
        </>
      ),
    },
  ]

  useEffect(() => {
    if (apiKey === "user_roles") {
      setUserList(hmsList)
    }
    if (apiKey === "roles_modified") {
      setUserList(updatePaginationResult(userList, updateResponse, "username"))
      toastr.success("Role added successfully");
      toggle()
    }
    if (apiKey === "default_roles") {
      setDefaultRoles(hmsList?.results)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/identity_type/?options=table, search"))
    dispatch(listHms("/accounts/user-perms/", null, "user_roles"))
    return () => dispatch(resetHmsState())
  }, [dispatch])


  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/accounts/user-perms/', decodeURIComponent(urlParams).replace("?", ""), "user_roles"))
      setUserList({})
    } else {
      dispatch(listHms('/accounts/user-perms/', null, "user_roles"))
      setUserList({})
    }
  }

  const handleEdit = (user_object) => {
    setUser(user_object)
    setSelectedRoles(user_object?.groups)
    toggle(false)
  }

  const toggle = (def = modal) => {
    setModal(!def)
  }

  const searchRoles = (value, callback) => {
    search(value, callback, "/hms/roles/")
  }

  const handleValidSubmit = (e, values) => {
    if (user.username) {
      values = { groups: selectedRoles?.map(role => role.id) || [] }
      dispatch(updateHms(`/accounts/user-perms/${user.username}/`, null, values, "roles_modified", "username"))
    }
  }

  return (
    <React.Fragment>
      <div>
        <Row className="justify-content-center">
          <Col>
            <Card>
              <CardBody>
                {loading && apiKey === "list_validity_get" ? (
                  <Loader />
                ) : error ? (
                  <p className="text-center text-danger">{error.detail}</p>
                ) : (
                  <>
                    {(!isEmpty(columns)) && (
                      <GenericTable
                        urlParams={urlParams}
                        search={options.search}
                        columns={columns}
                        keyField={"username"}
                        noDataIndication={"No users found"}
                        data={userList}
                        loading={loading}
                        apiTrigger={apiTrigger}
                        defaultPageSize={options.page_size}
                        removeQueryParams
                      />
                    )}
                  </>
                )
                }

                <Modal isOpen={modal} toggle={toggle} size="lg" fade={true}>
                  <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
                    <ModalHeader toggle={toggle} tag="h4"></ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col xl="5" sm="12" className="text-center">
                          <div className="avatar-sm mx-auto mb-4">
                            <span className={"avatar-title rounded-circle bg-soft font-size-16"} style={{ color: "#fff" }}>
                              {(user.full_name)
                                ?.split(' ')
                                ?.map(word => word.charAt(0).toUpperCase())
                                ?.slice(0, 2)
                                ?.join('')}
                            </span>
                          </div>

                          <h5 className="font-size-15 mb-1">
                            <Link to="#" className="text-dark">
                              {user?.full_name}
                            </Link>
                          </h5>
                          <p>{user?.username}</p>
                          <p>{user?.email}</p>
                          <p>{user?.phone_number}</p>
                        </Col>

                        <Col xl="7" sm="12" className="text-center">
                          <div className="mb-3 ajax-select select2-container text-start">
                            <EAsyncSelect
                              cacheOptions
                              options={{ label: "Roles", required: true }}
                              value={selectedRoles}
                              loadOptions={searchRoles}
                              formError={formError?.groups}
                              onChange={(roles) => setSelectedRoles(roles)}
                              onFocus={() => dispatch(listHms("/hms/roles/", null, "default_roles"))}
                              defaultOptions={defaultRoles}
                              placeholder="Type to search roles..."
                              getOptionLabel={e => e?.name}
                              getOptionValue={e => e.id}
                              isMulti
                              closeMenuOnSelect={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <CardFooter className="bg-transparent border-top">
                      <div className="d-flex justify-content-end m-2">
                        {loading && apiKey === "update_roles_modified" ? (
                          <Button color="primary" disabled>
                            Save
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-1"></i>
                          </Button>
                        ) : (
                          <Button type="submit" color="primary">
                            Save
                          </Button>
                        )}
                      </div>
                    </CardFooter>
                  </AvForm>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  )
}

export default UserPermissions;