import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import CallSidebarContent from "./sidebars/CallSidebar"
import LmsSidebarContent from "./sidebars/LmsSidebar"
import ProfileSidebarContent from "./sidebars/ProfileSidebar"
import HmsSidebarContent from "./sidebars/HmsSidebar";

import { Link } from "react-router-dom";

import logo from "../../assets/yanthura/images/logoIcon.png";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/yanthura/images/logo.jpg";

const Sidebar = props => {

  const renderSidebar = () => {
    if (props.type !== "condensed") {
      if (location.pathname.startsWith("/lms")) {
        return <LmsSidebarContent />
      } else if (location.pathname.startsWith("/hms")) {
        return <HmsSidebarContent />
      } else if (location.pathname.startsWith("/profile")) {
        return <ProfileSidebarContent />
      } else {
        return <CallSidebarContent />
      }
    } else {
      return <SidebarContent />
    }
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to={JSON.parse(localStorage.getItem('pinPage'))?.url || "/hms"} className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="45" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="19" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {renderSidebar()}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
