import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Badge, Card, CardBody, Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { deleteHms, getHmsOptions, listHms, resetHmsState } from "store/actions"
import GenericTable from "pages/HMS/common/table"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { formatDateTime, paymentVerboseFetch } from "components/Common/common"
import { HmsDeleteModal } from "../common/hms-crud-modals"
import toastr from "toastr"
import { getLocalStorageItem, Loader, updateURLParams } from "../common/common"

const Transactions = (props) => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const [filters, setFilter] = useState({})
  const dispatch = useDispatch()
  const { loading, error, hmsList, options, apiKey } = useSelector(state => state.Hms)
  const [columns, setColumns] = useState([])
  const [transactionObj, setTransactionObj] = useState({})
  const [transactionsList, setTransactionsList] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    dispatch(getHmsOptions("/hms/billing/billing/?options=table, search, daterange, filters", "transaction_options"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "transaction_options") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/billing/billing/", decodeURIComponent(urlParams).replace("?", ""), "transactions_list"))
      } else {
        const savedFilters = getLocalStorageItem('filters')?.transactions
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/billing/billing/", savedFilters, "transactions_list"))
      }
    }
    if (apiKey === "transactions_list") {
      setTransactionsList(hmsList)
    }
    if (apiKey === "transaction_deleted") {
      setTransactionsList(hmsList)
      toggleDelete()
      toastr.success("Transaction is deleted")
    }
  }, [apiKey])

  const renderDoctorOptions = (option) => {
    return option.full_name;
  };

  useEffect(() => {
    if (apiKey === "transaction_options") {
      if (options.filters) {
        setFilter({
          ...options.filters,
          doctor: {
            ...options.filters.doctor,
            searchApi: "/hms/staff/doctors/search/",
            renderOptions: renderDoctorOptions,
            optionValueKey: 'id',
          },
        });
      }
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "uid") {
            return {
              ...item,
              formatter: (cellContent, row) => <Link to={`/hms/billing/transactions/${row.id}/detail`}>{row.uid}</Link>
            }
          } else if (item.dataField === "doctor") {
            return {
              ...item,
              formatter: (cell, row) => row.doctor_by_name || "---"
            }
          } else if (item.dataField === "created_at") {
            return {
              ...item,
              formatter: (cell, row) => formatDateTime(row.created_at)
            }
          } else if (item.dataField === "is_paid") {
            return {
              ...item,
              formatter: (cellContent, row) => row.is_paid ? <Badge pill color="success">Paid</Badge> : <Badge pill color="danger">Unpaid</Badge>
            }
          } else if (item.dataField === "payment_mode") {
            return {
              ...item,
              formatter: (cellContent, row) => paymentVerboseFetch(row.payment_mode) || "---"
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle className="text-muted font-size-16 border-0" color="white">
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link to="#" className="dropdown-item" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [options])

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/billing/billing/", urlParams, "transactions_list"))
      setTransactionsList({})
    } else {
      dispatch(listHms("/hms/billing/billing/", null, "transactions_list"))
      setTransactionsList({})
    }
  }

  const toggleDelete = (def = deleteModal) => {
    setDeleteModal(!def)
  }

  const handleDelete = (transaction_object) => {
    setTransactionObj(transaction_object)
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Bills | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {apiKey === "options_transaction_options" ? (
            <Loader />
          ) : error ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (!isEmpty(columns)) && (
            <GenericTable
              moduleName="transactions"
              urlParams={urlParams}
              filters={filters}
              search={options.search}
              daterange={options.daterange}
              columns={columns}
              keyField="id"
              noDataIndication="No transactions created"
              data={transactionsList}
              loading={apiKey === "list_transactions_list"}
              apiTrigger={apiTrigger}
              defaultPageSize={options.page_size}
              exportUrl="/hms/billing/billing/"
            />
          )}
        </Container>
      </div>

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If transaction is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/billing/billing/", transactionObj?.id, "transaction_deleted")}
        deleteModal={deleteModal}
      />
    </React.Fragment>
  )
}

export default Transactions
