export const RESET_HMS = "RESET_HMS"

export const OPTIONS_HMS = "OPTIONS_HMS"
export const OPTIONS_HMS_SUCCESS = "OPTIONS_HMS_SUCCESS"
export const OPTIONS_HMS_FAIL = "OPTIONS_HMS_FAIL"

export const LIST_HMS = "LIST_HMS"
export const LIST_HMS_SUCCESS = "LIST_HMS_SUCCESS"
export const LIST_HMS_FAIL = "LIST_HMS_FAIL"

export const GET_HMS = "GET_HMS"
export const GET_HMS_SUCCESS = "GET_HMS_SUCCESS"
export const GET_HMS_FAIL = "GET_HMS_FAIL"

export const POST_HMS = "POST_HMS"
export const POST_HMS_SUCCESS = "POST_HMS_SUCCESS"
export const POST_HMS_FAIL = "POST_HMS_FAIL"

export const UPDATE_HMS = "UPDATE_HMS"
export const UPDATE_HMS_SUCCESS = "UPDATE_HMS_SUCCESS"
export const UPDATE_HMS_FAIL = "UPDATE_HMS_FAIL"

export const DELETE_HMS = "DELETE_HMS"
export const DELETE_HMS_SUCCESS = "DELETE_HMS_SUCCESS"
export const DELETE_HMS_FAIL = "DELETE_HMS_FAIL"

export const DOWNLOAD_REPORT_HMS = "DOWNLOAD_REPORT_HMS"
export const DOWNLOAD_REPORT_HMS_SUCCESS = "DOWNLOAD_REPORT_HMS_SUCCESS"
export const DOWNLOAD_REPORT_HMS_FAIL = "DOWNLOAD_REPORT_HMS_FAIL"

export const UPLOAD_HMS = "UPLOAD_HMS"
export const UPLOAD_HMS_SUCCESS = "UPLOAD_HMS_SUCCESS"
export const UPLOAD_HMS_FAIL = "UPLOAD_HMS_FAIL"

export const LIST_DIAG_TEST_PARAM = "LIST_DIAG_TEST_PARAM"
export const LIST_DIAG_TEST_PARAM_SUCCESS = "LIST_DIAG_TEST_PARAM_SUCCESS"
export const LIST_DIAG_TEST_PARAM_FAIL = "LIST_DIAG_TEST_PARAM_FAIL"

export const GET_DIAG_TEST_PARAM = "GET_DIAG_TEST_PARAM"
export const GET_DIAG_TEST_PARAM_SUCCESS = "GET_DIAG_TEST_PARAM_SUCCESS"
export const GET_DIAG_TEST_PARAM_FAIL = "GET_DIAG_TEST_PARAM_FAIL"

export const POST_DIAG_TEST_PARAM = "POST_DIAG_TEST_PARAM"
export const POST_DIAG_TEST_PARAM_SUCCESS = "POST_DIAG_TEST_PARAM_SUCCESS"
export const POST_DIAG_TEST_PARAM_FAIL = "POST_DIAG_TEST_PARAM_FAIL"

export const UPDATE_DIAG_TEST_PARAM = "UPDATE_DIAG_TEST_PARAM"
export const UPDATE_DIAG_TEST_PARAM_SUCCESS = "UPDATE_DIAG_TEST_PARAM_SUCCESS"
export const UPDATE_DIAG_TEST_PARAM_FAIL = "UPDATE_DIAG_TEST_PARAM_FAIL"

export const DELETE_DIAG_TEST_PARAM = "DELETE_DIAG_TEST_PARAM"
export const DELETE_DIAG_TEST_PARAM_SUCCESS = "DELETE_DIAG_TEST_PARAM_SUCCESS"
export const DELETE_DIAG_TEST_PARAM_FAIL = "DELETE_DIAG_TEST_PARAM_FAIL"

export const CHAINED_OPTIONS_HMS = "CHAINED_OPTIONS_HMS"
export const CHAINED_OPTIONS_HMS_SUCCESS = "CHAINED_OPTIONS_HMS_SUCCESS"
export const CHAINED_OPTIONS_HMS_FAIL = "CHAINED_OPTIONS_HMS_FAIL"

export const OPTIONS_PUBLIC = "OPTIONS_PUBLIC"
export const OPTIONS_PUBLIC_SUCCESS = "OPTIONS_PUBLIC_SUCCESS"
export const OPTIONS_PUBLIC_FAIL = "OPTIONS_PUBLIC_FAIL"

export const LIST_PUBLIC = "LIST_PUBLIC"
export const LIST_PUBLIC_SUCCESS = "LIST_PUBLIC_SUCCESS"
export const LIST_PUBLIC_FAIL = "LIST_PUBLIC_FAIL"

export const GET_PUBLIC = "GET_PUBLIC"
export const GET_PUBLIC_SUCCESS = "GET_PUBLIC_SUCCESS"
export const GET_PUBLIC_FAIL = "GET_PUBLIC_FAIL"

export const POST_PUBLIC = "POST_PUBLIC"
export const POST_PUBLIC_SUCCESS = "POST_PUBLIC_SUCCESS"
export const POST_PUBLIC_FAIL = "POST_PUBLIC_FAIL"

export const UPDATE_PUBLIC = "UPDATE_PUBLIC"
export const UPDATE_PUBLIC_SUCCESS = "UPDATE_PUBLIC_SUCCESS"
export const UPDATE_PUBLIC_FAIL = "UPDATE_PUBLIC_FAIL"

export const DELETE_PUBLIC = "DELETE_PUBLIC"
export const DELETE_PUBLIC_SUCCESS = "DELETE_PUBLIC_SUCCESS"
export const DELETE_PUBLIC_FAIL = "DELETE_PUBLIC_FAIL"
