import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Row, Col, Label } from "reactstrap"
import { getHmsOptions, postHms, resetHmsState, updateHms } from "store/hms/actions"
import { AvForm } from "availity-reactstrap-validation"
import { EAsyncSelect, EAvFieldGenericInput, RequiredFieldsMessage } from "pages/HMS/common/errored-avfields"
import { PhonenumberInput } from "components/Common/input-advanced"
import { Loader, handlePhonenumberBlur, handlePhonenumberFocus, SubmitLoaderButton, search } from "pages/HMS/common/common"
import { SingleFieldCRUModal } from "pages/HMS/common/hms-crud-modals"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { DoctorCRUModal } from "pages/HMS/staff/doctors/doctor-crud-modals"

const DiagnosisHome = ({ configuration }) => {
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const { error, options, postResponse, formError, apiKey, actionStatus, modifiedFormSchema, hmsList } = useSelector(state => state.Hms)
  const [phonenumber, setPhonenumber] = useState(null)
  const [ownerContact, setOwnerContact] = useState(null)
  const [phoneError, setPhoneError] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState({ affiliatedDoctors: false, affiliatedHospitals: false })
  const [selectedOptions, setSelectedOptions] = useState({ affiliatedDoctors: null, affiliatedHospitals: null })
  const [modal, setModal] = useState({ affiliatedHospital: false, affiliatedDoctor: false })
  const [customFormError, setCustomFormError] = useState(null)
  const [formOptions, setFormOptions] = useState(null)

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis-configuration/?options=formschema", "diagnosis_configuration_options", false, true))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "fail_configuration_diagnosis_update" || apiKey === "fail_configuration_diagnosis_post") {
      setCustomFormError(formError)
    }
    if (apiKey === "diagnosis_configuration_options") {
      setFormOptions(options?.form_schema)
    }
    if (apiKey === "affiliated_doctors_configuration_created") {
      setSelectedOptions({
        ...selectedOptions,
        affiliatedDoctors:
          selectedOptions?.affiliatedDoctors === null
            ? [postResponse]
            : [...selectedOptions?.affiliatedDoctors, postResponse],
      })
    }
    if (apiKey === "affiliated_hospitals_configuration_created") {
      setSelectedOptions({
        ...selectedOptions,
        affiliatedHospitals:
          selectedOptions?.affiliatedHospitals === null
            ? [postResponse]
            : [...selectedOptions?.affiliatedHospitals, postResponse],
      })
    }
    if (apiKey === "fetch_diagnosis_configuration") {
      setSelectedOptions({
        ...selectedOptions,
        affiliatedDoctors: hmsList?.results?.[0]?.affiliated_doctors_data.map(({ id, user__full_name }) => ({ id, user_data: { full_name: user__full_name } })),
        affiliatedHospitals: hmsList?.results?.[0]?.affiliated_hospitals_data || [],
      })
    }
  }, [apiKey])

  const handleSubmit = (event, values) => {
    if (!phonenumber) {
      setPhoneError('This field is invalid')
      return
    }
    setPhoneError('')

    values.phone_number = phonenumber
    values.owner_contact = ownerContact
    values.affiliated_hospitals = !isEmpty(selectedOptions?.affiliatedHospitals) ? selectedOptions?.affiliatedHospitals?.map(hospital => hospital?.id) : []
    values.affiliated_doctors = !isEmpty(selectedOptions?.affiliatedDoctors) ? selectedOptions?.affiliatedDoctors?.map(hospital => hospital?.id) : []

    if (configuration?.id) {
      dispatch(updateHms("hms/diagnosis-configuration/", configuration?.id, values, "configuration_diagnosis_update"))
    } else {
      dispatch(postHms("hms/diagnosis-configuration/", values, "configuration_diagnosis_post"))
    }
  }

  const searchHospitals = (value, callback) => {
    search(value, callback, "/hms/affiliated-hospitals/", () => setIsMenuOpen((prev) => ({ ...prev, affiliatedHospitals: value?.length > 0 })))
  }


  const searchDoctors = (value, callback) => {
    search(value, callback, "/hms/staff/doctors/", () => setIsMenuOpen((prev) => ({ ...prev, affiliatedDoctors: value?.length > 0 })))
  }

  const updateHospitalsSelect = (hospitals) => {
    setSelectedOptions({ ...selectedOptions, affiliatedHospitals: hospitals })
  }

  const updateDoctorsSelect = (doctors) => {
    setSelectedOptions({ ...selectedOptions, affiliatedDoctors: doctors })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Home | Yanthura</title>
      </MetaTags>
      <Row className="justify-content-center">
        <Col xl={(["configuration_diagnosis_post_success", "configuration_diagnosis_update_success"].includes(actionStatus)) ? 6 : 12}>
          <Card>
            <CardBody>
              {error ? (
                <p className="text-center text-danger">{error?.detail}</p>
              ) : (["options_diagnosis_configuration_options", "get_fetch_diagnosis_configuration"].includes(apiKey)) ? (
                <Loader />
              ) : (
                <>
                  {(["configuration_diagnosis_post_success", "configuration_diagnosis_update_success"].includes(actionStatus)) ? (
                    <div className="p-2">
                      <div className="text-center">
                        <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                        <div className="p-2 mt-2">
                          <h4>Configuration {actionStatus === "configuration_diagnosis_update_success" ? "modified" : "created"} successfully</h4>
                          <div className="mt-4 d-flex justify-content-center flex-wrap">
                            <Link
                              to="#"
                              className={window.innerWidth <= 425 ? "btn-sm ms-1 btn btn-primary mb-1" : "ms-1 btn btn-primary mb-1"}
                              onClick={() => window.location.replace("/hms/diagnosis/home")}
                            >
                              Explore
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (formOptions && (
                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} ref={formRef}>
                      <Row className="m2-4">
                        <Col>
                          <Row>
                            <Col>
                              <EAvFieldGenericInput
                                value={configuration?.name}
                                isError={customFormError?.name}
                                {...modifiedFormSchema?.name}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} sm={12}>
                              <div className="mb-3">
                                <PhonenumberInput
                                  label="Mobile"
                                  name="phone_number"
                                  value={configuration?.phone_number}
                                  error={customFormError?.phone_number || phoneError}
                                  required={formOptions?.phone_number?.required}
                                  updateValue={setPhonenumber}
                                  onFocus={() => handlePhonenumberFocus(setPhoneError)}
                                  onBlur={() => handlePhonenumberBlur(phonenumber, setPhoneError)}
                                />
                              </div>
                            </Col>
                            <Col md={6} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.email}
                                isError={customFormError?.email}
                                {...modifiedFormSchema?.email}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <EAvFieldGenericInput
                                value={configuration?.website}
                                isError={customFormError?.website}
                                {...modifiedFormSchema?.website}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="d-flex align-items-center mb-3">
                                <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                  <Label className={customFormError?.affiliated_hospitals ? "text-danger" : ''}>
                                    {formOptions?.affiliated_hospitals?.label}
                                  </Label>
                                  <div className="d-flex align-items-center">
                                    <div className="w-100">
                                      <EAsyncSelect
                                        isMulti
                                        onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, affiliatedHospitals: false }))}
                                        menuIsOpen={isMenuOpen.affiliatedHospitals}
                                        options={{ ...formOptions?.affiliated_hospitals, label: "" }}
                                        value={selectedOptions?.affiliatedHospitals}
                                        loadOptions={searchHospitals}
                                        formError={customFormError?.affiliated_hospitals}
                                        onChange={updateHospitalsSelect}
                                        placeholder="Type to search hospitals..."
                                        getOptionLabel={e => e?.name}
                                        getOptionValue={e => e?.id}
                                      />
                                    </div>
                                    <div onClick={() => setModal({ ...modal, affiliatedHospital: true })} title="Create a new hospital"
                                      className={`cursor-pointer ms-2 ${customFormError?.affiliated_hospitals ? "mb-3" : ""}`}>
                                      <i className="bx bx-plus-medical text-success" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="d-flex align-items-center mb-3">
                                <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                  <Label className={customFormError?.affiliated_doctors ? "text-danger" : ''}>
                                    {formOptions?.affiliated_doctors?.label}
                                  </Label>
                                  <div className="d-flex align-items-center">
                                    <div className="w-100">
                                      <EAsyncSelect
                                        isMulti
                                        onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, affiliatedDoctors: false }))}
                                        menuIsOpen={isMenuOpen.affiliatedDoctors}
                                        options={{ ...formOptions?.affiliated_doctors, label: "" }}
                                        value={selectedOptions?.affiliatedDoctors}
                                        loadOptions={searchDoctors}
                                        formError={customFormError?.affiliated_doctors}
                                        onChange={updateDoctorsSelect}
                                        placeholder="Type to search doctors..."
                                        getOptionLabel={e => e?.user_data?.full_name}
                                        getOptionValue={e => e?.id}
                                      />
                                    </div>
                                    <div onClick={() => setModal({ ...modal, affiliatedDoctor: true })} title="Create a new hospital"
                                      className={`cursor-pointer ms-2 ${customFormError?.affiliated_doctors ? "mb-3" : ""}`}>
                                      <i className="bx bx-plus-medical text-success" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h5 className="mt-2 text-muted">Address</h5>
                              <hr />
                              <Row>
                                <Col md={6} sm={12}>
                                  <EAvFieldGenericInput
                                    value={configuration?.address1}
                                    isError={customFormError?.address1}
                                    style={{ height: "150px", resize: "none" }}
                                    {...modifiedFormSchema?.address1}
                                    type="textarea"
                                  />
                                </Col>
                                <Col md={6} sm={12}>
                                  <EAvFieldGenericInput
                                    value={configuration?.address2}
                                    isError={customFormError?.address2}
                                    style={{ height: "150px", resize: "none" }}
                                    {...modifiedFormSchema?.address2}
                                    type="textarea"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl="1"></Col>
                        <Col>
                          <h5 className="mt-2 text-muted">Tax Information</h5>
                          <hr />
                          <Row>
                            <Col md={6} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.business_identification_number}
                                isError={customFormError?.business_identification_number}
                                {...modifiedFormSchema?.business_identification_number}
                              />
                            </Col>
                            <Col md={6} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.tax_country}
                                isError={customFormError?.tax_country}
                                {...modifiedFormSchema?.tax_country}
                              />
                            </Col>
                          </Row>
                          <h5 className="mt-2 text-muted">Registration Details</h5>
                          <hr />
                          <Row>
                            <Col md={4} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.registration_number}
                                isError={customFormError?.registration_number}
                                {...modifiedFormSchema?.registration_number}
                              />
                            </Col>
                            <Col md={4} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.registration_date}
                                isError={customFormError?.registration_date}
                                {...modifiedFormSchema?.registration_date}
                                daterange={{ start: { value: -100, units: "years" }, end: { value: 100, units: "years" } }}
                              />
                            </Col>
                            <Col md={4} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.issuing_authority}
                                isError={customFormError?.issuing_authority}
                                {...modifiedFormSchema?.issuing_authority}
                              />
                            </Col>
                          </Row>
                          <h5 className="mt-2 text-muted">Operating Hours</h5>
                          <hr />
                          <Row>
                            <Col md={4} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.opening_time}
                                isError={customFormError?.opening_time}
                                {...modifiedFormSchema?.opening_time}
                              />
                            </Col>
                            <Col md={4} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.closing_time}
                                isError={customFormError?.closing_time}
                                {...modifiedFormSchema?.closing_time}
                              />
                            </Col>
                            <Col md={4} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.days_of_operation}
                                isError={customFormError?.days_of_operation}
                                {...modifiedFormSchema?.days_of_operation}
                              />
                            </Col>
                          </Row>
                          <h5 className="mt-2 text-muted">Owner/Manager Details</h5>
                          <hr />
                          <Row>
                            <Col md={6} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.owner_name}
                                isError={customFormError?.owner_name}
                                {...modifiedFormSchema?.owner_name}
                              />
                            </Col>
                            <Col md={6} sm={12}>
                              <div className="mb-3">
                                <PhonenumberInput
                                  label="Mobile"
                                  name="phone_number"
                                  value={configuration?.owner_contact}
                                  error={customFormError?.owner_contact}
                                  required={formOptions?.owner_contact?.required}
                                  updateValue={setOwnerContact}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.owner_email}
                                isError={customFormError?.owner_email}
                                {...modifiedFormSchema?.owner_email}
                              />
                            </Col>
                            <Col md={6} sm={12}>
                              <EAvFieldGenericInput
                                value={configuration?.owner_designation}
                                isError={customFormError?.owner_designation}
                                {...modifiedFormSchema?.owner_designation}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <RequiredFieldsMessage />
                      </Row>
                      <Row>
                        <Col>
                          <div className="d-flex justify-content-end">
                            <SubmitLoaderButton
                              type="submit"
                              loading={[`update_configuration_diagnosis_update`, `post_configuration_diagnosis_post`].includes(apiKey)}
                            >
                              Submit
                            </SubmitLoaderButton>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  )
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <SingleFieldCRUModal
        modalLabel={"Affiliated Hospital"}
        modal={modal.affiliatedHospital}
        toggle={() => setModal({ ...modal, affiliatedHospital: false })}
        rudFlag={1}
        apiStartKey={"affiliated_hospitals_configuration"}
        apiEndpoint={"/hms/affiliated-hospitals/"}
      />

      <DoctorCRUModal
        apiStartKey={"affiliated_doctors_configuration"}
        modal={modal.affiliatedDoctor}
        toggle={() => setModal({ ...modal, affiliatedDoctor: false })}
        rudFlag={1}
      />
    </React.Fragment >
  )
}

export default DiagnosisHome
