import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
import { deleteHms, getHms, getHmsOptions, resetHmsState } from "store/actions";
import { isEmpty } from "lodash";
import { Loader, StaffInformation } from "pages/HMS/common/common";
import { TableBadge, TableChip } from "components/Common/common";
import { StaffCRUDModal } from "./staff-crud-modals";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals";
import toastr from "toastr"

const StaffDetail = (props) => {
  const dispatch = useDispatch();
  const { error, hmsObject, apiKey, loading, updateResponse } = useSelector(state => state.Hms);
  const [staff, setStaff] = useState({});
  const [dropdown, setDropdown] = useState(false)
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    if (apiKey === "staff_details") {
      setStaff(hmsObject);
    }
    if (apiKey === "staff_details_updated") {
      setStaff(updateResponse);
    }
    if (apiKey === "staff_detail_deleted") {
      toastr.success("Staff is deleted")
      setDeleteModal(false)
      setStaff({})
      window.location.href = "/hms/staff/staff"
    }
  }, [apiKey]);

  useEffect(() => {
    dispatch(getHmsOptions("/hms/staff/staff/?options=formschema", "staff_detail_options", false, true))
    if (props.match.params.staffID) dispatch(getHms("/hms/staff/staff/", props.match.params.staffID, "staff_details"))
    return () => dispatch(resetHmsState())
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {staff?.user_data?.full_name && `${staff?.user_data.full_name} |`} Yanthura
          </title>
        </MetaTags>
        <Card>
          <CardBody>
            {apiKey === "get_staff_details" ? (
              <Loader />
            ) : error ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : (
              <Row>
                <Col>
                  <div className="d-flex justify-content-between">
                    <h5>Staff Information</h5>
                    <Dropdown isOpen={dropdown} toggle={() => setDropdown(!dropdown)} direction="down">
                      <DropdownToggle caret color="primary" size="sm">
                        Actions
                        &nbsp;<i className="fa fa-caret-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <Link className="dropdown-item" to="#" onClick={() => setModal(true)}>
                          Modify
                        </Link>
                        <Link className="dropdown-item" to="#" onClick={() => setDeleteModal(true)}>
                          Remove
                        </Link>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <hr />
                  {staff?.user_data ? (
                    <StaffInformation staff={staff} />
                  ) : (
                    <p className="text-center text-danger">Staff information not existed</p>
                  )}
                  <h5 className="mt-2">Address</h5>
                  <hr />
                  {staff?.address || staff?.city ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <TableChip label="Line 1" value={staff?.address} />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip label="City" value={staff?.city} />
                      </div>
                    </>
                  ) : (
                    <p className="text-center text-danger">Address details not existed</p>
                  )}
                </Col>
                <Col lg={1}></Col>
                <Col>
                  <h5 className="mt-2">Professional Information</h5>
                  <hr />
                  {!isEmpty(staff) ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <TableBadge
                          label="Qualifications"
                          value={staff?.qualifications}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Years Of experience"
                          value={staff?.experience}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Certifications"
                          value={staff?.certifications}
                        />
                        <TableChip
                          label="Medical license number"
                          value={staff?.medical_license_number}
                        />
                      </div>
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Date of join"
                          value={staff?.date_of_join}
                        />
                        <TableChip
                          label="Role"
                          value={staff?.role}
                        />
                      </div>
                    </>
                  ) : (
                    <p className="text-center text-danger">Professional information not existed</p>
                  )}
                  <h5 className="mt-2">Permissions</h5>
                  <hr />
                  {!isEmpty(staff?.user_data?.groups) ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <TableBadge
                          label="Groups"
                          value={staff?.user_data?.groups}
                        />
                      </div>
                    </>
                  ) : (
                    <p className="text-center text-danger">Permissions details not existed</p>
                  )}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>

      <StaffCRUDModal
        modal={modal}
        toggle={() => setModal(false)}
        rudFlag={0}
        data={staff}
        apiStartKey="staff_details"
      />

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If staff is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={() => setDeleteModal(false)}
        dispatchOperation={deleteHms("/hms/staff/staff/", staff?.id, "staff_detail_deleted")}
        deleteModal={deleteModal}
      />
    </React.Fragment>
  );
};

export default StaffDetail;
