import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Input } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { loginUser } from "../../store/actions"
import profile from "assets/images/profile-img.png"
import logo from "assets/yanthura/images/logoIcon.png"
import { get } from "helpers/api_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import { handlePhonenumberBlur } from "pages/HMS/common/common"

const Login = props => {
  const dispatch = useDispatch()
  const { error, loading } = useSelector(state => state.Login)
  const [tglPwdDisp, setTglPwdDisp] = useState(false)
  const [err, setErr] = useState(null)
  const [alert, setAlert] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    get("/accounts/echo")
  }, [])

  useEffect(() => {
    if (error?.code === "NOT_VER_ACC") {
      setAlert(true);
      return;
    }

    if (error) {
      setErr(error);
      const timer = setTimeout(() => {
        setErr(null);
      }, 5000);
      return () => clearTimeout(timer);
    }

    setErr(null);
  }, [error]);

  const handleInputChange = (e) => {
    setPassword(e.target.value);
    setIsInvalid(e.target.value.trim() === "");
  };

  const handleValidSubmit = (event, values) => {
    if (isInvalid) return
    values.password = password;
    dispatch(loginUser(values, props.history))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Yanthura</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome!</h5>
                        <p>Sign in to yanthura.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {err && <Alert color="danger">In-valid username or password</Alert>}
                      <div className="mb-3">
                        <AvField
                          name="username"
                          label="Username"
                          type={"text"}
                          required
                          placeholder="Enter username or Login ID"
                        />
                      </div>
                      <div className="password-input-container position-relative">
                        <label htmlFor="password" className={isInvalid ? "text-danger" : ""}>
                          Password
                        </label>
                        <Input
                          name="password"
                          required
                          placeholder="Enter password"
                          type={tglPwdDisp ? "text" : "password"}
                          className={`pe-4 form-control ${isInvalid ? "border-danger" : ""}`}
                          value={password}
                          onChange={handleInputChange}
                          onBlur={() => handlePhonenumberBlur(password, setIsInvalid)}
                        />
                        <span
                          className="eye-icon position-absolute fs-5 translate-middle-y cursor-pointer ms-auto"
                          onClick={() => setTglPwdDisp(!tglPwdDisp)}
                          style={{ top: isInvalid ? "58%" : "72%", right: "10px" }}
                        >
                          <i className={`mdi ${tglPwdDisp ? "mdi-eye-off-outline" : "mdi-eye-outline"}`} />
                        </span>
                        {isInvalid && (
                          <div className="text-danger small mt-1">
                            This field is invalid
                          </div>
                        )}
                      </div>
                      <div className="mt-2 text-end">
                        <p>
                          <Link to="/forgot-password" className="fw-medium text-primary">
                            Forgot password ?
                          </Link>
                        </p>
                      </div>
                      <div className="mt-3 d-grid">
                        {loading ? (
                          <button className="btn btn-primary btn-block" disabled>
                            Log In{" "}
                            <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                          </button>
                        ) : (
                          <button className="btn btn-primary btn-block" type="submit">
                            Log In
                          </button>
                        )}
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Yanthura. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Yanthura
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {alert && (
        <SweetAlert
          title="Account is not verified"
          danger
          ErrorIcon
          confirmBtnBsStyle="secondary"
          onConfirm={() => setAlert(false)}
          confirmBtnText="Close"
        >
          {error?.detail}
        </SweetAlert>
      )}
    </React.Fragment>
  )
}

export default Login