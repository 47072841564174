import { AvForm } from "availity-reactstrap-validation";
import { EAvFieldSelect } from "pages/HMS/common/errored-avfields";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import { getHms } from "store/actions";

const Dropdown = ({ label, field, data, loading, dropdownOptions, handleSelect, editReportLayout, handleCreate, setSelectedReportKey }) => {
  const dispatch = useDispatch();
  const { options, formError } = useSelector(state => state.Hms)

  const handleClick = () => {
    setSelectedReportKey(field)
    dispatch(getHms("/hms/hms-report-layouts/", data?.[field], `preview_${field}`))
  };

  return (
    <AvForm>
      <Label>{label}</Label>
      <hr className="mt-0" />
      <Row className="mb-3">
        <Col md={4}>
          <div className="d-flex">
            <div className="w-100">
              <EAvFieldSelect
                field={field}
                value={data?.[field] || ""}
                isError={formError?.[field]}
                options={{ ...options?.formschema?.[field], label: "" }}
                onChange={loading ? undefined : handleSelect}
                disabled={loading}
                choices={
                  <>
                    <option value="">--Select an option--</option>
                    {dropdownOptions?.map((choice, index) => (
                      <option value={choice.id} key={index}>{choice.name}</option>
                    ))}
                  </>
                }
              />
            </div>
            {data?.[field] ? (
              <i
                className="mdi mdi-pencil text-success font-size-18 ms-2 mt-1"
                title="Modify Report Layout"
                style={{ cursor: "pointer" }}
                onClick={() => editReportLayout(data?.[field])}
              />
            ) : (
              <i
                className="mdi mdi-plus-thick text-success font-size-18 ms-2 mt-1"
                title="Add Report Layout"
                style={{ cursor: "pointer" }}
                onClick={handleCreate}
              />
            )}
            {data?.[field] && (
              <div
                title={loading ? "" : "Preview Report Layout"}
                style={{ cursor: "pointer" }}
                className="ms-2 mt-1"
                onClick={loading ? undefined : handleClick}
              >
                <i className={`${loading ? "bx bx-loader bx-spin mt-1" : "mdi mdi-eye text-primary"} font-size-18`} />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </AvForm>
  )
}

export default Dropdown;