import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Row, Col, CardTitle, Button } from "reactstrap"
import { getHms, getHmsOptions, postHms, resetHmsState, updateHms } from "store/hms/actions"
import { AvForm } from "availity-reactstrap-validation"
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { EAvFieldInput, EAvFieldSelect, EInputDropdown, RequiredFieldsMessage } from "pages/HMS/common/errored-avfields"
import { PhonenumberInput } from "components/Common/input-advanced"
import { SingleFieldCRUModal } from "pages/HMS/common/hms-crud-modals"
import { Loader, handlePhonenumberBlur, handlePhonenumberFocus, errorMappings, EAvFieldDOB, SubmitLoaderButton } from "pages/HMS/common/common"
import ConfirmationDialog from "pages/HMS/common/confirmation-dialog"
import { GUARDIAN_RELATION } from "pages/HMS/common/constants"
import moment from "moment"

const CreatePatient = props => {
  const dispatch = useDispatch()
  const { loading, error, options, hmsObject, updateResponse, formError, apiKey, hmsSingleObject, actionStatus } = useSelector((state) => state.Hms)
  const location = useLocation()
  const history = useHistory()
  const formRef = useRef(null)
  const [patient, setPatient] = useState({})
  const [phonenumber, setPhonenumber] = useState(null)
  const [alternatePhonenumber, setAlternatePhonenumber] = useState(null)
  const [emergencyNumber, setEmergencyNumber] = useState(null)
  const [guardian, setRelation] = useState({ relation: null, name: null })
  const [isModifyMode, setIsModifyMode] = useState(false)
  const [modalIssuer, setModalIssuer] = useState(false)
  const [modalIdentity, setModalIdentity] = useState(false)
  const [formOptions, setFormOptions] = useState(null)
  const [appointmentFlag, setAppointmentFlag] = useState(false)
  const [phoneError, setPhoneError] = useState("")
  const [guardianError, setGuardianError] = useState("")
  const [activeOPAlert, setActiveOPAlert] = useState(false)
  const [state, setState] = useState({ title: "", message: "", isExist: false })
  const [age, setAge] = useState({ dob: null, years: null, months: null, days: null })

  useEffect(() => {
    const row = location.state
    if (row) {
      setPatient(row)
      setAppointmentFlag(true)
      history.replace(location.pathname, null)
    }
  }, [location.state])


  useEffect(() => {
    if (options.form_schema) setFormOptions(options.form_schema)
  }, [options])

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (location.pathname === "/hms/patient/register") dispatch(resetHmsState())
    })

    if (apiKey === "get_patient") {
      setPatient(hmsObject)
      const [relation, ...rest] = hmsObject?.guardian?.split(" ") || []
      setRelation({ relation: relation, name: rest.join(" ") })
      if (hmsObject?.user_data?.dob) {
        setAge({
          dob: hmsObject.user_data.dob,
          years: hmsObject.user_data.age,
          months: moment().diff(hmsObject.user_data.dob, "months"),
          days: moment().diff(hmsObject.user_data.dob, "days"),
        })
      }
      setIsModifyMode(true)
    }
    if (formError?.detail && apiKey.startsWith("fail_patient")) {
      setActiveOPAlert(true)
      setState((prevState) => ({ ...prevState, ...errorMappings[formError?.detail] }))
    }
    if (apiKey === "patient_post") setPatient(hmsSingleObject)
    if (apiKey === "patient_update") setPatient(updateResponse)
    if (apiKey === "identity_type_patient_created" || apiKey === "insurance_issuer_patient_created") dispatch(getHmsOptions("/hms/patient/patients/?options=formschema"))

    return () => unlisten()
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/patients/?options=formschema", "fetch_patient_options"))
    if (props.match.params.patientID) dispatch(getHms("/hms/patient/patients/", props.match.params.patientID, "get_patient"))
    return () => dispatch(resetHmsState())
  }, [])

  const toggleIssuer = (def = modalIssuer) => {
    setModalIssuer(!def)
  }
  const toggleIdentity = (def = modalIdentity) => {
    setModalIdentity(!def)
  }

  const handleSubmit = (event, values) => {
    if (!phonenumber) {
      setPhoneError("This field is invalid")
      return
    }
    setPhoneError("")

    if (guardian?.name && !guardian?.relation) {
      setGuardianError("Please select a guardian relation")
      return
    }
    setGuardianError("")

    values.guardian = guardian?.relation && guardian?.name ? `${guardian.relation} ${guardian.name}` : null
    values.alternate_phone_number = alternatePhonenumber
    values.emergency_contact_number = emergencyNumber
    values.marital_status = values.marital_status || false

    values.user_post = {
      full_name: `${values.first_name} ${values.last_name}`,
      email: values.email,
      phone_number: phonenumber,
      gender: values.gender,
      dob: age?.dob,
    }
    delete values.email
    delete values.phone_number
    delete values.gender
    delete values.dob
    values.address_post = {
      line1: values.line1,
      line2: values.line2,
      city: values.city,
      state: values.state,
      pincode: values.pincode,
    }
    delete values.line1
    delete values.line2
    delete values.city
    delete values.state
    delete values.pincode
    values.health_insurance_post = {
      issuer: values.issuer,
      policy_number: values.policy_number,
      policy_valid_till: values.policy_valid_till,
    }
    delete values.issuer
    delete values.policy_number
    delete values.policy_valid_till

    if (appointmentFlag) values.appointment = patient.id
    if (state?.isExist) values.user_verification = 0

    if (isModifyMode) {
      dispatch(updateHms("/hms/patient/patients/", patient.id, values, "patient_update"))
    } else {
      dispatch(postHms("/hms/patient/patients/", values, "patient_post"))
    }
  }

  const onConfirm = () => {
    setState((prevState) => ({ ...prevState, isExist: true }))
    setActiveOPAlert(false)
    formRef.current.submit()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Patient Registration | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          <Col xl={12}>
            <Card>
              <CardBody>
                {error ? (
                  <p className="text-center text-danger">{error.detail}</p>
                ) : loading && apiKey === "options_fetch_patient_options" || apiKey === "get_get_patient" ? (
                  <Loader />
                ) : (
                  <>
                    {(["patient_post_success", "patient_update_success"].includes(actionStatus)) ? (
                      <div className="p-2">
                        <div className="text-center">
                          <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                          <div className="p-2 mt-2">
                            <h4>Patient {!isModifyMode ? "created" : "modified"} successfully</h4>
                            <div className="mt-4">
                              <div className="d-flex justify-content-center">
                                <Button
                                  color="primary"
                                  onClick={() => window.location.replace("/hms/patient/register")}
                                  className={window.innerWidth <= 425 ? "btn-sm me-1" : "me-1"}
                                >
                                  Add&nbsp;Another
                                </Button>
                                <Link
                                  to={"/hms/patients"}
                                  className={window.innerWidth <= 425 ? "btn-sm btn btn-primary me-1" : "me-1 btn btn-primary"}
                                >
                                  Patients
                                </Link>
                                <Button
                                  className={window.innerWidth <= 425 ? "btn-sm" : ""}
                                  color="primary"
                                  onClick={() => window.location.replace(`/hms/patient/register/${hmsSingleObject?.id}`)}
                                >
                                  Modify
                                </Button>
                                <Link
                                  to={`/hms/patient/${hmsSingleObject.id}/detail`}
                                  className={window.innerWidth <= 425 ? "btn-sm ms-1 btn btn-primary" : "ms-1 btn btn-primary"}
                                >
                                  Explore
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {formOptions && (
                          <>
                            <CardTitle className="mb-4">
                              {!isModifyMode ? "Patient Registration" : "Modify Patient"}
                            </CardTitle>
                            <AvForm className="form-horizontal" onValidSubmit={handleSubmit} ref={formRef}>
                              <Row>
                                <Col>
                                  <Row>
                                    <Col sm={2}>
                                      <EAvFieldSelect
                                        field="honorific"
                                        value={patient.honorific || "Mr"}
                                        isError={formError?.honorific}
                                        options={{ ...formOptions?.honorific, label: "." }}
                                        choices={<>
                                          {formOptions?.honorific?.choices.map((choice, cidx) =>
                                            <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                          )}
                                        </>}
                                      />
                                    </Col>
                                    <Col sm={5}>
                                      <EAvFieldInput
                                        field="first_name"
                                        type="text"
                                        value={patient?.first_name}
                                        isError={formError?.first_name}
                                        options={{ ...formOptions?.first_name, label: "Firstname" }}
                                      />
                                    </Col>
                                    <Col sm={5}>
                                      <EAvFieldInput
                                        field="last_name"
                                        type="text"
                                        value={patient?.last_name}
                                        isError={formError?.last_name}
                                        options={{ ...formOptions?.last_name, label: "Lastname" }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <EInputDropdown
                                        name="guardian"
                                        formError={formError?.guardian || guardianError}
                                        options={formOptions?.guardian}
                                        dropdownValue={guardian.relation}
                                        inputValue={guardian.name}
                                        setDropdownValue={(value) => setRelation(prevState => ({ ...prevState, relation: value }))}
                                        setInputValue={(e) => setRelation(prevState => ({ ...prevState, name: e.target.value }))}
                                        dropdownOptions={GUARDIAN_RELATION}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="mb-3">
                                        <PhonenumberInput
                                          label="Mobile"
                                          name="phone_number"
                                          value={patient?.user_data?.phone_number || patient?.phone_number}
                                          error={formError?.phone_number || phoneError}
                                          required={formOptions?.phone_number?.required}
                                          updateValue={setPhonenumber}
                                          onFocus={() => handlePhonenumberFocus(setPhoneError)}
                                          onBlur={() => handlePhonenumberBlur(phonenumber, setPhoneError)}
                                        />
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="mb-3">
                                        <PhonenumberInput
                                          label="Alternate mobile"
                                          name="alternate_phone_number"
                                          value={patient?.alternate_phone_number}
                                          error={formError?.alternate_phone_number}
                                          required={formOptions?.alternate_phone_number?.required}
                                          updateValue={setAlternatePhonenumber}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <EAvFieldInput
                                        field="email"
                                        type="email"
                                        value={patient?.user_data?.email}
                                        isError={formError?.email}
                                        options={{ ...formOptions?.email, label: "Email", max_length: 50 }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <EAvFieldSelect
                                        field="gender"
                                        value={patient?.gender || patient?.user_data?.gender}
                                        isError={formError?.gender}
                                        options={{ ...formOptions?.gender, label: "Sex" }}
                                        choices={<>
                                          <option value="">--Select an option--</option>
                                          {formOptions?.gender?.choices?.map((choice, cidx) =>
                                            <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                          )}
                                        </>}
                                      />
                                    </Col>
                                    <Col>
                                      <EAvFieldDOB
                                        age={age}
                                        setAge={setAge}
                                        required={formOptions?.dob?.required}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <EAvFieldSelect
                                        field="marital_status"
                                        value={patient.marital_status}
                                        isError={formError?.marital_status}
                                        options={formOptions?.marital_status}
                                        choices={<>
                                          <option value={false}>Unmarried</option>
                                          <option value={true}>Married</option>
                                        </>}
                                      />
                                    </Col>
                                    <Col>
                                      <EAvFieldSelect
                                        field="occupation"
                                        value={patient?.occupation}
                                        isError={formError?.occupation}
                                        options={formOptions?.occupation}
                                        choices={<>
                                          <option value="">-----Select an option-----</option>
                                          {formOptions?.occupation?.choices.map((choice, cidx) =>
                                            <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                          )}
                                        </>}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <h5 className="mt-2 text-muted">Address</h5>
                                      <hr />
                                      <EAvFieldInput
                                        field="line1"
                                        type="text"
                                        value={patient?.address_data?.line1 || patient?.coming_from}
                                        isError={formError?.line1}
                                        options={{ ...formOptions?.line1, label: "", required: false }}
                                        placeholder="Address line1"
                                      />
                                      <EAvFieldInput
                                        field="line2"
                                        type="text"
                                        value={patient?.address_data?.line2}
                                        isError={formError?.line2}
                                        options={{ ...formOptions?.line2, label: "", required: false }}
                                        placeholder="Address line2"
                                      />
                                      <Row>
                                        <Col>
                                          <EAvFieldInput
                                            field="city"
                                            type="text"
                                            value={patient?.address_data?.city}
                                            isError={formError?.city}
                                            options={{ ...formOptions?.city, label: "", required: false }}
                                            placeholder="City"
                                          />
                                        </Col>
                                        <Col>
                                          <EAvFieldInput
                                            field="state"
                                            type="text"
                                            value={patient?.address_data?.state}
                                            isError={formError?.state}
                                            options={{ ...formOptions?.state, label: "", required: false }}
                                            placeholder="State"
                                          />
                                        </Col>
                                        <Col>
                                          <EAvFieldInput
                                            field="pincode"
                                            type="text"
                                            value={patient?.address_data?.pincode}
                                            isError={formError?.pincode}
                                            options={{ ...formOptions?.pincode, label: "", required: false }}
                                            placeholder="pincode"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xl="1"></Col>
                                <Col>
                                  <h5 className="mt-2 text-muted">Health Information</h5>
                                  <hr />
                                  <Row>
                                    <Col md={6}>
                                      <EAvFieldSelect
                                        field="blood_group"
                                        value={patient?.blood_group}
                                        isError={formError?.blood_group}
                                        options={{ ...formOptions?.blood_group, label: <span>Blood&nbsp;group</span> }}
                                        choices={<>
                                          <option value={""}>--Select an option--</option>
                                          {formOptions?.blood_group?.choices.map((choice, cidx) =>
                                            <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                          )}
                                        </>}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <h5 className="mt-2 text-muted">Emergency Contact Information</h5>
                                    <hr />
                                    <Col>
                                      <EAvFieldInput
                                        field="emergency_contact_name"
                                        type="text"
                                        value={patient?.emergency_contact_name}
                                        isError={formError?.emergency_contact_name}
                                        options={{ ...formOptions?.emergency_contact_name, label: "Contact person" }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="mb-3">
                                        <PhonenumberInput
                                          label="Mobile number"
                                          name="emergency_contact_number"
                                          value={patient?.emergency_contact_number}
                                          error={formError?.emergency_contact_number}
                                          required={formOptions?.emergency_contact_number?.required}
                                          updateValue={setEmergencyNumber}
                                        />
                                      </div>
                                    </Col>
                                    <Col>
                                      <EAvFieldInput
                                        field="emergency_contact_relation"
                                        type="text"
                                        value={patient?.emergency_contact_relation}
                                        isError={formError?.emergency_contact_relation}
                                        options={{ ...formOptions?.emergency_contact_relation, label: "Relation" }}
                                      />
                                    </Col>
                                  </Row>
                                  <h5 className="mt-2 text-muted">Identity Information</h5>
                                  <hr />
                                  <Row>
                                    <Col>
                                      <div className="d-flex align-items-center">
                                        <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                          <EAvFieldSelect
                                            field="identity_type"
                                            value={patient?.identity_type}
                                            isError={formError?.identity_type}
                                            options={formOptions?.identity_type}
                                            choices={<>
                                              <option value={""}>-----Select an option-----</option>
                                              {formOptions?.identity_type?.choices.map((choice, cidx) =>
                                                <option value={choice.id} key={cidx}> {choice.name} </option>
                                              )}
                                            </>}
                                          />
                                        </div>
                                        <div onClick={() => toggleIdentity(false)} title="Add identity type" className="cursor-pointer ms-2 mt-3">
                                          <i className="bx bx-plus-medical text-success" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col>
                                      <EAvFieldInput
                                        field="identity_number"
                                        type="text"
                                        value={patient?.identity_number}
                                        isError={formError?.identity_number}
                                        options={{ ...formOptions?.identity_number, label: "ID number" }}
                                      />
                                    </Col>
                                  </Row>
                                  <h5 className="mt-2 text-muted">Insurance</h5>
                                  <hr />
                                  <Row>
                                    <Col>
                                      <div className="d-flex align-items-center">
                                        <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                          <EAvFieldSelect
                                            field="issuer"
                                            value={patient?.health_insurance_data?.issuer}
                                            isError={formError?.issuer}
                                            options={{ ...formOptions?.issuer, label: <span>Insurance&nbsp;issuer</span> }}
                                            choices={<>
                                              <option value="">--Select an option--</option>
                                              {formOptions?.insurance_issuer?.choices.map((choice, cidx) =>
                                                <option value={choice.id} key={cidx}> {choice.name} </option>
                                              )}
                                            </>}
                                          />
                                        </div>
                                        <div onClick={() => toggleIssuer(false)} title="Add insurance issuers" className="cursor-pointer ms-2 mt-3">
                                          <i className="bx bx-plus-medical text-success" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col>
                                      <EAvFieldInput
                                        field="policy_valid_till"
                                        type="date"
                                        value={patient?.health_insurance_data?.policy_valid_till}
                                        isError={formError?.policy_valid_till}
                                        options={{ ...formOptions?.policy_valid_till, label: "Valid till" }}
                                        daterange={{ start: { value: -100, units: "years" }, end: { value: 100, units: "years" } }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <EAvFieldInput
                                        field="policy_number"
                                        type="text"
                                        value={patient?.health_insurance_data?.policy_number}
                                        isError={formError?.policy_number}
                                        options={{ ...formOptions?.policy_number, label: <span>Policy&nbsp;number</span> }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <RequiredFieldsMessage />
                              </Row>
                              <Row className="justify-content-end">
                                <Col sm={9}>
                                  <div className="text-end">
                                    <SubmitLoaderButton
                                      type="submit"
                                      loading={["update_patient_update", "post_patient_post"].includes(apiKey)}
                                      history={props.history}
                                    >
                                      {isModifyMode ? "Modify" : "Add"} Patient
                                    </SubmitLoaderButton>
                                  </div>
                                </Col>
                              </Row>
                            </AvForm>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <SingleFieldCRUModal
          modalLabel={"Insurance issuer"}
          modal={modalIssuer}
          toggle={toggleIssuer}
          rudFlag={1}
          apiStartKey={"insurance_issuer_patient"}
          apiEndpoint={"/hms/patient/insurance_issuer/"}
        />

        <SingleFieldCRUModal
          modalLabel={"Identity Type"}
          modal={modalIdentity}
          toggle={toggleIdentity}
          rudFlag={1}
          apiStartKey={"identity_type_patient"}
          apiEndpoint={"/hms/patient/identity_type/"}
        />

        <ConfirmationDialog
          activeOPAlert={activeOPAlert}
          setActiveOPAlert={setActiveOPAlert}
          state={state}
          onConfirm={onConfirm}
        />
      </div>
    </React.Fragment>
  )
}
export default CreatePatient