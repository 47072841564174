import { Modal, ModalBody, Row, Col, Button, ModalFooter } from "reactstrap"
import { useEffect, useRef, useState } from "react";
import { postHms, postUploadHms, updateHms } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr"
import { AvForm } from "availity-reactstrap-validation";
import { EAvFieldCheck, EAvFieldGenericInput, EAvFieldSelect } from "pages/HMS/common/errored-avfields";
import { isEmpty } from "lodash";
import { cleanFilenameForSign } from "components/Common/common";
import Dropzone from "react-dropzone";
import DigitalSignature from "pages/HMS/common/digital-signature";
import SignaturePreview from "../utilize/signature-preview";
import { isMobileView } from "pages/HMS/common/common";

export const ConfigureCRUModal = (props) => {
  let { modal, toggle, rudFlag, data = null, apiStartKey, prepend = true } = props
  const formRef = useRef()
  const isMobile = isMobileView()
  const dispatch = useDispatch()
  const { loading, apiKey, postResponse, updateResponse, formError, options, signedURLs, modifiedFormSchema } = useSelector(state => state.Hms)
  const [layout, setLayout] = useState(null)
  const [state, setState] = useState({ base64String: null, selectedFiles: {}, preSignedURL: {}, previewLayout: false })
  const [isLoading, setIsLoading] = useState(false)
  const [signatures, setSignatures] = useState({ leftSignature: null, rightSignature: null })
  const [modals, setModals] = useState({ cancelModal: false, leftSignatureModal: false, rightSignatureModal: false });

  useEffect(() => {
    if (apiKey === `${apiStartKey}_created`) {
      toggleLayout()
      toastr.success(`${postResponse?.name} created successfully`)
      formRef.current?.reset()
    }
    if (apiKey === `${apiStartKey}_updated`) {
      toggleLayout()
      toastr.success(`${updateResponse?.name} updated successfully`)
    }
    if (apiKey === "upload_header_layout" || apiKey === "upload_footer_layout") {
      setState({ ...state, preSignedURL: signedURLs?.[0] })
    }
    if (apiKey === "preview_report") {
      setState({ ...state, base64String: postResponse })
    }
    if (apiKey === "preview_report" && isMobile) {
      const fileName = `${new Date().toISOString().split('.')[0].replace(/[-T:]/g, '')}.pdf`;
      const linkSource = `data:application/pdf;base64,${postResponse}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      toastr.success("PDF downloaded successfully");
    }
  }, [apiKey])

  useEffect(() => {
    if (modal && !isMobile) {
      const payload = data ? data : null
      dispatch(postHms("/hms/hms-report-layouts/preview/", payload, "preview_report", false))
    }
    setLayout(data)
    setSignatures((prevState) => ({ ...prevState, leftSignature: data.left_signature, rightSignature: data.right_signature }));
  }, [modal])

  const handleValidSubmit = (e, values) => {
    values.header_layout = values?.header_type === "Custom" ? layout?.header_layout : null
    values.footer_layout = values?.header_type === "Custom" ? layout?.footer_layout : null
    values.left_signature = signatures.leftSignature
    values.right_signature = signatures.rightSignature

    if (state?.previewLayout) {
      setState({ ...state, previewLayout: false })
      return dispatch(postHms("/hms/hms-report-layouts/preview/", values, "preview_report", false));
    }
    if (rudFlag === 0) {
      dispatch(updateHms("/hms/hms-report-layouts/", data?.id, values, `${apiStartKey}_updated`))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/hms-report-layouts/", values, `${apiStartKey}_created`, prepend))
    }
  }

  const handleAcceptedFile = (files, apiStartKey) => {
    if (files.length > 1) {
      toastr.error('You can only upload one image.');
      return;
    }

    const file = files[0];
    if (!file) return;

    if (file.size > 3 * 1024 * 1024) {
      toastr.error('File size exceeds 3MB.');
      return;
    }

    const allowedTypes = ['image/jpeg', 'image/png'];
    if (!allowedTypes.includes(file.type)) {
      toastr.error('Only JPEG and PNG files are allowed.');
      return;
    }

    const uid = Date.now() + Math.floor(Math.random() * 1000);
    const filename = cleanFilenameForSign(file.name);
    const newFileEntry = { [uid]: file };
    const filenames = [{ uid, filename }];
    setState((prevState) => ({ ...prevState, selectedFiles: newFileEntry, }));
    const payload = { filenames, target: "report_layouts" };
    dispatch(postUploadHms("/hms/uploader/upload/", payload, apiStartKey));
  };

  useEffect(() => {
    if (!isEmpty(signedURLs) && state?.preSignedURL) {
      setIsLoading(true)
      let filenames = []
      Promise.all(signedURLs.map(signedURL => fetch(signedURL.safelink, {
        method: 'PUT',
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: state?.selectedFiles[signedURL.uid]
      }))).then(responses => {
        responses.map(response => {
          if (response.status === 200) {
            filenames.push(response.url)
          }
        })
        if (!isEmpty(filenames)) {
          const filename = filenames[0].substring(filenames[0].indexOf("hms"), filenames[0].indexOf("?")) || ""
          const key = apiKey === "upload_header_layout" ? "header_layout" : apiKey === "upload_footer_layout" ? "footer_layout" : null
          setLayout({ ...layout, [key]: filename })
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [state?.preSignedURL])

  const toggleModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }))
  }

  const toggleLayout = () => {
    setState(null)
    toggle()
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggleLayout} backdrop="static" size="xl" scrollable keyboard={false}>
        <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
          <ModalBody>
            <Row style={{ height: "80vh" }}>
              <Col className="border">
                <Row className="mt-2">
                  <Col>
                    <EAvFieldGenericInput
                      value={layout?.name}
                      isError={formError?.name}
                      {...modifiedFormSchema?.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EAvFieldSelect
                      field="header_type"
                      value={layout?.header_type || "Default"}
                      isError={formError?.header_type}
                      options={options?.form_schema?.header_type}
                      onChange={(e) => setLayout({ ...layout, header_type: e.target.value })}
                      choices={<>
                        <option value={""}>--Select an option--</option>
                        {options?.form_schema?.header_type?.choices?.map((choice, cidx) =>
                          <option value={choice.value} key={cidx}>{choice.display_name}</option>
                        )}
                      </>}
                    />
                  </Col>
                  <Col>
                    <EAvFieldSelect
                      field="preview_on"
                      value={layout?.preview_on}
                      isError={formError?.preview_on}
                      options={options?.form_schema?.preview_on}
                      onChange={(e) => setLayout({ ...layout, preview_on: e.target.value })}
                      choices={<>
                        {options?.form_schema?.preview_on?.choices?.map((choice, cidx) =>
                          <option value={choice.value} key={cidx}>{choice.display_name}</option>
                        )}
                      </>}
                    />
                  </Col>
                </Row>
                {layout?.header_type === "Custom" && (
                  <>
                    <Row>
                      <Col>
                        <div className="d-flex align-items-center">
                          <Dropzone onDrop={(files) => handleAcceptedFile(files, "upload_header_layout")} accept="image/*">
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()} className="d-flex align-items-center cursor-pointer w-100">
                                <div className="w-100" title="Select an image">
                                  <EAvFieldGenericInput
                                    value={layout?.header_layout?.split("/").pop() || ""}
                                    isError={formError?.header_layout}
                                    readOnly
                                    style={{ pointerEvents: "none", cursor: "default" }}
                                    {...modifiedFormSchema?.header_layout}
                                    placeholder="No file chosen"
                                  />
                                  <input {...getInputProps()} className="d-none" />
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          {layout?.header_layout && (
                            <i
                              className="fas fa-times ms-2 mt-2 font-size-16 text-danger"
                              title="Clear selected image"
                              onClick={() => setLayout((layout) => ({ ...layout, header_layout: "" }))}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                {(layout?.header_type === "Blank" || layout?.header_type === "Custom") && (
                  <Row>
                    <Col>
                      <EAvFieldGenericInput
                        value={layout?.header_type === "Blank" && !layout?.header_spacing ? "100" : layout?.header_spacing}
                        isError={formError?.header_spacing}
                        onChange={(e) => setLayout({ ...layout, header_spacing: e.target.value })}
                        {...modifiedFormSchema?.header_spacing}
                      />
                    </Col>
                    <Col>
                      <EAvFieldGenericInput
                        value={layout?.footer_spacing}
                        isError={formError?.footer_spacing}
                        {...modifiedFormSchema?.footer_spacing}
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xs={6} sm={4} md={6} lg={3} xl={3}>
                    <EAvFieldGenericInput
                      value={layout?.margin_top}
                      isError={formError?.margin_top}
                      {...modifiedFormSchema?.margin_top}
                      label={<span>Margin&nbsp;top</span>}
                    />
                  </Col>
                  <Col xs={6} sm={4} md={6} lg={3} xl={3}>
                    <EAvFieldGenericInput
                      value={layout?.margin_right}
                      isError={formError?.margin_right}
                      {...modifiedFormSchema?.margin_right}
                      label={<span>Margin&nbsp;right</span>}
                    />
                  </Col>
                  <Col xs={6} sm={4} md={6} lg={3} xl={3}>
                    <EAvFieldGenericInput
                      value={layout?.margin_bottom}
                      isError={formError?.margin_bottom}
                      {...modifiedFormSchema?.margin_bottom}
                      label={<span>Margin&nbsp;bottom</span>}
                    />
                  </Col>
                  <Col xs={6} sm={4} md={6} lg={3} xl={3}>
                    <EAvFieldGenericInput
                      value={layout?.margin_left}
                      isError={formError?.margin_left}
                      {...modifiedFormSchema?.margin_left}
                      label={<span>Margin&nbsp;left</span>}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EAvFieldGenericInput
                      value={layout?.end_of_report}
                      isError={formError?.end_of_report}
                      {...modifiedFormSchema?.end_of_report}
                    />
                  </Col>
                </Row>

                <Row className="mb-n2">
                  <Col>
                    <EAvFieldCheck
                      name={"qrcode"}
                      value={layout?.qrcode}
                      formError={formError?.qrcode}
                      options={{ ...options?.form_schema?.qrcode, label: "QR code" }}
                    />
                  </Col>
                </Row>
                <Row className="mb-n2">
                  <Col>
                    <EAvFieldCheck
                      name={"print_time"}
                      value={layout?.print_time}
                      formError={formError?.print_time}
                      options={options?.form_schema?.print_time}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SignaturePreview
                      label={"Add Left Signature"}
                      toggleModal={() => toggleModal("leftSignatureModal")}
                      handleRemove={() => setSignatures((prevState) => ({ ...prevState, leftSignature: null }))}
                      base64String={signatures?.leftSignature}
                    />
                  </Col>
                  <Col>
                    <SignaturePreview
                      label={"Add Right Signature"}
                      toggleModal={() => toggleModal("rightSignatureModal")}
                      handleRemove={() => setSignatures((prevState) => ({ ...prevState, rightSignature: null }))}
                      base64String={signatures?.rightSignature}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EAvFieldGenericInput
                      value={layout?.left_signatory_info}
                      isError={formError?.left_signatory_info}
                      {...modifiedFormSchema?.left_signatory_info}
                      type="textarea"
                    />
                  </Col>
                  <Col>
                    <EAvFieldGenericInput
                      value={layout?.right_signatory_info}
                      isError={formError?.right_signatory_info}
                      {...modifiedFormSchema?.right_signatory_info}
                      type="textarea"
                    />
                  </Col>
                </Row>
              </Col>
              {!isMobile && (
                <Col className="border d-flex flex-column justify-content-center">
                  {apiKey === "post_preview_report" ? (
                    <p className="text-center">Loading preview... <i className="bx bx-hourglass bx-spin font-size-16 align-middle ms-2"></i></p>
                  ) : state?.base64String ? (
                    <>
                      <iframe
                        src={`data:application/pdf;base64,${state?.base64String}`}
                        title="Report Preview"
                        width="100%"
                        height="100%"
                        style={{ border: 'none' }}
                        className="mt-2"
                      />
                      <div className="mt-2 mb-n2">
                        <p className="small text-muted" style={{ textAlign: "right" }}>The data in this report is for representational purposes only.</p>
                      </div>
                    </>
                  ) : (
                    <p style={{ margin: "0 auto" }}>Create Report Layout</p>
                  )}
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="float-end mb-2">
              <div className="d-flex">
                <Button className="btn-sm me-1" type="button" color="secondary" onClick={() => toggleModal("cancelModal")}>
                  Cancel
                </Button>
                <Button
                  className="btn-sm me-1"
                  type="submit" color="primary"
                  onClick={() => setState({ ...state, previewLayout: true })}
                  disabled={apiKey === "post_preview_report" || apiKey === "post_upload_header_layout" || isLoading}
                >
                  Apply
                </Button>
                {loading && apiKey === `update_${apiStartKey}_updated` || apiKey === `post_${apiStartKey}_created` ? (
                  <Button className="btn-sm" type="button" color="primary">
                    Finish <i className="btn-sm bx bx-loader bx-spin font-size-16 align-middle ms-1"></i>
                  </Button>
                ) : (
                  <Button className="btn-sm" type="submit" color="primary">
                    Finish
                  </Button>
                )}
              </div>
            </Row>
          </ModalFooter>
        </AvForm>
      </Modal>

      <CancelModal
        modal={modals.cancelModal}
        toggle={() => toggleModal("cancelModal")}
        toggleLayout={toggleLayout}
      />

      <DigitalSignature
        modal={modals.leftSignatureModal}
        signature={signatures.leftSignature}
        toggle={() => toggleModal("leftSignatureModal")}
        onSave={(signature) => setSignatures((prevState) => ({ ...prevState, leftSignature: signature }))}
      />

      <DigitalSignature
        modal={modals.rightSignatureModal}
        signature={signatures.rightSignature}
        toggle={() => toggleModal("rightSignatureModal")}
        onSave={(signature) => setSignatures((prevState) => ({ ...prevState, rightSignature: signature }))}
      />
    </>
  )
}

export const CancelModal = (props) => {
  const { modal, toggle, toggleLayout } = props;
  return (
    <Modal isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody className="text-center">
        <p className="mt-3">Some changes are not saved. Do you want to cancel anyway?</p>
      </ModalBody>
      <ModalFooter>
        <Button className="me-1" type="button" color="primary" onClick={() => toggle(false)}>
          No
        </Button>
        <Button type="button" color="secondary" onClick={() => {
          toggle()
          toggleLayout()
        }}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  )
}