import { DELETE_HMS, DELETE_HMS_FAIL, DELETE_HMS_SUCCESS, DELETE_DIAG_TEST_PARAM, DELETE_DIAG_TEST_PARAM_FAIL, DELETE_DIAG_TEST_PARAM_SUCCESS, DOWNLOAD_REPORT_HMS, DOWNLOAD_REPORT_HMS_FAIL, DOWNLOAD_REPORT_HMS_SUCCESS, GET_HMS, GET_HMS_FAIL, GET_HMS_SUCCESS, GET_DIAG_TEST_PARAM, GET_DIAG_TEST_PARAM_FAIL, GET_DIAG_TEST_PARAM_SUCCESS, LIST_HMS, LIST_HMS_FAIL, LIST_HMS_SUCCESS, LIST_DIAG_TEST_PARAM, LIST_DIAG_TEST_PARAM_FAIL, LIST_DIAG_TEST_PARAM_SUCCESS, OPTIONS_HMS, OPTIONS_HMS_FAIL, OPTIONS_HMS_SUCCESS, POST_HMS, POST_HMS_FAIL, POST_HMS_SUCCESS, POST_DIAG_TEST_PARAM, POST_DIAG_TEST_PARAM_FAIL, POST_DIAG_TEST_PARAM_SUCCESS, RESET_HMS, UPDATE_HMS, UPDATE_HMS_FAIL, UPDATE_HMS_SUCCESS, UPDATE_DIAG_TEST_PARAM, UPDATE_DIAG_TEST_PARAM_FAIL, UPDATE_DIAG_TEST_PARAM_SUCCESS, UPLOAD_HMS, UPLOAD_HMS_FAIL, UPLOAD_HMS_SUCCESS, CHAINED_OPTIONS_HMS, CHAINED_OPTIONS_HMS_SUCCESS, CHAINED_OPTIONS_HMS_FAIL, UPDATE_PUBLIC, UPDATE_PUBLIC_SUCCESS, UPDATE_PUBLIC_FAIL, DELETE_PUBLIC, DELETE_PUBLIC_SUCCESS, DELETE_PUBLIC_FAIL, POST_PUBLIC_FAIL, POST_PUBLIC_SUCCESS, POST_PUBLIC, GET_PUBLIC_FAIL, GET_PUBLIC_SUCCESS, GET_PUBLIC, LIST_PUBLIC_FAIL, LIST_PUBLIC_SUCCESS, LIST_PUBLIC, OPTIONS_PUBLIC_FAIL, OPTIONS_PUBLIC_SUCCESS, OPTIONS_PUBLIC } from "./actionTypes"

export const resetHmsState = () => ({
  type: RESET_HMS,
})


export const getHmsOptions = (api_url, key = null, alterPaymentModeChoices = false, modifiedFormSchema = false) => ({
  type: OPTIONS_HMS,
  api_url,
  key,
  alterPaymentModeChoices,
  modifiedFormSchema
})
export const getHmsOptionsSuccess = (response, key, alterPaymentModeChoices, modifiedFormSchema) => ({
  type: OPTIONS_HMS_SUCCESS,
  payload: response,
  key: key,
  alterPaymentModeChoices: alterPaymentModeChoices,
  modifiedFormSchema: modifiedFormSchema
})
export const getHmsOptionsFail = (error, key) => ({
  type: OPTIONS_HMS_FAIL,
  payload: error,
  key: key
})


export const listHms = (api_url, params, key = null) => ({
  type: LIST_HMS,
  api_url,
  params,
  key,
})
export const listHmsSuccess = (response, key) => ({
  type: LIST_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const listHmsFail = (error, key) => ({
  type: LIST_HMS_FAIL,
  payload: error,
  key: key,
})


export const getHms = (api_url, id, key = null) => ({
  type: GET_HMS,
  api_url,
  id,
  key
})
export const getHmsSuccess = (response, key) => ({
  type: GET_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const getHmsFail = (error, key) => ({
  type: GET_HMS_FAIL,
  payload: error,
  key: key,
})


export const postHms = (api_url, payload, key = null, prepend = true) => ({
  type: POST_HMS,
  api_url,
  payload,
  key,
  prepend
})
export const postHmsSuccess = (response, key, prepend) => ({
  type: POST_HMS_SUCCESS,
  payload: response,
  key: key,
  prepend: prepend
})
export const postHmsFail = (error, key) => ({
  type: POST_HMS_FAIL,
  payload: error,
  key: key
})


export const updateHms = (api_url, id, payload, key = null, updateKey = "id") => ({
  type: UPDATE_HMS,
  api_url,
  id,
  payload,
  key,
  updateKey,
})
export const updateHmsSuccess = (response, key, updateKey) => ({
  type: UPDATE_HMS_SUCCESS,
  payload: response,
  key: key,
  updateKey,
})
export const updateHmsFail = (error, key) => ({
  type: UPDATE_HMS_FAIL,
  payload: error,
  key: key,
})


export const deleteHms = (api_url, id, key = null) => ({
  type: DELETE_HMS,
  api_url,
  id,
  key,
})
export const deleteHmsSuccess = (response, key) => ({
  type: DELETE_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const deleteHmsFail = (error, key) => ({
  type: DELETE_HMS_FAIL,
  payload: error,
  key: key,
})


export const reportDownloadHms = (api_url, key = null) => ({
  type: DOWNLOAD_REPORT_HMS,
  api_url,
  key: key,
})
export const reportDownloadHmsSuccess = (response, key) => ({
  type: DOWNLOAD_REPORT_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const reportDownloadHmsFail = (error, key) => ({
  type: DOWNLOAD_REPORT_HMS_FAIL,
  payload: error,
  key: key,
})


export const postUploadHms = (api_url, payload, key = null) => ({
  type: UPLOAD_HMS,
  api_url,
  payload,
  key,
})
export const postUploadHmsSuccess = (response, key) => ({
  type: UPLOAD_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const postUploadHmsFail = (error, key) => ({
  type: UPLOAD_HMS_FAIL,
  payload: error,
  key: key
})

export const listDiagTestParam = (api_url, params, key = null) => ({
  type: LIST_DIAG_TEST_PARAM,
  api_url,
  params,
  key,
})
export const listDiagTestParamSuccess = (response, key) => ({
  type: LIST_DIAG_TEST_PARAM_SUCCESS,
  payload: response,
  key: key,
})
export const listDiagTestParamFail = (error, key) => ({
  type: LIST_DIAG_TEST_PARAM_FAIL,
  payload: error,
  key: key,
})


export const getDiagTestParam = (api_url, id, key = null) => ({
  type: GET_DIAG_TEST_PARAM,
  api_url,
  id,
  key
})
export const getDiagTestParamSuccess = (response, key) => ({
  type: GET_DIAG_TEST_PARAM_SUCCESS,
  payload: response,
  key: key,
})
export const getDiagTestParamFail = (error, key) => ({
  type: GET_DIAG_TEST_PARAM_FAIL,
  payload: error,
  key: key,
})


export const postDiagTestParam = (api_url, payload, key = null) => ({
  type: POST_DIAG_TEST_PARAM,
  api_url,
  payload,
  key,
})
export const postDiagTestParamSuccess = (response, key) => ({
  type: POST_DIAG_TEST_PARAM_SUCCESS,
  payload: response,
  key: key,
})
export const postDiagTestParamFail = (error, key) => ({
  type: POST_DIAG_TEST_PARAM_FAIL,
  payload: error,
  key: key
})


export const updateDiagTestParam = (api_url, id, payload, key = null) => ({
  type: UPDATE_DIAG_TEST_PARAM,
  api_url,
  id,
  payload,
  key,
})
export const updateDiagTestParamSuccess = (response, key) => ({
  type: UPDATE_DIAG_TEST_PARAM_SUCCESS,
  payload: response,
  key: key,
})
export const updateDiagTestParamFail = (error, key) => ({
  type: UPDATE_DIAG_TEST_PARAM_FAIL,
  payload: error,
  key: key,
})


export const deleteDiagTestParam = (api_url, id, key = null) => ({
  type: DELETE_DIAG_TEST_PARAM,
  api_url,
  id,
  key,
})
export const deleteDiagTestParamSuccess = (response, key) => ({
  type: DELETE_DIAG_TEST_PARAM_SUCCESS,
  payload: response,
  key: key,
})
export const deleteDiagTestParamFail = (error, key) => ({
  type: DELETE_DIAG_TEST_PARAM_FAIL,
  payload: error,
  key: key,
})

export const chainedOptionsHms = (api_url, params, key = null) => ({
  type: CHAINED_OPTIONS_HMS,
  api_url,
  params,
  key,
})
export const chainedOptionsHmsSuccess = (response, key) => ({
  type: CHAINED_OPTIONS_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const chainedOptionsHmsFail = (error, key) => ({
  type: CHAINED_OPTIONS_HMS_FAIL,
  payload: error,
  key: key,
})


export const getPublicOptions = (api_url, key = null, alterPaymentModeChoices = false, modifiedFormSchema = false) => ({
  type: OPTIONS_PUBLIC,
  api_url,
  key,
  alterPaymentModeChoices,
  modifiedFormSchema
})
export const getPublicOptionsSuccess = (response, key, alterPaymentModeChoices, modifiedFormSchema) => ({
  type: OPTIONS_PUBLIC_SUCCESS,
  payload: response,
  key: key,
  alterPaymentModeChoices: alterPaymentModeChoices,
  modifiedFormSchema: modifiedFormSchema
})
export const getPublicOptionsFail = (error, key) => ({
  type: OPTIONS_PUBLIC_FAIL,
  payload: error,
  key: key
})


export const listPublic = (api_url, params, key = null) => ({
  type: LIST_PUBLIC,
  api_url,
  params,
  key,
})
export const listPublicSuccess = (response, key) => ({
  type: LIST_PUBLIC_SUCCESS,
  payload: response,
  key: key,
})
export const listPublicFail = (error, key) => ({
  type: LIST_PUBLIC_FAIL,
  payload: error,
  key: key,
})


export const getPublic = (api_url, id, key = null) => ({
  type: GET_PUBLIC,
  api_url,
  id,
  key
})
export const getPublicSuccess = (response, key) => ({
  type: GET_PUBLIC_SUCCESS,
  payload: response,
  key: key,
})
export const getPublicFail = (error, key) => ({
  type: GET_PUBLIC_FAIL,
  payload: error,
  key: key,
})


export const postPublic = (api_url, payload, key = null, prepend = true) => ({
  type: POST_PUBLIC,
  api_url,
  payload,
  key,
  prepend
})
export const postPublicSuccess = (response, key, prepend) => ({
  type: POST_PUBLIC_SUCCESS,
  payload: response,
  key: key,
  prepend: prepend
})
export const postPublicFail = (error, key) => ({
  type: POST_PUBLIC_FAIL,
  payload: error,
  key: key
})


export const updatePublic = (api_url, id, payload, key = null, updateKey = "id") => ({
  type: UPDATE_PUBLIC,
  api_url,
  id,
  payload,
  key,
  updateKey,
})
export const updatePublicSuccess = (response, key, updateKey) => ({
  type: UPDATE_PUBLIC_SUCCESS,
  payload: response,
  key: key,
  updateKey,
})
export const updatePublicFail = (error, key) => ({
  type: UPDATE_PUBLIC_FAIL,
  payload: error,
  key: key,
})


export const deletePublic = (api_url, id, key = null) => ({
  type: DELETE_PUBLIC,
  api_url,
  id,
  key,
})
export const deletePublicSuccess = (response, key) => ({
  type: DELETE_PUBLIC_SUCCESS,
  payload: response,
  key: key,
})
export const deletePublicFail = (error, key) => ({
  type: DELETE_PUBLIC_FAIL,
  payload: error,
  key: key,
})