import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Row, Col, Button, Label, Table } from "reactstrap";
import { getHms, getHmsOptions, listHms, postHms, resetHmsState, updateHms } from "store/hms/actions";
import { AvForm } from "availity-reactstrap-validation";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { AsyncSearch, EAsyncSelect, EAvFieldDiscountPercentage, EAvFieldSelect, ETextEditorInput } from "pages/HMS/common/errored-avfields";
import { ChequeSection, GoBack, Loader, PatientInformation, floatRoundDown, formatPercentage, handleError, handleKeyDown, search } from "../../common/common";
import { ProcedureCRUDModal } from "./modal";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { ReportDownload } from "components/Common/print-report";

const AddTreatment = (props) => {
  const dispatch = useDispatch()
  const { loading, options, hmsObject, formError, apiKey, hmsList, postResponse, hmsSingleObject, actionStatus } = useSelector(state => state.Hms)
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [patientSearch, setPatientSearch] = useState(!props.match.params.treatmentID);
  const [isModifyMode, setIsModifyMode] = useState(false)
  const [patient, setPatient] = useState({})
  const [state, setState] = useState({ procedure: null, performedDoctor: null, procDefOpts: null, discountBy: null })
  const [isMenuOpen, setIsMenuOpen] = useState({ searchPatients: false, performedDoctor: false, discountBy: false })
  const [customError, setCustomError] = useState()
  const [modalProcedure, setModalProcedure] = useState(false)
  const [formOptions, setFormOptions] = useState({ treatment: {}, procedure: {} })
  const [billing, setBilling] = useState({
    subTotal: 0,
    discountPercentage: 0,
    discountAmount: 0,
    cgstPercentage: 0,
    sgstPercentage: 0,
    cgstAmount: 0,
    sgstAmount: 0,
    paymentMode: "",
    grandTotal: 0,
    chequeNumber: null,
    chequeDate: null
  })

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/treatments/?options=formschema, table, search", "treatment_options", true))
    if (props.match.params.treatmentID) {
      dispatch(getHms("/hms/diagnosis/treatments/", props.match.params.treatmentID, "get_treatment"))
    }
    return () => dispatch(resetHmsState())
  }, [dispatch])

  useEffect(() => { if (formError) setCustomError(formError) }, [formError])

  useEffect(() => {
    if (apiKey === "get_treatment") {
      setIsModifyMode(true);
      if (hmsObject?.notes) {
        const contentState = stateFromHTML(hmsObject?.notes);
        const editorState = EditorState.createWithContent(contentState);
        onEditorStateChange(editorState);
      }
      setState({
        ...state,
        procedure: { id: hmsObject?.procedure, name: hmsObject?.procedure_name },
      });
      dispatch(getHms("/hms/billing/billing/", hmsObject?.billing, "get_billing"));
      dispatch(getHms("/hms/staff/doctors/", hmsObject?.performed_doctor, "get_doctor"));
      dispatch(getHms("/hms/patient/patients/", hmsObject.patient, "patient_detail"));
    }

    if (apiKey === "patient_detail") {
      setPatient(hmsObject)
    }
    if (formError && apiKey.startsWith("fail_procedure_post")) {
      let excludedKeys = ["procedure", "performed_doctor"]
      handleError(formError, apiKey, "fail_procedure_post", excludedKeys)
    }
    if (apiKey === "treatment_options") {
      setFormOptions((prevState) => ({ ...prevState, treatment: options }))
      dispatch(getHmsOptions("/hms/diagnosis/procedures/?options=formschema", "procedure_options"))
    }
    if (apiKey === "procedure_options") setFormOptions((prevState) => ({ ...prevState, procedure: options }))
    if (apiKey === "fetch_procedures") setState({ ...state, procDefOpts: hmsList?.results })
    if (apiKey === "fail_get_treatment") {
      dispatch(resetHmsState())
      setPatientSearch(true)
    }
    if (apiKey === "procedure_created") {
      setState({ ...state, procedure: postResponse })
    }
    if (apiKey === "get_doctor") {
      setState({ ...state, performedDoctor: hmsObject })
    }
    if (apiKey === "get_billing") {
      setState({
        ...state,
        procedure: {
          ...state.procedure,
          cost: hmsObject?.sub_total_amount
        },
        discountBy: {
          id: hmsObject?.discount_by,
          full_name: hmsObject?.discount_by_name
        }
      });

      setBilling(prevState => ({
        ...prevState,
        subTotal: hmsObject?.sub_total_amount,
        discountAmount: hmsObject?.discount_amount,
        grandTotal: hmsObject?.sub_total_amount,
        cgstAmount: hmsObject?.cgst,
        sgstAmount: hmsObject?.sgst,
        discountPercentage: hmsObject?.discount_percent,
        cgstPercentage: hmsObject?.cgst_percent,
        sgstPercentage: hmsObject?.sgst_percent,
        paymentMode: hmsObject?.payment_mode,
        chequeDate: hmsObject?.cheque_date,
        chequeNumber: hmsObject?.cheque_number,
      }))
    }
  }, [apiKey])

  const handleSubmit = (e, values) => {
    const htmlContent = stateToHTML(editorState.getCurrentContent());
    values = {
      patient: patient?.id,
      procedure: !isEmpty(state?.procedure) ? state?.procedure?.id : null,
      performed_doctor: state?.performedDoctor ? state?.performedDoctor.id : null,
      billing_post: {
        customer_name: patient?.user_data?.full_name,
        sub_total_amount: billing?.subTotal,
        discount_percent: billing?.discountPercentage,
        discount_by: billing?.discountPercentage > 0 ? state.discountBy?.id : null,
        cgst_percent: billing?.cgstPercentage || 0,
        sgst_percent: billing?.sgstPercentage || 0,
        payment_mode: billing?.paymentMode,
        cheque_number: billing?.paymentMode === "CHEQUE" ? billing?.chequeNumber : null,
        cheque_date: billing?.paymentMode === "CHEQUE" ? billing?.chequeDate : null,
      },
      notes: htmlContent
    }

    if (isModifyMode) {
      dispatch(updateHms("/hms/diagnosis/treatments/", props.match.params.treatmentID, values, "procedure_modified"))
    } else {
      dispatch(postHms("/hms/diagnosis/treatments/", values, "procedure_post"))
    }
  }

  const searchProcedures = (value, callback) => {
    search(value, callback, "/hms/diagnosis/procedures/")
  }

  const searchDoctors = (value, callback) => {
    search(value, callback, "/hms/staff/doctors/", () => setIsMenuOpen((prev) => ({ ...prev, performedDoctor: value.length > 0 })))
  }

  const searchPatients = (value, callback) => {
    search(value, callback, "/hms/patient/patients/", () => setIsMenuOpen((prev) => ({ ...prev, searchPatients: value.length > 0 })))
  }

  const searchDiscountBy = (value, callback) => {
    search(value, callback, "/hms/patient/inpatients/search_hms_users/", () => setIsMenuOpen((prev) => ({ ...prev, discountBy: value.length > 0 })))
  }

  const patientSelect = (patient) => {
    if (!isEmpty(patient)) {
      dispatch(getHms("/hms/patient/patients/", patient?.id, "patient_detail"))
      setPatientSearch(false)
    }
  }

  const onDiscountPercentChange = (e) => {
    let value = e.target.value
    if (!isNaN(value) && value >= 0 && value <= 100) {
      let discountPercentage = value
      setBilling(prevState => ({ ...prevState, discountPercentage }));
    }
  }

  const onCgstPercentageChange = (e) => {
    const cgstPercentage = formatPercentage(e.target.value);
    setBilling(prevState => ({ ...prevState, cgstPercentage }));
  };

  const onSgstPercentageChange = (e) => {
    const sgstPercentage = formatPercentage(e.target.value);
    setBilling(prevState => ({ ...prevState, sgstPercentage }));
  };

  const calculateTotals = () => {
    let subTotal = state?.procedure?.cost || 0;
    subTotal = floatRoundDown(subTotal);
    const discountAmount = floatRoundDown(subTotal * (billing?.discountPercentage / 100));
    const discountedSubTotal = floatRoundDown(subTotal - discountAmount);
    const cgstAmount = floatRoundDown(discountedSubTotal * (billing?.cgstPercentage / 100));
    const sgstAmount = floatRoundDown(discountedSubTotal * (billing?.sgstPercentage / 100));
    const grandTotal = floatRoundDown(discountedSubTotal + cgstAmount + sgstAmount);

    setBilling(prevState => ({ ...prevState, subTotal, discountAmount, grandTotal, cgstAmount, sgstAmount }));
  };

  useEffect(() => {
    calculateTotals();
  }, [state.procedure, billing.discountPercentage, billing.cgstPercentage, billing.sgstPercentage]);

  function toggleProcedure(def = modalProcedure) {
    setModalProcedure(!def);
    setCustomError(null)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Treatment | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          {patientSearch ? (
            <Col xl={8}>
              <Card>
                <CardBody>
                  <AsyncSearch
                    onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, searchPatients: false }))}
                    menuIsOpen={isMenuOpen.searchPatients}
                    loadOptions={searchPatients}
                    onChange={patientSelect}
                    placeholder="Search with patient name, uid or patient ID, mobile number"
                    noOptionsMessage={() => (
                      <p className="text-center my-4" style={{ letterSpacing: "1px" }}>
                        No patient records found?{" "}
                        <Link to={"/hms/patient/register"} className="font-weight-bold">
                          Create a new patient
                        </Link>
                      </p>
                    )}
                    getOptionLabel={(e) => (
                      <div className="custom-option">
                        <div className="user-box">
                          <p className="user-title m-0">{e?.user_data?.full_name}</p>
                          <p className="text-muted mb-0">
                            {e?.uid} {e?.user_data?.email ? `| ${e?.user_data?.email}` : ""} | {e?.user_data?.phone_number}
                          </p>
                        </div>
                      </div>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col xl={(["procedure_post_success", "procedure_modified_success"].includes(actionStatus)) ? 6 : 12}>
              <Card>
                <CardBody>
                  {(["procedure_post_success", "procedure_modified_success"].includes(actionStatus)) ? (
                    <div className="p-2">
                      <div className="text-center">
                        <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                        <div className="p-2 mt-2">
                          <h4>Treatment successfully {isModifyMode ? "modified" : "added"}</h4>
                          <div className="mt-4">
                            <div className="mt-4 d-flex justify-content-center flex-wrap">
                              <Button
                                color="primary"
                                onClick={() => window.location.replace("/hms/patient/treatment/add")}
                                className={window.innerWidth <= 425 ? "btn-sm mb-1" : "mb-1"}
                              >
                                Add&nbsp;Another
                              </Button>
                              <Link
                                to={"/hms/patient/treatment"}
                                className={window.innerWidth <= 425 ? "btn-sm ms-1 btn btn-primary mb-1" : "ms-1 btn btn-primary mb-1"}
                              >
                                Treatments
                              </Link>
                              <Button
                                className={window.innerWidth <= 425 ? "btn-sm ms-1 mb-1 me-1" : " ms-1 mb-1 me-1"}
                                color="primary"
                                onClick={() => window.location.replace(`/hms/patient/treatment/add/${hmsSingleObject.id}`)}
                              >
                                Modify
                              </Button>
                              <ReportDownload
                                url={`/hms/diagnosis/treatments/${hmsSingleObject?.id}/invoice_receipt/`}
                                label="Print Invoice"
                              />
                              <Link
                                to={`/hms/patient/treatment/${hmsSingleObject.id}/detail`}
                                className={window.innerWidth <= 425 ? "btn-sm btn btn-primary mb-1" : "btn btn-primary mb-1"}
                              >
                                Explore
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (loading && apiKey === "get_patient_detail" || apiKey === "get_get_treatment" ? (
                    <Loader />
                  ) : (
                    <AvForm onValidSubmit={handleSubmit}>
                      <Row>
                        <Col md={12} lg={6}>
                          <Row>
                            <Col>
                              <div className="d-flex align-items-center">
                                <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                  <Label className={formError?.procedure ? "text-danger" : ""}>
                                    Select Procedure *
                                  </Label>
                                  <div className="d-flex align-items-center mb-3">
                                    <div className="w-100">
                                      <EAsyncSelect
                                        cacheOptions
                                        loadOptions={searchProcedures}
                                        formError={formError?.procedure}
                                        onChange={(procedure) => setState({ ...state, procedure: procedure })}
                                        value={[state.procedure]}
                                        placeholder="Search with procedure name..."
                                        getOptionLabel={e => `${e.name} | Cost-${e.cost}`}
                                        getOptionValue={e => e.id}
                                        defaultOptions={state.procDefOpts}
                                        onFocus={() => dispatch(listHms("/hms/diagnosis/procedures/", null, "fetch_procedures"))}
                                      />
                                    </div>
                                    <div
                                      onClick={() => toggleProcedure(false)}
                                      title="Add procedure"
                                      style={{ cursor: "pointer" }}
                                      className={`ms-2 ${formError?.procedure ? "mb-2" : ""}`}
                                    >
                                      <i className="bx bx-plus-medical text-success" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div className="mb-3 ajax-select select2-container">
                                <EAsyncSelect
                                  onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, performedDoctor: false }))}
                                  menuIsOpen={isMenuOpen.performedDoctor}
                                  options={formOptions?.treatment?.form_schema?.performed_doctor}
                                  value={[state.performedDoctor]}
                                  loadOptions={searchDoctors}
                                  formError={formError?.performed_doctor}
                                  onChange={(doctor) => setState({ ...state, performedDoctor: doctor })}
                                  placeholder="Type to search doctors..."
                                  getOptionLabel={e => `${e.user_data.full_name} - ${e.specialization}`}
                                  getOptionValue={e => e.id}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ETextEditorInput
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                                formError={formError?.notes}
                                options={{ label: "Notes" }}
                                height="275px"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="table-responsive">
                                <Table className="table mb-0 mt-2 table table-sm">
                                  <tbody>
                                    <tr>
                                      <th>Sub total : </th>
                                      <td><b>{billing?.subTotal}</b></td>
                                    </tr>
                                    <tr>
                                      <td className="w-50">
                                        <div className="d-flex align-items-baseline">
                                          <span className={formError?.discount_percent ? "text-danger" : ""}>Discount percent :</span>
                                          <EAvFieldDiscountPercentage
                                            style={{ width: "120px" }}
                                            mb={false}
                                            className="form-control-sm mb-0 ms-2"
                                            bsSize="sm"
                                            value={billing?.discountPercentage}
                                            disabled={billing?.subTotal == 0}
                                            formError={formError?.discount_percent}
                                            options={{ ...formOptions?.treatment?.form_schema?.discount_percent, label: "" }}
                                            onChange={onDiscountPercentChange}
                                            onKeyDown={handleKeyDown}
                                          />
                                        </div>
                                      </td>
                                      <td className="w-50">
                                        <span>Discount amount : </span>
                                        <span><b>- {billing?.discountAmount}</b></span>
                                      </td>
                                    </tr>
                                    {billing?.discountPercentage > 0 ?
                                      <tr>
                                        <td>
                                          <EAsyncSelect
                                            options={formOptions?.treatment?.form_schema?.discount_by}
                                            onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, discountBy: false }))}
                                            menuIsOpen={isMenuOpen.discountBy}
                                            field="discount_by"
                                            value={state.discountBy}
                                            loadOptions={searchDiscountBy}
                                            formError={formError?.discount_by}
                                            onChange={(value) => setState({ ...state, discountBy: value })}
                                            placeholder="Type to search users..."
                                            getOptionLabel={e => e.full_name}
                                            getOptionValue={e => e.id}
                                            className="form-control-sm ms-n2"
                                            additionalStyles={{
                                              control: (provided, state) => ({
                                                ...provided,
                                                minHeight: "30px",
                                              }),
                                            }}
                                          />
                                        </td>
                                        <td></td>
                                      </tr>
                                      : null}
                                    <tr>
                                      <td className="w-50">
                                        <span>CGST percent : </span>
                                        <input
                                          type="number"
                                          name="discount"
                                          className="form-control-sm mb-0 ms-2"
                                          value={billing?.cgstPercentage}
                                          disabled={billing?.subTotal == 0}
                                          onChange={onCgstPercentageChange}
                                          maxLength="2"
                                          style={{ width: "120px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                          onKeyDown={handleKeyDown}
                                        />
                                      </td>
                                      <td className="w-50">
                                        <span>CGST : </span>
                                        <span><b>{billing?.cgstAmount}</b></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="w-50">
                                        <span>SGST percent :</span>
                                        <input
                                          type="number"
                                          name="discount"
                                          className="form-control-sm mb-0 ms-2"
                                          value={billing?.sgstPercentage}
                                          disabled={billing?.subTotal == 0}
                                          onChange={onSgstPercentageChange}
                                          maxLength="2"
                                          style={{ width: "120px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                          onKeyDown={handleKeyDown}
                                        />
                                      </td>
                                      <td className="w-50">
                                        <span>SGST : </span>
                                        <span><b>{billing?.sgstAmount}</b></span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Grand Total : </th>
                                      <td><b>{billing?.grandTotal}</b></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <EAvFieldSelect
                                          mb={false}
                                          field="payment_mode"
                                          value={billing.paymentMode}
                                          onChange={(e) => setBilling(prevState => ({ ...prevState, paymentMode: e.target.value }))}
                                          isError={formError?.payment_mode}
                                          bsSize="sm"
                                          options={{ ...formOptions?.treatment?.form_schema?.payment_mode, required: true }}
                                          choices={<>
                                            <option value={""}>{"-Select option-"}</option>
                                            {formOptions?.treatment?.form_schema?.payment_mode?.choices.map((choice, cidx) =>
                                              <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                            )}
                                          </>}
                                        />
                                      </td>
                                      <td></td>
                                    </tr>
                                    <ChequeSection
                                      billing={billing}
                                      setBilling={setBilling}
                                      formError={formError}
                                      options={formOptions?.treatment?.form_schema}
                                      mb={true}
                                    />
                                  </tbody>
                                </Table>
                              </div>
                            </Col>
                          </Row>
                          <Row className="justify-content-end mt-3">
                            <Col sm={9}>
                              <div>
                                {apiKey === "post_procedure_post" || apiKey === "update_procedure_modified" ? (
                                  <>
                                    <Button color="primary" className="float-end" disabled>
                                      Submit
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                                    </Button>
                                    <GoBack disabled />
                                  </>
                                ) : (
                                  <>
                                    <Button type="submit" color="primary" className="float-end" disabled={!patient?.id}>
                                      Submit
                                    </Button>
                                    <GoBack historyProp={props.history} />
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={12} lg={6}>
                          <h5 className="mt-2">
                            Patient details
                          </h5>
                          {patient?.user_data ? (
                            <PatientInformation
                              data={patient}
                              instance={patient}
                              includedFields={[
                                "patient_name",
                                "patient_id",
                                "gender",
                                "marital_status",
                                "date_of_birth",
                                "age",
                                "mobile",
                                "email",
                                "blood_group",
                              ]}
                            />
                          ) : (
                            <p className="text-center text-danger">
                              Patient information not existed. {props.match.params.treatmentID && "It might be removed."}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </AvForm>
                  ))}
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>

      <ProcedureCRUDModal
        modal={modalProcedure}
        toggle={toggleProcedure}
        rudFlag={1}
        options={formOptions?.procedure?.form_schema}
        customError={customError}
      />
    </React.Fragment>
  )
}

export default AddTreatment