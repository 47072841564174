import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap"
import { useEffect, useRef, useState } from "react";
import { postHms, updateHms, chainedOptionsHms } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr"
import { AvForm } from "availity-reactstrap-validation";
import { EAsyncSelect, EAvFieldInput, EAvFieldNumber } from "pages/HMS/common/errored-avfields";
import { search } from "pages/HMS/common/common";

export const GroupCRUModal = (props) => {
  const { modal, toggle, rudFlag, data = null, apiStartKey } = props
  const formRef = useRef()
  const dispatch = useDispatch()
  const { loading, apiKey, postResponse, updateResponse, formError, options, optionsChainedHms } = useSelector(state => state.Hms)
  const [selectedTests, setSelectedTests] = useState(null)
  const [defaultTests, setDefaultTests] = useState(null)

  useEffect(() => {
    setSelectedTests(data?.tests)
  }, [data?.tests])

  useEffect(() => {
    if (apiKey === `${apiStartKey}_created`) {
      toggle()
      toastr.success(`${postResponse?.name} created successfully`)
      formRef.current?.reset()
      setSelectedTests(null)
      setDefaultTests(null)
    }
    if (apiKey === `${apiStartKey}_updated`) {
      toggle()
      toastr.success(`${updateResponse?.name} updated successfully`)
    }
    if (apiKey === "default_tests") {
      setDefaultTests(optionsChainedHms?.results)
    }
  }, [apiKey])

  const handleValidSubmit = (e, values) => {
    values.tests = selectedTests?.map(test => test.id) || []
    if (rudFlag === 0) {
      dispatch(updateHms("/hms/diagnosis/test-groups/", data.id, values, `${apiStartKey}_updated`))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/diagnosis/test-groups/", values, `${apiStartKey}_created`))
    }
  }

  const searchTests = (value, callback) => {
    search(value, callback, "/hms/diagnosis/tests/")
  }

  return (
    <Modal isOpen={modal} toggle={toggle} backdrop="static" size={"lg"}>
      <ModalHeader toggle={toggle} tag="h4">
        {rudFlag === 0 ? `Modify Test Group` : rudFlag === 1 ? `Add Test Group` : ""}
      </ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
          <Row>
            <Col md={"8"}>
              <EAvFieldInput
                field={"name"}
                type="text"
                value={data.name}
                isError={formError?.name}
                options={options?.form_schema?.name}
              />
            </Col>
            <Col>
              <EAvFieldNumber
                field="fee"
                value={data.fee}
                isError={formError?.fee}
                options={{ ...options?.form_schema?.fee, required: true }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3 ajax-select select2-container text-start">
                <EAsyncSelect
                  isMulti
                  cacheOptions
                  options={{ label: "Tests", required: true }}
                  value={selectedTests}
                  loadOptions={searchTests}
                  formError={formError?.tests}
                  onChange={(tests) => setSelectedTests(tests)}
                  onFocus={() => dispatch(chainedOptionsHms("/hms/diagnosis/tests/", null, "default_tests"))}
                  defaultOptions={defaultTests}
                  placeholder="Type to search tests..."
                  getOptionLabel={e => `${e?.name}  ${e.short_name ? `(${e.short_name})` : ''} - Fee ${e.fee}`}
                  getOptionValue={e => e.id}
                  closeMenuOnSelect={false}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                {loading && apiKey === `update_${apiStartKey}_updated` || apiKey === `post_${apiStartKey}_created` ? (
                  <button disabled className="btn btn-primary btn-sm save-user">
                    SAVE{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary btn-sm save-user">
                    SAVE
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}
