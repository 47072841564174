import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row } from "reactstrap"
import { getHms, resetHmsState } from "store/actions";
import { TableChip } from "components/Common/common";
import { formatDateString } from "../common/common";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const MedicineDetail = (props) => {
  const dispatch = useDispatch();
  const { loading, hmsObject, apiKey, error } = useSelector(state => state.Hms);
  const [medicine, setMedicine] = useState();

  useEffect(() => {
    dispatch(getHms("/hms/pharmacy/inventory/", props.match.params.medicineID, "fetch-medicine"))
    return () => dispatch(resetHmsState())
  }, [dispatch]);

  useEffect(() => {
    if (apiKey === "fetch-medicine") setMedicine(hmsObject)
  }, [hmsObject]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Medicine Details | Yanthura</title>
        </MetaTags>
        {loading ? (
          <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Fetching medicine details...</p>
        ) : (
          <Card>
            <CardBody>
              {error ? (
                <p className="text-center text-danger">{error.detail}</p>
              ) : (
                <Row>
                  <Col>
                    <div className="mt-2 mb-0 d-flex justify-content-between">
                      <h5 className="mt-2">Medicine Details</h5>
                      <div className="d-flex justify-content-end">
                        <Link title="Modify" className="text-muted me-2" to={`/hms/pharmacy/medicine/medstock/${props.match.params.medicineID}`}>
                          <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                        </Link>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <TableChip label="Medicine name" value={medicine?.medicine_data?.name} />
                      <TableChip label="mg / ml" value={medicine?.medicine_data?.dosage} />
                    </div>
                    <TableChip label="Formula" value={medicine?.medicine_data?.formula} />
                    <div className="d-flex justify-content-between">
                      <TableChip label="Stock quantity" value={medicine?.current_stock} />
                      <TableChip label="Manufacturer" value={medicine?.medicine_data?.manufacturer} />
                    </div>
                    <div className="d-flex justify-content-between">
                      <TableChip label="Batch number" value={medicine?.batch_number} />
                      <TableChip label="Expiry date" value={formatDateString(medicine?.expiry_date)} />
                    </div>
                    <TableChip label="Quantity" value={medicine?.quantity} />
                    <div className="d-flex justify-content-between">
                      <TableChip label="CGST (%)" value={medicine?.cgst_percent} />
                      <TableChip label="CGST" value={medicine?.cgst} />
                      <TableChip label="SGST (%)" value={medicine?.sgst_percent} />
                      <TableChip label="SGST" value={medicine?.sgst} />
                    </div>
                    <div className="d-flex justify-content-between">
                      <TableChip label="MRP" value={medicine?.mrp} />
                      <TableChip label="Price" value={medicine?.price} />
                      <TableChip label="Total price" value={medicine?.total_price} />
                    </div>
                  </Col>
                  <Col xl="1"></Col>
                  <Col>
                    <h5 className="mt-2">Supplier Information</h5>
                    <hr />
                    {medicine?.supplier_data?.id ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Name" value={medicine?.supplier_data?.name} />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Contact person" value={medicine?.supplier_data?.contact_person} />
                          <TableChip label="Contact pobile" value={medicine?.supplier_data?.mobile} />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Contact person 2" value={medicine?.supplier_data?.contact_person_2} />
                          <TableChip label="Contact mobile 2" value={medicine?.supplier_data?.alternate_mobile} />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Contact mail" value={medicine?.supplier_data?.email} />
                          <TableChip label="Website" value={medicine?.supplier_data?.website} />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="GST number" value={medicine?.supplier_data?.gst_number} />
                          <TableChip label="Customer type" value={medicine?.supplier_data?.customer_type} />
                        </div>
                        <TableChip label="Notes" value={medicine?.supplier_data?.notes} />
                        <h5 className="mt-2">Address</h5>
                        <hr />
                        <TableChip label="Line 1" value={medicine?.supplier_data?.address?.line1} />
                        <TableChip label="Line 2" value={medicine?.supplier_data?.address?.line2} />
                        <div className="d-flex justify-content-between">
                          <TableChip label="City" value={medicine?.supplier_data?.address?.city} />
                          <TableChip label="District" value={medicine?.supplier_data?.address?.district} />
                          <TableChip label="State" value={medicine?.supplier_data?.address?.state} />
                          <TableChip label="Pincode/Zipcode" value={medicine?.supplier_data?.address?.pincode} />
                        </div>
                      </>
                    ) : (
                      <p className="text-center text-muted">Supplier information not available or deleted</p>
                    )}
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        )}
      </div>
    </React.Fragment >
  );
};

export default MedicineDetail;
