import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import toastr from "toastr"
import { isMobileView } from './common';

function PreviewFile({ modal, toggle }) {
  const [fileData, setFileData] = useState({ base64String: null, mimePrefix: null, filePath: null, fileName: null, blob: null });
  const { loading, apiKey, postResponse } = useSelector(state => state.Hms);
  const isMobile = isMobileView()

  useEffect(() => {
    if (apiKey === "download_file") {
      const [fileName, filePath] = Object.entries(postResponse.signed_urls)[0];
      const fileExtension = fileName.split('.').pop().toLowerCase();
      const mimePrefix = getMimePrefix(fileExtension);
      setFileData((prevStates) => ({ ...prevStates, mimePrefix, filePath, fileName }));

      fetch(filePath)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to download file');
          }
          return response.blob();
        })
        .then(blob => {
          if (mimePrefix && !isMobile) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64String = reader.result.split(',')[1];
              setFileData((prevStates) => ({ ...prevStates, base64String, blob }));
              toggle();
            };
            reader.readAsDataURL(blob);
          } else {
            downloadFile(blob, fileName)
            toastr.success("File downloaded successfully");
          }
        })
        .catch(error => {
          toastr.error("Failed to download file");
        });
    }
  }, [apiKey]);

  function downloadFile(blob, fileName) {
    if (blob && fileName) {
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName || 'download';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);
    }
  };

  function getMimePrefix(fileExtension) {
    if (["pdf"].includes(fileExtension)) {
      return "application/pdf";
    } else if (["jpg", "jpeg", "png", "bmp", "webp", "tiff"].includes(fileExtension)) {
      return "image/png";
    } else {
      return null;
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} scrollable={true} size={"xl"}>
      <ModalBody className='p-0' style={{ overflow: "hidden" }}>
        <iframe
          src={`data:${fileData?.mimePrefix};base64,${fileData?.base64String}`}
          style={{ border: 'none', width: '100%', height: '75vh' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="primary"
          disabled={loading}
          onClick={() => downloadFile(fileData?.blob, fileData?.fileName)}
        >
          Download
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PreviewFile