import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Table, Label } from "reactstrap"
import { getHms, getHmsOptions, resetHmsState, updateHms } from "store/actions";
import { TableChip, formatDateTime } from "components/Common/common";
import { ReportDownload } from "components/Common/print-report";

const OrderDetails = (props) => {
  const dispatch = useDispatch();
  const { options, hmsObject, apiKey, error, updateResponse } = useSelector(state => state.Hms);
  const [order, setOrder] = useState();

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy/medicine_orders/?options=formschema"))
    dispatch(getHms(`/hms/pharmacy/medicine_orders/${props.match.params.orderID}/?detail=true`, null, "fetch-order"))
    return () => dispatch(resetHmsState())
  }, [dispatch]);

  useEffect(() => {
    if (apiKey === "fetch-order") setOrder(hmsObject)
    if (apiKey === "status_change") setOrder({ ...order, status: updateResponse.status })
  }, [apiKey]);

  const onStatusChange = (evt) => {
    dispatch(updateHms("/hms/pharmacy/medicine_orders/", order.uid, { status: evt.target.value }, "status_change"))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Details | Yanthura</title>
        </MetaTags>
        {apiKey === "get_fetch-order" ? (
          <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Fetching Order...</p>
        ) :
          <Card>
            <CardBody>
              {error ? (
                <p className="text-center text-danger">{error.detail}</p>
              ) : (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between">
                      <h5 className="mt-2">Order Details</h5>
                      <div className="mt-2">
                        <ReportDownload
                          url={`/hms/pharmacy/medicine_orders/${props.match.params.orderID}/report_order/`}
                          label="Print report"
                          className="btn-sm"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <TableChip label="ID" value={order?.uid} />
                      <div className="d-flex flex-column">
                        <Label>Status</Label>
                        <select
                          value={order?.status ? order?.status : ""}
                          onChange={onStatusChange}
                          style={{
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            padding: '0',
                          }}
                          className="text-end me-3 text-muted mb-2"
                        >
                          {options?.form_schema?.status?.choices.map((choice, cidx) =>
                            <option value={choice.value} key={cidx}>{choice.display_name}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <TableChip label="Ordered date" value={formatDateTime(order?.created_at)} />
                      <TableChip label="Ordered medicines" value={order?.medicines_count} defaultIfNull={0} />
                    </div>
                    <h5 className="mt-2">Supplier Information</h5>
                    <hr />
                    {order?.supplier?.id ? (
                      <>
                        <TableChip label="Name" value={order?.supplier?.name} />
                        <TableChip label="Supplier ID" value={order?.supplier?.uid} />
                        <TableChip label="Contact mail" value={order?.supplier?.email} />
                        <div className="d-flex justify-content-between">
                          <TableChip label="Contact person" value={order?.supplier?.contact_person} />
                          <TableChip label="Contact mobile" value={order?.supplier?.mobile} />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Contact person 2" value={order?.supplier?.contact_person_2} />
                          <TableChip label="Contact mobile 2" value={order?.supplier?.alternate_mobile} />
                        </div>
                        <TableChip label="Website" value={order?.supplier?.website} />
                        <div className="d-flex justify-content-between">
                          <TableChip label="GST number" value={order?.supplier?.gst_number} />
                          <TableChip label="Customer type" value={order?.supplier?.customer_type} />
                        </div>
                        <TableChip label="Notes" value={order?.supplier?.notes} />
                        <h5 className="mt-2">Supplier Address</h5>
                        <hr />
                        <TableChip label="Line 1" value={order?.supplier?.address?.line1} />
                        <TableChip label="Line 2" value={order?.supplier?.address?.line2} />
                        <div className="d-flex justify-content-between">
                          <TableChip label="City" value={order?.supplier?.address?.city} />
                          <TableChip label="District" value={order?.supplier?.address?.district} />
                          <TableChip label="State" value={order?.supplier?.address?.state} />
                          <TableChip label="Pincode/Zipcode" value={order?.supplier?.address?.pincode} />
                        </div>
                      </>
                    ) : (
                      <p className="text-center text-muted">Supplier information not available or deleted</p>
                    )}
                  </Col>
                  <Col xl="1"></Col>
                  <Col>
                    <h5 className="mt-2">Ordered Medicines</h5>
                    <hr />
                    {order?.ordered_medicines ? (
                      <div className="table-responsive scrollbar-thin" style={{ maxHeight: "74vh" }}>
                        <Table>
                          <thead className="thead-light text-capitalize">
                            <tr>
                              <th>Medicine</th>
                              <th>Mg</th>
                              <th>Formula</th>
                              <th>Ordered Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order?.ordered_medicines?.map(item =>
                              <tr key={item?.id}>
                                <td>{item?.medicine?.name}</td>
                                <td>{item?.medicine?.dosage || "---"}</td>
                                <td>{item?.medicine?.formula || "---"}</td>
                                <td>{item?.quantity}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <p className="text-center text-muted">Medicines not available for this order</p>
                    )}
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        }
      </div>
    </React.Fragment >
  );
};

export default OrderDetails;
