import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import { getHmsOptions, postHms, resetHmsState } from "store/actions"
import toastr from "toastr"
import { Button, Col, Container, Row, Table, Card, CardBody, Label, CardTitle, CardText, Input, FormFeedback, Collapse } from "reactstrap"
import { isEmpty } from "lodash"
import { EAsyncSelect } from "../common/errored-avfields"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { SupplierCRUDModal } from "./pharmacy-pharm-crud-modals"
import { MedicineCRUDModal } from "./pharmacy-pharm-crud-modals"
import { BlankModal } from "./pharmacy-pharm-crud-modals"
import classnames from "classnames"
import { ReportDownload } from "components/Common/print-report"
import { search } from "../common/common"

const PlaceOrder = () => {
  const dispatch = useDispatch()
  const { loading, options, apiKey, error, formError, hmsSingleObject } = useSelector((state) => state.Hms)
  const [medicines, setMedicines] = useState([]);
  const [formData, setFormData] = useState({});
  const [createdOrders, setCreatedOrders] = useState();
  const [modalSupplier, setModalSupplier] = useState(false)
  const [modalMedicine, setModalMedicine] = useState(false)
  const [blankModal, setBlankModal] = useState(false)
  const [previewModal, setPreviewModal] = useState(false)
  const [customError, setCustomError] = useState(null)
  const [supplierOptions, setSupplierOptions] = useState(null)
  const [medicineOptions, setMedicineOptions] = useState(null)
  const [cachedSuppliers, setCachedSuppliers] = useState([]);
  const [removeMedicineId, setRemoveMedicineId] = useState()
  const [previewAccordionTabState, setPreviewAccordionTabState] = useState({})
  const [groupedMedicines, setGroupedMedicines] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState({ medicine: false, supplier: false })

  useEffect(() => {
    if (apiKey === "place-order") {
      setCreatedOrders(hmsSingleObject)
      setMedicines([])
      setFormData({})
      togglePreviewModal()
    }
    if (apiKey === "supplier_place_order_created") {
      setFormData(prevState => ({ ...prevState, supplier: hmsSingleObject }))
    }
    if (apiKey === "medicine-created") {
      toggleMedicine()
      toastr.success("Medicine created successfully")
      setFormData(prevState => ({ ...prevState, medicine: hmsSingleObject }))
    }
  }, [apiKey])

  useEffect(() => {
    if (apiKey === "supplier-options") setSupplierOptions(options)
    if (apiKey === "medicine-options") setMedicineOptions(options)
  }, [options])

  useEffect(() => {
    if (formError) {
      setCustomError(formError)
    }
  }, [formError])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy/suppliers/?options=formschema", "supplier-options"))
    dispatch(getHmsOptions("/hms/pharmacy/medicines/?options=formschema", "medicine-options"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  const placeOrder = () => {
    if (medicines.length > 0) {
      dispatch(postHms("/hms/pharmacy/medicine_orders/place_order/", {
        medicines: medicines.map(item => ({
          medicine: item.medicine.id,
          quantity: item.quantity,
          supplier: item.supplier.id
        }))
      }, "place-order"))
    }
  }

  const searchSupplier = (value, callback) => {
    search(value, callback, "/hms/pharmacy/", () => setIsMenuOpen((prev) => ({ ...prev, supplier: value.length > 0 })), "action=suppliers&query")
  }

  const searchMedicine = (value, callback) => {
    search(value, callback, "/hms/pharmacy/medicines/", () => setIsMenuOpen((prev) => ({ ...prev, medicine: value.length > 0 })))
  }

  function isUniqueTogether(medicine, supplier) {
    const uniqueCombinations = new Set();
    uniqueCombinations.add(`${medicine?.id}-${supplier?.id}`)
    for (const item of medicines) {
      const combination = `${item?.medicine?.id}-${item?.supplier?.id}`;
      if (uniqueCombinations.has(combination)) {
        return true;
      }
      uniqueCombinations.add(combination);
    }
    return false;
  }

  const handleCreate = () => {
    if (!formData?.medicine) {
      setCustomError({ medicine: ["This field is required"] })
      return
    }
    if (!formData?.quantity) {
      setCustomError({ quantity: ["This field is required"] })
      return
    }
    if (formData?.quantity < 1) {
      setCustomError({ quantity: ["Quantity should be 1 or more"] })
      return
    }
    if (!formData?.supplier) {
      setCustomError({ supplier: ["This field is required"] })
      return
    }
    if (isUniqueTogether(formData?.medicine, formData?.supplier) && !formData?.id) {
      setCustomError({ supplier: ["Medicine with this supplier is already added"] })
      return
    }

    let formDataId = formData?.id;
    if (formDataId) {
      setMedicines(prevData => prevData.map(item => (item.id === formDataId ? { ...item, ...formData } : item)))
    } else {
      setMedicines([{ ...formData, id: Date.now() }, ...medicines])
    }
    setFormData({})
    setCustomError(null)
  }

  const handleEdit = (id) => {
    if (!isEmpty(medicines)) {
      setFormData(medicines.filter(item => item.id === id)[0])
    }
  }

  function toggleSupplier(def = modalSupplier) {
    setModalSupplier(!def)
    setCustomError(null)
  }

  function toggleMedicine(def = modalMedicine) {
    setModalMedicine(!def)
    setCustomError(null)
  }

  function toggleBlankModal(def = blankModal) {
    setBlankModal(!def)
  }

  function togglePreviewModal(def = previewModal) {
    if (medicines.length > 0) {
      setPreviewModal(!def)
    }
  }

  const frameMedicineAccordian = () => {
    let grpdMeds = {};
    medicines.map((item, idx) => {
      const supIds = Object.keys(grpdMeds)
      const supId = item?.supplier?.id.toString();
      if (!supIds.includes(supId)) grpdMeds[supId] = [];
      grpdMeds[supId].push(item);
      setPreviewAccordionTabState(prevState => ({ ...prevState, [supId]: idx === 0 }))
    })
    setGroupedMedicines(grpdMeds)
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Order Medicines | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12">
              {error ? (
                <p className="text-center text-danger">{error?.detail}</p>
              ) : (
                <>
                  {!createdOrders ? (
                    <Card>
                      <CardBody>
                        <Row className="mb-2 d-flex justify-content-between">
                          <Col>
                            <div className="d-flex justify-content-end">
                              <Button color="primary" className="btn-sm" id="sa-success" onClick={() => {
                                frameMedicineAccordian();
                                togglePreviewModal();
                              }}>Preview & Submit</Button>
                            </div>
                          </Col>
                        </Row>
                        <Row className="container">
                          <Col className="mb-2">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                <Label className={formError?.medicine ? "text-danger" : ''}>
                                  Medicine
                                </Label>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <div style={{ width: "100%" }}>
                                    <EAsyncSelect
                                      onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, medicine: false }))}
                                      menuIsOpen={isMenuOpen.medicine}
                                      value={[formData?.medicine]}
                                      loadOptions={searchMedicine}
                                      formError={customError?.medicine}
                                      onChange={(value) => setFormData(prevState => ({ ...prevState, medicine: value }))}
                                      placeholder="Type to search medicines..."
                                      getOptionLabel={e => `${e?.name} ${e?.dosage ? `| ${e?.dosage}mg` : ''}`}
                                      getOptionValue={e => e?.id}
                                    />
                                  </div>
                                  <div onClick={() => toggleMedicine(false)} title="Add medicine" style={{ cursor: "pointer" }} className="ms-2">
                                    <i className="bx bx-plus-medical text-success" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <Label for="id_quantity">Quantity</Label>
                            <Input
                              id="id_quantity"
                              name="quantity"
                              type="number"
                              className="form-control"
                              value={formData?.quantity ? formData?.quantity : ""}
                              min={1}
                              required={options?.form_schema?.quantity?.required}
                              onChange={(e) => setFormData(prevState => ({ ...prevState, quantity: e.target.value }))}
                              invalid={customError?.quantity ? true : false}
                            />
                            {customError?.quantity && <FormFeedback>{customError?.quantity[0]}</FormFeedback>}
                          </Col>
                          <Col>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                <Label className={formError?.supplier ? "text-danger" : ''}>
                                  Supplier
                                </Label>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <div style={{ width: "100%" }}>
                                    <EAsyncSelect
                                      onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, supplier: false }))}
                                      menuIsOpen={isMenuOpen.supplier}
                                      value={[formData?.supplier]}
                                      loadOptions={searchSupplier}
                                      formError={customError?.supplier}
                                      onChange={(value) => {
                                        setFormData(prevState => ({ ...prevState, supplier: value }))
                                        setCachedSuppliers((prevState) => [value, ...prevState.map(item => item.id !== value.id)]);
                                      }}
                                      placeholder="Type to search suppliers..."
                                      getOptionLabel={e => e?.name}
                                      getOptionValue={e => e?.id}
                                      defaultValue={cachedSuppliers}
                                    />
                                  </div>
                                  <div onClick={() => toggleSupplier(false)} title="Add supplier" style={{ cursor: "pointer" }} className="ms-2">
                                    <i className="bx bx-plus-medical text-success" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col className="d-flex align-items-end mb-3">
                            <Button color="light" className="btn btn-sm ms-2 waves-effect" onClick={handleCreate}>
                              {formData?.id ? "UPDATE" : "ADD"}
                            </Button>
                            <Button color="light" className="btn btn-sm ms-2 waves-effect" onClick={() => setFormData({})}>CLEAR</Button>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <Table>
                                <thead className="thead-light text-capitalize">
                                  <tr>
                                    <th>Drug Name</th>
                                    <th>mg</th>
                                    <th>Formula</th>
                                    <th>Supplier</th>
                                    <th>Quantity</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!isEmpty(medicines) ? (
                                    medicines?.map(item => (
                                      <tr key={item?.id}>
                                        <td>{item?.medicine?.name}</td>
                                        <td>{item?.medicine?.dosage || "---"}</td>
                                        <td>
                                          <Link title={item?.medicine?.formula} to="#" className="text-muted">
                                            {item?.medicine?.formula?.substring(0, 16) || "---"}
                                          </Link>
                                        </td>
                                        <td>{item?.supplier?.name}</td>
                                        <td>{item?.quantity}</td>
                                        <td>
                                          <div className="d-flex gap-3">
                                            <Link
                                              to="#"
                                              className="text-muted"
                                              title="Modify"
                                              onClick={() => handleEdit(item?.id)}
                                            >
                                              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                            </Link>
                                            <Link
                                              to="#"
                                              className="text-danger"
                                              title="Remove"
                                              onClick={() => {
                                                setRemoveMedicineId(item?.id);
                                                toggleBlankModal();
                                              }}
                                            >
                                              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                            </Link>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="7" className="react-bs-table-no-data" style={{ padding: "3px" }}>
                                        <p className="text-center mt-2">Search and add medicines to order</p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ) : (
                    <Card body className="text-center">
                      <CardTitle className="mt-0">{createdOrders?.detail}</CardTitle>
                      <CardText>{createdOrders.data.length} order(s) created for {createdOrders.data.length} supplier(s)</CardText>
                      <div className="d-flex justify-content-center mb-3">
                        <Link
                          to="#"
                          className="btn btn-sm btn-primary me-2"
                          onClick={() => setCreatedOrders(null)}
                        >
                          Create Order
                        </Link>
                        <Link
                          to="/hms/pharmacy/order-medicine"
                          className="btn btn-sm btn-primary"
                        >
                          Orders
                        </Link>
                      </div>
                      <div className="table-responsive">
                        <Table>
                          <thead className="thead-light text-capitalize">
                            <tr>
                              <th>Order ID</th>
                              <th>Supplier</th>
                              <th>Medicines count</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {createdOrders?.data?.map(item => (
                              <tr key={item?.id}>
                                <td>
                                  <Link to={`/hms/pharmacy/orders/details/${item?.uid}`}>
                                    {item?.uid}
                                  </Link>
                                </td>
                                <td>{item?.supplier}</td>
                                <td>{item?.medicines.length}</td>
                                <td>
                                  <ReportDownload
                                    url={`/hms/pharmacy/medicine_orders/${item?.uid}/report_order/`}
                                    label="Print report"
                                    type="text"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Card>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <SupplierCRUDModal
        modal={modalSupplier}
        toggle={toggleSupplier}
        rudFlag={1}
        options={supplierOptions?.form_schema}
        apiStartKey="supplier_place_order"
      />
      <MedicineCRUDModal
        modal={modalMedicine}
        toggle={toggleMedicine}
        rudFlag={1}
        options={medicineOptions?.form_schema}
      />
      <BlankModal
        modal={blankModal}
        toggleModal={toggleBlankModal}
        body={<>
          <p>Are you sure to remove this medicine from orders?</p>
          <div className="text-center mt-3">
            <Button color="light" className="btn btn-sm ms-2 waves-effect" onClick={() => {
              toggleBlankModal();
              setMedicines(medicines.filter(item => item.id !== removeMedicineId));
            }}>OK</Button>
            <Button color="light" className="btn btn-sm ms-2 waves-effect" onClick={toggleBlankModal}>CANCEL</Button>
          </div>
        </>}
      />
      <BlankModal
        modal={previewModal}
        toggleModal={togglePreviewModal}
        header={"Preview your order"}
        closable={true}
        body={<div className="accordion accordion-flush" id="orderpreviewaccordion">
          {Object.entries(groupedMedicines).map(([supId, ordMeds]) => (
            <div className="accordion-item" key={supId}>
              <h2 className="accordion-header" id={supId}>
                <button
                  className={classnames(
                    "accordion-button",
                    "fw-medium",
                    { collapsed: !previewAccordionTabState[supId] }
                  )}
                  type="button"
                  onClick={() => {
                    setPreviewAccordionTabState(prevState => ({ ...prevState, [supId]: !previewAccordionTabState[supId] }))
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <p className="me-5">{ordMeds[0].supplier?.name}</p>
                  <p>Total medicines: {ordMeds.length}</p>
                </button>
              </h2>

              <Collapse
                isOpen={previewAccordionTabState[supId]}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  <div className="table-responsive">
                    <Table className="table table-sm m-0">
                      <thead>
                        <tr>
                          <th>Medicine</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ordMeds.map((item, idx) =>
                          <tr key={idx}>
                            <td>{item?.medicine?.name}</td>
                            <th scope="row">{item?.quantity}</th>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Collapse>
            </div>
          ))}
        </div>}
        footer={<>
          {loading ? (
            <Button color="primary" className="btn-sm" disabled>
              SUBMIT
              <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
            </Button>
          ) : (
            <Button color="primary" className="btn-sm" id="sa-success" onClick={placeOrder}>SUBMIT</Button>
          )}
        </>}
      />
    </>
  )
}

export default PlaceOrder
