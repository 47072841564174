import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportDownloadHms } from 'store/actions';
import { handleIncludedKeysError } from 'pages/HMS/common/common';
import { DownloadModal } from 'pages/HMS/common/hms-crud-modals';
import { openPdf, postPdf } from 'helpers/api_helper';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const PreviewPDF = ({ buttonName = "DOWNLOAD REPORT", className, size = "xl", showIcon = true, apiEndpoint, apiStartKey }) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false);

  const { loading, error, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    if (apiKey === `${apiStartKey}_download_report`) {
      setShowModal(true);
    }
    if (error && apiKey.startsWith(`fail_${apiStartKey}_download_report`)) {
      const includedKeys = ['detail']
      handleIncludedKeysError(error, apiKey, `fail_${apiStartKey}_download_report`, includedKeys, false)
    }
  }, [apiKey]);

  const fetchReport = () => {
    dispatch(reportDownloadHms(apiEndpoint, `${apiStartKey}_download_report`))
  }

  return (
    <>
      <div>
        {loading && apiKey === `get_${apiStartKey}_download_report` ? (
          <button type="button" className={className} disabled>
            {buttonName} {" "}<i className="bx bx-loader bx-spin align-middle ms-1"></i>
          </button>
        ) : (
          <button type="button" className={className} onClick={fetchReport}>
            {buttonName} {" "}{showIcon && <i className="mdi mdi-download align-middle ms-1"></i>}
          </button>
        )}
      </div>

      <DownloadModal
        modal={showModal}
        toggle={() => setShowModal(false)}
        apiStartKey={`${apiStartKey}_download_report`}
        size={size}
      />
    </>
  );
}
export default PreviewPDF;


export const ReportDownload = ({ url, payload = false, label = "Download Report", type = "button", color = "primary", disabled = false, showIcon = true, className = window.innerWidth <= 425 ? 'btn-sm me-1' : 'me-1', ...props }) => {
  const [loading, setLoading] = useState(disabled);
  const download = () => {
    setLoading(true);

    const requestFn = payload
      ? () => postPdf(url, payload, { responseType: "blob", method: "POST", headers: { "Content-Type": "application/json" } })
      : () => openPdf(url, { responseType: "blob", method: "GET", headers: { "Content-Type": "application/json" } });

    requestFn()
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        const newTab = window.open();
        if (newTab) {
          newTab.location = url;
          setTimeout(() => window.URL.revokeObjectURL(url), 5000);
        } else {
          toastr.error("Failed to open a file. Please check your browser's pop-up blocker settings.");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {type === "text" ? (
        <>
          {loading ? (
            <p className="text-muted" {...props}> Downloading {showIcon && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>}</p>
          ) : (
            <p className="text-muted" {...props} onClick={!loading ? download : undefined}>{label} {showIcon && <i className="mdi mdi-download align-middle ms-1"></i>}</p>
          )}
        </>
      ) : type === "menu-item" ? (
        <>
          {loading ? (
            <Link className={`disabled text-muted dropdown-item ${className}`} to="#" {...props}>
              {label} {showIcon && <i className="bx bx-loader bx-spin align-middle ms-1" />}
            </Link>
          ) : (
            <Link className={`text-dark dropdown-item ${className}`} to="#" onClick={!loading ? download : undefined} {...props}>
              {label} {showIcon && <i className="mdi mdi-download align-middle ms-1" />}
            </Link>
          )}
        </>
      ) : (
        <div>
          {loading ? (
            <Button
              color={color}
              className={className}
              disabled={loading || disabled}
              {...props}
            >
              {label} {showIcon && <i className="bx bx-loader bx-spin align-middle ms-1"></i>}
            </Button>
          ) : (
            <Button
              color={color}
              className={className}
              onClick={!loading ? download : undefined}
              {...props}
            >
              {label} {showIcon && <i className="mdi mdi-download align-middle ms-1"></i>}
            </Button>
          )}
        </div>
      )}
    </>
  );
};
