import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, getHmsOptions, resetHmsState, deleteHms } from "store/actions"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { isEmpty } from "lodash"
import { MedicineCRUDModal, PharmacyDeleteModal } from "./pharmacy-pharm-crud-modals"
import toastr from "toastr"

const Medicines = props => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const [medicineStockData, setMedicineStockData] = useState({})
  const [columns, setColumns] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [medicine, setMedicine] = useState({})
  const [modalMedicine, setModalMedicine] = useState(false)
  const [rudFlag, setRUDFlag] = useState(null)

  const { loading, options, hmsList, apiKey, error, postResponse } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
  }))

  useEffect(() => {
    if (apiKey === "medicine-stock-list") {
      setMedicineStockData(hmsList)
    }
    if (apiKey === "medicine-stock-refresh") setMedicineStockData(hmsList)
    if (apiKey === "medicine_deleted") {
      setMedicineStockData(hmsList)
      toggleDelete()
      toastr.success("Medicine is deleted successfully")
    }
    if (apiKey === "medicine-created") {
      toastr.success("Medicine created successfully")
      toggleMedicine()
      setMedicine(postResponse)
    }
    if (apiKey === "medicine-updated") {
      toastr.success("Medicine updated successfully")
      setMedicineStockData(hmsList)
      toggleMedicine()
    }
  }, [apiKey, hmsList])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy/medicines/?options=table, search, daterange, formschema", "fetch_medicine_options"))
    if (urlParams.length > 1) {
      dispatch(listHms("/hms/pharmacy/medicines/", decodeURIComponent(urlParams).replace("?", ""), "medicine-stock-list"))
    } else {
      dispatch(listHms("/hms/pharmacy/medicines/", null, "medicine-stock-list"))
    }
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(options)) {
      if (options.table_columns && apiKey === "fetch_medicine_options") {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "name") {
            return {
              ...item,
              formatter: (cellContent, row) => <Link to="#" onClick={() => handleEdit(row)}>{row?.name}</Link >
            }
          } else if (item.dataField === "manufacturer") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.manufacturer || "---"
            }
          } else if (item.dataField === "dosage") {
            return {
              ...item,
              headerStyle: { textTransform: "none" },
              formatter: (cellContent, row) => row?.dosage || "---"
            }
          } else if (item.dataField === "formula") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <div title={row?.formula} className="text-truncate" style={{ width: '150px' }}>
                  {row?.formula || "---"}
                </div>
              )
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "Action",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle
                className="text-muted font-size-16"
                color="white"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link className="dropdown-item" to="#" onClick={() => handleEdit(row)}>
                  Modify
                </Link>
                <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [options])

  const toggleMedicine = (def = modalMedicine) => {
    setModalMedicine(!def)
  }

  const handleCreate = () => {
    setMedicine({})
    setRUDFlag(1)
    toggleMedicine(false)
  }

  const handleEdit = medicine_object => {
    setMedicine(medicine_object)
    setRUDFlag(0)
    toggleMedicine(false)
  }

  const handleDelete = medicine_object => {
    setMedicine(medicine_object)
    toggleDelete(false)
  }

  function toggleDelete(state = true) {
    setDeleteModal(!state)
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/pharmacy/medicines/', decodeURIComponent(urlParams).replace("?", ""), "medicine-stock-refresh"))
      setMedicineStockData({})
    } else {
      dispatch(listHms('/hms/pharmacy/medicines/', null, "medicine-stock-refresh"))
      setMedicineStockData({})
    }
  }

  const createButton = () => {
    return (
      <div className="text-sm-start">
        <Link
          to="#"
          className="me-2 btn btn-primary btn-sm"
          onClick={handleCreate}
        >
          +&nbsp;Add&nbsp;Medicines
        </Link>
      </div>
    );
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Medicines | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {error ?
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
            :
            <Row>
              <Col xs="12">
                {!isEmpty(columns) &&
                  <GenericTable
                    urlParams={urlParams}
                    search={options.search}
                    daterange={options.daterange}
                    filters={options.filters}
                    columns={columns}
                    keyField={"id"}
                    noDataIndication={"No records found"}
                    data={medicineStockData}
                    loading={loading}
                    apiTrigger={apiTrigger}
                    defaultPageSize={options.page_size}
                    createButton={createButton}
                  />
                }
              </Col>
            </Row>
          }
        </Container>
      </div>
      <MedicineCRUDModal
        modal={modalMedicine}
        data={medicine}
        toggle={toggleMedicine}
        rudFlag={rudFlag}
        options={options?.form_schema}
      />
      <PharmacyDeleteModal
        body={<>
          <ul>
            <li>If medicine is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/pharmacy/medicines/", medicine?.id, "medicine_deleted")}
        deleteModal={deleteModal}
      />
    </React.Fragment>
  )
}

export default Medicines
